/**
 * @file
 * Contains navbar finance icon.
 */
import React from 'react';

const NavBarFinanceIcon = (props) => {
  const { color } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
      <path
        d="M5.84243 4.56821L15.8087 1.84783L15.2809 0.78101C14.9356 0.0875795 14.0934 -0.198776 13.4 0.146535L4.47803 4.56821H5.84243Z"
        fill={color}/>
      <path
        d="M18.9025 1.92627C18.779 1.92627 18.6554 1.94311 18.5319 1.9768L16.1905 2.61689L9.04004 4.56804H17.1563H20.6936L20.2556 2.9622C20.0872 2.33615 19.5201 1.92627 18.9025 1.92627Z"
        fill={color}/>
      <path
        d="M22.1561 5.55078H21.836H21.4009H20.9657H17.6446H5.44076H3.84054H2.49298H2.24312H1.40651C0.962942 5.55078 0.567097 5.75572 0.308815 6.07857C0.190904 6.22737 0.101067 6.39862 0.0505334 6.58672C0.0196519 6.70463 0 6.82815 0 6.95449V7.12293V8.72316V22.5946C0 23.3694 0.62886 23.9983 1.40371 23.9983H22.1533C22.9282 23.9983 23.557 23.3694 23.557 22.5946V18.6782H15.2274C13.9107 18.6782 12.8411 17.6086 12.8411 16.2919V15.0061V14.571V14.1358V13.1701C12.8411 12.5244 13.0994 11.9377 13.5177 11.5081C13.8883 11.1263 14.3852 10.868 14.941 10.8034C15.0337 10.7922 15.1291 10.7866 15.2246 10.7866H22.3891H22.8243H23.2594H23.557V6.95449C23.5598 6.17964 22.9309 5.55078 22.1561 5.55078Z"
        fill={color}/>
      <path
        d="M24.5424 12.1285C24.4021 11.9993 24.2364 11.9011 24.0511 11.8365C23.9079 11.7888 23.7564 11.7607 23.5963 11.7607H23.5598H23.5318H23.0966H21.5273H15.2274C14.4526 11.7607 13.8237 12.3896 13.8237 13.1644V13.8635V14.2986V14.7338V16.2891C13.8237 17.0639 14.4526 17.6928 15.2274 17.6928H23.5598H23.5963C23.7564 17.6928 23.908 17.6647 24.0511 17.617C24.2364 17.5552 24.4021 17.4541 24.5424 17.325C24.8232 17.0695 25 16.699 25 16.2891V13.1644C25 12.7545 24.8232 12.3839 24.5424 12.1285ZM18.1584 15.0061C18.1584 15.3935 17.8439 15.7079 17.4565 15.7079H16.9905C16.6031 15.7079 16.2886 15.3935 16.2886 15.0061V14.5401C16.2886 14.3155 16.3925 14.1161 16.5581 13.9898C16.6789 13.8971 16.8277 13.8382 16.9905 13.8382H17.1084H17.4565C17.8439 13.8382 18.1584 14.1526 18.1584 14.5401V15.0061Z"
        fill={color}/>
    </svg>)
};

export default NavBarFinanceIcon;
