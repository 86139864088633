/**
 * @file Contains user permissions util.
 */
import { ACCESS_DATA_MAPPING } from '_common/constants/accessData.mapping';

export const getUserPermissionsUtil = (userPermissions) => {
	return userPermissions.reduce((arr, permission) => {
		ACCESS_DATA_MAPPING?.[permission] ?
			arr = [...arr, ...ACCESS_DATA_MAPPING?.[permission]] :
			arr = [...arr, permission];
		return arr;
	}, []);
};
