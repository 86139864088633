/**
 * @file Contains Switch element.
 */

import React from 'react';
import { Switch } from './SwitchElement.style';

const SwitchElement = (props) => {
	const { ...rest } = props;

	return (
		<Switch {...rest} />
	)
};

export default SwitchElement;
