import styled from 'styled-components';

import { AuthInputPassword } from 'Auth/Auth.style';
import { RecoveryAuthButonSubmit } from '../Recovery.style';

export const NewPasswordAuthInputPassword = styled(AuthInputPassword)`
  width: 100%;
`;

export const NewPasswordAuthButtonSubmit = styled(RecoveryAuthButonSubmit)`
  width: 250px !important;
  margin: 0 auto !important;
`;

export const Error = styled.span`
  color: #dc2626;
  font-weight: 600;
`;
