/**
 * @file
 * Contains Auth Style Component
 */
import styled from 'styled-components';
import React from 'react';
import { Button } from 'antd';

export const MainContainer = styled.div`
  background: ${(props) => props.theme.colors.backgroundMain};
  padding: 100px 131px 0;
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: auto;
`;

export const Title = styled.div`
  font-weight: 800;
  font-size: 24px;
  line-height: 40px;
  letter-spacing: 0.045em;
  color: ${(props) => props.theme.colors.white};
`;

export const Description = styled.div`
  font-weight: 400;
  font-size: 24px;
  line-height: 40px;
  letter-spacing: 0.045em;
  color: ${(props) => props.theme.colors.white};
`;

export const Icon = styled.div`
  width: 140px;
  height: 122px;
  margin: 30px 0 62px;
  
  svg {
    width: 100%;
    height: 100%;
  }
`;

export const DividerLine = styled.div`
  border-top: 1px solid ${(props) => props.theme.colors.white};
  padding-bottom: 96px;
  margin: 0 auto;
  max-width: 1170px;
  width: 100%;
`;

export const Footer = styled.div`
  padding-bottom: 28px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 1170px;
  width: 100%;
`;

export const SubmitButton = styled(Button)`
  border: none;
  background: none !important;
  color: ${(props) => props.theme.colors.white} !important;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0;

  &.ant-btn.ant-btn-loading::before {
    background: none;
  }

  svg {
    margin-left: 10px;
  }
`;

export const FooterWrapper = styled.div`
  margin-top: auto;
`;
