/**
 * @file
 * Contains brand data utils.
 */

import themeConfig from 'config';

/**
 * Get brand data.
 * @param {string} brand
 * Brand name.
 */
export const getBrandData = (brand) => {
  const brandValue = themeConfig.hasOwnProperty(brand) ? brand : 'DEFAULT';
  return themeConfig[brandValue];
};
