import React from 'react';
import {
  AuthRow,
} from './Auth.style';
import AuthScreenAction from '../_common/components/AuthScreensAction/AuthScreenAction';

export const LayoutView = ({ children, type, setType, loading = false }) => {

  return (
    <div>
      <AuthRow>
        {children}
        <AuthScreenAction
          type={type}
          setType={setType}
          loading={loading}
        />
      </AuthRow>
    </div>
  );
};
