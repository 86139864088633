import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Form } from 'antd';
import links from '_common/routes/urls';
import themeConfig from 'config';
import { AuthIconWrapper, AuthRow } from '../../Auth.style';
import {
  VerificationBottomMenu,
  VerificationEmailLabel,
  VerificationFormWrapper,
  VerificationInputsWrapper,
} from '../../Verification/Verification.Style';
import Loader from '_common/components/Loader/Loader';
import {AUTH_TYPES, CREATE_PASSWORD_RULES} from 'Auth/Auth.constants';
import AuthLinks from '../../AuthLinks';
import {
  Error,
  NewPasswordAuthButtonSubmit,
  NewPasswordAuthInputPassword,
} from './NewPassword.style';
import { RecoveryFormItemElement } from '../Recovery.style';
import { useBrowserSettingsFlow } from '_common/hooks/api/kratos/useSettingsFlow.hook';
import { useSettingsMutation } from '_common/hooks/api/kratos/useSettingsMutation.hook';
import { AuthNodes } from '../../Auth.nodes';
import AuthScreenAction from '../../../_common/components/AuthScreensAction/AuthScreenAction';

const theme = themeConfig[process.env.REACT_APP_THEME] || themeConfig.DEFAULT;

const NewPassword = ({ history }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  /**
   * This query creates and fetches the settings flow
   */
  const query = useBrowserSettingsFlow();

  /**
   * Validates that two passwords match
   * @return {{validator(*, *): (Promise<never>)}}
   */
  const validatePasswordsMatch = () => ({
    validator(_, value) {
      if (value !== form.getFieldValue('password'))
        return Promise.reject('the passwords do not match');
      return Promise.resolve();
    },
  });

  /**
   * This mutation changes the user's password
   */
  const mutation = useSettingsMutation({
    onSuccess: () => {
      history.push(links.dashboard);
    },
    flowId: query?.data?.id,
  });

  /**
   *
   * @type {string[]}
   */
  const passwordMessages = useMemo(() => {
    const ui = query.data?.ui;
    if (!ui) return [];

    const passwordNode = ui.nodes.find((node) => node.attributes.name === 'password');
    const message = passwordNode.messages.find((message) => message.text);
    return message ? [message.text] : [];
  }, [query]);

  const hiddenNodes = useMemo(() => {
    const ui = query.data?.ui;
    if (!ui) return [];

    return ui.nodes.filter((node) => node.attributes.type === 'hidden');
  }, [query]);

  if (query.isLoading) {
    return <Loader />;
  }
  return (
    <div>
      <AuthRow>
        <Form layout="vertical" form={form} style={{ width: '40%' }} onFinish={mutation.mutate}>
          <VerificationFormWrapper>
            <AuthIconWrapper>
              <theme.assets.icons.loginLogo />
            </AuthIconWrapper>
            <VerificationInputsWrapper>
              <VerificationEmailLabel>{t('recovery.password.cta')} </VerificationEmailLabel>

              <AuthNodes nodes={hiddenNodes} />

              <RecoveryFormItemElement rules={CREATE_PASSWORD_RULES(t)} name="password">
                <NewPasswordAuthInputPassword placeholder={t('recovery.placeholder.password')} />
              </RecoveryFormItemElement>
              <RecoveryFormItemElement rules={[validatePasswordsMatch]} name="confirm-password">
                <NewPasswordAuthInputPassword
                  placeholder={t('recovery.placeholder.password.confirm')}
                />
              </RecoveryFormItemElement>
              {passwordMessages.map((message) => (
                <Error key={message}>{message}</Error>
              ))}
            </VerificationInputsWrapper>
            <VerificationBottomMenu>
              <Form.Item noStyle name="method" initialValue="password">
                <NewPasswordAuthButtonSubmit htmlType="submit" loading={mutation.isLoading}>
	                {t('recovery.password.cta.changePassword')}
                </NewPasswordAuthButtonSubmit>
              </Form.Item>
            </VerificationBottomMenu>
          </VerificationFormWrapper>
          <AuthLinks />
        </Form>
        <AuthScreenAction type={AUTH_TYPES.login} noRegBtn={true}/>
      </AuthRow>
    </div>
  );
};

export default NewPassword;
