/**
 * @file
 * Contains report store.
 */

import { observable, action, computed } from 'mobx';
import { TRANSACTIONS_TABLE_CONFIG } from '_common/constants/reports.constant';
import ReportService from '_common/services/report.service';

const { startPage, startOffset, defaultResultsByValue, defaultViewMode } = TRANSACTIONS_TABLE_CONFIG;

class reportStore {
  /**
   * Period data.
   */
  @observable
  period = {};

  /**
   * Current page.
   */
  @observable
  currentPage = startPage;

  /**
   * Is table loading.
   */
  @observable
  isTableLoading = false;

  /**
   * Table type.
   */
  @observable
  tableType = defaultViewMode;

  /**
   * Results by value.
   */
  @observable
  resultsByValue = defaultResultsByValue;

  /**
   * Results by value.
   */
  @observable
  currentOffset = startOffset;

  /**
   * Order by.
   */
  @observable
  orderBy = {};

  /**
   * Searchbar form by.
   */
  @observable
  searchBarForm = {};

  /**
   * Table request data.
   */
  @observable
  tableRequestData = {};


  /**
   * Get transactions table period.
   */
  @computed
  get getPeriod() {
    return this.period;
  }

  /**
   * Get current page.
   */
  @computed
  get getCurrentPage() {
    return this.currentPage;
  }

  /**
   * Get is table loading.
   */
  @computed
  get getIsTableLoading() {
    return this.isTableLoading;
  }

  /**
   * Get table type.
   */
  @computed
  get getTableType() {
    return this.tableType;
  }

  /**
   * Get results by value.
   */
  @computed
  get getResultsByValue() {
    return this.resultsByValue;
  }

  /**
   * Get current offset.
   */
  @computed
  get getCurrentOffset() {
    return this.currentOffset;
  }

  /**
   * Get order by.
   */
  @computed
  get getOrderBy() {
    return this.orderBy;
  }

  /**
   * Get order by.
   */
  @computed
  get getSearchBarForm() {
    return this.searchBarForm;
  }

  /**
   * Get table request data.
   */
  @computed
  get getTableRequestData() {
    return this.tableRequestData;
  }

  /**
   * Set transactions table period.
   * @param {number} from.
   * Period timestamp from.
   * @param {number} to.
   * Period timestamp to.
   */
  @action
  setPeriod = (from, to) => {
    this.period = { from, to };
  };

  /**
   * Set current page.
   * @param {number} page.
   * Current page number.
   */
  @action
  setCurrentPage = (page) => {
    this.currentPage = page;
  };

  /**
   * Set is table loading.
   * @param {boolean} status.
   * Status flag.
   */
  @action
  setIsTableLoading = (status) => {
    this.isTableLoading = status;
  };

  /**
   * Set table type.
   * @param {string} type.
   * Table type.
   */
  @action
  setTableType = (type) => {
    this.tableType = type;
  };

  /**
   * Set results by value.
   * @param {string} value.
   * Value.
   */
  @action
  setResultsByValue = (value) => {
    this.resultsByValue = value;
  };

  /**
   * Set current offset.
   * @param {string} value.
   * Offset.
   */
  @action
  setCurrentOffset = (value) => {
    this.currentOffset = value;
  };

  /**
   * Set order by.
   * @param {string} value.
   * Order by.
   */
  @action
  setOrderBy = (value) => {
    this.orderBy = value;
  };

  /**
   * Set order by.
   * @param {Object} form.
   * Order by.
   */
  @action
  setSearchBarForm = (form) => {
    this.searchBarForm = form;
  };

  /**
   * Set table request data.
   * @param {Object} data.
   * Table data.
   */
  @action
  setTableRequestData = (data) => {
    this.tableRequestData = data;
  }

	/**
	 * Transaction refund.
	 *
	 * @async
	 * @function - transactionRefund.
	 * @param {string} profileId - merchant guid.
	 * @param {string} transactionId - transaction guid.
	 * @param {Object} data - reason of refund.
	 * @param {string} twoFactorAuthToken - two factor auth token.
	 */
	@action
	transactionRefund = async (profileId, transactionId, data, twoFactorAuthToken) => {
		try {
			return await ReportService.transactionRefund(profileId, transactionId, data, twoFactorAuthToken);
		} catch (e) {
			if (e.message === '2FA needed') {
				throw new Error(JSON.stringify(e.data['2fa_token']));
			} else {
				throw new Error(e);
			}
		}
	};

	/**
	 * Transaction refund rates.
	 *
	 * @async
	 * @function - getTransactionRefundRates.
	 * @param {string} profileId - merchant guid.
	 * @param {string} transactionId - transaction id.
	 * @param {number} amount - refund amount.
	 */
	@action
	getTransactionRefundRates = async (profileId, transactionId, amount) => {
		try {
			return await ReportService.transactionRefundRates(profileId, transactionId, amount);
		} catch (e) {
			throw new Error(e);
		}
	};

	/**
	 * Get available refund amount.
	 *
	 * @async
	 * @function - getAvailableRefundAmount.
	 * @param {string} profileId - merchant guid.
	 * @param {string} transactionId - transaction id.
	 */
	@action
	getAvailableRefundAmount = async (profileId, transactionId, amount) => {
		try {
			return await ReportService.getAvailableRefundAmount(profileId, transactionId);
		} catch (e) {
			throw new Error(e);
		}
	};
}

export default reportStore;
