/**
 * @file
 * Contains Dashboard graphics component.
 */
import React, { useState } from 'react';
import { Col, Row } from 'antd';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import DashboardWeeklyReport from './DashboardWeeklyReport';
import AmountGraphic from './DepositGraphic';
import MethodDiagram from './MethodDiagram';
import QuantityGraphic from './AverageBillGraphic';
import { DashboardGraphicsWrapper, DashboardRow, DashboardCol } from './Dashboard.style';
import ConversionGraphic from './ConversionGraphic';
import PermissionsGate from '_common/components/PermissionsGate/PermissionsGate';

const DashboardGraphics = ({ profileStore }) => {
  /**
   * Toggle Report State.
   */
  const [showReport, setShowReport] = useState(true);

  /**
   * Toggle Report Handler.
   */
  const toggleReportHandler = () => {
    setShowReport(!showReport);
  };

  return (
    <DashboardGraphicsWrapper showReport={showReport}>
      <DashboardRow>
        {/*<PermissionsGate*/}
        {/*  scopes={['merchant.finances.dashboard.transactions.read']}*/}
        {/*>*/}
        {/*  <DashboardCol xl={8} xxl={8}>*/}
        {/*    <MethodDiagram />*/}
        {/*  </DashboardCol>*/}
        {/*</PermissionsGate>*/}
        <PermissionsGate
          scopes={['merchant.finances.dashboard.weekly.read']}
        >
          <DashboardCol xl={8} xxl={8}>
            <DashboardWeeklyReport toggleReport={toggleReportHandler} showReport={showReport} selectedProfileId={profileStore.getSelectedProfileId} />
          </DashboardCol>
        </PermissionsGate>
      </DashboardRow>
      {/*<PermissionsGate*/}
      {/*  scopes={['merchant.finances.dashboard.transactions.read']}*/}
      {/*>*/}
      {/*  <Row gutter={[30, 30]}>*/}
      {/*    <Col xs={24}>*/}
      {/*      <AmountGraphic />*/}
      {/*    </Col>*/}
      {/*  </Row>*/}
      {/*</PermissionsGate>*/}
      {/*<PermissionsGate*/}
      {/*  scopes={['merchant.finances.dashboard.transactions.read']}*/}
      {/*>*/}
      {/*  <Row gutter={[30, 30]}>*/}
      {/*    <Col xs={24}>*/}
      {/*      <QuantityGraphic />*/}
      {/*    </Col>*/}
      {/*  </Row>*/}
      {/*</PermissionsGate>*/}
      {/*<PermissionsGate*/}
      {/*  scopes={['merchant.finances.dashboard.transactions.read']}*/}
      {/*>*/}
      {/*  <Row gutter={[30, 30]}>*/}
      {/*    <Col xs={24}>*/}
      {/*      <ConversionGraphic />*/}
      {/*    </Col>*/}
      {/*  </Row>*/}
      {/*</PermissionsGate>*/}
    </DashboardGraphicsWrapper>
  );
};

export default compose(inject('profileStore'))(observer(DashboardGraphics));
