/**
 * @file
 * Contains No Data Diagram Component.
 */
import React from 'react';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import themeConfig from 'config';
import { NoDataDiagramWrap, NoDataTitleWrap, NoDataTitle, NoDataSubTitle, NoDataImgWrap } from './NoDataDiagram.style';
import NoDataIcon from '_common/assets/renderSvgIcons/NoDataIcon';

const NoDataDiagram = (props) => {
  const { t } = props;

  /**
   * App Theme
   */
  const theme = themeConfig[process.env.REACT_APP_THEME] || themeConfig.DEFAULT;

  return (
    <NoDataDiagramWrap>
      <NoDataTitleWrap>
        <NoDataTitle>{t('dashboard.graphic.noData.title')}</NoDataTitle>
        <NoDataSubTitle>{t('dashboard.graphic.noData.subTitle')}</NoDataSubTitle>
      </NoDataTitleWrap>
      <NoDataImgWrap>
        <NoDataIcon />
      </NoDataImgWrap>
    </NoDataDiagramWrap>
  );
};

export default compose(withTranslation())(observer(NoDataDiagram));
