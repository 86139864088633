import React from 'react';
import { useTranslation } from 'react-i18next';
import themeConfig from 'config';
import FormItemElement from '_common/components/FormItem/FormItemElement';
import { EMAIL_RULES, PASSWORD_RULES } from './Auth.constants';
import {
  AuthButtonSubmit,
  AuthFormItemWrapper,
  AuthFormWrapper,
  AuthIconWrapper,
  AuthInput,
  AuthInputPassword,
  AuthLoginTab,
  AuthLoginTabs,
  ForgotPasswordContainer,
  ForgotPasswordLink,
  LoginWrapper,
  SubTabTitle,
} from './Auth.style';
import AuthLinks from './AuthLinks';

const theme = themeConfig[process.env.REACT_APP_THEME] || themeConfig.DEFAULT;

export const Login = ({ enabled, loading, history }) => {
  const { t } = useTranslation();

  const items = [
    {
      key: 'merchant',
      label: t('questionnaire.login.tab.merchantAccount'),
      children: <>
        {theme?.name === 'default' && <SubTabTitle>{t('login.sub.title.test')}</SubTabTitle>}
        <AuthFormItemWrapper>
          <FormItemElement rules={EMAIL_RULES(t)} name="identifier" normalize={(value) => value.trim()}>
            <AuthInput placeholder={t('login.placeholder.login.details')} />
          </FormItemElement>
        </AuthFormItemWrapper>
        <AuthFormItemWrapper>
          <FormItemElement rules={PASSWORD_RULES(t)} name="password">
            <AuthInputPassword placeholder={t('login.placeholder.password')} />
          </FormItemElement>
        </AuthFormItemWrapper>
        <ForgotPasswordContainer>
          <ForgotPasswordLink
            onClick={() => {
              history.push('/auth/verification');
            }}
          >
            {t('login.links.verification', 'Verify your email')}
          </ForgotPasswordLink>
          <ForgotPasswordLink
            onClick={() => {
              history.push('/auth/recovery');
            }}
          >
            {t('login.links.recovery', 'Forgot password?')}
          </ForgotPasswordLink>
        </ForgotPasswordContainer>
        <AuthButtonSubmit loading={loading} htmlType="submit" themename={theme?.name}>
          {t('login.button.login')}
        </AuthButtonSubmit>
      </>,
    },
  ];

  return (
    <LoginWrapper showLogin={enabled}>
      <AuthFormWrapper>
        <AuthIconWrapper>
          <theme.assets.icons.loginLogo />
        </AuthIconWrapper>
        <AuthLoginTabs items={items}/>
      </AuthFormWrapper>
      {theme?.authLinks && <AuthLinks />}
    </LoginWrapper>
  );
};
