/**
 * @file
 * Contains crypto icon.
 */
import React from 'react';

const CryptoIcon = (props) => {
  const { color } = props;

  return (
    <svg width="49" height="49" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M43.7823 20.6719H39.4963L37.9651 22.2031H34.1768C34.2947 22.7008 34.3751 23.2115 34.4149 23.7344H38.599L40.1303 22.2031H43.7823C44.0993 23.092 44.9414 23.7344 45.9383 23.7344C47.2046 23.7344 48.2352 22.7038 48.2352 21.4375C48.2352 20.1712 47.2046 19.1406 45.9383 19.1406C44.9414 19.1406 44.0993 19.783 43.7823 20.6719Z" fill={color ? color : 'black'} />
      <path d="M45.9383 25.2656C44.9414 25.2656 44.0993 25.908 43.7823 26.7969H40.1303L38.599 25.2656H34.4149C34.3751 25.7885 34.2939 26.2992 34.1768 26.7969H37.9651L39.4963 28.3281H43.7823C44.0993 29.217 44.9414 29.8594 45.9383 29.8594C47.2046 29.8594 48.2352 28.8288 48.2352 27.5625C48.2352 26.2962 47.2046 25.2656 45.9383 25.2656Z" fill={color ? color : 'black'}/>
      <path d="M20.6719 5.2185V9.50447L22.2031 11.0357V14.824C22.7008 14.7061 23.2115 14.6257 23.7344 14.5859V10.4018L22.2031 8.87053V5.2185C23.092 4.90153 23.7344 4.05934 23.7344 3.0625C23.7344 1.79616 22.7038 0.765625 21.4375 0.765625C20.1712 0.765625 19.1406 1.79616 19.1406 3.0625C19.1406 4.05934 19.783 4.90153 20.6719 5.2185Z" fill={color ? color : 'black'}/>
      <path d="M5.2185 28.3281H9.50447L11.0357 26.7969H14.824C14.7061 26.2992 14.6257 25.7885 14.5859 25.2656H10.4018L8.87053 26.7969H5.2185C4.90153 25.908 4.05934 25.2656 3.0625 25.2656C1.79616 25.2656 0.765625 26.2962 0.765625 27.5625C0.765625 28.8288 1.79616 29.8594 3.0625 29.8594C4.05934 29.8594 4.90153 29.217 5.2185 28.3281Z" fill={color ? color : 'black'}/>
      <path d="M3.0625 23.7344C4.05934 23.7344 4.90153 23.092 5.2185 22.2031H8.87053L10.4018 23.7344H14.5859C14.6257 23.2115 14.7069 22.7008 14.824 22.2031H11.0357L9.50447 20.6719H5.2185C4.90153 19.783 4.05934 19.1406 3.0625 19.1406C1.79616 19.1406 0.765625 20.1712 0.765625 21.4375C0.765625 22.7038 1.79616 23.7344 3.0625 23.7344Z" fill={color ? color : 'black'}/>
      <path d="M28.3281 43.7823V39.4963L26.7969 37.9651V34.1768C26.2992 34.2947 25.7885 34.3751 25.2656 34.4149V38.599L26.7969 40.1303V43.7823C25.908 44.0993 25.2656 44.9414 25.2656 45.9383C25.2656 47.2046 26.2962 48.2352 27.5625 48.2352C28.8288 48.2352 29.8594 47.2046 29.8594 45.9383C29.8594 44.9414 29.217 44.0993 28.3281 43.7823Z" fill={color ? color : 'black'}/>
      <path d="M9.1875 16.0781C12.9865 16.0781 16.0781 12.9865 16.0781 9.1875C16.0781 5.38847 12.9865 2.29688 9.1875 2.29688C5.38847 2.29688 2.29688 5.38847 2.29688 9.1875C2.29688 12.9865 5.38847 16.0781 9.1875 16.0781ZM9.95312 9.95312H8.42188C7.15553 9.95312 6.125 8.92259 6.125 7.65625V5.35938H8.42188V3.82812H9.95312V5.35938C11.2195 5.35938 12.25 6.38991 12.25 7.65625H10.7188C10.7188 7.23439 10.375 6.89062 9.95312 6.89062H7.65625V7.65625C7.65625 8.07811 8.00002 8.42188 8.42188 8.42188H9.95312C11.2195 8.42188 12.25 9.45241 12.25 10.7188V13.0156H9.95312V14.5469H8.42188V13.0156C7.15553 13.0156 6.125 11.9851 6.125 10.7188H7.65625C7.65625 11.1406 8.00002 11.4844 8.42188 11.4844H10.7188V10.7188C10.7188 10.2969 10.375 9.95312 9.95312 9.95312Z" fill={color ? color : 'black'}/>
      <path d="M9.1875 32.9219C5.38847 32.9219 2.29688 36.0135 2.29688 39.8125C2.29688 43.6115 5.38847 46.7031 9.1875 46.7031C12.9865 46.7031 16.0781 43.6115 16.0781 39.8125C16.0781 36.0135 12.9865 32.9219 9.1875 32.9219ZM11.4844 35.9844H9.95312C8.68678 35.9844 7.65625 37.0149 7.65625 38.2812V39.0469H9.1875V40.5781H7.65625V43.6406H11.4844V45.1719H6.125V40.5781H4.59375V39.0469H6.125V38.2812C6.125 36.1704 7.8423 34.4531 9.95312 34.4531H11.4844V35.9844Z" fill={color ? color : 'black'}/>
      <path d="M24.5 16.0781C19.8565 16.0781 16.0781 19.8565 16.0781 24.5C16.0781 29.1435 19.8565 32.9219 24.5 32.9219C29.1435 32.9219 32.9219 29.1435 32.9219 24.5C32.9219 19.8565 29.1435 16.0781 24.5 16.0781ZM28.3281 26.7969C28.3281 28.4858 26.9546 29.8594 25.2656 29.8594V31.3906H23.7344V29.8594H22.2031V19.1406H23.7344V17.6094H25.2656V19.1406C26.9546 19.1406 28.3281 20.5142 28.3281 22.2031C28.3281 23.1165 27.9262 23.9388 27.2892 24.5C27.9262 25.0612 28.3281 25.8835 28.3281 26.7969Z" fill={color ? color : 'black'}/>
      <path d="M25.2656 25.2656H23.7344V28.3281H25.2656C26.1101 28.3281 26.7969 27.6414 26.7969 26.7969C26.7969 25.9524 26.1101 25.2656 25.2656 25.2656Z" fill={color ? color : 'black'}/>
      <path d="M25.2656 20.6719H23.7344V23.7344H25.2656C26.1101 23.7344 26.7969 23.0476 26.7969 22.2031C26.7969 21.3586 26.1101 20.6719 25.2656 20.6719Z" fill={color ? color : 'black'}/>
      <path d="M39.8125 16.0781C43.6115 16.0781 46.7031 12.9865 46.7031 9.1875C46.7031 5.38847 43.6115 2.29688 39.8125 2.29688C36.0135 2.29688 32.9219 5.38847 32.9219 9.1875C32.9219 12.9865 36.0135 16.0781 39.8125 16.0781ZM35.2188 9.95312H36.75V8.42188H35.2188V6.89062H36.75C36.75 5.20166 38.1235 3.82812 39.8125 3.82812C41.5015 3.82812 42.875 5.20166 42.875 6.89062H41.3438C41.3438 6.04614 40.657 5.35938 39.8125 5.35938C38.968 5.35938 38.2812 6.04614 38.2812 6.89062H39.8125V8.42188H38.2812V9.95312H39.8125V11.4844H38.2812C38.2812 12.3289 38.968 13.0156 39.8125 13.0156C40.657 13.0156 41.3438 12.3289 41.3438 11.4844H42.875C42.875 13.1733 41.5015 14.5469 39.8125 14.5469C38.1235 14.5469 36.75 13.1733 36.75 11.4844H35.2188V9.95312Z" fill={color ? color : 'black'}/>
      <path d="M39.8125 32.9219C36.0135 32.9219 32.9219 36.0135 32.9219 39.8125C32.9219 43.6115 36.0135 46.7031 39.8125 46.7031C43.6115 46.7031 46.7031 43.6115 46.7031 39.8125C46.7031 36.0135 43.6115 32.9219 39.8125 32.9219ZM42.1094 39.0469V40.5781H40.5781V42.1094H42.1094V43.6406H40.5781V45.1719H39.0469V43.6406H37.5156V42.1094H39.0469V40.5781H37.5156V39.0469H39.0469V38.5982L36.2087 35.76L37.2913 34.6775L39.8125 37.1987L42.3337 34.6775L43.4163 35.76L40.5781 38.5982V39.0469H42.1094Z" fill={color ? color : 'black'}/>
      <path d="M26.7969 11.0357L28.3281 9.50447V5.2185C29.217 4.90153 29.8594 4.05934 29.8594 3.0625C29.8594 1.79616 28.8288 0.765625 27.5625 0.765625C26.2962 0.765625 25.2656 1.79616 25.2656 3.0625C25.2656 4.05934 25.908 4.90153 26.7969 5.2185V8.87053L25.2656 10.4018V14.5859C25.7885 14.6257 26.2992 14.7069 26.7969 14.824V11.0357Z" fill={color ? color : 'black'}/>
      <path d="M22.2031 37.9651L20.6719 39.4963V43.7823C19.783 44.0993 19.1406 44.9414 19.1406 45.9383C19.1406 47.2046 20.1712 48.2352 21.4375 48.2352C22.7038 48.2352 23.7344 47.2046 23.7344 45.9383C23.7344 44.9414 23.092 44.0993 22.2031 43.7823V40.1303L23.7344 38.599V34.4149C23.2115 34.3751 22.7008 34.2939 22.2031 34.1768V37.9651Z" fill={color ? color : 'black'}/>
    </svg>
  )
};

export default CryptoIcon;

