/**
 * @file
 * Contains Reports Component styles.
 */
import styled, { css } from 'styled-components';
import { Input, Table, Radio } from 'antd';

export const ReportsContainer = styled.div`
  align-items: center;
  margin-top: 40px;
  
  .ant-tabs-nav {
    margin-bottom: 45px;
    
    &:before {
      border: none;
    }
    .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
      background: ${(props) => props.theme.colors.mainColorDark} !important;
    }
    .ant-tabs-tab {
      &:hover {
        color: ${(props) => props.theme.colors.mainColorDark} !important;
      }
      &.ant-tabs-tab-active {
        .ant-tabs-tab-btn {
          color: ${(props) => props.theme.colors.mainColorDark} !important;
        }
      }
    }
  }
`;

export const TransactionFiltersWrapper = styled.div`
  margin-bottom: 20px;
  
  @media (min-width: 1400px) {
    margin-bottom: 24px;
  }
`;

export const CancelStatusWrap = styled.span`
  position: relative;
  
  svg {
    position: absolute;
    left: calc(100% + 2px);
  }
`;

export const ReportsTableWrapper = styled.div`
  
`;

export const ImageMethod = styled.img`
  height: 12px;
  width: auto;
`;

export const TableSummaryRow = styled(Table.Summary.Row)`
  background: ${(props) => props.theme.colors.darkLight};
  width: 100%;
`;

export const TableSummaryCell = styled(Table.Summary.Cell)`
  font-size: 14px;
  font-weight: 700;
  color: ${(props) => props.theme.colors.white};
  text-transform: uppercase;
  text-align: center;
`;

export const TableSummaryTransCell = styled(Table.Summary.Cell)`
  text-align: left;
  font-size: 14px;
  font-weight: 700;
  color: ${(props) => props.theme.colors.white};
  text-transform: uppercase;
   
   span {
     font-weight: 200;
     margin-right: 6px;
   }
`;

export const DownloadLink = styled.div`
  width: 26px;
  height: 26px;
  cursor: pointer;
  margin-left: auto;

  svg {
    width: 26px;
    height: 26px;
    display: block;
  }
  
  .ant-spin-nested-loading > div > .ant-spin {
    top: 24px;
  }
`;

export const DownloadCsvLink = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.colors.mainColor};

  svg {
    fill: ${(props) => props.theme.colors.mainColor};
    margin-right: 8px;
    display: block;
    width: 16px;
    height: 16px;
  }
`;

export const PaginationStyle = styled.div`
  margin-top: 20px;
  text-align: center;
  
  .ant-pagination-item {
    border: none;
    width: 30px;
    height: 30px;
    
    a {
      &:hover {
        color: ${(props) => props.theme.colors.darkLight};
      }
    }
  }
  
  .ant-pagination-prev, .ant-pagination-next {
    color: ${(props) => props.theme.colors.darkLight};
  }
  
  .ant-pagination-prev .ant-pagination-item-link, .ant-pagination-next .ant-pagination-item-link {
    border: none;
  }
  
  .ant-pagination-item-active {
    border-radius: 50%;
    background: ${(props) => props.theme.colors.darkLight};
    
    &:hover {
      color: ${(props) => props.theme.colors.white};
    }
    
    & a {
      font-weight: 700;
      color: ${(props) => props.theme.colors.white};
      
      &:focus {
        color: ${(props) => props.theme.colors.white};
      }
    }
  }
  
  .ant-pagination-item-active:focus a, .ant-pagination-item-active:hover a {
    color: ${(props) => props.theme.colors.white};
    border: none;
  }
`;

export const UserColumn = styled.span`
  text-transform: capitalize;
`;

export const TransactionsPaginationButton = styled.div`
  position: relative;
  ${(props) => props.direction === 'prev' && css `
    svg {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: -16px;
    }
  `}
  
  ${(props) => props.direction === 'next' && css `
    svg {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: -16px;
    }   
  `}
`;

export const TransactionRegistryDownloadLink = styled.div`
  padding: 0 45px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
`;

export const TransactionRegistryDownloadLinkIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  
  svg {
    width: 16px !important;
    margin-right: 8px !important;
  }
`;

export const TransactionRegistryDownloadLinkText = styled.div`
  color: ${(props) => props.theme.colors.mainColor};
`;

export const TransactionRegistryDownloadTitle = styled.div`
  color: ${(props) => props.theme.colors.lightBlack};
`;

export const ShowMoreButtonWrapperStyle = styled.div`
  max-width: 160px;
  width: 100%;
  height: 28px;
  margin: 20px auto;
`;

export const TableTop = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: flex-end;
  margin: 0 0 12px;
  
  svg path{
    fill: ${(props) => props.theme.colors.grey}; 
  }
`;

export const Notes = styled.div`
  max-width: 450px;
  color: ${(props) => props.theme.colors.darkGrey};
  font-size: 12px;
  line-height: 1.4;
  font-weight: 300;
`;

export const TabLabelContainer = styled.span`
  display: flex;
  
  svg {
    margin-right: 5px;
  }
`;

export const SearchInput = styled(Input)`
  padding: 0 7px;
  font-weight: 500;
  color: ${(props) => props.theme.colors.darkGrey};
  font-size: 12px;
  line-height: 26px;
  border-radius: 5px;
  border-color: ${(props) => props.theme.colors.darkGrey};
  background: ${(props) => props.theme.colors.extraLightGrey};
  
  &.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper {
    background: ${(props) => props.theme.colors.extraLightGrey};
  }
  
  &:hover {
    border-color: ${(props) => props.theme.colors.darkGrey} !important;
  }
  &:focus {
    box-shadow: none;
  }
  input {
    background-color: ${(props) => props.theme.colors.extraLightGrey};
    &::placeholder {
      color: ${(props) => props.theme.colors.darkGrey};
      font-weight: 500;
      font-size: 12px;
    }
    &::-webkit-input-placeholder {
      color: ${(props) => props.theme.colors.darkGrey};
    }
    &:-moz-placeholder {
      color: ${(props) => props.theme.colors.darkGrey};
    }
    &::-moz-placeholder {
      color: ${(props) => props.theme.colors.darkGrey};
    }
    &:-ms-input-placeholder {
      color: ${(props) => props.theme.colors.darkGrey};
    }
    &:-webkit-autofill,
    &:-webkit-autofill:focus {
      transition: background-color 0s 600000s, color 0s 600000s !important;
    }
  }
`;

export const RadioGroupWrap = styled(Radio.Group)`
  display: flex;

  .ant-radio-button-wrapper {
    height: 40px;
    line-height: 40px;
    border-radius: 4px;
    border-color: ${(props) => props.theme.colors.darkGrey};
    
    &:first-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    &:last-child {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
    }
    &:hover {
      color: ${(props) => props.theme.colors.mainColor};
    }
    &.ant-radio-button-wrapper-checked {
      color: ${(props) => props.theme.colors.white};
      background: ${(props) => props.theme.colors.mainColor};
      border-color: ${(props) => props.theme.colors.mainColor};
      
      &:hover {
        color: inherit;
      }
      &::before {
        display: none;
      }
      &:first-child {
        border-color: ${(props) => props.theme.colors.mainColor} !important;
      }
    }
  }

  .ant-radio-button {
    &.ant-radio-button-checked {

    }
  }
`;

export const RefundTableWrap = styled.div`
  position: relative;
  padding: 7px 0 16px 11%;
  
  &:before {
    content: '';
    position: absolute;
    display: block;
    background-color: ${(props) => props.theme.colors.iconColor};
    height: 1px;
    width: calc(100% - 13%);
    left: 11%;
    top: -3px;
  }
  
  .ant-table-wrapper {
    width: fit-content;
  }
`;

export const LineTo = styled.div`
  position: absolute;
  display: block;
  background-color: ${(props) => props.theme.colors.iconColor};
  width: 8%;
  height: 1px;
  left: 1.6%;
  top: 35px;

  &:before {
    content: '';
    position: absolute;
    display: block;
    background-color: ${(props) => props.theme.colors.iconColor};
    width: 1px;
    height: 45px;
    left: 0;
    top: -44px;
  }
`;

export const LineArrow = styled.div`
  &:before,
  &:after {
    content: '';
    position: absolute;
    display: block;
    background-color: ${(props) => props.theme.colors.iconColor};
    width: 5px;
    height: 1px;
  }

  &:before {
    transform: rotate(45deg);
    top: -2px;
    right: 0;
  }

  &:after {
    transform: rotate(-45deg);
    top: 2px;
    right: 0;
  }
`;

export const RefundTableDataId = styled.div`
	display: flex;
	align-items: center;
	text-align: center;
	justify-content: center;
`;

export const RefundTableDataIdCopyToClipboardContainer = styled.div`
	margin-left: 6px;
	cursor: pointer;
`;

export const ReportsTableTitleContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
`;

export const TableColumnsSettingsWrap = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 35px;
  
  svg {
    height: auto;
    width: 35px;
  }
`;

export const ReportFilterModalButtonWrapper = styled.div`
	width: 50%;
	text-align: center;
`;

export const TableTransactionIdColumn = styled.div`
	display: inline-flex;
	align-items: center;
`;

export const TableTransactionIdColumnContainer = styled.div`
	align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
	width: ${(props) => props.isOpen ? '260px' : '140px'};
	
	p {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
		margin-bottom: 0;
		text-align: left;
	}
`;

export const TableTransactionIdColumnCopyContainer = styled.div`
	display: flex;
	align-items: center;
	cursor: pointer;
	margin-left: 4px;
`;

export const TableTransactionIdColumnVisibleContainer = styled.div`
	display: flex;
	align-items: center;
  cursor: pointer;
  margin-left: 4px;
`;

export const TableDropdownMenuContainer = styled.div`
	cursor: pointer;
`;

export const RefundTransactionInfoContainer = styled.div`
	background: ${(props) => props.theme.colors.lightestGrey};
	padding: 40px;
	border-radius: 15px;
	margin-bottom: 25px;
	font-size: 16px;
`;

export const RefundTransactionInfo = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	
	div:last-child {
		font-size: 16px;
		line-height: 23px;
		text-transform: ${(props) => props.refundTransactionKey === 'transaction_id' ? 'lowercase' : 'capitalize'};
	}
`;

export const RefundTransactionInfoTitle = styled.div`
	font-weight: 700;
`;

export const RefundTransactionInfoValue = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: ${(props) => props.fontWeight ? props.fontWeight : '400'};
  line-height: 140%;
	display: flex;
	align-items: center;
	
	div {
		margin-left: ${(props) => props.ml ? `${props.ml}px` : '0'} !important;
	}
	
	& > .ant-form-item-has-error {
		input {
      border-top: 1px solid ${(props) => props.theme.colors.red};
      border-left: 1px solid ${(props) => props.theme.colors.red};
      border-bottom: 1px solid ${(props) => props.theme.colors.red};
		}
    .ant-form-item-explain-error {
      margin-top: 10px;
			text-align: right;
      font-size: 10px;
      color: ${(props) => props.theme.colors.red};
    }
	}

  & > .ant-form-item-has-success {
    .ant-form-item-explain {
	    text-align: right;
      margin-top: 10px;
	    font-size: 10px;
      color: ${(props) => props.theme.colors.red};
    }
  }
	
	svg {
		margin-left: 4px;
		cursor: pointer;
		
		path {
			fill: ${(props) => props.theme.colors.mainColor};
		}
	}
`;

export const RefundAvailableAmount = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
	border-top: 1px solid ${(props) => props.theme.colors.black};
	margin-top: 20px;
	padding-top: 10px;
  font-size: 16px;
`;

export const RefundTransactionButtonsContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	
	button {
		width: 278px !important;
		font-size: 16px !important;
	}
`;

export const RefundTransactionWrapper = styled.div`
	padding: 21px;
`;

export const RefundTransactionTitle = styled.div`
	font-size: 20px;
	font-weight: 700;
	line-height: 28px;
	margin-bottom: 10px;
`;

export const RefundTransactionDescription = styled.div`
	color: ${(props) => props.grey ? props.theme.colors.darkestGrey : props.theme.colors.black};
	margin-bottom: ${(props) => props.mb ? `${props.mb}px` : '10px'};
	font-size: 14px;
	font-weight: 400;
	line-height: 20px;
	transition: all .5s ease;
	
	span {
		font-weight: 700;
	}
`;

export const RefundTransactionFeeDescription = styled.div`
	color: ${(props) => props.grey ? props.theme.colors.darkestGrey : props.theme.colors.black};
	margin-bottom: 30px;
	font-size: 14px;
	font-weight: 400;
	line-height: 20px;
	background: ${(props) => props.theme.colors.alertBackgroundColor};
	border: 1px solid ${(props) => props.theme.colors.alertBorderColor};
	padding: 10px;
	display: flex;
	align-items: center;
  border-radius: 15px;
	
	div {
		span {
			font-weight: 700;
		}
	}
`;

export const RefundTransactionFeeDescriptionIconContainer = styled.div`
	width: 16px;
	height: 16px;
	margin-right: 10px;
`;

export const RefundTransactionTwoFactorContainer = styled.div`
	margin-bottom: 30px;
`;

export const RefundEnableTwoFactorWrapper = styled.div`
	
`;

export const RefundEnableTwoFactorInfoContainer = styled.div`

`;

export const RefundEnableTwoFactorInfoTitle = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 14px;
`;

export const RefundEnableTwoFactorInfoSubtitle = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 135px;
`;

export const RefundEnableTwoFactorButtonsContainer = styled.div`

`;

export const RefundEnableTwoFactorButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  button {
    width: 49% !important;
  }
`;

export const RefundSuccessContainer = styled.div`
	padding: 21px;
`;

export const RefundSuccessIconWrapper = styled.div`
	text-align: center;
	margin-bottom: 25px;
  padding-top: 12px;
`;

export const RefundSuccessTitle = styled.div`
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
	margin-bottom: 25px;
`;

export const RefundSuccessSubtitle = styled.div`
	margin-bottom: 25px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
`;

export const RefundSuccessDescription = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
	color: ${(props) => props.theme.colors.darkestGrey};
	margin-bottom: 25px;
`;

export const RefundSuccessTransactionInfo = styled.div`
  background: ${(props) => props.theme.colors.lightestGrey};
  padding: 40px;
  border-radius: 20px;
  margin-bottom: 25px;
`;

export const RefundSuccessTransactionInfoWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

export const RefundSuccessTransactionInfoTitle = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
	width: ${(props) => props.width ? `${props.width}%` : '32%'};
	display: flex;
	
	div:nth-child(2) {
		margin-left: 4px;
		
		svg {
			cursor: pointer;
      path {
        fill: ${(props) => props.theme.colors.mainColor};
      }
		}
	}
`;

export const RefundSuccessTransactionInfoValue = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: ${(props) => props.fontWeight ? props.fontWeight : '400'};
  line-height: 140%;
	display: flex;
	align-items: center;
  width: ${(props) => props.width ? `${props.width}%` : '32%'};
	justify-content: flex-end;
	
	svg {
		margin-left: 4px;
		cursor: pointer;
		path {
			fill: ${(props) => props.theme.colors.mainColor};
		}
	}
`;

export const RefundIdContainer = styled.div`
  overflow-wrap: break-word;
  width: 100%;
  display: block;
  font-size: 16px;
  overflow: hidden;
	cursor: pointer;
	text-align: right;
`;

export const RefundSuccessButtonContainer = styled.div`
	width: 278px;
	margin: 0 auto; 
	
	button {
    text-align: center !important;
    font-size: 16px !important;
    font-style: normal !important;
    line-height: 140% !important;
  }
`;

export const RefundErrorContainer = styled.div`
  padding: 45px;
`;

export const RefundErrorIconWrapper = styled.div`
  text-align: center;
	margin-bottom: 25px;
`;

export const RefundErrorTitle = styled.div`
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
	margin-bottom: 25px;
`;

export const RefundErrorSubtitle = styled.div`
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
	margin-bottom: 25px;
	
	span a {
		color: ${(props) => props.theme.colors.mainColor};
	}
`;

export const RefundErrorButtonContainer = styled.div`
	button {
    text-align: center !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: 140% !important;
  }
`;

export const ReportsStatusContainer = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  gap: 6px;
`;

export const ReportsStatusWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 22px;
  padding: 0 5px;
  background-color: ${(props) => props.color};
  border-radius: 2px;
`;

export const ReportsStatusIconContainer = styled.div`
  display: flex;
  margin-right: 5px;
`;

export const ReportsStatusTextContainer = styled.div``;
