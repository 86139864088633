/**
 * @file
 * Contains transaction out icon.
 */
import React from 'react';

const TransactionOutIcon = (props) => {
  const { color } = props;

  return (
    <svg width="14" height="18" viewBox="0 0 14 18" xmlns="http://www.w3.org/2000/svg">
      <g id="Layer_1">
        <path id="Vector"
              d="M5.24443 16.1999C5.24443 16.6774 5.42957 17.1351 5.75871 17.4726C6.08785 17.8101 6.53424 18 6.99983 18C7.46542 18 7.91182 17.8101 8.24095 17.4726C8.57009 17.1351 8.75523 16.6774 8.75523 16.1999L8.75523 6.14485L11.0249 8.47228C11.3561 8.79995 11.7991 8.98137 12.2599 8.97715C12.72 8.97293 13.1602 8.78378 13.4859 8.44978C13.8116 8.11579 13.9961 7.66507 14.0002 7.19325C14.0043 6.72143 13.8274 6.2665 13.5072 5.92687L8.24096 0.526662C7.91182 0.189149 7.46543 -0.000702476 6.99983 -0.000702516C6.53424 -0.000702557 6.08785 0.189149 5.75871 0.526662L0.49251 5.92687C0.172972 6.26649 -0.00393864 6.72073 -0.000510225 7.19325C0.00291819 7.66577 0.188058 8.11649 0.513767 8.45049C0.839476 8.78448 1.2797 8.97363 1.7398 8.97785C2.19991 8.98207 2.64356 8.80066 2.97476 8.47299L5.24443 6.14555L5.24443 16.1999Z"
              fill={color}/>
      </g>
      <defs>
        <clipPath id="clip0_548_3427">
          <rect width="14" height="18" fill="white" transform="translate(14 18) rotate(-180)"/>
        </clipPath>
      </defs>
    </svg>

  )
};

export default TransactionOutIcon;
