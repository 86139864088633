/**
 * @file
 * Contains Statistic by method icon.
 */
import React from 'react';

const StatisticByMethod = (props) => {
  const { color, gradientColor } = props;

  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path fillRule="evenodd" clipRule="evenodd" d="M16.25 8.33337H17.7502C17.8882 8.33337 18.0002 8.48269 18.0002 8.66672V18.3334H18.6667C18.8507 18.3334 19 18.4827 19 18.6667C19 18.8507 18.8507 19 18.6667 19H17.7536C17.7525 19.0001 17.7513 19.0001 17.7502 19.0001H16.25C16.2489 19.0001 16.2477 19.0001 16.2466 19H13.7537L13.7502 19.0001H12.25L12.2465 19H9.75076L9.75016 19H8.25003L8.24942 19H5.75015H4.25003H3.33334C3.14934 19 3 18.8507 3 18.6667C3 18.4827 3.14934 18.3334 3.33334 18.3334H4V14.6667C4 14.4827 4.11202 14.3334 4.25003 14.3334H5.75015C5.88817 14.3334 6.00018 14.4827 6.00018 14.6667V18.3334H8V11.3333C8 11.1493 8.11202 11 8.25003 11H9.75016C9.88817 11 10.0002 11.1493 10.0002 11.3333V18.3334H12V12.6667C12 12.4827 12.112 12.3334 12.25 12.3334H13.7502C13.8882 12.3334 14.0002 12.4827 14.0002 12.6667V18.3334H16V8.66672C16 8.48272 16.112 8.33337 16.25 8.33337Z" fill={gradientColor ? 'url(#statistic_by_method_0_linear)' : color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M17.0001 3C16.2647 3 15.6667 3.598 15.6667 4.33334C15.6667 4.58036 15.7342 4.81188 15.8517 5.01044L13.6772 7.18498C13.4786 7.06748 13.2471 7 13.0001 7C12.5809 7 12.2063 7.19437 11.9617 7.49775L10.333 7.03329C10.3333 7.02222 10.3334 7.01111 10.3334 6.99997C10.3334 6.26462 9.73544 5.66663 9.00009 5.66663C8.26475 5.66663 7.66675 6.26462 7.66675 6.99997C7.66675 7.19389 7.70834 7.37825 7.78306 7.54462L5.70475 9.20174C5.50019 9.07392 5.25863 9 5.00009 9C4.26475 9 3.66675 9.598 3.66675 10.3333C3.66675 11.0687 4.26475 11.6667 5.00009 11.6667C5.73544 11.6667 6.33344 11.0687 6.33344 10.3333C6.33344 10.099 6.2727 9.87858 6.16613 9.68704L8.19956 8.0657C8.42263 8.23366 8.69992 8.33331 9.00009 8.33331C9.48947 8.33331 9.91802 8.06846 10.15 7.67453L11.6851 8.1123C11.673 8.18421 11.6667 8.25806 11.6667 8.33334C11.6667 9.06869 12.2647 9.66669 13.0001 9.66669C13.7354 9.66669 14.3334 9.06869 14.3334 8.33334C14.3334 8.08636 14.266 7.85488 14.1485 7.65634L16.3231 5.48176C16.5216 5.59923 16.7531 5.66669 17.0001 5.66669C17.7354 5.66669 18.3334 5.06869 18.3334 4.33334C18.3334 3.598 17.7354 3 17.0001 3Z" fill={gradientColor ? 'url(#statistic_by_method_1_linear)' : color}/>
      </g>
      <defs>
        <linearGradient id="statistic_by_method_0_linear" x1="17.9056" y1="9.17713" x2="0.827768" y2="23.3261" gradientUnits="userSpaceOnUse">
          <stop stopColor={gradientColor} />
          <stop offset="0.75" stopColor={color} />
        </linearGradient>
        <linearGradient id="statistic_by_method_1_linear" x1="17.3302" y1="3.68555" x2="3.24028" y2="16.8556" gradientUnits="userSpaceOnUse">
          <stop stopColor={gradientColor} />
          <stop offset="0.75" stopColor={color}/>
        </linearGradient>
        <clipPath id="clip0">
          <rect width="16" height="16" fill="white" transform="translate(3 3)"/>
        </clipPath>
      </defs>
    </svg>
  )
};

export default StatisticByMethod;
