/**
 * @file Contains Alert icon.
 */

import React from 'react';

export const AlertIcon = () => {

	return (
		<svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M16 8.9999C15.9976 11.1289 15.1508 13.17 13.6454 14.6755C12.1399 16.1809 10.0988 17.0277 7.96982 17.0301C-2.65811 16.6527 -2.6551 1.34514 7.96982 0.969727C10.0988 0.972118 12.1399 1.81892 13.6454 3.32435C15.1508 4.82978 15.9976 6.8709 16 8.9999ZM8.9736 13.015C8.9736 12.7488 8.86784 12.4935 8.6796 12.3052C8.49135 12.117 8.23604 12.0112 7.96982 12.0112C7.70361 12.0112 7.44829 12.117 7.26005 12.3052C7.07181 12.4935 6.96605 12.7488 6.96605 13.015C6.96605 13.2812 7.07181 13.5365 7.26005 13.7248C7.44829 13.913 7.70361 14.0188 7.96982 14.0188C8.23604 14.0188 8.49135 13.913 8.6796 13.7248C8.86784 13.5365 8.9736 13.2812 8.9736 13.015ZM8.9736 4.98481C8.9736 4.7186 8.86784 4.46328 8.6796 4.27504C8.49135 4.0868 8.23604 3.98104 7.96982 3.98104C7.70361 3.98104 7.44829 4.0868 7.26005 4.27504C7.07181 4.46328 6.96605 4.7186 6.96605 4.98481V8.9999C6.96605 9.26612 7.07181 9.52143 7.26005 9.70967C7.44829 9.89792 7.70361 10.0037 7.96982 10.0037C8.23604 10.0037 8.49135 9.89792 8.6796 9.70967C8.86784 9.52143 8.9736 9.26612 8.9736 8.9999V4.98481Z"
				fill="#FFB800"/>
		</svg>
	)
};

export default AlertIcon;
