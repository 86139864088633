/**
 * @file Contains Totp input element.
 */

import React, { useState } from 'react';
import OtpInput from 'react-otp-input';

const TotpInputElement = (props) => {
	const { ...rest } = props;

	/**
	 * Otp value.
	 */
	const [otp, setOtp] = useState('');

	return (
		<OtpInput
			value={otp}
			onChange={setOtp}
			inputType={'number'}
			inputStyle={'otp-input'}
			renderInput={(props) => <input {...props} />}
			{...rest}
		/>
	)
};

export default TotpInputElement;
