/**
 * @file
 * Contains reports filters.
 */
import React, { useEffect, useState } from 'react';
import { Form, Modal, notification } from 'antd';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import { intervalToDuration } from 'date-fns';
import Utils from '_common/utils';
import DatePickerElement from '_common/components/DatePicker/DatePickerElement';
import FormItemElement from '_common/components/FormItem/FormItemElement';
import { FILTERS_FIELDS_CONDITION_MAPPING, TRANSACTIONS_VIEW_MODE } from '_common/constants/reports.constant';
import themeConfig from 'config';
import CalendarFilledIcon from '_common/assets/renderSvgIcons/CalendarFilledIcon';
import usePermissions from '_common/hooks/usePermissions.hook';
import PermissionsGate from '_common/components/PermissionsGate/PermissionsGate';
import ButtonElement from '_common/components/Button/ButtonElement';
import { SearchInput, TransactionFiltersWrapper, TableColumnsSettingsWrap, ReportFilterModalButtonWrapper } from './Reports.style';
import { FilterColumn, FiltersRow } from 'globalStyles/globalView.style';
import TreeSelectElement from '_common/components/TreeSelect/TreeSelectElement';
import DropdownArrowIcon from '_common/assets/renderSvgIcons/DropdownArrowIcon';
import TransactionTypeIcon from '_common/assets/renderSvgIcons/TrasnactionTypeIcon';
import CurrencyIcon from '_common/assets/renderSvgIcons/CurrencyIcon';
import StatusIcon from '_common/assets/renderSvgIcons/StatusIcon';
import VolumesByMethodIcon from '_common/assets/renderSvgIcons/VolumesByMethodIcon';
import TooltipElement from '_common/components/Tooltip/TooltipElement';
import { ReactComponent as ReorderIcon } from '_common/assets/icons/reorder-icon.svg';

const ReportsFiltersV2 = (props) => {
  const {
    t,
    generalReportStore,
    reportStore,
    profileStore,
    paymentStore,
  } = props;

  /**
   * Use form object.
   */
  const [form] = Form.useForm();

  const { timeFormatStartOfDay, timeFormatEndOfDay, getStartEndOfDayMilliTimestamp } = Utils;

  /**
   * Filter loading.
   */
  const [filterLoading, setFilterLoading] = useState(false);

  /**
   * Is downloading csv file.
   */
  const [isDownloadingCsvFile, setIsDownloadingCsvFile] = useState(false);

  /**
   * Is open modal.
   */
  const [openModal, setOpenModal] = useState(false);

	/**
	 * Is export report loading.
	 */
	const [isExportReportLoading, setIsReportLoading] = useState(false);

  /**
   * Report transactions access flag.
   */
  const [reportTransactionsAccess] = usePermissions(['merchant.finances.transactions.read']);

  /**
   * Reports download access flag.
   */
  const [reportsDownloadAccess] = usePermissions(['merchant.finances.reports.download']);

  /**
   * App Theme
   */
  const { theme } = themeConfig[process.env.REACT_APP_THEME] || themeConfig.DEFAULT;

  /**
   * Load Transactions list
   */
  const loadTransactions = async () => {
    const profileId = profileStore.getSelectedProfileId;
    reportStore.setIsTableLoading(true);
    try {
      await generalReportStore.getTransactionsListV2(profileId);
    } catch (e) {
      console.error(e);
    } finally {
      reportStore.setIsTableLoading(false);
      setFilterLoading(false);
    }
  };

  /**
   * Request data on merchant guid change or tab change.
   */
  useEffect(() => {
    generalReportStore.setFilterParams({});
    form.resetFields();
    reportStore.getTableType === TRANSACTIONS_VIEW_MODE.transactions && loadTransactions();
  }, [profileStore.getSelectedProfileId, reportStore.getTableType]);

  const handleSubmit = async (formData) => {
    setFilterLoading(true);
    const { date, guid, external_id, merchant_payer_id, payment_groups, assets, kinds, states } = formData;
    if (reportTransactionsAccess) {
      if (reportStore.getTableType === TRANSACTIONS_VIEW_MODE.transactions) {
        const filterData = {
          ...(!!date) && {
            date_from: getStartEndOfDayMilliTimestamp(date?.[0]),
            date_to: getStartEndOfDayMilliTimestamp(date?.[1], 'end'),
          },
          ...(!!guid) && { guid },
          ...(!!external_id) && { external_id },
          ...(!!merchant_payer_id) && { merchant_payer_id },
          ...(!!kinds) && { kinds },
          ...(!!payment_groups && !!payment_groups?.length) && { payment_groups: payment_groups.join(',') },
          ...(!!assets && !!assets?.length) && { assets: assets.join(',') },
          ...(!!states && !!states?.length) && { states: states.join(',') },
        };

        generalReportStore.setFilterParams(filterData);
        try {
          await loadTransactions();
        } catch (error) {
          console.error(error);
        }
      }
    } else {
      setFilterLoading(false);
    }
  };

  /**
   * Download CSV file handler.
   */
  const downloadCsvHandler = async () => {
    const { date } = form.getFieldsValue();
    if (!date) {
      notification.warning({
        message: t('notification.warning.dateSelection.title'),
        description: t('notification.warning.dateSelection.description'),
        duration: 5,
      });
      return null;
    }
    if (date) {
      const from = timeFormatStartOfDay(date[0]);
      const to = timeFormatEndOfDay(date[1]);

      const {
        days,
        months,
        years,
      } = intervalToDuration({ start: from, end: to });
      if (years > 0 || months > 3 || (months === 3 && days > 5)) {
        notification.warning({
          message: t('notification.warning.dateSelection.title'),
          description: t('notification.warning.dateSelection.description'),
          duration: 5,
        });
        return null;
      }
    }
    setOpenModal(true);
  };

  const handleModalCreateReport = async () => {
    const { date, guid, external_id, merchant_payer_id, payment_groups, assets, kinds, states } = form.getFieldsValue();
    const filterData = {
      ...(!!date && { date_from: getStartEndOfDayMilliTimestamp(date[0]) }),
      ...(!!date && { date_to: getStartEndOfDayMilliTimestamp(date[1], 'end') }),
      ...(!!guid && { guid }),
      ...(!!external_id && { external_id }),
	    ...(!!merchant_payer_id && { merchant_payer_id }),
      ...(!!kinds && { kinds }),
      ...((!!payment_groups && !!payment_groups.length) && { payment_groups }),
      ...((!!assets && !!assets.length) && { assets }),
      ...((!!states && !!states.length) && { states }),
    };

		setIsReportLoading(true);
    try {
      const profileId = profileStore.getSelectedProfileId;
      Object.keys(filterData)?.length && generalReportStore.setFilterParams(filterData);
      await generalReportStore.getCreateTransactionCsvReport(profileId);
      reportStore.setTableType(TRANSACTIONS_VIEW_MODE.reports);
    } catch (e) {
      console.error(e);
    } finally {
      Object.keys(filterData)?.length && generalReportStore.setFilterParams({});
      setOpenModal(false);
	    setIsReportLoading(false);
    }
  };

  /**
   * Render select depending on type
   * @param { string } type
   */
  const renderSelectOptions = (type = '') => {
    if (type === 'assets') {
      return (paymentStore.getDictionariesListData?.[type] || []).map(item => {
        const [AssetName, AssetKey, AssetIssuer] = item?.split(':');
        return ({
          key: `${AssetKey}:${AssetIssuer}`,
          value: `${AssetKey}:${AssetIssuer}`,
          title: AssetName,
        });
      });
    }
    if (['kinds']?.includes(type)) {
      return (paymentStore.getDictionariesListData?.[type] || []).map(item => ({
        key: item?.value,
        value: item?.value,
        title: item?.text,
      }));
    }
    return (paymentStore.getDictionariesListData?.[type] || []).map(item => ({
      key: item,
      value: item,
      title: item,
    }));
  };

  /**
   * Clear filter function
   */
  const clearFilter = () => {
    form.resetFields();
    generalReportStore.setFilterParams({});
    loadTransactions();
  };

  return (
    <TransactionFiltersWrapper>
      <Modal
        title={t('modal.csv.reports.title')}
        open={openModal}
        onCancel={() => setOpenModal(false)}
        wrapClassName='modal-center'
        footer={[
					<ReportFilterModalButtonWrapper>
						<ButtonElement
							design='link'
							onClick={() => setOpenModal(false)}
						>
							{t('button.cancel')}
						</ButtonElement>
					</ReportFilterModalButtonWrapper>,
	        <ReportFilterModalButtonWrapper>
		        <ButtonElement
			        onClick={handleModalCreateReport}
			        side_padding={32}
			        loading={isExportReportLoading}
		        >
			        {t('button.create.report')}
		        </ButtonElement>
	        </ReportFilterModalButtonWrapper>,
        ]}
      >
        {t('modal.csv.reports.content')}
      </Modal>
      <Form
        form={form}
        layout='vertical'
        onFinish={handleSubmit}
      >
        <FiltersRow>
          {FILTERS_FIELDS_CONDITION_MAPPING[reportStore.getTableType].includes('date') && (
            <FilterColumn width={255} pr={6}>
              <FormItemElement name='date'>
                <DatePickerElement
                  allowClear={true}
                  iconComponent={<CalendarFilledIcon color={theme.colors.iconColor} gradientColor={theme.colors.iconGradientColor} />}
                  small
                  className='custom-filter-date-input-small'
                  popupClassName='custom-date-dropdown with-today'
                />
              </FormItemElement>
            </FilterColumn>
          )}
          {FILTERS_FIELDS_CONDITION_MAPPING[reportStore.getTableType].includes('idSearch') && (
            <>
              <FilterColumn width={270} pr={6}>
                <FormItemElement
                  name='guid'
                  className='no-padding-sides'
                  rules={[{
                    pattern: /^(?=.{36}$)\w{8}-\w{4}-\w{4}-\w{4}-\w{12}/g,
                    message: t('transaction.filters.transactionID.error'),
                  }]}
                >
                  <SearchInput
                    placeholder={t('transaction.filters.transactionID.label')}
                    allowClear />
                </FormItemElement>
              </FilterColumn>
              <FilterColumn width={165} pr={6}>
                <FormItemElement
                  name='external_id'
                  className='no-padding-sides'
                >
                  <SearchInput
                    placeholder={t('transaction.filters.externalID.label')}
                    allowClear />
                </FormItemElement>
              </FilterColumn>
	            <FilterColumn width={165} pr={6}>
		            <FormItemElement
			            name='merchant_payer_id'
			            className='no-padding-sides'
		            >
			            <SearchInput
				            placeholder={t('transaction.filters.externalPayerID.label')}
				            allowClear />
		            </FormItemElement>
	            </FilterColumn>
            </>
          )}
        </FiltersRow>
        <FiltersRow mt={16}>
          {FILTERS_FIELDS_CONDITION_MAPPING[reportStore.getTableType].includes('assetsSearch') && (
            <PermissionsGate
              scopes={['merchant.finances.transactions.read']}
            >
              <FilterColumn width={200} pr={6}>
                <FormItemElement name='assets'>
                  <TreeSelectElement
                    maxTagCount={2}
                    ph_fz={12}
                    showArrow
                    suffixIcon={<DropdownArrowIcon />}
                    switcherIcon={<DropdownArrowIcon />}
                    multiple
                    allowClear
                    small
                    placeholder={t('payment.method.currencies')}
                    popupClassName='tree-select-custom-dropdown'
                    data={renderSelectOptions('assets')}
                    iconComponent={<CurrencyIcon color={theme.colors.iconColor} gradientColor={theme.colors.iconGradientColor} />}
                  />
                </FormItemElement>
              </FilterColumn>
            </PermissionsGate>
          )}
          {FILTERS_FIELDS_CONDITION_MAPPING[reportStore.getTableType].includes('kindsSearch') && (
            <PermissionsGate
              scopes={['merchant.finances.transactions.read']}
            >
              <FilterColumn width={200} pr={6}>
                <FormItemElement name='kinds'>
                  <TreeSelectElement
                    showArrow
                    ph_fz={12}
                    suffixIcon={<DropdownArrowIcon />}
                    switcherIcon={<DropdownArrowIcon />}
                    allowClear
                    showSearch
                    small
                    placeholder={t('payment.method.kind')}
                    popupClassName='tree-select-custom-dropdown'
                    data={renderSelectOptions('kinds')}
                    iconComponent={<TransactionTypeIcon color={theme.colors.iconColor} gradientColor={theme.colors.iconGradientColor} />}
                  />
                </FormItemElement>
              </FilterColumn>
            </PermissionsGate>
          )}
          {FILTERS_FIELDS_CONDITION_MAPPING[reportStore.getTableType].includes('statesSearch') && (
            <PermissionsGate
              scopes={['merchant.finances.transactions.read']}
            >
              <FilterColumn width={200} pr={6}>
                <FormItemElement name='states'>
                  <TreeSelectElement
                    maxTagCount={2}
                    showArrow
                    ph_fz={12}
                    suffixIcon={<DropdownArrowIcon />}
                    switcherIcon={<DropdownArrowIcon />}
                    multiple
                    allowClear
                    small
                    placeholder={t('payment.method.states')}
                    popupClassName='tree-select-custom-dropdown'
                    data={renderSelectOptions('states')}
                    iconComponent={<StatusIcon color={theme.colors.iconColor}
                    gradientColor={theme.colors.iconGradientColor} />}
                  />
                </FormItemElement>
              </FilterColumn>
            </PermissionsGate>
          )}
          {FILTERS_FIELDS_CONDITION_MAPPING[reportStore.getTableType].includes('paymentGroupsSearch') && (
            <PermissionsGate
              scopes={['merchant.finances.transactions.read']}
            >
              <FilterColumn width={230} pr={6}>
                <FormItemElement name='payment_groups'>
                  <TreeSelectElement
                    maxTagCount={2}
                    showArrow
                    ph_fz={12}
                    suffixIcon={<DropdownArrowIcon />}
                    switcherIcon={<DropdownArrowIcon />}
                    multiple
                    allowClear
                    small
                    placeholder={t('payment.method.paymentGroups')}
                    popupClassName='tree-select-custom-dropdown'
                    data={renderSelectOptions('payment_groups')}
                    iconComponent={<VolumesByMethodIcon color={theme.colors.iconColor} gradientColor={theme.colors.iconGradientColor} />}
                  />
                </FormItemElement>
              </FilterColumn>
            </PermissionsGate>
          )}
          <FilterColumn width={80} pl={20}>
            <ButtonElement
              clear='true'
              onClick={clearFilter}
              className='filter-search-btn'
            >
              {t('button.clear')}
            </ButtonElement>
          </FilterColumn>
          <FilterColumn width={80} pl={20}>
            <ButtonElement
              htmlType='submit'
              loading={filterLoading}
              className='filter-search-btn'
            >
              {t('button.search')}
            </ButtonElement>
          </FilterColumn>
          {FILTERS_FIELDS_CONDITION_MAPPING[reportStore.getTableType].includes('download-csv') && (
            <PermissionsGate
              scopes={['merchant.finances.transactions.export']}
            >
              <FilterColumn width={80} pr={0} ml='auto'>
                <ButtonElement
                  onClick={downloadCsvHandler}
                  className='filter-search-btn'
                >
                  {t('button.export')}
                </ButtonElement>
              </FilterColumn>
            </PermissionsGate>
          )}
          <FilterColumn width={30} pr={0}>
            <TooltipElement trigget="hover" placement="topRight" title={`Columns settings`}>
              <TableColumnsSettingsWrap onClick={() => generalReportStore.setColumnsSettingsOpen(!generalReportStore.getColumnsSettingsOpen)}>
                <ReorderIcon />
              </TableColumnsSettingsWrap>
            </TooltipElement>
          </FilterColumn>
        </FiltersRow>
      </Form>
    </TransactionFiltersWrapper>
  );
};

export default compose(
  withTranslation(),
  inject('generalReportStore', 'reportStore', 'profileStore', 'paymentStore'),
)(observer(ReportsFiltersV2));
