/**
 * @file
 * Contains Number operations util.
 */

import format from 'number-format.js';

/**
 * Format number.
 * @param {number | string} number.
 * @param {string} mask.
 * Number.
 */
export const formatNumber = (number, mask = '#,##0.####') => {
  return format(mask, Number(number));
};

/**
 * Trim number after dot.
 * @param {number} number.
 * Number.
 * @param {number} decimals.
 * Decimals.
 */
export const trimNumber = (number, decimals = 2) => {
  const d = 10 ** decimals;
  return formatNumber(Number(Math.round( number * d) / d).toFixed(decimals));
};

/**
 * Trim number after dot.
 * @param {number} number.
 * Number.
 * @param {number} decimals.
 * Decimals.
 */
export const trimAmount = (number = 0, decimals = 2) => {
  const d = 10 ** decimals;
  return formatNumber(Number(Math.round( number * d) / d), '#,##0.00');
};
