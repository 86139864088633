/**
 * @file
 * Contains KYC Component.
 */
import React from 'react';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { Form } from 'antd';
import { ReactComponent as BackIcon } from '_common/assets/icons/kyc-back-icon.svg';
import {
  KycContainer,
  KycContentWrapper,
  KycTitle,
  KycSubTitle,
  KycInput,
  KycFormItem,
  KycButton,
  KycFooter,
  KycFooterButtonsWrapper,
  KycFooterButton,
  KycFooterDescription,
} from './Kyc.style';
import themeConfig from '../config';

const KYC = (props) => {
  const { t } = props;

  const theme = themeConfig[process.env.REACT_APP_THEME] || themeConfig.DEFAULT;

  return (
    <KycContainer background={theme?.assets?.img?.kycBackground}>
      <KycContentWrapper>
        <KycTitle>{t('kyc.title')}</KycTitle>
        <KycSubTitle>{t('kyc.subTitle')}</KycSubTitle>
        <Form layout="vertical">
          <KycFormItem label="Name">
            <KycInput placeholder="Your name" />
          </KycFormItem>
          <KycFormItem label="Surname">
            <KycInput placeholder="Your surname" />
          </KycFormItem>
          <KycFormItem label="Phone Number">
            <KycInput placeholder="Your phone Number" />
          </KycFormItem>
          <KycFormItem label="Email">
            <KycInput placeholder="Your email" />
          </KycFormItem>
          <KycButton>{t('kyc.button.getStarted')}</KycButton>
        </Form>
        <KycFooter>
          <KycFooterButtonsWrapper>
            <KycFooterButton>
              <BackIcon />
              {t('kyc.button.back')}
            </KycFooterButton>
          </KycFooterButtonsWrapper>
          <KycFooterDescription>{t('kyc.description')}</KycFooterDescription>
        </KycFooter>
      </KycContentWrapper>
    </KycContainer>
  );
};

export default compose(withTranslation())(KYC);
