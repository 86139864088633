/**
 * @file
 * Contains modal continue icon.
 */
import React from 'react';

const ModalContinueIcon = (props) => {
  const { color } = props;

  return (
    <svg width="29" height="25" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d)">
        <path d="M4.77734 10.2148L18.4639 10.2148" stroke={color ? color : '#DDEDE2'} strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M14.3477 5.22266L18.3808 9.35327C18.8497 9.83346 18.8502 10.5999 18.3819 11.0807L14.3477 15.2227" stroke={color ? color : '#DDEDE2'} strokeLinecap="round" strokeLinejoin="round"/>
      </g>
      <defs>
        <filter id="filter0_d" x="0.446306" y="0.891618" width="27.7256" height="23.7701" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
          <feOffset dx="2.55403" dy="2.55403"/>
          <feGaussianBlur stdDeviation="3.19253"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
        </filter>
      </defs>
    </svg>

  )
};

export default ModalContinueIcon;
