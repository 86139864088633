/**
 * @file
 * Contains Dashboard Statistic Component.
 */
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import StatisticElement from '_common/components/Statistic/StatisticElement';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import SpinnerElement from '_common/components/Spinner/SpinnerElement';
import {
  DashboardStatisticItem,
  StatisticInfoWrapper,
  StatisticInfoQuantity,
  StatisticInfoDescription,
  StatisticInfoDifference,
  DashboardBalanceItem,
  StatisticInfoDescriptionTitle,
  DashboardBalanceTitleWapper,
  DashboardBalanceTitle,
  DashboardBalanceContent,
  BalanceInfoWrapper,
  BalanceInfo,
  DashboardRow,
  DashboardCol,
  DashboardStatisticCol,
} from './Dashboard.style';
import TooltipElement from '_common/components/Tooltip/TooltipElement';
import BalanceInfoIn from '_common/assets/renderSvgIcons/BalanceInfoInIcon';
import BalanceInfoOut from '_common/assets/renderSvgIcons/BalanceInfoOutIcon';
import BalanceInfoInOutIcon from '_common/assets/renderSvgIcons/BalanceInfoInOutIcon';
import RightArrowIcon from '_common/assets/renderSvgIcons/RightArrowIcon';
import PermissionsGate from '_common/components/PermissionsGate/PermissionsGate';
import Utils from '_common/utils';

const DashboardStatistics = (props) => {
  const { t, generalReportStore, loading } = props;

  /**
   * Render statistic prefix.
   * @param {number} statisticData.
   * Statistic value.
   */
  const renderStatisticPrefix = (statisticData) => {
    return statisticData === 0 ? '' : statisticData > 0 && '+';
  };

  return (
    <DashboardRow>
      <PermissionsGate
        scopes={['merchant.finances.dashboard.transactions.read']}
      >
        <DashboardCol lg={11} xl={4} xxl={4}>
          <SpinnerElement spinning={loading}>
            <DashboardStatisticItem>
              <StatisticInfoWrapper>
                <StatisticInfoQuantity>{generalReportStore.getDateReportData?.n}</StatisticInfoQuantity>
                <StatisticInfoDescription>
                  <StatisticInfoDescriptionTitle>{t('dashboard.statistic.transactions')}</StatisticInfoDescriptionTitle>
                  <TooltipElement trigget="hover" placement="top" title={t('dashboard.statistic.tooltip.compareToPreviousPeriod')}>
                    <StatisticInfoDifference>
                      <StatisticElement
                        prefix={renderStatisticPrefix(generalReportStore.getDateReportData?.n_diff)}
                        value={Math.round(generalReportStore.getDateReportData?.n_diff || 0)}
                        diff={generalReportStore.getDateReportData?.n_diff < 0}
                      />
                    </StatisticInfoDifference>
                  </TooltipElement>
                </StatisticInfoDescription>
              </StatisticInfoWrapper>
            </DashboardStatisticItem>
          </SpinnerElement>
        </DashboardCol>
      </PermissionsGate>
      {/*<PermissionsGate*/}
      {/*  scopes={['merchant.finances.dashboard.transactions.read']}*/}
      {/*>*/}
      {/*  <DashboardCol lg={11} xl={4} xxl={4}>*/}
      {/*    <SpinnerElement spinning={loading}>*/}
      {/*      <DashboardStatisticItem>*/}
      {/*        <StatisticInfoWrapper>*/}
      {/*          <StatisticInfoQuantity>{generalReportStore.getDateReportData?.conversion}%</StatisticInfoQuantity>*/}
      {/*          <StatisticInfoDescription>*/}
      {/*            <StatisticInfoDescriptionTitle>{t('dashboard.statistic.conversion')}</StatisticInfoDescriptionTitle>*/}
      {/*            <TooltipElement trigget="hover" placement="top" title={t('dashboard.statistic.tooltip.compareToPreviousPeriod')}>*/}
      {/*              <StatisticInfoDifference>*/}
      {/*                <StatisticElement*/}
      {/*                  prefix={renderStatisticPrefix(generalReportStore.getDateReportData?.conversion_diff)}*/}
      {/*                  value={`${Math.round(generalReportStore.getDateReportData?.conversion_diff || 0)}%`}*/}
      {/*                  diff={generalReportStore.getDateReportData?.conversion_diff < 0}*/}
      {/*                />*/}
      {/*              </StatisticInfoDifference>*/}
      {/*            </TooltipElement>*/}
      {/*          </StatisticInfoDescription>*/}
      {/*        </StatisticInfoWrapper>*/}
      {/*      </DashboardStatisticItem>*/}
      {/*    </SpinnerElement>*/}
      {/*  </DashboardCol>*/}
      {/*</PermissionsGate>*/}
      {/*<PermissionsGate*/}
      {/*  scopes={['merchant.finances.dashboard.users.read']}*/}
      {/*>*/}
      {/*  <DashboardCol lg={11} xl={4} xxl={4}>*/}
      {/*    <SpinnerElement spinning={generalReportStore.getDateUsersData && !Object.values(generalReportStore.getDateUsersData).length}>*/}
      {/*      <DashboardStatisticItem>*/}
      {/*        <StatisticInfoWrapper>*/}
      {/*          <StatisticInfoQuantity>{generalReportStore.getDateUsersData?.total?.n || 0}</StatisticInfoQuantity>*/}
      {/*          <StatisticInfoDescription>*/}
      {/*            <StatisticInfoDescriptionTitle>{t('dashboard.statistic.users')}</StatisticInfoDescriptionTitle>*/}
      {/*            <TooltipElement trigget="hover" placement="top" title={`Compare to the previous period`}>*/}
      {/*              <StatisticInfoDifference>*/}
      {/*                <StatisticElement*/}
      {/*                  prefix={renderStatisticPrefix(generalReportStore.getDateUsersData?.total?.n_diff || 0)}*/}
      {/*                  value={Math.round(generalReportStore.getDateUsersData?.total?.n_diff || 0)}*/}
      {/*                  diff={generalReportStore.getDateUsersData?.total?.n_diff < 0}*/}
      {/*                />*/}
      {/*              </StatisticInfoDifference>*/}
      {/*            </TooltipElement>*/}
      {/*          </StatisticInfoDescription>*/}
      {/*        </StatisticInfoWrapper>*/}
      {/*      </DashboardStatisticItem>*/}
      {/*    </SpinnerElement>*/}
      {/*  </DashboardCol>*/}
      {/*</PermissionsGate>*/}

      {/*<PermissionsGate*/}
      {/*  scopes={['merchant.finances.dashboard.transactions.read']}*/}
      {/*>*/}
      {/*  <DashboardCol lg={11} xl={4} xxl={4}>*/}
      {/*    <SpinnerElement spinning={pickedDateData && !Object.values(pickedDateData).length}>*/}
      {/*      <DashboardStatisticItem>*/}
      {/*        <StatisticInfoWrapper>*/}
      {/*          <StatisticInfoQuantity>{pickedDateData?.total?.avg_bill} {pickedDateData?.total?.currency}</StatisticInfoQuantity>*/}
      {/*          <StatisticInfoDescription>*/}
      {/*            <StatisticInfoDescriptionTitle>{t('dashboard.statistic.averageBill')}</StatisticInfoDescriptionTitle>*/}
      {/*            <TooltipElement trigget="hover" placement="top" title={`Compare to the previous period`}>*/}
      {/*              <StatisticInfoDifference>*/}
      {/*                <StatisticElement*/}
      {/*                  prefix={renderStatisticPrefix(pickedDateData?.total?.avg_bill_diff)}*/}
      {/*                  value={`${Utils.trimNumber(Math.round(pickedDateData?.total?.avg_bill_diff || 0))}`}*/}
      {/*                  diff={pickedDateData?.total?.avg_bill_diff < 0}*/}
      {/*                />*/}
      {/*              </StatisticInfoDifference>*/}
      {/*            </TooltipElement>*/}
      {/*          </StatisticInfoDescription>*/}
      {/*        </StatisticInfoWrapper>*/}
      {/*      </DashboardStatisticItem>*/}
      {/*    </SpinnerElement>*/}
      {/*  </DashboardCol>*/}
      {/*</PermissionsGate>*/}
      <PermissionsGate
        scopes={['merchant.finances.balances.read']}
      >
        <DashboardCol lg={24} xl={8} xxl={8}>
          <SpinnerElement spinning={generalReportStore.getIsBalanceReportLoading}>
            <DashboardBalanceItem>
              <DashboardBalanceTitleWapper>
                <DashboardBalanceTitle>
                  {t('dashboard.statistic.balance.title', { currency: generalReportStore.getBalanceReport?.total_currency })}
                </DashboardBalanceTitle>
                {/*<DashboardBalanceArrow>*/}
                {/*  <RightArrowIcon />*/}
                {/*</DashboardBalanceArrow>*/}
              </DashboardBalanceTitleWapper>
              <DashboardBalanceContent>
                <Row>
                  <Col xs={8}>
                    <BalanceInfoWrapper>
                      <BalanceInfo
                        weight={700}
                      >
                        {Utils.trimAmount(generalReportStore.getBalanceReport?.total || 0)}
                        {/*{Utils.trimNumber(Math.round(generalReportStore.getBalanceReport?.total)) || 0}*/}
                        {/*<span><BalanceInfoInOutIcon /></span>*/}
                      </BalanceInfo>
                    </BalanceInfoWrapper>
                  </Col>
                </Row>
              </DashboardBalanceContent>
            </DashboardBalanceItem>
          </SpinnerElement>
        </DashboardCol>
      </PermissionsGate>
    </DashboardRow>
  );
};

export default compose(inject('generalReportStore'), withTranslation())(observer(DashboardStatistics));
