/**
 * @file Contains validate amount util.
 */

/**
 * Validate amount util.
 *
 * @function - validateAmountUtil.
 * @param {number} amount - amount value.
 * @param {number} maxAmount - max amount value.
 * @param {string} currency - amount currency.
 * @param {function} t - translation function.
 */
export const validateAmountUtil = async (amount, maxAmount, currency,  t) => {
	if (amount) {
		if (amount > maxAmount) {
			return Promise.reject(t('input.field.error.maxRefundAmount', { amount: `${maxAmount} ${currency}` }));
		} else {
			return Promise.resolve();
		}
	} else {
		return Promise.reject(t('input.field.required'));
	}
};
