/**
 * @file
 * Contains customization icon.
 */
import React from 'react';

const CustomizationIcon = (props) => {
  const { color } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
      <path
        d="M24.7782 4.88421C24.7108 4.63113 24.5134 4.43338 24.2605 4.36541C24.0077 4.29744 23.7377 4.36966 23.5526 4.55486L20.9359 7.17156L18.3464 6.65369L17.8285 4.06419L20.4452 1.44749C20.6304 1.26234 20.7026 0.992414 20.6346 0.739533C20.5667 0.486603 20.3689 0.28924 20.1158 0.221857C17.8462 -0.382927 15.4082 0.26966 13.7534 1.92449C11.9336 3.74422 11.3513 6.46971 12.2257 8.8575L1.15822 18.871C0.436393 19.524 0.0254556 20.4196 0.00113917 21.3927C-0.0231772 22.3658 0.342496 23.2808 1.03083 23.9692C1.69767 24.636 2.57716 25 3.51618 25C3.54645 25 3.57687 24.9996 3.60729 24.9988C4.58038 24.9745 5.47599 24.5636 6.12907 23.8418L16.1421 12.7747C16.8756 13.0434 17.641 13.1745 18.4023 13.1745C20.1191 13.1745 21.8148 12.5075 23.0756 11.2467C24.7304 9.59182 25.3828 7.15384 24.7782 4.88421ZM3.62033 22.112C3.21583 22.112 2.88791 21.7841 2.88791 21.3796C2.88791 20.9751 3.21583 20.6472 3.62033 20.6472C4.02482 20.6472 4.35275 20.9751 4.35275 21.3796C4.35275 21.7841 4.02487 22.112 3.62033 22.112Z"
        fill={color}/>
    </svg>)
};

export default CustomizationIcon;
