/**
 * @file
 * Contains default theme settings.
 */
const mamlakaTheme = {
  colors: {
    mainColor: '#DAA520',
    mainColorLight: '#FFF9EA',
    mainColorDark: '#DAA520',
    backgroundMain: 'linear-gradient(241deg, #00000F 7.22%, #DAA520 94.19%)',
    backgroundDarkMain: 'linear-gradient(241deg, #00000F 7.22%, #DAA520 94.19%)',
    white: '#FFFFFF',
    whiteSnow: '#FBFBFB',
    lightWhite: '#e5e5e5',
    black: '#000000',
    whiteGrey: '#F7F7F7',
    darkWhite: '#F6F6F6',
    dark: 'linear-gradient(180deg, #AE8318 41.85%, #FFE49F 100%)',
    darkLight: 'linear-gradient(180deg, #AE8318 41.85%, #FFE49F 100%)',
    lightBlack: '#27272A',
    extraLightGrey: '#F2F1FF',
    grey: '#C7C7C7',
    lightGrey: '#E2DFDF',
	  lightestGrey: '#F0F0F0',
    darkGrey: '#9F9EAB',
	  darkestGrey: '#646464',
    silver: '#c4c4c4',
    silverLight: '#E9E7E7',
    silverDark: '#78787A',
	  inputDisabledColor: '#B3B3B3',
	  inputDisabledBackground: '#D6D6D6',
    red: '#FF3F46',
    green: '#29C65B',
    errorColor: 'red',
    iconColor: '#DAA520',
    iconGradientColor: '#00000F',
    navigationIconDisabledColor: '#ffffff80',
	  warningBackgroundColor: '#FFFAEB',
	  warningBorderColor: '#F6AD25',
	  alertBorderColor: '#FFB800',
	  alertBackgroundColor: '#FCF9E6',
	  menuButtonBorderColor: '#CDCDCD',
	  menuButtonDarkBorderColor: '#444444',
	  menuButtonDotColor: '#444444',
	  inputAmountBorderColor: '#B1B1B1',
  },
  shadow: {
    box: '15px 15px 38px rgba(0, 0, 0, 0.13), -15px -15px 38px rgba(226, 223, 223, 0.49)',
    button: '4px 4px 20px rgba(0, 0, 0, 0.13)',
    verificationInput: '2px 2px 6px rgba(0, 0, 0, 0.15);',
  },
  fonts: {
    secondFont: "'Montserrat', sans-serif",
  },
};

export default mamlakaTheme;
