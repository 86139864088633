import { kratosClient } from '_common/services/apiClient.service';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { kratosService } from '_common/services/kratos.service';
import { useTranslation } from 'react-i18next';

/**
 *
 * @param {function} [onSuccess]
 * @param {function} [onError]
 * @param flowId {string}
 */
export const useVerificationMutation = ({ onSuccess, onError, flowId }) => {
  const queryClient = useQueryClient();
	const { t } = useTranslation();

  /**
   *
   * @param values {import('@ory/client').updateVerificationFlowBody}
   * @return {Promise<any>}
   */
  const submit = async (values) => {
    if (values.code) delete values.email;

    const res = await kratosClient.updateVerificationFlow({
      flow: flowId,
      updateVerificationFlowBody: values,
    });

    return res.data;
  };

  return useMutation({
    mutationFn: submit,
    onSuccess,
    onError,
    onSettled: (res, error) => {
      const flowResponse = res ?? error?.response?.data;
      if (flowResponse) {
        queryClient.setQueryData(['kratos-verification', flowId], flowResponse);
      }
	    const text = res.state === 'sent_email' ? t('verification.notification.emailSent') : '';

      return kratosService.onMutation(res, error, text);
    },
  });
};
