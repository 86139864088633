/**
 * @file
 * Contains Select Component styles.
 */
import React from 'react';
import styled, { css } from 'styled-components';
import { TreeSelect as AntdTreeSelect } from 'antd';
import CloseSelectItemIcon from '_common/assets/icons/close_selet_Item_Icon.svg';

export const TreeSelectWrapper = styled.div`
  position: relative;
  overflow: hidden;
`;

export const TreeSelect = styled(({ innerRef, isInvalid, ...rest }) => <AntdTreeSelect {...rest} />)`
  &.ant-select-show-search.ant-select:not(.ant-select-customize-input) {
    display: block;
    .ant-select-selector {
      border-radius: 5px;
      padding: ${(props) => props.small ? (props.iconComponent ? `0 20px 0 33px` : `0 20px 0 20px`) : `0 20px 0 14px`};
      font-size: 12px;
      overflow: hidden;
      background-color: ${(props) => props.design ? props.theme.colors.white : props.theme.colors.extraLightGrey};
      line-height: ${(props) => props.small ? '30px' : '46px'};
      height: ${(props) => props.small ? '30px' : '46px'};
      border-color: ${(props) => props.theme.colors.darkGrey};

      &:before {
        content: '';
        position: absolute;
        width: 28px;
        height: 100%;
        top: 0;
        left: 0;
      }
    }

    &.ant-select-single {
      .ant-select-selector {
        //height: 100%;
      }
    }
  }

  .ant-select-selection-search {
    display: none;
  }

  &.ant-tree-select {
    &.ant-select-single {
      .ant-select-selection-placeholder {
        position: absolute;
        top: 50%;
        right: 11px;
        transform: translateY(-50%);
        transition: all 0.3s;
      }
    }
    .ant-select-selection-placeholder {
      font-size: 12px;
      left: ${(props) => props.iconComponent ? '33px' : '20px'};
      ${(props) => props?.ph_fz && `font-size: ${props?.ph_fz}px !important`};
      color: ${(props) => props.theme.colors.darkGrey};
    }
  }

  &.ant-tree-select .ant-select-selection-item {
    background: none;
    margin-left: 0;
    padding: 2px 0 0 0;
    border: none;
    line-height: 28px;
    font-size: 16px;

    .ant-select-selection-item-remove {
      span {
        display: inline-block;
        position: relative;
        width: 18px;
        height: 18px;

        svg {
          display: none;
        }
        
        &:before {
          content: '';
          background-image: url(${CloseSelectItemIcon});
          display: block;
          width: 18px;
          height: 18px;
          background-repeat: no-repeat;
        }
      }
    }
  }

  &.ant-tree-select {
    .ant-select-selection-item-content, .ant-select-selection-item {
      color: ${(props) => props.theme.colors.mainColor};
      margin: 0;
      border: none;
      line-height: 1.2rem;
      ${(props) => props?.ph_fz && `font-size: ${props?.ph_fz}px !important`};
    }
    &.ant-select-single .ant-select-selection-item {
      padding: 0;
      line-height: 1;
      font-size: 12px;
      align-self: center;
    }
  }

  .ant-select-arrow {
    transform: rotate(0deg);
    transition: transform 0.2s ease;
    margin-right: 0;
		  
		& svg {
			width: 11px;
		}  

    @media (min-width: 1440px) {
      width: 10px;
      height: 10px;
    }

    span {
      display: none;
    }
  }

  &.ant-select-open {
    outline: transparent !important;

    .ant-select-arrow {
      transform: rotate(180deg);

      @media (min-width: 1440px) {
        width: 10px;
        height: 10px;
      }
    }
  }
	
  &.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: ${(props) => props.theme.colors.inputDisabledBackground};
    color: ${(props) => props.theme.colors.inputDisabledColor} !important;

    .ant-tree-select .ant-select-selection-item-content, .dUrhEw.ant-tree-select .ant-select-selection-item {
		  color: red;
    }
  }
`;

export const IconWrapper = styled.div`
  svg {
    position: absolute;
    top: 50%;
    left: 8px;
    width: 20px;
    transform: translateY(-50%);
    z-index: 9;
  }
`;
