/**
 * @file
 * Contains kyc Level Component.
 */
import React, { useState } from 'react';
import { Col } from 'antd';
import { ReactComponent as EditIcon } from '_common/assets/icons/kyc-deit-icon.svg';
import { ReactComponent as AddressIcon } from '_common/assets/icons/kyc-address-icon.svg';
import { ReactComponent as IdIcon } from '_common/assets/icons/kyc-id-account-icon.svg';
import UploadIcon from '_common/assets/icons/kyc-upload-icon.svg';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import ModalElement from '_common/components/Modal/ModalElement';
import UploadElement from '_common/components/UploadElement/UploadElement';
import {
  KycStepWrapper,
  BeneficiariesWrapper,
  BeneficiariesTitleWrapper,
  BeneficiariesCardTitle,
  BeneficiariesCardSubTitle,
  BeneficiariesCardRow,
  KycUploadModalTitle,
  KycUploadFooterTitle,
} from '../Kyc.style';
import KycContentHeader from '../KycContentHeader';

const KycLevel = (props) => {
  const { t, level, currentLevel, description, Icon } = props;

  /**
   * Modal Show handler State.
   */
  const [popupShow, setPopupShow] = useState(false);

  /**
   * Show Modal Handler.
   */
  const showPopup = () => {
    setPopupShow(!popupShow);
  };

  const maskStyle = {
    background: 'rgba(56, 134, 189, 0.25)',
    backdropFilter: 'blur(2px)',
  };

  return (
    <KycStepWrapper level={level} currentLevel={currentLevel + 1}>
      <KycContentHeader Icon={Icon} description={description} />
      <BeneficiariesWrapper>
        <BeneficiariesTitleWrapper>
          {t('kyc.step.first.card.mainTitle')} <EditIcon />
        </BeneficiariesTitleWrapper>
        <BeneficiariesCardRow>
          <Col xs={2}>
            <AddressIcon />
          </Col>
          <Col xs={16}>
            <BeneficiariesCardTitle>{t('kyc.step.first.card.title.first')}</BeneficiariesCardTitle>
            <BeneficiariesCardSubTitle>{t('kyc.step.first.card.subTitle.first')}</BeneficiariesCardSubTitle>
          </Col>
          <Col xs={6}>
            <ModalElement
              showPopup={showPopup}
              showModal={popupShow}
              icon={UploadIcon}
              buttonText="Select & upload"
              buttonType="upload"
              footer={null}
              maskStyle={maskStyle}
              width="auto"
            >
              <KycUploadModalTitle>{t('kyc.modal.title')}</KycUploadModalTitle>
              <UploadElement />
              <KycUploadFooterTitle>{t('kyc.modal.footer.text')}</KycUploadFooterTitle>
            </ModalElement>
          </Col>
        </BeneficiariesCardRow>
        <BeneficiariesCardRow>
          <Col xs={2}>
            <IdIcon />
          </Col>
          <Col xs={16}>
            <BeneficiariesCardTitle>{t('kyc.step.first.card.title.second')}</BeneficiariesCardTitle>
            <BeneficiariesCardSubTitle>{t('kyc.step.first.card.subTitle.second')}</BeneficiariesCardSubTitle>
          </Col>
          <Col xs={6}>
            <ModalElement
              showPopup={showPopup}
              showModal={popupShow}
              icon={UploadIcon}
              buttonText="Select & upload"
              buttonType="upload"
              footer={null}
              maskStyle={maskStyle}
            >
              <KycUploadModalTitle>{t('kyc.modal.title')}</KycUploadModalTitle>
              <UploadElement />
              <KycUploadFooterTitle>{t('kyc.modal.footer.text')}</KycUploadFooterTitle>
            </ModalElement>
          </Col>
        </BeneficiariesCardRow>
      </BeneficiariesWrapper>
    </KycStepWrapper>
  );
};

export default compose(withTranslation())(KycLevel);
