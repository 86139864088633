/**
 * @file
 * Contains CurvedGraphic component styles.
 */
import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { ComposedChart as ReComposedChart, ResponsiveContainer as ReResponsiveContainer } from 'recharts';

export const GlobalStyle = createGlobalStyle`
  .curve-graphic-tooltip {
    background: rgba(0, 0, 0, .4);
    padding: 4px 8px;
    color: ${(props) => props.theme.colors.white};
  }
`;

export const ResponsiveContainer = styled(({ ...rest }) => <ReResponsiveContainer {...rest} />)``;

export const ComposedChart = styled(({ ...rest }) => <ReComposedChart {...rest} />)`
  .recharts-surface {
    cursor: pointer;

    .yAxis {
      line {
        stroke: ${(props) => props.theme.colors.silver};
      }
    }
    .xAxis {
      line {
        stroke: ${(props) => props.theme.colors.silver};
      }
    }
    .recharts-layer {
      .recharts-text {
        tspan {
          font-size: 8px;
          font-family: ${(props) => props.theme.fonts.secondFont};
          color: ${(props) => props.theme.colors.silverDark};
        }
      }
    }
    .recharts-cartesian-grid {
      .recharts-cartesian-grid-horizontal {
        line {
          stroke: ${(props) => props.theme.colors.silver};
          opacity: 0.2;
        }
      }
      .recharts-cartesian-grid-vertical {
        line {
          stroke: ${(props) => props.theme.colors.silver};
          opacity: 0.2;
        }
      }
    }
  }
`;
