/**
 * @file
 * Contains edit icon.
 */
import React from 'react';

const EditIcon = (props) => {
  const { color } = props;

  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.4365 3.3703L16.5049 7.43872L6.20657 17.7371L2.14042 13.6687L12.4365 3.3703ZM19.5921 2.3891L17.7777 0.574722C17.0765 -0.12647 15.938 -0.12647 15.2344 0.574722L13.4964 2.31271L17.5648 6.38116L19.5921 4.35389C20.136 3.81001 20.136 2.93294 19.5921 2.3891ZM0.0113215 19.3872C-0.0627191 19.7204 0.238132 20.019 0.571391 19.9379L5.105 18.8387L1.03885 14.7703L0.0113215 19.3872Z" fill={color ? color : '#A4A6B3'} />
    </svg>
  )
};

export default EditIcon;
