/**
 * @file
 * Contains Select Component styles.
 */
import React from 'react';
import styled, { css } from 'styled-components';
import { Select as AntdSelect } from 'antd';

export const Select = styled(({
  innerRef,
  isInvalid,
  ...rest
}) => <AntdSelect {...rest} />)`
  &.ant-select {
    width: 100%;

    .ant-select-selector {
      box-shadow: none !important;
      border-radius: 5px !important;
      border-color: ${(props) => props.theme.colors.darkGrey};
    }
  }

  &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: ${(props) => props.theme.colors.darkGrey};
  }

  &.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: ${(props) => props.theme.colors.darkGrey};
  }

  &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
  .ant-select-selector {
    padding: 0 4px;
    height: ${(props) => props?.small ? '30px' : '48px' };
    line-height: ${(props) => props?.small ? '28px' : '48px' };
    box-sizing: border-box;
    background: ${(props) => props.design === 'light' ? props.theme.colors.white : props.theme.colors.extraLightGrey};
    outline: none;
    overflow: hidden;
    position: relative;

    @media (min-width: 1440px) {
		  font-size: 12px;
    }

    ${props => props.icon && css`
      padding: 14px 14px 14px 30px;
    `};

    &:before {
      width: ${props => props.icon ? '20px' : '0px'};

      @media (min-width: 1140px) {
        width: ${props => props.icon ? '32px' : '0px'};
      }
    }

    ${props => props.value ? css`
      &:before {
        content: '';
        position: absolute;
        height: 100%;
        top: 0;
        left: 0;
        background: transparent;
      }

    ` : css`
      &:before {
        content: '';
        position: absolute;
        height: 100%;
        top: 0;
        left: 0;
        background: ${(props) => props.theme.colors.whiteGrey};
      }
    `}
    .ant-select-selection-placeholder {
      opacity: 1;
      line-height: ${(props) => props.small ? '30px' : '46px'};
		  font-size: 12px;
      color: ${(props) => props.theme.colors.darkGrey};
    }
  }

  &.ant-select-open {
    outline: transparent !important;

    .ant-select-arrow {
      border-color: ${(props) => props.theme.colors.dark};
      border-style: solid;
      border-width: 2px 0 0 2px;
      height: 8px;
      width: 8px;
      transform: rotate(45deg);

      @media (min-width: 1440px) {
        width: 10px;
        height: 10px;
      }
    }
  }

  .ant-select-arrow {
    border-color: ${(props) => props.theme.colors.dark};
    border-style: solid;
    border-width: 2px 0 0 2px;
    height: 8px;
    width: 8px;
    transform: rotate(-135deg);
    transition: transform 0.2s ease;
    margin-right: 0;

    @media (min-width: 1440px) {
      width: 10px;
      height: 10px;
    }

    span {
      display: none;
    }
  }

  &.ant-select-single .ant-select-selector {
    .ant-select-selection-item,
    .ant-select-selection-placeholder {
      line-height: ${(props) => props?.small ? '28px' : '48px'};
      padding: ${(props) => props?.small ? '0 0 0 22px' : props.iconComponent ? '0 0 0 40px' : '0 0 0 10px'};
      color: ${(props) => props.theme.colors.mainColor};
		  font-size: 12px;
      font-weight: 500;
    }

    .ant-select-selection-search {
      left: 0;
      right: 0;
    }
  }

  .ant-select-selection-search-input {
    padding: 0 21px;
    caret-color: transparent;
  }

  .ant-select-dropdown {
    padding: 40px 0;
  }

  .ant-select-selection-item {
    svg {
      display: none;
    }
  }

  &.ant-select-multiple {
    .ant-select-selection-placeholder {
      cursor: pointer;
    }

    .ant-select-selector {
      padding-left: 36px;
    }

    .ant-select-selection-item {
      margin: 0 0 0 10px;
      padding: 0;
      background: none;
      border: none;

      &:first-child {
        margin-left: 0;
      }
    }

    .ant-select-selection-item-content {
      font-size: 16px;
      color: ${(props) => props.theme.colors.mainColor};
      font-weight: 700;
    }

    .ant-select-selection-placeholder {
      padding-left: 30px;
      color: ${(props) => props.theme.colors.mainColor};
      font-weight: 700;
    }
  }

  ${(props) =>
    props.mode === 'miltiple' && css`
      .ant-select-selection-item {
        display: none;
      }
    `
  }`;

export const SelectWrapper = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  z-index: 9;

  ${(props) =>
    props.icon && css
      `
        &:before {
          content: '';
          display: block;
          width: ${(props) => props.iconType === 'arrow' ? '8px' : '12px'};
          height: ${(props) => props.iconType === 'arrow' ? '12px' : '12px'};
          position: absolute;
          left: ${(props) => props.iconType === 'arrow' ? '8px' : '4px'};
          top: 50%;
          transform: translateY(-50%);
          background: transparent url(${props.icon}) center center no-repeat;
          background-size: cover;
          z-index: 2;
          cursor: pointer;

          @media (min-width: 1140px) {
            width: ${(props) => props.iconType === 'arrow' ? '20px' : '20px'};
            height: ${(props) => props.iconType === 'arrow' ? '20px' : '20px'};
            background-size: auto;
            left: 6px;
          }
        }`
  }

  ${(props) => props.width && css`
    & {
      width: ${props.width} !important;
    }`}

  ${(props) => props.type === 'transactions' && css`
    width: 140px;

    .ant-select {
      margin-right: 40px;

      &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector, .ant-select-selector {
        height: auto !important;
        padding: 0 10px;
        border: none;
        box-shadow: none;
        background: transparent;

        & .ant-select-selection-placeholder {
          top: 5px !important;
          left: 10px !important;
        }

        & .ant-select-selection-item {
          color: ${(props) => props.theme.colors.mainColor} !important;
          font-weight: 700;
          font-size: 16px;
          line-height: 22px;
        }

        &:hover {
          outline: none;
          border: none;
        }
      }

      & .ant-select-arrow {
        margin-right: 0px !important;
        border-color: ${(props) => props.theme.colors.mainColor};
        border-left: 3px solid ${(props) => props.theme.colors.mainColor};
        border-top: 3px solid ${(props) => props.theme.colors.mainColor};
      }
    }

    .ant-select-focused .ant-select-selector,
    .ant-select-selector:focus,
    .ant-select-selector:active,
    .ant-select-open .ant-select-selector {
      border-color: #d9d9d9 !important;
      box-shadow: none !important;
    }

    &:after {
      background: none;
      border: none;
    }
  `}

  ${(props) => props.type === 'simple-text' && css`
    width: auto;

    .ant-select {
      margin-right: 0;

      &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector, .ant-select-selector {
        height: auto !important;
        padding: 0 10px;
        border: none;
        box-shadow: none;
        background: transparent;

        & .ant-select-selection-item {
          color: ${(props) => props.theme.colors.lightBlack} !important;
          font-weight: 700;
          font-size: 14px;
          padding: 0 20px 0 0;
          line-height: 28px;
        }

        &:hover {
          outline: none;
          border: none;
        }

        &:before {
          display: none;
        }
      }

      .ant-select-selection-search {
        padding: 0 30px 0 10px;
      }

      &.ant-select-single {
        &.ant-select-open {
          .ant-select-selector {
            .ant-select-selection-item {
              color: ${(props) => props.theme.colors.grey} !important;
            }
          }
        }
      }

      & .ant-select-arrow {
        // margin-top: -9px;
        // margin-right: 0 !important;
        // border-color: ${(props) => props.theme.colors.lightBlack};
        // border-left: 3px solid ${(props) => props.theme.colors.lightBlack};
        // border-top: 3px solid ${(props) => props.theme.colors.lightBlack};

        border: none;
        height: auto;
        width: auto;
        transform: rotate(0);
        margin-right: 0;

        span {
          display: inline !important;
          svg {
            fill: ${(props) => props.theme.colors.lightBlack} 
          }
        }
      }

      &.ant-select-open {
        & .ant-select-arrow {
          //margin-top: -4px;
        }
      }
    }

    .ant-select-focused .ant-select-selector,
    .ant-select-selector:focus,
    .ant-select-selector:active,
    .ant-select-open .ant-select-selector {
      border-color: #d9d9d9 !important;
      box-shadow: none !important;
    }

    &:after {
      background: none;
      border: none;
    }
  `}
  
  .ant-select-selection-search {
    padding: 0 30px 0 10px;
  }
`;

export const IconWrapper = styled.div`
  width: ${(props) => props?.small ? '15px' : '24px'};
  height: ${(props) => props?.small ? '15px' : '24px'};
  position: absolute;
  left: ${(props) => props?.small ? '6px' : '12px'};
  top: 50%;
  display: flex;
  transform: translateY(-50%);
  z-index: 9;
  align-items: center;
  justify-content: center;
  
  svg {
    width: 100%;
    height: auto;
  }
`;
