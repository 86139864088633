/**
 * @file
 * Contains Button Component.
 */
import React from 'react';
import { Button } from './ButtonElement.style';

const ButtonElement = (props) => {
  const { height, children, ...rest } = props;

  return <Button height={height} {...rest}>{children}</Button>;
};

export default ButtonElement;
