/**
 * @file
 * Contains FormItemElement component.
 *
 */
import React from 'react';
import { FormItem } from './FormItemElement.style';

const FormItemElement = (props) => <FormItem {...props} />;

export default FormItemElement;
