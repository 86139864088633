/**
 * @file Contains Theme Config
 */

// Default theme icons and images import
import { ReactComponent as LoginLogo } from './themes/default/assets/icons/login-logo.svg';

// Tempo theme icons and images import
import { ReactComponent as LoginTempoLogo } from './themes/tempo/assets/icons/login-logo.svg';

// APS theme icons and images import
import { ReactComponent as LoginApsLogo } from 'themes/aps/assets/icons/login-logo.svg';
import { ReactComponent as MainApsLogo } from 'themes/aps/assets/icons/main-logo.svg';

// Mamlaka theme icons and images import
import { ReactComponent as LoginMamlakaLogo } from 'themes/mamlaka/assets/icons/login-logo.svg';

import authBgTempo from 'themes/tempo/assets/img/registration-bg.png';
import authBgAps from 'themes/aps/assets/img/registration-bg_v2.png';
import authBgMamlaka from 'themes/mamlaka/assets/img/registration-bg.png';
import authBg from 'themes/default/assets/img/registration-bg.png';

import questionnaireBgTempo from 'themes/tempo/assets/img/questionnaire-background.png';
import questionnaireBgAps from 'themes/aps/assets/img/questionnaire-background.jpg';
import questionnaireBgMamlaka from 'themes/mamlaka/assets/img/questionnaire-background.png';
import questionnaireBg from 'themes/default/assets/img/questionnaire-background.png';

import kycBackgroundTempo from 'themes/tempo/assets/img/kyc-background.png';
import kycBackgroundAps from 'themes/aps/assets/img/kyc-background.png';
import kycBackgroundMamlaka from 'themes/mamlaka/assets/img/kyc-background.png';
import kycBackground from 'themes/default/assets/img/kyc-background.png';


/**
 * Import Themes
 */
import themes from './themes';

const themeConfig = {
  TEMPO: {
    name: 'tempo',
    theme: themes.tempo,
    assets: {
      img: {
        questionnaireBg: questionnaireBgTempo,
        authBg: authBgTempo,
        kycBackground: kycBackgroundTempo,
      },
      icons: {
        loginLogo: LoginTempoLogo,
      },
    },
    mainLandingLink: {
      title: 'Tempo',
      url: 'https://tempo.eu.com',
    },
    termsAndConditionsLink: {
      title: 'auth.links.terms',
      url: 'https://tempo.eu.com/terms-conditions',
    },
    privacyPolicyLink: {
      title: 'auth.links.privacy',
      url: 'https://tempo.eu.com/privacy-policy',
    },
    contactSalesLink: '',
    authLinks: true,
  },
  APS: {
    name: 'aps',
    theme: themes.aps,
    assets: {
      img: {
        kycBackground: kycBackgroundAps,
        questionnaireBg: questionnaireBgAps,
        authBg: authBgAps,
      },
      icons: {
        loginLogo: LoginApsLogo,
        mainLogo: MainApsLogo,
      },
    },
    loginRegScreen: {
      showHiddenRegButton: true,
    },
    mainLandingLink: {
      title: 'APS',
      url: 'https://aps.money',
    },
    termsAndConditionsLink: {
      title: 'auth.links.terms',
      url: 'https://aps.money/terms-and-conditions',
    },
    privacyPolicyLink: {
      title: 'auth.links.privacy',
      url: 'https://aps.money/privacy-policy',
    },
    authLinks: true,
    contactSalesLink: 'https://aps.money/#contact',
  },
  MAMLAKA: {
    name: 'mamlaka',
    theme: themes.mamlaka,
    assets: {
      img: {
        kycBackground: kycBackgroundMamlaka,
        questionnaireBg: questionnaireBgMamlaka,
        authBg: authBgMamlaka,
      },
      icons: {
        loginLogo: LoginMamlakaLogo,
      },
    },
    privacyPolicyLink: {
      title: 'auth.links.privacy',
      url: 'https://mam-laka.com/privacy_policy',
    },
    authLinks: false,
    contactSalesLink: 'https://mam-laka.com/',
  },
  DEFAULT: {
    name: 'default',
    theme: themes.default,
    assets: {
      img: {
        kycBackground,
        questionnaireBg,
        authBg: authBg,
      },
      icons: {
        loginLogo: LoginLogo,
      },
    },
    loginRegScreen: {
      hideContactBtn: true,
      customLoginTitle: 'login.title.custom',
      customLoginSubTitle: 'login.subtitle.custom',
      showHiddenRegButton: true,
      customRegTitle: ' ',
      customRegSubTitle: ' ',
    },
    privacyPolicyLink: {
      title: 'auth.links.privacy',
      url: 'https://armenotech.com/',
      hidden: true,
    },
    mainLandingLink: {
      title: 'auth.links.powered.armenotech',
      url: 'https://armenotech.com/',
    },
    contactSalesLink: '',
    authLinks: true,
  },
};

export default themeConfig;
