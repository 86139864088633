/**
 * @file
 * Contains No Data Graphic Component.
 */

import React from 'react';
import themeConfig from 'config';
import {
  NoDataGraphicWrapper,
  NoDataGraphicTitleWrap,
  NoDataGraphicTitle,
  NoDataGraphicSubTitle,
  NoDataGraphicImgWrap,
} from './NoDataGraphic.style';
import NoDataGraphicIcon from '_common/assets/renderSvgIcons/NoDataGraphicIcon';

const NoDataGraphic = () => {

  return (
    <NoDataGraphicWrapper>
      <NoDataGraphicTitleWrap>
        <NoDataGraphicTitle>No Data</NoDataGraphicTitle>
        <NoDataGraphicSubTitle>Results will appear once operations start</NoDataGraphicSubTitle>
      </NoDataGraphicTitleWrap>
      <NoDataGraphicImgWrap>
        <NoDataGraphicIcon />
      </NoDataGraphicImgWrap>
    </NoDataGraphicWrapper>
  );
};

export default NoDataGraphic;
