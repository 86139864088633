/**
 * @file
 * Contains pagination prev icon.
 */
import React from 'react';

const PaginationPrevArrowIcon = (props) => {
  const { color } = props;

  return (
    <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.25 1.90625L2.34375 5.8125L6.25 9.71875L5.46875 11.2812L-2.39047e-07 5.8125L5.46875 0.34375L6.25 1.90625Z" fill={color ? color : 'black'} />
    </svg>
  )
};

export default PaginationPrevArrowIcon;
