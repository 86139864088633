import React from 'react';
import { VerificationFormItemElement } from './Verification/Verification.Style';
import { AuthInput } from './Auth.style';
import { NodesWrapper, SubmitNode, SubmitNodeWrapper } from './Auth.nodes.style';

/**
 * @returns {import('react').ReactNode}
 * @param {import('@ory/client').UiNode[]} nodes
 */
export const AuthNodes = ({ nodes }) => {
  return (
    <NodesWrapper>
      {nodes.map((node) => {
        if (node.type === 'input') {
          const isHidden = node.attributes.type === 'hidden';

          return (
            <VerificationFormItemElement
              name={node.attributes.name}
              key={node.group + node.attributes.name}
              initialValue={node.attributes.value}
              style={isHidden ? { display: 'none' } : {}}
              preserve={false}
            >
              {node.attributes.type === 'submit' ? (
                <SubmitNodeWrapper>
                  <SubmitNode type="submit">{node.meta.label?.text}</SubmitNode>
                  <input type="submit" hidden value={node.attributes.value} />
                </SubmitNodeWrapper>
              ) : (
                <AuthInput type={node.attributes.type} placeholder={node.meta.label?.text} />
              )}
            </VerificationFormItemElement>
          );
        }

        return null;
      })}
    </NodesWrapper>
  );
};
