import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { kratosService } from '_common/services/kratos.service';

/**
 *
 * @param {string} [flowId]
 * @param {function} [onError]
 */
export const useBrowserVerificationFlow = (flowId, onError) => {
  const [flow, setFlow] = useState(flowId);

  return useQuery({
    queryKey: ['kratos-verification', flow],
    queryFn: () => kratosService.fetchVerificationFlow(flow),
    onError,
    onSettled: (res, error) => {
      const fid = res?.id ?? error?.response?.data?.id;
      if (!flow && fid) {
        setFlow(fid);
      }
    },
  });
};
