/**
 * @file
 * Contains FormItemElements element styles.
 */
import React from 'react';
import styled, { css } from 'styled-components';
import { Form } from 'antd';

export const FormItem = styled((props) => <Form.Item {...props}>{props.children}</Form.Item>)`
  &.ant-form-item {
    position: relative;
    outline: none;
    margin-bottom: 0;
  }
  
  ${(props) => props.questionnairelabel && css `
    .ant-form-item-label {
      background: ${(props) => props.theme.colors.mainColor};
      z-index: 9;
      position: absolute;
      top: -16px;
      left: 10px;
      border-radius: 4px;
      height: 25px;
    }

    .ant-form-item-label > label {
      color: ${(props) => props.theme.colors.white};
      background: ${(props) => props.theme.colors.mainColor};
      z-index: 9;
      padding: 0 10px;
    }
    
    .ant-form-item-label > label::after {
      content: '';
      margin: 0;
    }
  `}

  .ant-form-item-explain.ant-form-item-explain-error {
    font-size: 10px;
  }

  &.ant-form-item-with-help .ant-form-item-explain {
	  ${(props) => props.errorDisplay === 'top' && css `
      position: absolute;
      top: -30px;
      width: 100%;
		  font-size: 14px;
	  `}
	  
	  ${(props) => props.errorDisplay === 'refund-top' && css `
      position: absolute;
      top: -50px;
      width: 100%;
		  font-size: 14px;
	  `}
  }
`;
