/**
 * @file
 * Contains Header Styles.
 */
import styled from 'styled-components';

export const HeaderContainer = styled.div`
  margin-top: 8px;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: start;
  height: 49px;
  
`;

export const HeaderLoginInfoWrapper = styled.div`
  margin-top: 6px;
`;

export const HeaderAccountDetails = styled.div`
  font-size: 15px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  font-weight: 400;
`;

export const HeaderLastLogin = styled.div`
  color: ${(props) => props.theme.colors.darkGrey};
  font-size: 16px;
  line-height: 1;
`;

export const HeaderMessageWrapper = styled.div`
  position: relative;
  text-align: center;
  width: 19px;
  margin: 0 auto;
`;

export const HeaderMessageCounter = styled.div`
  position: absolute;
  top: -12px;
  right: -17px;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
`;

export const HeaderWarningContainer = styled.div`
  position: relative;
  height: 100%;
`;

export const HeaderWarningWrapper = styled.div`
  border-radius: 10px;
  border: 1px solid ${(props) => props.theme.colors.mainColor};
  background: ${(props) => props.theme.colors.white};
  padding: 10px 20px;
`;

export const HeaderWarningContent = styled.div`
  margin-left: 40px;
  position: relative;
`;

export const HeaderWarningIconWrapper = styled.div`
  position: absolute;
  left: -30px;
  top: 4px;
`;

export const HeaderWarningTitle = styled.h3`
  color: ${(props) => props.theme.colors.mainColor};
  font-weight: 700;
`;

export const HeaderWarningSubTitle = styled.p`
  margin-bottom: 0;

  a {
    font-weight: 700;
    color: ${(props) => props.theme.colors.darkGrey};
    text-decoration: underline;
  }
`;

export const ProfileIdWrapper = styled.div`
  color: ${(props) => props.theme.colors.lightBlack};
  font-weight: 900;
  font-size: 16px;
  padding: 0 0 0 10px;
`;

export const HeaderSearchBarWrapper = styled.div`
  margin: 6px 0 0;
  padding-left: 0;
  width: 100%;
  max-width: 350px;

  //@media (min-width: 960px) {
  //  padding-left: 30px;
  //}
`;
