import styled from 'styled-components';

import FormItemElement from '_common/components/FormItem/FormItemElement';
import { AuthButtonSubmit } from 'Auth/Auth.style';
import { AuthInput } from 'Auth/Auth.style';
import { Form } from 'antd';

export const VerificationFormItemElement = styled(FormItemElement)`
  width: 100%;
  max-width: 420px;
`;

export const VerificationAuthInput = styled(AuthInput)`
  width: 100%;
`;

export const VerificationAuthButonSubmit = styled(AuthButtonSubmit)`
  margin: 0 !important;
`;

export const VerificationForm = styled(Form)`
  width: 40vw;
`;

export const VerificationFormWrapper = styled.div`
  height: 100vh;
  background-color: ${(props) => props.theme.colors.extraLightGrey};
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  row-gap: 20px;
  padding: 0 20px;
  
  .ant-btn {
    width: 210px;
  }
  
  .auth-links {
    margin-top: 90px;
  }
`;

export const VerificationInputsWrapper = styled.section`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const VerificationEmailLabel = styled.h1`
  font-weight: 600;
  text-align: center;
  font-size: 20px;
  max-width: 450px;
`;

export const VerificationBottomMenu = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 420px;
`;

export const VerificationBackLink = styled.a`
  font-weight: 500;
  font-size: 14px;
  color: ${(props) => props.theme.colors.darkGrey};
`;

export const SendCodeAgain = styled.a`
    font-weight: 600;
    font-size: 12px;
    text-decoration: underline;
    display: flex;
    justify-content: flex-end;
    width 100%;
    max-width: 420px;

`;
