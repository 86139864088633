/**
 * @file
 * Contains styles for Button Component.
 */
/* eslint-disable */
import React from 'react';
import styled from 'styled-components';
import { Button as AntdButton } from 'antd';

export const Button = styled(({ ...rest }) => <AntdButton {...rest} />)`
  &.ant-btn {
    background: ${(props) => props.theme.colors.mainColor};
    width: 100%;
    border: 1px solid ${(props) => props.theme.colors.mainColor};
    border-radius: 5px;
    height: ${(props) => props.height ? `${props.height}px` : '100%'};
    font-size: 12px;
    line-height: 28px;
	  padding: 0;
    
    span {
      color: ${(props) => props.theme.colors.black};
    }

    &:hover {
      color: ${(props) => props.theme.colors.mainColor}};
    }

    ${(props) =>
      // eslint-disable-next-line indent
      props.design === 'export' &&
      `
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-width: 142px;
      text-align: center;
      height: 50px;
      background: #3886bd;
      border-radius: 10px;
      color: #ffffff;
      padding: 0 20px;
      span {
        font-family: Avenir;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 22px;
        color: #ffffff;
        display: inline-block;
        margin-right: 10px;
      }
      &:hover,
      &:focus {
        background-color: rgb(54 110 149);
        border-color: rgb(164, 166, 179);
      }
    `}

    ${(props) =>
      // eslint-disable-next-line indent
      !!props.custom_icon &&
      `
      &:before {
        content: '';
        display: block;
        width: 20px;
        height: 20px;
        position: absolute;
        left: auto;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
        opacity: 1;
        background: none;
        background-repeat: no-repeat !important;
        border-radius: 0;
        background-image: url(${props.custom_icon});
        background-size: 100%;
        z-index: 2;
      }
    `}

    ${(props) =>
      // eslint-disable-next-line indent
      !!props?.clear &&
      `
      background: ${props.theme.colors.extraLightGrey} !important;
      border: 1px solid ${props.theme.colors.mainColorDark} !important;
      padding: 0;
      span {
        color: ${props.theme.colors.mainColorDark} !important;
      }
      &:hover,
      &:focus {
        background: ${props.theme.colors.extraLightGrey};
        span {
            color: ${props.theme.colors.mainColorDark};
        }
      }
    `}

    ${(props) =>
      // eslint-disable-next-line indent
      props.design === 'link' &&
      `
      width: auto !important;
      text-align: center;
      background: none !important;
      color:${props.theme.colors.mainColor};
      border: none !important;
      span {
        font-weight: 800;
        font-size: 16px;
        line-height: 1;
        color: ${props.theme.colors.mainColor} !important;
        display: inline-block;
        text-decoration: underline;
      }
      &:hover,
      &:focus {
        background: none !important;
        border: none !important;
      }
    `}

    ${(props) =>
      // eslint-disable-next-line indent
      !!props?.side_padding &&
      `
      width: auto !important;
      padding: 0 ${props.side_padding}px !important;
      span {
        font-weight: 800;
        font-size: 16px;
        line-height: 40px;
        display: inline-block;
      }
    `}
}
`;
