/**
 * @file
 * Contains JWT tokens services.
 */

import LocalStorageService from '_common/services/localStorage.service';

/**
 * Get JWT access token action.
 * @return {any} token.
 * JWT token.
 */
export const getJwtAccessToken = () => LocalStorageService.getItem('api_token') || '';

/**
 * Save JWT access token action.
 * @param {string} token.
 * JWT token.
 */
export const saveJwtAccessToken = (token) => {
  LocalStorageService.setItem('api_token', token);
};

/**
 * Remove JWT access token action.
 */
export const removeJwtAccessToken = () => {
  localStorage.removeItem('api_token');
};

/**
 * Get JWT refresh token action.
 * @return {any} token.
 * JWT token.
 */
export const getJwtRefreshToken = () => LocalStorageService.getItem('refresh_api_token') || '';

/**
 * Save JWT refresh token action.
 * @param {string} token.
 * JWT token.
 */
export const saveJwtRefreshToken = (token) => {
  LocalStorageService.setItem('refresh_api_token', token);
};

/**
 * Remove JWT refresh token action.
 */
export const removeJwtRefreshToken = () => {
  localStorage.removeItem('refresh_api_token');
};
