/**
 * @file Contains Refund Error icon.
 */

import React from 'react';

const RefundErrorIcon = () => {

	return (
		<svg width="61" height="62" viewBox="0 0 61 62" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M30.5 35.27L39.345 44.115C39.9042 44.6742 40.6158 44.9538 41.48 44.9538C42.3442 44.9538 43.0558 44.6742 43.615 44.115C44.1742 43.5558 44.4538 42.8442 44.4538 41.98C44.4538 41.1158 44.1742 40.4042 43.615 39.845L34.77 31L43.615 22.155C44.1742 21.5958 44.4538 20.8842 44.4538 20.02C44.4538 19.1558 44.1742 18.4442 43.615 17.885C43.0558 17.3258 42.3442 17.0462 41.48 17.0462C40.6158 17.0462 39.9042 17.3258 39.345 17.885L30.5 26.73L21.655 17.885C21.0958 17.3258 20.3842 17.0462 19.52 17.0462C18.6558 17.0462 17.9442 17.3258 17.385 17.885C16.8258 18.4442 16.5462 19.1558 16.5462 20.02C16.5462 20.8842 16.8258 21.5958 17.385 22.155L26.23 31L17.385 39.845C16.8258 40.4042 16.5462 41.1158 16.5462 41.98C16.5462 42.8442 16.8258 43.5558 17.385 44.115C17.9442 44.6742 18.6558 44.9538 19.52 44.9538C20.3842 44.9538 21.0958 44.6742 21.655 44.115L30.5 35.27ZM30.5 61.5C26.2808 61.5 22.3158 60.6994 18.605 59.0981C14.8942 57.4969 11.6663 55.3238 8.92125 52.5788C6.17625 49.8338 4.00313 46.6058 2.40188 42.895C0.800625 39.1842 0 35.2192 0 31C0 26.7808 0.800625 22.8158 2.40188 19.105C4.00313 15.3942 6.17625 12.1663 8.92125 9.42125C11.6663 6.67625 14.8942 4.50313 18.605 2.90188C22.3158 1.30062 26.2808 0.5 30.5 0.5C34.7192 0.5 38.6842 1.30062 42.395 2.90188C46.1058 4.50313 49.3338 6.67625 52.0788 9.42125C54.8238 12.1663 56.9969 15.3942 58.5981 19.105C60.1994 22.8158 61 26.7808 61 31C61 35.2192 60.1994 39.1842 58.5981 42.895C56.9969 46.6058 54.8238 49.8338 52.0788 52.5788C49.3338 55.3238 46.1058 57.4969 42.395 59.0981C38.6842 60.6994 34.7192 61.5 30.5 61.5ZM30.5 55.4C37.3117 55.4 43.0812 53.0363 47.8088 48.3088C52.5363 43.5812 54.9 37.8117 54.9 31C54.9 24.1883 52.5363 18.4188 47.8088 13.6913C43.0812 8.96375 37.3117 6.6 30.5 6.6C23.6883 6.6 17.9188 8.96375 13.1913 13.6913C8.46375 18.4188 6.1 24.1883 6.1 31C6.1 37.8117 8.46375 43.5812 13.1913 48.3088C17.9188 53.0363 23.6883 55.4 30.5 55.4Z"
				fill="#18342D"/>
		</svg>
	)
};

export default RefundErrorIcon;
