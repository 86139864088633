/**
 * @file
 * Contains API client factory service.
 */

import axios from 'axios';

export default (config) => {
  const { baseUrl, headers } = config;

  return axios.create({
    baseURL: baseUrl,
    headers,
  });
};
