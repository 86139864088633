import React, { createContext } from 'react';
import ReactDOM from 'react-dom';
import { Provider as MobxProvider } from 'mobx-react';
import { configure } from 'mobx';
import { ThemeProvider } from 'styled-components';
import App from './App';
import * as serviceWorker from './serviceWorker';
import stores from './stores/stores';
import GlobalStyle from './globalStyles/global.style';
import './i18n';
// dangerous, will not work in Node.JS 18+
import packageJson from '../package.json';
import themeConfig from './config';

/**
 * App version from package.json
 */
// eslint-disable-next-line no-console
console.log(`v${packageJson.version}`);

// don't allow state modifications outside actions.
configure({ enforceActions: 'always' });

/**
 * App Theme.
 */
const theme = themeConfig[process.env.REACT_APP_THEME];

/**
 * Create stores context.
 */
export const StoresContext = createContext(stores);

ReactDOM.render(
  <MobxProvider {...stores}>
   <ThemeProvider theme={theme ? theme.theme : themeConfig.DEFAULT.theme}>
     <GlobalStyle />
     <App />
   </ThemeProvider>
  </MobxProvider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
