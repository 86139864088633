/**
 * @file
 * Contains Radios Group Graphic Element
 */
import React from 'react';
import { RadioGroup } from './RadioGroupGraphic.style';

const RadioGroupGraphic = (props) => {
  const { options, ...rest } = props;

  return <RadioGroup {...rest} />;
};

export default RadioGroupGraphic;
