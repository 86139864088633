/**
 * @file Contains Switch element style.
 */

import React from 'react';
import styled from 'styled-components';
import { Switch as AntdSwitch } from 'antd';

export const Switch = styled((props => <AntdSwitch {...props} />))`
   &.ant-switch-checked {
	   background-color: ${(props) => props.theme.colors.mainColor}; 
   }
`;
