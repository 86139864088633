/**
 * @file
 * Contains SidebarNav component.
 */
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import urls from '_common/routes/urls';
import { compose } from 'recompose';
import TooltipElement from '_common/components/Tooltip/TooltipElement';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import themeConfig from 'config';
import {
  SidebarLoginItem,
  SideBarLoginWrapper,
  SidebarNavItem,
  SidebarNavWrapper,
  SidebarSettingsItem,
  SidebarSettingsWrapper,
  SideBarSupportWrapper,
} from './Sidebar.style';
import { kratosClient } from '_common/services/apiClient.service';
import LocalStorageService from '_common/services/localStorage.service';
import NavBarDashboardIcon from '_common/assets/renderSvgIcons/NavBarDashboardIcon';
import NavBarReportsIcon from '_common/assets/renderSvgIcons/NavBarReportsIcon';
import NavBarFinanceIcon from '_common/assets/renderSvgIcons/NavBarFinanceIcon';
import NavBarUsersIcon from '_common/assets/renderSvgIcons/NavBarUsersIcon';
import PermissionsGate from '../PermissionsGate/PermissionsGate';
import CustomizationIcon from '_common/assets/renderSvgIcons/CustomizationIcon';
import NavBarLogoutIcon from '_common/assets/renderSvgIcons/NavBarLogoutIcon';
import SupportIcon from '_common/assets/renderSvgIcons/SupportIcon';
import { FRESHDESK_ACTIVE, DOCUMENTATION_ACTIVE } from '_common/constants/apiEndpoints.constant';
import DocumentationApiIcon from '_common/assets/renderSvgIcons/DocumentationApiIcon';
import { destroyFreshChat } from '_common/utils/freshChat.util';
import AddUserInfoModal from '_common/components/AddUserInfoModal/AddUserInfoModal';
import usePermissions from '_common/hooks/usePermissions.hook';

const SidebarNav = (props) => {
  const { profileStore, t } = props;
  const { identity: { traits: { full_name: userFullName } } } = profileStore.getUserData;
  const [addUserInfoModalState, setAddUserInfoModalState] = useState(false);
  const [adminRole] = usePermissions(['admin']);
  const [financeRole] = usePermissions(['finance']);

  /**
   * App Theme
   */
  const { theme } = themeConfig[process.env.REACT_APP_THEME] || themeConfig.DEFAULT;

  const ssoUrl = 'https://aps-support.myfreshworks.com/login/auth/contacts?client_id=451980218021503405&redirect_uri=https://aps-help.freshdesk.com/freshid/customer_authorize_callback?hd=support.aps.money';

  const openSupportWindow = () => {
    window.open(ssoUrl, '_blank', 'noopener,noreferrer');
  }

  /**
   * Logout.
   */
  const logout = async () => {
    LocalStorageService.deleteItem('user_permissions');
    LocalStorageService.deleteItem('selected_id');
    const res = await kratosClient.createBrowserLogoutFlow();
    destroyFreshChat();
    if (res.data && res.data.logout_url) {
      window.location.replace(res.data.logout_url);
    }
  };

  const supportClick = () => {
    if(!userFullName) {
      setAddUserInfoModalState(true);
    } else {
      openSupportWindow();
    }
  }

  return (
    <div>
      <AddUserInfoModal
        modalState={addUserInfoModalState}
        setModalState={setAddUserInfoModalState}
        onSuccessUpdate={() => openSupportWindow()}
      />
      <SidebarNavWrapper>
        <TooltipElement trigger='hover' placement='right' title={t('sidebar.dashboard')}>
          <NavLink to={urls.dashboard}>
            <SidebarNavItem>
              <NavBarDashboardIcon color={theme.colors.navigationIconDisabledColor} />
            </SidebarNavItem>
          </NavLink>
        </TooltipElement>
        <PermissionsGate
          scopes={[
            'merchant.finances.dashboard.transactions.read',
            'merchant.finances.reports.download',
          ]}
        >
          <TooltipElement trigger='hover' placement='right' title={t('sidebar.reports')}>
            <NavLink to={`${urls.reports}?tab=transactions`}>
              <SidebarNavItem>
                <NavBarReportsIcon color={theme.colors.navigationIconDisabledColor} />
              </SidebarNavItem>
            </NavLink>
          </TooltipElement>
        </PermissionsGate>

        <PermissionsGate scopes={['merchant.finances.balances.read']}>
          <TooltipElement trigger='hover' placement='right' title={t('sidebar.finance')}>
            <NavLink to={urls.finance}>
              <SidebarNavItem>
                <NavBarFinanceIcon color={theme.colors.navigationIconDisabledColor} />
              </SidebarNavItem>
            </NavLink>
          </TooltipElement>
        </PermissionsGate>
	      <PermissionsGate scopes={['iam.read']}>
		      <TooltipElement trigger='hover' placement='right' title={t('sidebar.users')}>
			      <NavLink to={urls.users}>
				      <SidebarNavItem>
					      <NavBarUsersIcon color={theme.colors.navigationIconDisabledColor} />
				      </SidebarNavItem>
			      </NavLink>
		      </TooltipElement>
	      </PermissionsGate>
        {/*<TooltipElement trigger="hover" placement="right" title={t('sidebar.partners')}>*/}
        {/*  <NavLink to={urls.partners}>*/}
        {/*    <SidebarNavItem>*/}
        {/*      <PartnersIcon />*/}
        {/*    </SidebarNavItem>*/}
        {/*  </NavLink>*/}
        {/*</TooltipElement>*/}
      </SidebarNavWrapper>

      <SidebarSettingsWrapper>
        {(adminRole || financeRole) && (
          <TooltipElement trigger='hover' placement='right' title={t('sidebar.settings')}>
            <NavLink to={urls.settings}>
              <SidebarSettingsItem>
                <CustomizationIcon color={theme.colors.iconColor} />
              </SidebarSettingsItem>
            </NavLink>
          </TooltipElement>
        )}

        {DOCUMENTATION_ACTIVE === 'true' && (
          <TooltipElement placement='right' title={t('sidebar.documentation')} trigger='hover'>
            <SideBarLoginWrapper href="/developers" target="_blank" rel="noopener noreferrer">
              <SidebarLoginItem>
                <DocumentationApiIcon color={theme.colors.iconColor} />
              </SidebarLoginItem>
            </SideBarLoginWrapper>
          </TooltipElement>
        )}

        {FRESHDESK_ACTIVE === 'true' && (
          <TooltipElement placement='right' title={t('sidebar.support')} trigger='hover'>
            <SideBarSupportWrapper onClick={supportClick}>
              <SidebarLoginItem>
                <SupportIcon color={theme.colors.iconColor} />
              </SidebarLoginItem>
            </SideBarSupportWrapper>
          </TooltipElement>
        )}

        <TooltipElement placement='right' title={t('sidebar.logout')} trigger='hover'>
          <SideBarLoginWrapper onClick={logout}>
            <SidebarLoginItem>
              <NavBarLogoutIcon color={theme.colors.iconColor} />
            </SidebarLoginItem>
          </SideBarLoginWrapper>
        </TooltipElement>
      </SidebarSettingsWrapper>
    </div>
  );
};

export default compose(
  withTranslation(),
  inject('profileStore', 'globalStore')
)(observer(SidebarNav));
