/**
 * @file
 * Contains Pie Graphic Element Component styles.
 */
import styled, { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  .method-pie-graphic-tooltip {
    background: rgba(0, 0, 0, .4);
    padding: 4px 8px;
    color: ${(props) => props.theme.colors.white};
  }
`;

export const PieGraphicWrapper = styled.div`
  .recharts-default-legend {
    display: flex;
    flex-wrap: wrap;
    flex: 1 1 0;

    .recharts-legend-item {
      cursor: pointer;
      text-align: left;
      font-family: 'Avenir',sans-serif;
      width: 50%;
      margin-right: 0 !important;
      
      span {
        font-size: 10px;
        
        @media (min-width: 1440px) {
           font-size: 12px;
        }
      }
    }
  }

  svg {
    transition: all 2s ease;
  }
`;
