/**
 * @file
 * Contains status icon.
 */
import React from 'react';

const StatusIcon = (props) => {
  const { color } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="17" viewBox="0 0 12 17" fill="none">
      <path
        d="M1.71451 5.37051V3.77143C1.71451 2.63726 2.63749 1.71429 3.77166 1.71429C4.90583 1.71429 5.8288 2.63726 5.8288 3.77143V5.14286C5.90011 5.14286 5.96731 5.15931 6.03726 5.16617C6.54194 4.61691 6.85737 3.89074 6.85737 3.08571C6.85737 1.38171 5.47566 0 3.77166 0C2.06766 0 0.685944 1.38171 0.685944 3.08571C0.685944 3.99634 1.08777 4.80549 1.71451 5.37051Z"
        fill={color}/>
      <path
        d="M10.9714 7.54292C10.4037 7.54292 9.94286 8.00372 9.94286 8.57149V8.74292C9.94286 8.83755 9.86606 8.91435 9.77143 8.91435C9.6768 8.91435 9.6 8.83755 9.6 8.74292V7.88578C9.6 7.31801 9.1392 6.85721 8.57143 6.85721C8.00366 6.85721 7.54286 7.31801 7.54286 7.88578V8.74292C7.54286 8.83755 7.46606 8.91435 7.37143 8.91435C7.2768 8.91435 7.2 8.83755 7.2 8.74292V7.20006C7.2 6.63229 6.7392 6.17149 6.17143 6.17149C5.60366 6.17149 5.14286 6.63229 5.14286 7.20006V8.74292C5.14286 8.83755 5.06606 8.91435 4.97143 8.91435C4.8768 8.91435 4.8 8.83755 4.8 8.74292V3.77149C4.8 3.20372 4.3392 2.74292 3.77143 2.74292C3.20366 2.74292 2.74286 3.20372 2.74286 3.77149V9.94292C2.5536 9.94292 2.4 9.78932 2.4 9.60006V8.22863H2.06126C0.933258 8.22863 -0.000685337 9.13926 3.77356e-07 10.2673C0.00342895 13.8885 2.63931 16.4572 4.26034 16.4572H8.57143C10.4647 16.4572 12 14.9219 12 13.0286V8.57149C12 8.00372 11.5392 7.54292 10.9714 7.54292Z"
        fill={color}/>
    </svg>);
};

export default StatusIcon;
