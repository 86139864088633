/**
 * @file
 * Contains Settings Component.
 */
import React from 'react';
import { Redirect } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import MainLayout from '_common/layouts/Main.layout';
import usePermissions from '_common/hooks/usePermissions.hook';
import links from '_common/routes/urls';
import {
  Container,
	SettingsTabsContainer,
} from './Settings.style';
import Security from './Security';

const Settings = (props) => {
  const [adminRole] = usePermissions(['admin']);
  const [financeRole] = usePermissions(['finance']);

  if (!adminRole && !financeRole) {
    return <Redirect to={links.dashboard} />;
  }

  return (
    <MainLayout>
      <Container>
        <SettingsTabsContainer>
          {(adminRole || financeRole) && <Security />}
        </SettingsTabsContainer>
      </Container>
    </MainLayout>
  );
}


export default inject('profileStore')(observer(Settings));

