/**
 * @file
 * Contains transaction in icon.
 */
import React from 'react';

const TransactionInIcon = (props) => {
  const { color } = props

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="18" viewBox="0 0 14 18">
      <path
        d="M8.75557 1.80007C8.75557 1.32263 8.57043 0.864877 8.24129 0.527364C7.91215 0.189851 7.46576 0 7.00017 0C6.53458 0 6.08818 0.189851 5.75905 0.527364C5.42991 0.864877 5.24477 1.32263 5.24477 1.80007V11.8552L2.97509 9.52772C2.6439 9.20005 2.20093 9.01863 1.74014 9.02285C1.28003 9.02707 0.839812 9.21622 0.514103 9.55022C0.188394 9.88421 0.00394026 10.3349 -0.000173956 10.8067C-0.00428817 11.2786 0.172623 11.7335 0.492847 12.0731L5.75905 17.4733C6.08818 17.8109 6.53458 18.0007 7.00017 18.0007C7.46576 18.0007 7.91215 17.8109 8.24129 17.4733L13.5075 12.0731C13.827 11.7335 14.0039 11.2793 14.0005 10.8067C13.9971 10.3342 13.8119 9.88351 13.4862 9.54951C13.1605 9.21552 12.7203 9.02637 12.2602 9.02215C11.8001 9.01793 11.3564 9.19934 11.0252 9.52701L8.75557 11.8544V1.80007Z"
        fill={color}/>
    </svg>
  )
};

export default TransactionInIcon;
