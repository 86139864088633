/**
 * @file
 * Contains transaction type icon.
 */
import React from 'react';

const TransactionTypeIcon = (props) => {
  const { color } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" viewBox="0 0 15 14" fill="none">
      <path
        d="M2.80529 8.45025C2.80529 8.69926 2.90422 8.93812 3.0803 9.11417C3.25639 9.29032 3.49521 9.38919 3.74423 9.38919C3.99326 9.38919 4.23208 9.29032 4.40817 9.11417C4.58425 8.93812 4.68318 8.69926 4.68318 8.45025V3.20535L5.89723 4.4194C6.07431 4.59044 6.31149 4.68507 6.55768 4.68293C6.80387 4.6808 7.03936 4.58205 7.21345 4.40796C7.38754 4.23388 7.48628 3.99838 7.48842 3.75219C7.49056 3.50601 7.39593 3.26882 7.22489 3.09174L4.40807 0.274913C4.23199 0.0988893 3.9932 0 3.74423 0C3.49525 0 3.25648 0.0988893 3.0804 0.274913L0.263578 3.09174C0.0925399 3.26882 -0.00209616 3.50601 3.52368e-05 3.75219C0.00217602 3.99838 0.100925 4.23388 0.275014 4.40796C0.449103 4.58205 0.684599 4.6808 0.930789 4.68293C1.17697 4.68507 1.41416 4.59044 1.59124 4.4194L2.80529 3.20535V8.45025ZM12.1947 4.69451C12.1947 4.44548 12.0958 4.20667 11.9197 4.03058C11.7436 3.8545 11.5048 3.75557 11.2557 3.75557C11.0067 3.75557 10.7679 3.8545 10.5918 4.03058C10.4158 4.20667 10.3168 4.44548 10.3168 4.69451V9.93941L9.10275 8.72536C8.92567 8.55438 8.68849 8.45973 8.4423 8.46189C8.19611 8.46396 7.96063 8.56273 7.78655 8.73681C7.61247 8.91089 7.51369 9.14638 7.51153 9.39257C7.50946 9.63876 7.60411 9.87594 7.77509 10.053L10.5919 12.8698C10.768 13.0459 11.0068 13.1448 11.2557 13.1448C11.5048 13.1448 11.7435 13.0459 11.9196 12.8698L14.7364 10.053C14.9075 9.87594 15.0021 9.63876 15 9.39257C14.9978 9.14638 14.899 8.91089 14.7249 8.73681C14.5509 8.56273 14.3154 8.46396 14.0692 8.46189C13.823 8.45973 13.5858 8.55438 13.4087 8.72536L12.1947 9.93941V4.69451Z"
        fill={color}/>
    </svg>)
};

export default TransactionTypeIcon;
