/**
 * Contains access data constant.
 */

export const ACCESS_DATA_MAPPING = {
	'transactions.read': [
		'merchant.finances.transactions.read',
		'merchant.finances.dashboard.weekly.read',
		'merchant.finances.dashboard.transactions.read',
	],
	'reports.write': ['merchant.finances.transactions.export'],
	'reports.read': ['merchant.finances.reports.download'],
	'balances.read': ['merchant.finances.balances.read'],
	'docs.read': ['merchant.finances.docs.get'],
};
