/**
 * @file
 * Contains pagination next icon.
 */
import React from 'react';

const PaginationNextArrowIcon = (props) => {
  const { color } = props;

  return (
    <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.625 9.71875L4.53125 5.8125L0.625001 1.90625L1.40625 0.34375L6.875 5.8125L1.40625 11.2813L0.625 9.71875Z" fill={color ? color : 'black'} />
    </svg>
  )
};

export default PaginationNextArrowIcon;
