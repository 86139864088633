/**
 * @file
 * Contains Kyc Levels Component.
 */
import React, { useState } from 'react';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { Row } from 'antd';
import { ReactComponent as BusinessmanIcon } from '_common/assets/icons/kyc-bussinessman-icon.svg';
import { ReactComponent as CertificateIcon } from '_common/assets/icons/kyc-cetificate-icon.svg';
import { ReactComponent as LicenseIcon } from '_common/assets/icons/kyc-license-icon.svg';
import { ReactComponent as DirectorIcon } from '_common/assets/icons/kyc-director-icon.svg';
import { ReactComponent as AddressIcon } from '_common/assets/icons/kyc-address-icon.svg';
import { ReactComponent as AdminSiteIcon } from '_common/assets/icons/kyc-admin-site-icon.svg';
import { ReactComponent as FullscreenIcon } from '_common/assets/icons/kyc-fullscreen-icon.svg';
import { ReactComponent as NetworkIcon } from '_common/assets/icons/kyc-network-icon.svg';
import CheckedIcon from '_common/assets/icons/kyc-checked-icon.svg';
import {
  KycLevelsContainer,
  KycContentCol,
  KycLevelsCol,
  KycLevelsTitle,
  KycLevelsSubTitle,
  KycLevelsStepsWrapper,
  KycLevelsStep,
  KycStepNumber,
} from './Kyc.style';
import KycContentFooter from './KycContentFooter';
import KycFirstLevel from './levels/FirstLevel';
import KycSecondaryLevel from './levels/SecondaryLevel';
import KycFinalLevel from './levels/FinalLevel';

const KycLevels = (props) => {
  const { t } = props;

  /**
   * Kyc Level State.
   */
  const [level, setLevel] = useState(0);

  /**
   * Next Level Handler.
   */
  const nextLevel = () => {
    if (level < 9) {
      setLevel(level + 1);
    }
  };

  /**
   * Previous Level Handler
   */
  const prevLevel = () => {
    if (level) {
      setLevel(level - 1);
    }
  };

  return (
    <KycLevelsContainer>
      <Row>
        <KycLevelsCol xs={10}>
          <KycLevelsTitle>{t('kyc.levels.title')}</KycLevelsTitle>
          <KycLevelsSubTitle>{t('kyc.levels.subTitle')}</KycLevelsSubTitle>
          <KycLevelsStepsWrapper currentLevel={level}>
            <KycLevelsStep level={1} currentLevel={level}>
              <KycStepNumber icon={CheckedIcon} level={1} currentLevel={level} /> <span>{t('kyc.step.first')}</span>
            </KycLevelsStep>
            <KycLevelsStep level={2} currentLevel={level}>
              <KycStepNumber icon={CheckedIcon} level={2} currentLevel={level} /> <span>{t('kyc.step.second')}</span>
            </KycLevelsStep>
            <KycLevelsStep level={3} currentLevel={level}>
              <KycStepNumber icon={CheckedIcon} level={3} currentLevel={level} /> <span>{t('kyc.step.third')}</span>
            </KycLevelsStep>
            <KycLevelsStep level={4} currentLevel={level}>
              <KycStepNumber icon={CheckedIcon} level={4} currentLevel={level} /> <span>{t('kyc.step.fourth')}</span>
            </KycLevelsStep>
            <KycLevelsStep level={5} currentLevel={level}>
              <KycStepNumber icon={CheckedIcon} level={5} currentLevel={level} /> <span>{t('kyc.step.fifth')}</span>
            </KycLevelsStep>
            <KycLevelsStep level={6} currentLevel={level}>
              <KycStepNumber icon={CheckedIcon} level={6} currentLevel={level} /> <span>{t('kyc.step.sixth')}</span>
            </KycLevelsStep>
            <KycLevelsStep level={7} currentLevel={level}>
              <KycStepNumber icon={CheckedIcon} level={7} currentLevel={level} /> <span>{t('kyc.step.seventh')}</span>
            </KycLevelsStep>
            <KycLevelsStep level={8} currentLevel={level}>
              <KycStepNumber icon={CheckedIcon} level={8} currentLevel={level} /> <span>{t('kyc.step.eighth')}</span>
            </KycLevelsStep>
          </KycLevelsStepsWrapper>
        </KycLevelsCol>
        <KycContentCol xs={14}>
          <KycFirstLevel
            level={1}
            currentLevel={level}
            Icon={BusinessmanIcon}
            description={t('kyc.step.first.title')}
          />
          <KycSecondaryLevel
            level={2}
            currentLevel={level}
            Icon={CertificateIcon}
            description={t('kyc.step.second.title')}
            cardTitle={t('kyc.step.second.card.title')}
          />
          <KycSecondaryLevel
            level={3}
            currentLevel={level}
            Icon={LicenseIcon}
            description={t('kyc.step.third.title')}
            cardTitle={t('kyc.step.third.card.title')}
          />
          <KycSecondaryLevel
            level={4}
            currentLevel={level}
            Icon={DirectorIcon}
            description={t('kyc.step.fourth.title')}
            cardTitle={t('kyc.step.fourth.card.title')}
          />
          <KycSecondaryLevel
            level={5}
            currentLevel={level}
            Icon={AddressIcon}
            description={t('kyc.step.fifth.title')}
            cardTitle={t('kyc.step.fifth.card.title')}
          />
          <KycSecondaryLevel
            level={6}
            currentLevel={level}
            Icon={AdminSiteIcon}
            description={t('kyc.step.sixth.title')}
            cardTitle={t('kyc.step.sixth.card.title')}
          />
          <KycSecondaryLevel
            level={7}
            currentLevel={level}
            Icon={FullscreenIcon}
            description={t('kyc.step.seventh.title')}
            cardTitle={t('kyc.step.seventh.card.title')}
          />
          <KycSecondaryLevel
            level={8}
            currentLevel={level}
            Icon={NetworkIcon}
            description={t('kyc.step.eighth.title')}
            cardTitle={t('kyc.step.eighth.card.title')}
          />
          <KycFinalLevel level={9} currentLevel={level} />
          {level < 8 && <KycContentFooter level={level} nextLevel={nextLevel} prevLevel={prevLevel} />}
        </KycContentCol>
      </Row>
    </KycLevelsContainer>
  );
};

export default compose(withTranslation())(KycLevels);
