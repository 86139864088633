/**
 * @file
 * Contains close modal icon.
 */
import React from 'react';

const CloseModalIcon = (props) => {
	const { color } = props;

	return (
		<svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_1924_1092)">
				<path
					d="M9.81687 6.06687L8.38375 7.5L9.81687 8.93313C10.0612 9.1775 10.0612 9.5725 9.81687 9.81687C9.695 9.93875 9.535 10 9.375 10C9.215 10 9.055 9.93875 8.93313 9.81687L7.5 8.38375L6.06687 9.81687C5.945 9.93875 5.785 10 5.625 10C5.465 10 5.305 9.93875 5.18313 9.81687C4.93875 9.5725 4.93875 9.1775 5.18313 8.93313L6.61625 7.5L5.18313 6.06687C4.93875 5.8225 4.93875 5.4275 5.18313 5.18313C5.4275 4.93875 5.8225 4.93875 6.06687 5.18313L7.5 6.61625L8.93313 5.18313C9.1775 4.93875 9.5725 4.93875 9.81687 5.18313C10.0612 5.4275 10.0612 5.8225 9.81687 6.06687ZM15 7.5C15 11.6356 11.6356 15 7.5 15C3.36437 15 0 11.6356 0 7.5C0 3.36437 3.36437 0 7.5 0C11.6356 0 15 3.36437 15 7.5ZM13.75 7.5C13.75 4.05375 10.9462 1.25 7.5 1.25C4.05375 1.25 1.25 4.05375 1.25 7.5C1.25 10.9462 4.05375 13.75 7.5 13.75C10.9462 13.75 13.75 10.9462 13.75 7.5Z"
					fill={color ? color : '#183130'} />
			</g>
			<defs>
				<clipPath id="clip0_1924_1092">
					<rect width="15" height="15" fill="white"/>
				</clipPath>
			</defs>
		</svg>
	);
};

export default CloseModalIcon;
