/**
 * @file
 * Contains local storage service.
 */

/**
 * Get data from local storage.
 * @param {string} key.
 * Key name.
 * @return {any}
 * Stored data.
 */
const getItem = (key) => {
  const data = localStorage.getItem(key);
  return (!!data && data !== 'undefined') ? JSON.parse(data) : null;
};

/**
 * Set data to local storage.
 * @param {string} key.
 * Key name.
 * @param {object} value.
 * Stored data.
 */
const setItem = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};

/**
 * Delete item from local storage.
 * @param {string} keyName.
 * Key name.
 */
const deleteItem = (keyName) => {
  localStorage.removeItem(keyName);
};

/**
 * Clear all items from local storage.
 */
const clearStore = () => {
  localStorage.clear();
};

export default {
  getItem,
  setItem,
  deleteItem,
  clearStore,
};
