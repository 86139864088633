/**
 * @file Contains Users service.
 */

import { apiClient } from '_common/services/apiClient.service';
import {
	ROLES,
	MERCHANT_USERS,
	MERCHANT_USER_ROLES,
	MERCHANT_USER_INVITE,
	MERCHANT_USER_NAME_UPDATE,
} from '_common/constants/apiEndpoints.constant';

/**
 * Get users roles.
 *
 * @async
 * @function - getUsersRoles.
 */
const getUsersRoles = async () => {
	try {
		const response = await apiClient.get(ROLES);
		return response?.data;
	} catch (e) {
		throw new Error(e);
	}
};

/**
 * Get merchant users.
 *
 * @async
 * @function - getMerchantUsers
 * @param {string} id - merchant id.
 */
const getMerchantUsers = async (id) => {
	try {
		const url = MERCHANT_USERS.replace('<MERCHANT_GUID>', id);
		const response = await apiClient.get(url);
		return response?.data;
	} catch (e) {
		throw new Error(e);
	}
};

/**
 * Invite user.
 *
 * @async
 * @function - inviteUser.
 * @param {string} id - merchant id.
 * @param {object} data - users data.
 * @param {string} twoFactorAuthToken - two factor auth token.
 */
const inviteUser = async (id, data, twoFactorAuthToken) => {
	try {
		const url = MERCHANT_USER_INVITE.replace('<MERCHANT_GUID>', id);

		const response = await apiClient.post(url, data, { headers: { ...(twoFactorAuthToken && { 'X-2FA-Token': twoFactorAuthToken }) } });
		return response?.data;
	} catch (e) {
		throw e;
	}
};

/**
 * Edit user data.
 *
 * @async
 * @function - editUserData.
 * @param {string} id - merchant id.
 * @param {Object} data - users data.
 * @param {string} twoFactorAuthToken - two factor auth token.
 */
const editUsersData = async (id, data, twoFactorAuthToken) => {
	try {
		const url = MERCHANT_USER_ROLES.replace('<MERCHANT_GUID>', id);

		const response = await apiClient.put(url, data, { headers: { ...(twoFactorAuthToken && { 'X-2FA-Token': twoFactorAuthToken }) } });
		return response?.data;
	} catch (e) {
		throw e;
	}
};

const updateUserName = async (data, profileId) => {
	const url = MERCHANT_USER_NAME_UPDATE.replace('<MERCHANT_GUID>', profileId);
	const response = await apiClient.put(url, data);
	return response.data;
};


export default {
	getUsersRoles,
	getMerchantUsers,
	inviteUser,
	editUsersData,
	updateUserName,
};
