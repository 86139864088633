/**
 * @file
 * Contains Input Element component.
 * InputElement Contains next attributes:
 * # icon {string} - set up icon at input.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { InputWrapper, Input } from './InputElement.style';

const InputElement = (props) => {
  const { getRef, design, disabled, border, small, ...rest } = props; // excluded getRef prop. DO NOT DELETE!!!

  /**
   * Ref to input.
   * @param {object} ref.
   * Ref data.
   */
  const inputRef = (ref) => {
    if (props.getRef) {
      props.getRef(ref);
    }
  };

  return (
    <InputWrapper {...rest}>
      <Input
        innerRef={inputRef}
        {...rest}
        onFocus={(e) => (e.target.placeholder = '')}
        onBlur={(e) => (e.target.placeholder = rest.placeholder ? rest.placeholder : '')}
        design={design}
        disabled={disabled}
        border={border}
        small={small}
      />
    </InputWrapper>
  );
};

InputWrapper.propTypes = {
  icon: PropTypes.string,
};

export default InputElement;
