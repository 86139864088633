/**
 * @file
 * Contains Language detector util.
 */

import i18n from 'i18next';
import WhiteLable from 'whiteLabel.config';

/**
 * Get current language.
 */
export const getLanguage = () => {
  const i18nLang = i18n.language ? i18n.language.substring(0, 2) : WhiteLable.defaultLanguage;
  return window.localStorage.i18nextLng || i18nLang;
};
