/**
 * @file
 * Contains Finance Report Component.
 */
import React from 'react';
import FinanceReportTable from './FinanceReportTable';
import { FinanceReportWrapper } from './Finance.style';
import PermissionsGate from '_common/components/PermissionsGate/PermissionsGate';
// import FinanceReportGraphic from './FinanceReportGraphic';

const FinanceReport = () => (
  <FinanceReportWrapper>
    <PermissionsGate
      scopes={['merchant.finances.balances.read']}
    >
      <FinanceReportTable />
    </PermissionsGate>
    {/* <FinanceReportGraphic /> */}
  </FinanceReportWrapper>
);

export default FinanceReport;
