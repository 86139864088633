/**
 * @file
 * Contains i18n localization configurations.
 */

import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';
import WhiteLabel from 'whiteLabel.config';
import Utils from '_common/utils';

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: Utils.getLanguage() || navigator.language || navigator.userLanguage,
    backend: {
      loadPath: '/assets/i18n/{{lng}}/{{ns}}.json',
    },
    fallbackLng: WhiteLabel.defaultLanguage,
    debug: false,
    ns: ['translations'],
    defaultNS: 'translations',
    keySeparator: false,
    interpolation: {
      escapeValue: false,
      formatSeparator: ',',
    },
    react: {
      wait: true,
    },
  });

export default i18n;
