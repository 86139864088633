/**
 * @file
 * Contains Settings Styles.
 */
import React from 'react';
import styled from 'styled-components';
import ButtonElement from '_common/components/Button/ButtonElement';

export const Container = styled.div`
  height: 100%;
`;

export const MainTitle = styled.div`
  font-weight: 700;
  font-size: 15px;
  line-height: 25px;
  width: fit-content;
  color: ${(props) => props.theme.colors.dark};
  padding-bottom: 4px;
  border-bottom: 1px solid ${(props) => props.theme.colors.dark};
`;

export const SettingsLinksList = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
`;

export const SettingsLinksListItem = styled.div`
  height: 65px;
  width: 50%;
  background: ${(props) => props.theme.colors.extraLightGrey};
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 25px 10px 14px;
`;

export const SettingsLinksListItemTitle = styled.div`
  font-weight: 700;
  font-size: 15px;
  line-height: 22px;
  color: ${(props) => props.theme.colors.dark};
`;

export const SettingsLinksListItemLink = styled(ButtonElement)`
  &.ant-btn {
    padding: 0 10px;
    border: 1px solid ${(props) => props.theme.colors.mainColorDark};
    border-radius: 5px;
    background: transparent;
    height: fit-content;
    width: fit-content;
    transition:all .2s ease-in-out;
    
    span {
      font-weight: 500;
      font-size: 12px;
      line-height: 34px;
      color: ${(props) => props.theme.colors.black};
    }
    
    &:hover {
      border-color: ${(props) => props.theme.colors.mainColor};
      background: ${(props) => props.theme.colors.mainColor};
    }
  }
`;

export const SettingsTabsContainer = styled.div`
	margin-top: 32px;
		
  .ant-tabs-nav {
		  
    &:before {
        border: none;
    }
		  
    .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
        background: ${(props) => props.theme.colors.mainColorDark} !important;
    }
		  
    .ant-tabs-tab {
      &:hover {
         color: ${(props) => props.theme.colors.mainColorDark} !important;
      }
      &.ant-tabs-tab-active {
        .ant-tabs-tab-btn {
            color: ${(props) => props.theme.colors.mainColorDark} !important;
        }
      }
    }
  }
`;

export const SecurityContainer = styled.div`
	border: 1px solid ${(props) => props.border ? 'transparent' : props.theme.colors.darkGrey};
	padding: 48px 45px 40px 54px;
	border-radius: 10px;
	max-width: 1044px;
`;

export const SecurityTwoFactorAuthContainer = styled.div`
	form {
    width: 360px;
	}

  button {
	  margin-top: 34px;
    width: 156px !important;
    border-radius: 10px !important;
    padding: 13px 35px !important;
    font-size: 16px !important;
    font-weight: 700 !important;
  }
`;

export const SecurityEnableTwoFactorTitle = styled.div`
	font-weight: 700;
	font-size: 20px;
	margin-bottom: 4px;
`;

export const SecurityEnableTwoFactorSubTitle = styled.div`
	font-size: 16px;
`;

export const SecurityEnableScanQrSubTitle = styled.div`
	margin-bottom: 35px;
`;

export const SecurityTwoFactorAuthTitle = styled.div`
	margin-bottom: 48px;
	font-size: 16px;
	max-width: 285px;
`;

export const SecurityDisableTwoFactorTitle = styled.div`
	font-size: 18px;
	font-weight: 700;
`;

export const SecurityDisableTwoFactorSubTitle = styled.div`
	margin-bottom: 20px;
`;

export const SecurityTwoFactorAuthWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

export const SecuritySwitchButtonWrapper = styled.div`
  .ant-switch-checked {
    background-color: ${(props) => props.theme.colors.mainColor};
  }
`;

export const SecurityDisableTwoFactorAuthContainer = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 10px;
`;

export const SecurityDisableTwoFactorAuthTextContainer = styled.div`
	max-width: 250px;
	margin-right: 6px;
`;

export const SecurityDisableTwoFactorAuthTextTitle = styled.div`
	font-size: 18px;
	font-weight: 700;
`;

export const SecurityDisableTwoFactorAuthTextSubTitle = styled.div`
	font-size: 14px;
`;
