/**
 * @file
 *  Contains String operations util.
 */

/**
 * @param {string} str.
 * String.
 */
export const editString = (str) => {
  const replaceDash = str.replace(/_/g, ' ');

  return replaceDash.charAt(0).toUpperCase() + replaceDash.slice(1);
};

/**
 * @param {string} str.
 * String
 */
export const checkEmail = (str) => {
  const re = /^[\w-\\.]+@([\w-]+\.)+[\w-]{2,4}$/g;

  return re.test(str);
};
