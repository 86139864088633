/**
 * @file
 * Contains Select Element Component.
 *
 * SelectElement contains next attributes:
 *  # type {string} - noBorder. // transactions
 *  # icon {string} - set up icon at select.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Select, SelectWrapper, IconWrapper } from './SelectElement.style';

const SelectElement = (props) => {
  const { iconType, getRef, icon, iconComponent, design, small, ...rest } = props; // excluded getRef prop. DO NOT DELETE!!!
  /**
   * Ref to input.
   * @param {object} ref.
   * Ref data.
   */
  const inputRef = (ref) => {
    if (getRef) {
      getRef(ref);
    }
  };

  return (
    <SelectWrapper iconType={iconType} icon={icon} {...rest}>
	    {!!iconComponent && (
		    <IconWrapper small={small} {...rest}>
			    {iconComponent}
		    </IconWrapper>
	    )}
      <Select small={small} iconComponent={iconComponent} design={design} innerRef={inputRef} {...rest} />
    </SelectWrapper>
  );
};

SelectWrapper.propTypes = {
  icon: PropTypes.string,
  type: PropTypes.string,
};

export default SelectElement;
