/**
 * @file
 * Contains menu vertical dots icon.
 */
import React from 'react';

const MenuVerticalDotsIcon = (props) => {
	const { color } = props;

	return (
		<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29.96 122.88">
			<path d="M15,0A15,15,0,1,1,0,15,15,15,0,0,1,15,0Zm0,92.93a15,15,0,1,1-15,15,15,15,0,0,1,15-15Zm0-46.47a15,15,0,1,1-15,15,15,15,0,0,1,15-15Z" fill={color ? color : 'black'} />
		</svg>
	)
};

export default MenuVerticalDotsIcon;

