/**
 * @file
 * Contain Table Element Component.
 */
import React from 'react';
import { TableElement } from './TableElement.style';

const Table = (props) => {
  const { ...rest } = props;

  return (
    <TableElement
      {...rest}
    />
  );
};

export default Table;
