/**
 * @file Contains two factor auth service.
 */

import { apiClient } from '_common/services/apiClient.service';
import { LINK_TWO_FACTOR_AUTH_FLOW_ID } from '_common/constants/apiEndpoints.constant';

/**
 * Link two factor auth flow id.
 *
 * @async
 * @function - linkTwoFactorAuthFlowId.
 * @param {Object} data - two factor auth data.
 */
const linkTwoFactorAuthFlowId = async (data) => {
	try {
		const response = await apiClient.post(LINK_TWO_FACTOR_AUTH_FLOW_ID, data);
		return response.data;
	} catch (e) {
		console.error(e);
	}
};

export default {
	linkTwoFactorAuthFlowId,
};
