/**
 * @file
 * Contains Curved Graphic component.
 */
import React from 'react';
import { XAxis, Tooltip, CartesianGrid } from 'recharts';
import PropTypes from 'prop-types';
import { ComposedChart, GlobalStyle } from './CurvedGraphic.style';
import Utils from '_common/utils';

const CurvedGraphic = (props) => {
  const { children, data, ...rest } = props;

  /**
   * Custom tool tip.
   */
  const CustomTooltip = (data) => {
    const { active, payload } = data;

    if (active && payload && payload.length) {
      const {
        xUnit,
        xValue,
        yUnit,
        yValue,
      } = payload[0]?.payload;

      return (
        <div className="curve-graphic-tooltip">
          <div>{xValue} {xUnit}</div>
          <div>{Utils.trimNumber(yValue)} {yUnit}</div>
        </div>
      );
    }

    return null;
  };

  return (
    <>
      <GlobalStyle />
      <ComposedChart {...rest} data={data}>
        <defs>
          <linearGradient id="splitColor" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#50BEBE" stopOpacity={0.8} />
            <stop offset="95%" stopColor="#FFFFFF" stopOpacity={0} />
          </linearGradient>
        </defs>
        <CartesianGrid strokeWidth="1px" />
        <XAxis strokeWidth="1px" dataKey="xValue" />
        <Tooltip content={<CustomTooltip />} />
        {children}
      </ComposedChart>
    </>
  );
};

CurvedGraphic.propTypes = {
  data: PropTypes.instanceOf(Array).isRequired,
};

export default CurvedGraphic;
