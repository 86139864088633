/**
 * @file Contains copy to clipboard util
. */

/**
 * Copy to clipboard.
 * @function - copyToClipboardUtil.
 * @param {string} text - text to copy
 */
export const copyToClipboardUtil = (text) => {
	navigator.clipboard.writeText(text);
};
