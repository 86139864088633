/**
 * @file
 * Contains Sidebar logo component.
 */
import React from 'react';
import urls from '_common/routes/urls';
import themeConfig from 'config';
import { SidebarLogoWrapper, LogoLink } from './Sidebar.style';

const SidebarLogo = () => {
  /**
   * App Theme
   */
  const theme = themeConfig[process.env.REACT_APP_THEME] || themeConfig.DEFAULT;

  return (
    <SidebarLogoWrapper>
      <LogoLink to={urls.dashboard}>
        {theme?.assets?.icons?.mainLogo ? <theme.assets.icons.mainLogo/> : <theme.assets.icons.loginLogo/>}
      </LogoLink>
    </SidebarLogoWrapper>
  );
};

export default SidebarLogo;
