/**
 * @file
 *  Contains User Profile Service.
 */
import { apiClient } from './apiClient.service';
import { MERCHANT, PROFILE, MY_ACCESS } from '../constants/apiEndpoints.constant';

/**
 * Get Profile Data.
 */
const getProfile = async () => {
  const response = await apiClient.get(PROFILE);
  return response.data;
};

/**
 * Get access data.
 */
const getAccessData = async () => {
	const response = await apiClient.get(MY_ACCESS);
	return response.data;
};

/**
 * Update Profile Data.
 * @param {object} data.
 * Profile data.
 */
const updateProfile = async (data) => {
  const response = await apiClient.post(MERCHANT, data);
  return response.data;
};

export default {
  getProfile,
  updateProfile,
	getAccessData,
};
