/**
 * @file
 * Contains Something went wrong component styles.
 */

import styled from 'styled-components';

export const Wrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
`;

export const IconWrapper = styled.div`
  width: 180px;
  height: 180px;
  margin: 0 auto 44px;

  svg {
    width: 100%;
    height: 100%;

    path {
      &.brand-color {
        fill: ${(props) => props.theme.colors.mainBrandColor};
      }

      &.opacity {
        fill-opacity: .4;
      }
    }

    stop {
      &.brand-color {
        stop-color: ${(props) => props.theme.colors.mainBrandColor};
      }
    }
  }
`;

export const Title = styled.div`
  padding: 0 0 40px;
  text-transform: uppercase;
  font-size: 18px;
  line-height: 25px;
  font-weight: 800;
  color: ${(props) => props.theme.colors.mainColor};
  text-align: center;
`;

export const Description = styled.div`;
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  text-align: center;
  margin-bottom: 68px;
`;

export const ButtonWrapper = styled.div`;
  width: 156px;
  margin: 68px auto 0;

  .ant-btn {
    color: ${(props) => props.theme.colors.white};
    border: none;
    border-radius: 5px;
    padding: 8px 0;
    background: ${(props) => props.theme.colors.backgroundMain} !important;
    box-shadow: ${(props) => props.theme.shadow.button};
    display: block;
    margin: 0 auto 20px;
    height: auto;
    transition: all 0.5s ease;
    width: 155px;
    font-size: 16px;

    &:hover {
      color: ${(props) => props.theme.colors.white};
    }
  }
`;
