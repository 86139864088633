/**
 * @file
 * Contains Conversion Graphic.
 */
import React, { useEffect, useState } from 'react';
import GraphicContainer from '_common/components/CurvedGraphic/GraphicContainer';
import CurvedGraphic from '_common/components/CurvedGraphic/CurvedGraphic';
import NoDataGraphic from '_common/components/NoDataGraphic/NoDataGraphic';
import { Bar } from 'recharts';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import Utils from '_common/utils';
import SpinnerElement from '_common/components/Spinner/SpinnerElement';
import { DashboardGraphicTitle, DashboardGraphicContainer } from './Dashboard.style';

const ConversionGraphic = (props) => {
  const { t, generalReportStore } = props;

  const { timeFormatByTimestamp, isToday } = Utils;

  /**
   * Quantity Data State.
   */
  const [quantityData, setQuantityData] = useState({});

  /**
   * Quantity Graphic Dara State.
   */
  const [quantityGraphicData, setQuantityGraphicData] = useState([]);

  /**
   * Get Date Report Data from Store.
   */
  useEffect(() => {
    const fetchData = async () => {
      generalReportStore.setIsLoading(true);
      try {
        const data = generalReportStore.getDateReportData;

        if (data) setQuantityData(data.breakdown.by_time);
        generalReportStore.setIsLoading(false);
      } catch (e) {
        console.error(e);
      }
    };

    fetchData();
  }, [generalReportStore.getDateReportData]);

  /**
   * Set Curved Graphic Data to Store.
   */
  useEffect(() => {
    if (quantityData) {
      const pickedData = Object.entries(quantityData).map((data) => {
        const dataValue = {
          xValue: timeFormatByTimestamp(data[0], isToday(quantityData) ? 'HH:mm' : 'DD MMM'),
          yValue: data[1].conversion,
          yUnit: data[1].currency,
          xUnit: '',
        };
        return dataValue;
      });

      setQuantityGraphicData(pickedData);
    }
  }, [quantityData]);

  return (
    <SpinnerElement spinning={generalReportStore.getIsLoading}>
      <DashboardGraphicContainer>
        {quantityGraphicData.length ? (
          <>
            <DashboardGraphicTitle>{t('dashboard.graphic.conversion.title')}</DashboardGraphicTitle>
            <GraphicContainer height={200} width="100%">
              <CurvedGraphic data={quantityGraphicData}>
                <Bar minPointSize={1} barSize={16} dataKey="yValue" fill="#50BEBE" />
              </CurvedGraphic>
            </GraphicContainer>
          </>
        ) : (
          <NoDataGraphic />
        )}
      </DashboardGraphicContainer>
    </SpinnerElement>
  );
};

export default compose(inject('generalReportStore'), withTranslation())(observer(ConversionGraphic));
