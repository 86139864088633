/**
 * @file
 * Contains reports filters.
 */
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { Form, notification, Radio, Select, TreeSelect } from 'antd';
import Utils from '_common/utils';
import DatePickerElement from '_common/components/DatePicker/DatePickerElement';
import FormItemElement from '_common/components/FormItem/FormItemElement';
import SelectElement from '_common/components/Select/SelectElement';
import TreeSelectElement from '_common/components/TreeSelect/TreeSelectElement';
import { inject, observer } from 'mobx-react';
import FileSaver from 'file-saver';
import {
  FILTERS_FIELDS_CONDITION_MAPPING,
  TRANSACTIONS_TABLE_CONFIG,
  TRANSACTIONS_VIEW_MODE,
} from '_common/constants/reports.constant';
import themeConfig from 'config';
import SpinnerElement from '_common/components/Spinner/SpinnerElement';
import { DownloadLink, RadioGroupWrap, TransactionFiltersWrapper } from './Reports.style';
import CalendarFilledIcon from '_common/assets/renderSvgIcons/CalendarFilledIcon';
import TransactionTypeIcon from '_common/assets/renderSvgIcons/TrasnactionTypeIcon';
import TransactionInIcon from '_common/assets/renderSvgIcons/TransactionInIcon';
import TransactionOutIcon from '_common/assets/renderSvgIcons/TransactionOutIcon';
import CreditCardIcon from '_common/assets/renderSvgIcons/CreditCardIcon';
import ResultByDayIcon from '_common/assets/renderSvgIcons/ResultByDayIcon';
import ResultByWeekIcon from '_common/assets/renderSvgIcons/ResultByWeekIcon';
import ResultByMonthIcon from '_common/assets/renderSvgIcons/ResultByMonthIcon';
import DownloadIcon from '_common/assets/renderSvgIcons/DownloadIcon';
import DropdownArrowIcon from '_common/assets/renderSvgIcons/DropdownArrowIcon';
import CountryIcon from '_common/assets/renderSvgIcons/CountryIcon';
import StatusIcon from '_common/assets/renderSvgIcons/StatusIcon';
import { FilterColumn, FiltersRow } from 'globalStyles/globalView.style';
import { fromUnixTime, intervalToDuration, startOfDay, endOfDay } from 'date-fns';
import usePermissions from '_common/hooks/usePermissions.hook';
import PermissionsGate from '_common/components/PermissionsGate/PermissionsGate';
import STATUS_FILTER_ICONS from '_common/constants/filterIconsMapping.constant';
import moment from 'moment';
import { useQueryClient } from '@tanstack/react-query';

const ReportsFilters = (props) => {
  const {
    t,
    generalReportStore,
    reportStore,
    profileStore,
    paymentStore,
  } = props;

  const {
    transactionsPerPage,
    defaultSelectedType,
  } = TRANSACTIONS_TABLE_CONFIG;

	const queryClient = useQueryClient();

  /**
   * Use form object.
   */
  const [form] = Form.useForm();

  const { TreeNode } = TreeSelect;

  const { Option } = Select;

  const { timeFormatStartOfDay, timeFormatEndOfDay, getMonthsBetween, getDaysOfMonth } = Utils;

  /**
   * Format of month output
   */
  const monthFormat = 'MMMM yyyy';

  /**
   * Selected date from.
   */
  const [selectedDateFrom, setSelectedDateFrom] = useState(0);

  /**
   * Selected date to.
   */
  const [selectedDateTo, setSelectedDateTo] = useState(0);

  /**
   * Selected method.
   */
  const [selectedMethod, setSelectedMethod] = useState([]);

  /**
   * Selected country.
   */
  const [selectedCountry, setSelectedCountry] = useState([]);

  /**
   * Selected type.
   */
  const [selectedType, setSelectedType] = useState(defaultSelectedType);

  /**
   * Period type.
   */
  const [periodType, setPeriodType] = useState('type');

  /**
   * Is downloading csv file.
   */
  const [isDownloadingCsvFile, setIsDownloadingCsvFile] = useState(false);

  /**
   * Check Today Date.
   */
  const [todayDate, setTodayDate] = useState(false);

  /**
   * Payment methods data.
   */
  const [paymentMethodsData, setPaymentMethodsData] = useState([]);

  /**
   * Country list data.
   */
  const [countryListData, setCountryListData] = useState([]);

  /**
   * Reports status.
   */
  const [statusList, setStatusListData] = useState([]);

  /**
   * Selected status.
   */
  const [selectedStatus, setSelectedStatus] = useState([]);

  /**
   * Report transactions access flag.
   */
  const [reportTransactionsAccess] = usePermissions(['merchant.finances.transactions.read']);

  /**
   * App Theme
   */
  const { theme } = themeConfig[process.env.REACT_APP_THEME] || themeConfig.DEFAULT;

  /**
   * Get profile data
   * @return {Promise<void>}
   */
  const getProfileDetails = async () => {
    try {
      await profileStore.getProfileDataAction();
    } catch (e) {
      console.error(e);
    }
  };

  /**
   * Set default date.
   */
  useEffect(() => {
    if([TRANSACTIONS_VIEW_MODE.volumes, TRANSACTIONS_VIEW_MODE.statistic].includes(reportStore.getTableType)) {
      const dateFrom = timeFormatStartOfDay();
      const dateTo = timeFormatEndOfDay();

      setSelectedDateFrom(dateFrom);
      setSelectedDateTo(dateTo);
      setTodayDate(true);
      reportStore.setPeriod(dateFrom, dateTo);
      form.setFieldValue('date', [
        moment(startOfDay(new Date())),
        moment(endOfDay(new Date())),
      ]);

			const { access } = queryClient.getQueryData(['access']);
      if(!profileStore.getProfileData?.created_at && !(access && access.length)) {
	      (async () => {
					try {
						await getProfileDetails();
					} catch (e) {
						console.error(e);
					}
	      })()
      }
    }
  }, [reportStore.getTableType]);

  /**
   * Fetch transactions list data.
   */
  useEffect(() => {
    (async () => {
      reportStore.setIsTableLoading(true);

      try {
        if (
          selectedDateFrom > 0
          && selectedDateTo > 0
          && [TRANSACTIONS_VIEW_MODE.volumes, TRANSACTIONS_VIEW_MODE.statistic]?.includes(reportStore.getTableType)
          && reportTransactionsAccess
        ) {
          const profileId = profileStore.getSelectedProfileId;
          const dateParams = {
            date_from: selectedDateFrom,
            date_to: selectedDateTo,
          };

          const paymentMethodsDataResponse = await paymentStore.getPaymentMethodsDataAction(dateParams, profileId);
          setPaymentMethodsData(paymentMethodsDataResponse?.data || []);
          const data = {
            date_from: selectedDateFrom,
            date_to: selectedDateTo,
            limit: transactionsPerPage,
            offset: reportStore.getCurrentOffset,
            method: selectedMethod,
            tx_type: selectedType,
            group_by: reportStore.getResultsByValue,
            order_by: reportStore.getOrderBy,
          };

          if (reportStore.getTableType === TRANSACTIONS_VIEW_MODE.volumes) {
            await generalReportStore.getVolumesReport(data, profileId);
          } else if (reportStore.getTableType === TRANSACTIONS_VIEW_MODE.statistic) {
            await generalReportStore.getTransactionsReport(data, profileId);
          }

          reportStore.setTableRequestData({
            country: selectedCountry,
            date_from: selectedDateFrom,
            date_to: selectedDateTo,
            limit: transactionsPerPage,
            offset: reportStore.getCurrentOffset,
            method: selectedMethod,
            tx_type: selectedType,
            order_by: reportStore.getOrderBy,
          });
        }
      } catch (e) {
        console.error(e);
      } finally {
        reportStore.setIsTableLoading(false);
      }
    })();
    return () => {
      generalReportStore.setTransactionsReportData({});
    };
  }, [
    selectedDateFrom,
    selectedDateTo,
    selectedMethod,
    selectedType,
    periodType,
    selectedCountry,
    selectedStatus,
    reportStore.getResultsByValue,
    reportStore.getCurrentOffset,
    // reportStore.getTableType,
    reportStore.getOrderBy,
    profileStore.getSelectedProfileId,
  ]);

  /**
   * Render payment methods options.
   */
  const renderPaymentMethodsOptions = () =>
    paymentMethodsData.map((item) => (
        {
          key: item.id,
          value: item.id,
          title: item?.payment_group?.String,
          disabled: !item?.payment_group?.Valid,
        }
      ),
    );

  /**
   * Render country list options.
   */
  const renderCountryListOptions = () => {
    return countryListData.map((countryData) => {
      const item = countryData?.country;
      const title = (item === '' || item === undefined || item === 'WWC') ? 'Worldwide' : item;

      return (
        <TreeNode
          key={item}
          value={item}
          title={title}
        />
      );
    });
  };

  /**
   * Render status list options.
   */
  const renderStatusListOptions = () => {
    return statusList.map((item) => {
      return (
        <TreeNode
          key={item.id}
          value={item.id}
          title={item.status}
          icon={STATUS_FILTER_ICONS[item.id]}
        />
      );
    });
  };

  /**
   * On form values change handler.
   * @param {object} changedValues.
   * Changed values.
   */
  const onFormValuesChangeHandler = async (changedValues) => {
    const {
      date,
      paymentMethod,
      type,
      resultsBy,
      transactionsViewMode,
      periodType,
      countryList,
      status,
      monthCalendar,
    } = changedValues;

    /**
     * Search bar form.
     * For clear search id.
     */
    const searchBarForm = reportStore.getSearchBarForm;
    searchBarForm.setFieldsValue({
      transactionId: '',
    });

    reportStore.setCurrentPage(1);
    reportStore.setCurrentOffset(0);

    // if (transactionsViewMode) {
    //   reportStore.setTableType(transactionsViewMode);
    //   reportStore.setOrderBy({});
    //   reportStore.setResultsByValue(TRANSACTIONS_TABLE_CONFIG.defaultResultsByValue);
    // }

    if (date) {
      const from = timeFormatStartOfDay(date[0]);
      const to = timeFormatEndOfDay(date[1]);

      const {
        days,
        hours,
        minutes,
        months,
        seconds,
        years,
      } = intervalToDuration({ start: from * 1000, end: to * 1000 });

      if (
        years === 0
        && months === 3
        && (
          days > 0
          || hours > 0
          || minutes > 0
          || seconds > 0
        )
      ) {
        notification.warning({
          message: t('notification.warning.dateSelection.title'),
          description: t('notification.warning.dateSelection.description'),
          duration: 5,
        });
      } else {
        setSelectedDateFrom(from);
        setSelectedDateTo(to);
        reportStore.setPeriod(from, to);

        const checkFrom = timeFormatStartOfDay();
        const checkTo = timeFormatEndOfDay();

        if (timeFormatStartOfDay(date[0]) === checkFrom && timeFormatEndOfDay(date[1]) === checkTo) {
          setTodayDate(true);
        } else {
          setTodayDate(false);
        }
      }
    }

    if (paymentMethod) {
      setSelectedMethod(paymentMethod);
    }

    if (countryList) {
      setSelectedCountry(countryList);
    }

    if (type) {
      setSelectedType(type);
    }

    if (resultsBy) {
      reportStore.setResultsByValue(resultsBy);
    }

    if (periodType) {
      setPeriodType(periodType);
    }

    if (statusList) {
      setSelectedStatus(status);
    }
  };

  /**
   * Download CSV file handler.
   */
  const downloadCsvHandler = async () => {
    const params = {
      date_range: {
        from: fromUnixTime(selectedDateFrom),
        to: fromUnixTime(selectedDateTo),
      },
      date_column: 'created_at',
      sort_by: generalReportStore.getTransactionsCreatedSortType,
    };

    try {
      setIsDownloadingCsvFile(true);
      const profileId = profileStore.getSelectedProfileId;
      const csvData = await generalReportStore.getTransactionListCsv(params, profileId);
      const file = new File([csvData], 'transactions.csv', { type: 'text/plain;charset=utf-8' });
      FileSaver.saveAs(file);
    } catch (e) {
      console.error(e);
    } finally {
      setIsDownloadingCsvFile(false);
    }
  };

  return (
    <TransactionFiltersWrapper>
      <Form
        initialValues={{
          date: [
            moment(startOfDay(new Date())),
            moment(endOfDay(new Date())),
          ],
          type: selectedType,
          resultsBy: reportStore.getResultsByValue,
          periodType: 'monthly',
          monthCalendar: moment(new Date()),
        }}
        form={form}
        onValuesChange={onFormValuesChangeHandler}
        layout='vertical'
      >
        <FiltersRow>
          {FILTERS_FIELDS_CONDITION_MAPPING[reportStore.getTableType].includes('date') && (
            <FilterColumn width={200} pr={6}>
              <FormItemElement name='date'>
                <DatePickerElement
                  todayDate={todayDate}
                  iconComponent={<CalendarFilledIcon color={theme.colors.iconColor}
                  gradientColor={theme.colors.iconGradientColor} />}
                  small
                />
              </FormItemElement>
            </FilterColumn>
          )}
          {FILTERS_FIELDS_CONDITION_MAPPING[reportStore.getTableType].includes('resultsBy') && (
            <>
              <FilterColumn width={110} pr={6}>
                <FormItemElement name='resultsBy'>
                  <SelectElement
                    iconComponent={
                      (() => {
                        switch (reportStore.getResultsByValue) {
                          case 'day':
                            return <ResultByDayIcon color={theme.colors.iconColor}
                                                    gradientColor={theme.colors.iconGradientColor} />;
                          case 'week':
                            return <ResultByWeekIcon color={theme.colors.iconColor}
                                                     gradientColor={theme.colors.iconGradientColor} />;
                          case 'month':
                            return <ResultByMonthIcon color={theme.colors.iconColor}
                                                      gradientColor={theme.colors.iconGradientColor} />;
                        }
                      })()
                    }
                    popupClassName='custom-dropdown'
                    small
                  >
                    <Option value='day'>
                      <ResultByDayIcon color={theme.colors.iconColor} gradientColor={theme.colors.iconGradientColor} />
                      {t('transaction.filters.sortBy.day')}
                    </Option>
                    <Option value='week'>
                      <ResultByWeekIcon color={theme.colors.iconColor} gradientColor={theme.colors.iconGradientColor} />
                      {t('transaction.filters.sortBy.week')}
                    </Option>
                    <Option value='month'>
                      <ResultByMonthIcon color={theme.colors.iconColor}
                                         gradientColor={theme.colors.iconGradientColor} />
                      {t('transaction.filters.sortBy.month')}
                    </Option>
                  </SelectElement>
                </FormItemElement>
              </FilterColumn>
            </>
          )}
          {FILTERS_FIELDS_CONDITION_MAPPING[reportStore.getTableType].includes('period-type') && (
            <>
              <FilterColumn width={145} pr={6}>
                <FormItemElement name='periodType'>
                  <RadioGroupWrap>
                    <Radio.Button value='daily'>{t('transaction.filters.periodType.daily')}</Radio.Button>
                    <Radio.Button value='monthly'>{t('transaction.filters.periodType.monthly')}</Radio.Button>
                  </RadioGroupWrap>
                </FormItemElement>
              </FilterColumn>
            </>
          )}
          {(FILTERS_FIELDS_CONDITION_MAPPING[reportStore.getTableType].includes('month') && periodType === 'daily') && (
            <FilterColumn width={255} pr={6}>
              <FormItemElement name='monthCalendar'>
                <DatePickerElement
                  iconComponent={<CalendarFilledIcon color={theme.colors.iconColor}
                                               gradientColor={theme.colors.iconGradientColor} />}
                  picker='month'
                  noRange={true}
                  format={monthFormat}
                />
              </FormItemElement>
            </FilterColumn>
          )}
          {FILTERS_FIELDS_CONDITION_MAPPING[reportStore.getTableType].includes('type') && (
            <FilterColumn width={117} pr={6}>
              <FormItemElement name='type'>
                <SelectElement
                  iconComponent={
                    (() => {
                      switch (selectedType) {
                        case 'all':
                          return <TransactionTypeIcon color={theme.colors.iconColor}
                                                      gradientColor={theme.colors.iconGradientColor} />;
                        case 'deposit':
                          return <TransactionInIcon color={theme.colors.iconColor}
                                                    gradientColor={theme.colors.iconGradientColor} />;
                        case 'withdraw':
                          return <TransactionOutIcon color={theme.colors.iconColor}
                                                     gradientColor={theme.colors.iconGradientColor} />;
                      }
                    })()
                  }
                  popupClassName='custom-dropdown'
                  iconType='arrow'
                  small
                  dropdownMatchSelectWidth={false}
                >
                  <Option value='all'>
                    <TransactionTypeIcon color={theme.colors.iconColor}
                                         gradientColor={theme.colors.iconGradientColor} />
                    {t('transaction.filters.select.type')}
                  </Option>
                  <Option value='deposit'>
                    <TransactionInIcon color={theme.colors.iconColor} gradientColor={theme.colors.iconGradientColor} />
                    {t('transaction.filters.select.in')}
                  </Option>
                  <Option value='withdraw'>
                    <TransactionOutIcon color={theme.colors.iconColor} gradientColor={theme.colors.iconGradientColor} />
                    {t('transaction.filters.select.out')}
                  </Option>
                </SelectElement>
              </FormItemElement>
            </FilterColumn>
          )}
          {FILTERS_FIELDS_CONDITION_MAPPING[reportStore.getTableType].includes('paymentMethod') && (
            <PermissionsGate
              scopes={['merchant.finances.transactions.read']}
            >
              <FilterColumn width={165} pr={6}>
                <FormItemElement name='paymentMethod'>
                  <TreeSelectElement
                    maxTagCount={1}
                    ph_fz={12}
                    showArrow
                    suffixIcon={<DropdownArrowIcon />}
                    switcherIcon={<DropdownArrowIcon />}
                    treeIcon
                    multiple
                    allowClear
                    small
                    placeholder={t('payment.method.title')}
                    popupClassName='tree-select-custom-dropdown'
                    iconComponent={<CreditCardIcon color={theme.colors.iconColor}
                                                   gradientColor={theme.colors.iconGradientColor} />}
                    data={renderPaymentMethodsOptions()}
                  />
                </FormItemElement>
              </FilterColumn>
            </PermissionsGate>
          )}
          {FILTERS_FIELDS_CONDITION_MAPPING[reportStore.getTableType].includes('countryList') && (
            <FilterColumn width={220} pr={6}>
              <FormItemElement name='countryList'>
                <TreeSelectElement
                  maxTagCount={1}
                  ph_fz={16}
                  showArrow
                  suffixIcon={<DropdownArrowIcon />}
                  switcherIcon={<DropdownArrowIcon />}
                  treeIcon
                  multiple
                  allowClear
                  small
                  placeholder={t('payment.country.title')}
                  popupClassName='tree-select-custom-dropdown'
                  iconComponent={<CountryIcon color={theme.colors.iconColor}
                                              gradientColor={theme.colors.iconGradientColor} />}
                >
                  {renderCountryListOptions()}
                </TreeSelectElement>
              </FormItemElement>
            </FilterColumn>
          )}
          {FILTERS_FIELDS_CONDITION_MAPPING[reportStore.getTableType].includes('status') && (
            <FilterColumn width={134} pr={6}>
              <FormItemElement name='status'>
                <TreeSelectElement
                  maxTagCount={1}
                  ph_fz={16}
                  showArrow
                  suffixIcon={<DropdownArrowIcon />}
                  switcherIcon={<DropdownArrowIcon />}
                  treeIcon
                  multiple
                  allowClear
                  small
                  placeholder={t('payment.status.title')}
                  popupClassName='tree-select-custom-dropdown'
                  iconComponent={selectedStatus && STATUS_FILTER_ICONS[selectedStatus[0]] ?
                    STATUS_FILTER_ICONS[selectedStatus[0]] :
                    <StatusIcon color={theme.colors.iconColor} gradientColor={theme.colors.iconGradientColor} />
                  }
                >
                  {renderStatusListOptions()}
                </TreeSelectElement>
              </FormItemElement>
            </FilterColumn>
          )}
          {FILTERS_FIELDS_CONDITION_MAPPING[reportStore.getTableType].includes('download-csv') && (
            <PermissionsGate
              scopes={['merchant.finances.transactions.export']}
            >
              <FilterColumn width={24} pr={0} ml='auto'>
                <DownloadLink onClick={downloadCsvHandler}>
                  <SpinnerElement spinning={isDownloadingCsvFile}>
                    <DownloadIcon color={theme.colors.iconColor} />
                  </SpinnerElement>
                </DownloadLink>
              </FilterColumn>
            </PermissionsGate>
          )}
        </FiltersRow>
      </Form>
    </TransactionFiltersWrapper>
  );
};

export default compose(
  withTranslation(),
  inject('generalReportStore', 'reportStore', 'profileStore', 'paymentStore'),
)(observer(ReportsFilters));
