/**
 * @file
 * Contains Kyc Content Header Component.
 */
import React from 'react';
import { ReactComponent as AddIcon } from '_common/assets/icons/kyc-add-more-icon.svg';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import {
  KycContentHeaderWrapper,
  KycContentHeaderDescription,
  KycContentHeaderButtonWrapper,
  KycContentHeaderButton,
} from './Kyc.style';

const KycContentHeader = (props) => {
  const { t, description, Icon } = props;

  return (
    <KycContentHeaderWrapper>
      <KycContentHeaderDescription>
        <Icon /> {description}
      </KycContentHeaderDescription>
      <KycContentHeaderButtonWrapper>
        <KycContentHeaderButton>
          <AddIcon /> {t('kyc.button.addMore')}
        </KycContentHeaderButton>
      </KycContentHeaderButtonWrapper>
    </KycContentHeaderWrapper>
  );
};

export default compose(withTranslation())(KycContentHeader);
