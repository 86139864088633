/**
 * @file
 * Contains Sidebar component.
 */
import React, { useEffect, useState } from 'react';
import { compose } from 'recompose';
import { inject } from 'mobx-react';
import { SidebarWrapper } from './Sidebar.style';
import SidebarLogo from './SidebarLogo';
import SidebarNav from './SidebarNav';

const Sidebar = (props) => {
  const { profileStore, ...rest } = props;

  /**
   * Permissions State.
   */
  const [permissions, setPermissions] = useState({});

  /**
   * Add Permissions Data To State.
   */
  useEffect(() => {
    const perms = profileStore.getProfileData.permission;

    // TODO: Should be fixed.
    // setPermissions(perms.finances);
  }, [profileStore.getProfileData]);

  return (
    <SidebarWrapper {...rest}>
      <SidebarLogo />
      <SidebarNav permissions={permissions} />
    </SidebarWrapper>
  );
};

export default compose(inject('profileStore'))(Sidebar);
