/**
 * @file
 * Contains Registration is successfully Component.
 */
import React from 'react';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import {
  MainContainer,
  TextContainer,
  Title,
  Description,
  Icon,
  DividerLine,
  Footer,
  SubmitButton,
  FooterWrapper,
} from './RegistrationIsSuccessfully.style';
import { ReactComponent as PaperAirplaneIcon } from '_common/assets/icons/paper-airplane.svg';
import { ReactComponent as KycNextArrow } from '_common/assets/icons/kyc-continue-icon.svg';
import links from '_common/routes/urls';

const RegistrationIsSuccessfully = (props) => {
  const {
    t,
    history,
  } = props;

  return (
    <MainContainer>
      <TextContainer>
        <Title>
          {t('regSuccessfully.title')}
        </Title>
        <Icon>
          <PaperAirplaneIcon />
        </Icon>
        <Description>
          {t('regSuccessfully.description')}
        </Description>
      </TextContainer>
      <FooterWrapper>
        <Footer>
          <div></div>
          <SubmitButton
            htmlType="submit"
            onClick={() => history.push(links.questionnaire)}
          >
            {t('questionnaire.button.next')} <KycNextArrow />
          </SubmitButton>
        </Footer>
        <DividerLine />
      </FooterWrapper>
    </MainContainer>
  );
};

export default compose(withTranslation())(RegistrationIsSuccessfully);
