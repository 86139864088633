/**
 * @file
 * Contains general report store.
 */

import { action, computed, observable, runInAction } from 'mobx';
import ReportService from '_common/services/report.service';
import { TRANSACTIONS_TABLE_CONFIG } from '_common/constants/reports.constant';

class GeneralReportStore {
  /**
   * Data Is Loading.
   */
  @observable
  isLoading = false;

  /**
   * Weekly Report Data.
   */
  @observable
  weeklyReport = {};

  /**
   * Date report.
   */
  @observable
  dateReport = {};

  /**
   * Transactions report.
   */
  @observable
  transactionsReport = {};

  /**
   * Users Report
   */
  @observable
  usersReport = {};

  /**
   * Balance Report
   */
  @observable
  balanceReport = {};

  /**
   * Statistic Report
   */
  @observable
  statisticReport = {};

  /**
   * Filter params
   */
  @observable
  filterParams = {};

  /**
   * Transactions created sort type.
   */
  @observable
  transactionsCreatedSortType = 'DESC';

  /**
   *  Loading Weekly Report status.
   */
  @observable
  isLoadingWeeklyReport = false;

  /**
   *  Loading Balance Report status.
   */
  @observable
  isBalanceReportLoading = false;

  /**
   *  Csv reports list.
   */
  @observable
  csvReportsList = [];

  /**
   *  Columns Settings Open state.
   */
  @observable
  columnsSettingsOpen = false;

  /**
   * Get columns Settings Open state.
   */
  @computed
  get getColumnsSettingsOpen() {
    return this.columnsSettingsOpen;
  }

  /**
   * Get transactions created sort type.
   */
  @computed
  get getTransactionsCreatedSortType() {
    return this.transactionsCreatedSortType;
  }

  /**
   * Get Data Is Loading.
   */
  @computed
  get getIsLoading() {
    return this.isLoading;
  }

  /**
   * Get dashboard data from store.
   */
  @computed
  get getDashboardWeeklyReport() {
    return this.weeklyReport.data;
  }

  /**
   * Get Date Report Data.
   */
  @computed
  get getDateReportData() {
    return this.dateReport;
  }

  /**
   * Get Transactions Report Data.
   */
  @computed
  get getTransactionsReportData() {
    return this.transactionsReport;
  }

  /**
   * Get Date Report Data.
   */
  @computed
  get getDateUsersData() {
    return this.usersReport.data;
  }

  /**
   * Get Balance Report Data.
   */
  @computed
  get getBalanceReport() {
    return this.balanceReport;
  }

  /**
   * Get Statistic report
   */
  @computed
  get getStatisticReport() {
    return this.statisticReport;
  }

  /**
   * Get filter params
   */
  @computed
  get getFilterParams() {
    return this.filterParams;
  }

  /**
   * Get loading Weekly Report
   */
  @computed
  get getIsLoadingWeeklyReport() {
    return this.isLoadingWeeklyReport;
  }

  /**
   * Get is Balance Report Loading
   */
  @computed
  get getIsBalanceReportLoading() {
    return this.isBalanceReportLoading;
  }

  /**
   * Get is CSV reports list
   */
  @computed
  get getCsvReportsList() {
    return this.csvReportsList;
  }

  /**
   * Set filter params.
   * @param {any} params.
   * Status flag.
   */
  @action
  setFilterParams = (params) => {
    this.filterParams = params;
  };

  /**
   * Set is table loading.
   * @param {boolean} status.
   * Status flag.
   */
  @action
  setIsLoading = (status) => {
    this.isLoading = status;
  };

  /**
   * Set is Weekly Report loading.
   * @param {boolean} status.
   * Status flag.
   */
  @action
  setIsLoadingWeeklyReport = (status) => {
    this.isLoadingWeeklyReport = status;
  };

  /**
   * Set is CSV reports list.
   * @param {array} data.
   * Status flag.
   */
  @action
  setCsvReportsList = (data) => {
    this.csvReportsList = data;
  };

  /**
   * Fetch Weekly Report Data.
   * @param {string} profileId.
   * Profile id.
   */
  @action
  getDashboardWeeklyReportAction = async (profileId) => {
    this.setIsLoadingWeeklyReport(true);
    try {
      const dashboardWeeklyReport = await ReportService.getWeeklyReport(profileId);
      runInAction(() => {
        this.weeklyReport = dashboardWeeklyReport;
      });
    } catch (e) {
      throw new Error(e);
    } finally {
      this.setIsLoadingWeeklyReport(false);
    }
  };

  /**
   * Fetch Date Report Data.
   * @param {object} data.
   * Finance report data.
   * @param {string} profileId.
   * Profile id.
   */
  @action
  getDateReportDataAction = async (data, profileId) => {
    try {
      ReportService.cancelGetDateReportPreviousRequest();
      const dashboardDateReport = await ReportService.getDateReport(data, profileId);

      runInAction(() => {
        this.dateReport = dashboardDateReport || {};
      });
    } catch (e) {
      throw new Error(e);
    }
  };

  /**
   * Get transactions report data.
   * @param {object} data.
   * Transactions data.
   */
  @action
  setTransactionsReportData = (data) => {
    this.transactionsReport = data;
  };

  /**
   * Get Date Report Data.
   * @param {object} value.
   */
  @action
  setDateReportData(value = {}) {
    this.dateReport = value;
  }

  /**
   * Set Balance Report Loading status.
   * @param {boolean} status.
   * Loading status.
   */
  @action
  setIsBalanceReportLoading = (status) => {
    this.isBalanceReportLoading = status;
  };

  /**
   * Get balance report.
   * @param {string} profileId.
   * Profile id.
   */
  @action
  getBalanceReportAction = async (profileId) => {
    this.setIsBalanceReportLoading(true);
    try {
      const requestData = await ReportService.getBalanceReport(profileId);
      const currency = requestData.total_currency;
      const renderCurrencyOutput = (asset = '') => {
        const currencyDataArray = asset.split(':');
        if (asset.startsWith('iso')) {
          return currencyDataArray?.[1];
        }
        if (currencyDataArray?.length >= 2) {
          return `${currencyDataArray?.[1]} (${currencyDataArray?.[0]})`
        }

        return asset;
      }
      const data = {
        ...requestData,
        total_currency: renderCurrencyOutput(currency),
      };

      runInAction(() => {
        this.balanceReport = data;
      });

      return data;
    } catch (e) {
      runInAction(() => {
        this.balanceReport = {};
      });
      throw new Error(e);
    } finally {
      this.setIsBalanceReportLoading(false);
    }
  };

  /**
   * Get Users report.
   * @param {object} data.
   * Users data.
   * @param {string} profileId.
   * Profile id.
   */
  @action
  getUsersReportDataAction = async (data, profileId) => {
    try {
      const dashboardUsersReport = await ReportService.getUsersReport(data, profileId);

      runInAction(() => {
        this.usersReport = dashboardUsersReport;
      });
    } catch (e) {
      throw new Error(e);
    }
  };

  /**
   * Get transaction list CSV data file.
   * @param {object} data.
   * Transactions data.
   * @param {string} profileId.
   * Profile id.
   */
  @action
  getTransactionListCsv = async (data, profileId) => {
    try {
      return ReportService.getTransactionListCsv(data, profileId);
    } catch (e) {
      throw new Error(e);
    }
  };

  /**
   * Get transaction list CSV data file V2.
   * @param {string} profileId.
   * Profile id.
   */
  @action
  getCreateTransactionCsvReport = async (profileId) => {
    try {
      await ReportService.getCreateTransactionCsvReportService(
        {
          sort_by: this.transactionsCreatedSortType,
          ...this.filterParams,
        },
        profileId,
      );
    } catch (e) {
      throw new Error(e);
    }
  };

  /**
   * Get transactions report.
   * @param {object} data.
   * Action type.
   * @param {string} profileId.
   * Profile id.
   */
  @action
  getTransactionsReport = async (data, profileId) => {
    try {
      const requestData = await ReportService.getTransactionsReport(data, profileId);
      if (!('data' in requestData)) requestData.data = [];

      runInAction(() => {
        this.transactionsReport = requestData;
      });
      return requestData;
    } catch (e) {
      throw new Error(e);
    }
  };

  /**
   * Get transactions list.
   * @param {object} data - Request data.
   * @param {string} profileId - Profile id.
   */
  @action
  getTransactionsList = async (data, profileId) => {
    try {
      const { start_from } = data;

      const {
        show_more: showMore,
        transactions,
      } = await ReportService.getTransactionsList(data, profileId);
      const prevTransactions = this.transactionsReport.transactions || [];

      const updatedData = {
        ...(!!start_from ? {
          show_more: showMore,
          transactions: [...prevTransactions, ...transactions],
        } : {
          show_more: showMore,
          transactions,
        }),
      };

      runInAction(() => {
        this.transactionsReport = updatedData;
      });
      return updatedData;
    } catch (e) {
      throw new Error(e);
    }
  };

  /**
   * Get transactions list V2.
   * @param {string} profileId - Profile id.
   */
  @action
  getTransactionsListV2 = async (profileId) => {
    try {
      const { start_from } = this.filterParams;
      const {
        show_more: showMore,
        transactions,
      } = await ReportService.getTransactionsListV2(
        {
          date_column: "created_at",
          limit: TRANSACTIONS_TABLE_CONFIG.transactionsPerPage,
          sort_by: !start_from ? this.transactionsCreatedSortType : 'DESC',
          ...this.filterParams
        },
        profileId,
      );
      const prevTransactions = this.transactionsReport.transactions || [];

      const updatedData = {
        ...(!!start_from ? {
          show_more: showMore,
          transactions: [...prevTransactions, ...transactions],
        } : {
          show_more: showMore,
          transactions,
        }),
      };

      runInAction(() => {
        this.transactionsReport = updatedData;
      });
      return updatedData;
    } catch (e) {
      throw new Error(e);
    }
  };

  /**
   * Get volumes report.
   * @param {object} data.
   * Action type.
   * @param {string} profileId.
   * Profile id.
   */
  @action
  getVolumesReport = async (data, profileId) => {
    try {
      const requestData = await ReportService.getVolumesReport(data, profileId);
      if (!('data' in requestData)) requestData.data = [];

      runInAction(() => {
        this.transactionsReport = requestData;
      });
      return requestData;
    } catch (e) {
      throw new Error(e);
    }
  };

  /**
   * Get registry report.
   * @param {object} data.
   * Action type.
   * @param {string} profileId.
   * Profile id.
   */
  @action
  getRegistryReport = async (data, profileId) => {
    try {
      const requestData = await ReportService.getRegistryReport(data, profileId);
      if (!('data' in requestData)) requestData.data = [];

      runInAction(() => {
        this.transactionsReport = requestData;
      });
      return requestData;
    } catch (e) {
      throw new Error(e);
    }
  };

  /**
   * Get transaction list CSV data file.
   * @param {string} id.
   * File id.
   * @param {string} profileId.
   * Profile id.
   */
  @action
  getTransactionRegistryCsv = async (id, profileId) => {
    try {
      return await ReportService.getTransactionRegistryCsv(id, profileId);
    } catch (e) {
      throw new Error(e);
    }
  };

  /**
   * Set table Transactions created sort type.
   * @param {string} type.
   * Table data.
   */
  @action
  setTransactionsCreatedSortType = (type) => {
    this.transactionsCreatedSortType = type;
  };

  /**
   * Set Columns Settings Open State.
   * @param {boolean} state.
   * Table data.
   */
  @action
  setColumnsSettingsOpen = (state) => {
    this.columnsSettingsOpen = state;
  };

  @action
  getLoadCsvReportsList = async (profileId) => {
    try {
      const requestData = await ReportService.getCsvReports(profileId);

      runInAction(() => {
        this.csvReportsList = requestData;
      });
	    return requestData;
    } catch (e) {
      throw new Error(e);
    }
  };

  @action
  getLoadCsvReport = async (profileId, guid) => {
    try {
      await ReportService.getTransactionCsvV2(profileId, guid);
    } catch (e) {
      throw new Error(e);
    }
  };

}

export default GeneralReportStore;
