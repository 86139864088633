import React from 'react';
import { useTranslation } from 'react-i18next';

import { Form } from 'antd';

import themeConfig from 'config';
import Background from '_common/assets/img/registration-bg.png';
import Loader from '_common/components/Loader/Loader';
import { AuthIconWrapper, AuthRow, ErrorMessage } from '../Auth.style';
import {
  VerificationAuthButonSubmit,
  VerificationBackLink,
  VerificationBottomMenu,
  VerificationEmailLabel,
  VerificationForm,
  VerificationFormWrapper,
  VerificationInputsWrapper,
} from './Verification.Style';
import AuthLinks from 'Auth/AuthLinks';
import { useBrowserVerificationFlow } from '_common/hooks/api/kratos/useVerificationFlow.hook';
import { useVerificationMutation } from '_common/hooks/api/kratos/useVerificationMutation.hook';

import links from '_common/routes/urls';
import { AuthNodes } from '../Auth.nodes';
import {AUTH_TYPES} from '../Auth.constants';
import AuthScreenAction from '_common/components/AuthScreensAction/AuthScreenAction';

const theme = themeConfig[process.env.REACT_APP_THEME] || themeConfig.DEFAULT;

const Verification = ({ history }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const urlSearchParams = new URLSearchParams(window.location.search);
  const flowId = urlSearchParams.get('flow');
  const code = urlSearchParams.get('code');

  const query = useBrowserVerificationFlow(flowId);

  const mutation = useVerificationMutation({
    onSuccess: (res) => {
      if (res.state === 'passed_challenge') {
        history.push(links.dashboard);
      }

			if (res.state === 'sent_email') {
				history.push(links.public.login);
			}
    },
    flowId: query?.data?.id,
  });

  if (query.isLoading) return <Loader />;

  if (query.isError)
    return (
      <div>
        <AuthRow>
          <VerificationForm>
            <VerificationFormWrapper>
              <AuthIconWrapper>
                <theme.assets.icons.loginLogo />
                <ErrorMessage>
	                {t('verification.error.somethingWentWrong')}
                </ErrorMessage>
              </AuthIconWrapper>
            </VerificationFormWrapper>
          </VerificationForm>

          <AuthScreenAction type={AUTH_TYPES.login} noRegBtn={true}/>
        </AuthRow>
      </div>
    );

  if (query.isSuccess) {
    const nodes = query.data.ui.nodes.filter((node) => node.attributes.name !== 'method');

    const initialValues = code ? { code } : {};

    const state = query.data.state;

    return (
      <div>
        <AuthRow>
          <VerificationForm
            layout="vertical"
            form={form}
            onFinish={mutation.mutate}
            initialValues={initialValues}
          >
            <VerificationFormWrapper>
              <AuthIconWrapper>
                <theme.assets.icons.loginLogo />
              </AuthIconWrapper>
              <VerificationInputsWrapper>
                {state === 'choose_method' && (
                  <VerificationEmailLabel>{t('verification.email.cta')}</VerificationEmailLabel>
                )}{' '}
                {state === 'sent_email' && (
                  <VerificationEmailLabel>
                    {t('verification.email.cta.sentEmail')}{' '}
                  </VerificationEmailLabel>
                )}
                {state === 'passed_challenge' && (
                  <VerificationEmailLabel>
                    {t('verification.email.cta.passedChallange')}{' '}
                  </VerificationEmailLabel>
                )}
                <AuthNodes nodes={nodes} />
              </VerificationInputsWrapper>
              <VerificationBottomMenu>
                <VerificationBackLink href="/auth?type=login">{t('verification.email.cta.backToLogin')}</VerificationBackLink>
                {state !== 'passed_challenge' && (
                  <Form.Item name="method" initialValue="code">
                    <VerificationAuthButonSubmit htmlType="submit" loading={mutation.isLoading}>
	                    {t('verification.email.cta.submit')}
                    </VerificationAuthButonSubmit>
                  </Form.Item>
                )}
              </VerificationBottomMenu>
              <AuthLinks />
            </VerificationFormWrapper>
          </VerificationForm>
          <AuthScreenAction type={AUTH_TYPES.login} noRegBtn={true}/>
        </AuthRow>
      </div>
    );
  }

  return null;
};

export default Verification;
