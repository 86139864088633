import { kratosClient } from './apiClient.service';
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';

/**
 *
 * @type {import('@tanstack/react-query').QueryFunction<import('@ory/client').SettingsFlow, string[]>}
 * @param {string} [id]
 */
const fetchSettingsFlow = async (id) => {
  if (!id) {
    const { data } = await kratosClient.createBrowserSettingsFlow();
    return data;
  }

  const { data } = await kratosClient.getSettingsFlow({ id });
  return data;
};

/**
 * Update settings flow.
 *
 * @async
 * @function - fetchUpdateSettingsFlow.
 * @param {string} id - flow id.
 * @param {object} data - settings flow data.
 */
const fetchUpdateSettingsFlow = async (id, data) => {
	const response = await kratosClient.updateSettingsFlow({ flow: id, updateSettingsFlowBody: data });
	return response.data;
};

/**
 *
 * @param id {string}
 * @type {import('@tanstack/react-query').QueryFunction<import('@ory/client').VerificationFlow, string[]>}
 */
const fetchVerificationFlow = async (id) => {
  if (!id) {
    const { data } = await kratosClient.createBrowserVerificationFlow();
    return data;
  }

  const { data } = await kratosClient.getVerificationFlow({ id });
  return data;
};

/**
 *
 * @param id {string}
 * @type {import('@tanstack/react-query').QueryFunction<import('@ory/client').RegistrationFlow, string[]>}
 */
const fetchRegistrationFlow = async (id) => {
  if (!id) {
    const { data } = await kratosClient.createBrowserRegistrationFlow();
    return data;
  }

  const { data } = await kratosClient.getRegistrationFlow({ id });
  return data;
};

/**
 *
 * @param id {string}
 * @type {import('@tanstack/react-query').QueryFunction<import('@ory/client').RecoveryFlow, string[]>}
 */
const fetchRecoveryFlow = async (id) => {
  if (!id) {
    const { data } = await kratosClient.createBrowserRecoveryFlow();
    return data;
  }

  const { data } = await kratosClient.getRecoveryFlow({ id });
  return data;
};

/**
 *
 * @param id {string}
 * @param aal {'aal1' | 'aal2'}
 * @param needRefresh {boolean}
 * @type {import('@tanstack/react-query').QueryFunction<import('@ory/client').RecoveryFlow, string[]>}
 */
const fetchLoginFlow = async (id, aal, needRefresh) => {
  if (!id) {
    const { data } = await kratosClient.createBrowserLoginFlow({
      aal: aal || 'aal1',
      refresh: needRefresh,
    });
    return data;
  }

  const { data } = await kratosClient.getLoginFlow({
    id,
  });

  return data;
};

/**
 * Update login flow.
 *
 * @async
 * @function - fetchUpdateLoginFlow.
 * @param {object} data - update login flow parameters.
 */
const fetchUpdateLoginFlow = async (data) => {
	await kratosClient.updateLoginFlow(data);
};

/**
 *
 * @type {import('@tanstack/react-query').QueryFunction<import('@ory/client').Session, string[]>}
 */
const fetchSession = async () => {
  const { data } = await kratosClient.toSession();
  return data;
};

/**
 *
 * @param res {unknown}
 * @param error {unknown}
 * @param text {string} - notification text.
 */
export const onMutation = (res, error, text) => {
  const messages = res?.ui?.messages || error.response.data.ui.messages;
  const lastMessage = messages[messages.length - 1];

	notification.open({
		message: !!text ? text : lastMessage.text,
		type: !!text ? 'info' : lastMessage.type,
	});
};

export const kratosService = {
  fetchSettingsFlow,
	fetchUpdateSettingsFlow,
  fetchVerificationFlow,
  fetchRegistrationFlow,
  fetchRecoveryFlow,
  fetchLoginFlow,
	fetchUpdateLoginFlow,
  fetchSession,
  onMutation,
};
