export const AUTH_TYPES = {
  login: 'login',
  register: 'register',
};

export const CSRF_TOKEN_NAME = 'csrf_token';

export const STATUS_SESSION_EXISTS = 'session_already_available'

export const METHOD = 'password';

export const EMAIL_RULES = (t) => {
  return [
    {
      required: true,
      message: t('input.field.required'),
    },
    {
      type: 'email',
      message: t('input.field.notValid.email'),
    },
  ];
};

export const PASSWORD_RULES = (t) => {
  return [
    {
      required: true,
      message: t('input.field.required'),
    },
  ];
};

export const CREATE_PASSWORD_RULES = (t) => {
  return [
    {
      required: true,
      message: t('input.field.required'),
    },
    {
      pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!$#%&*+-.\/:<=>?@\[\]^_{}~])[a-zA-Z\d!$#%&*+-.\/:<=>?@\[\]^_{}~]{8,}$/,
      message: t('register.password.validation'),
    },
  ];
};
