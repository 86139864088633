import styled from 'styled-components';

import FormItemElement from '_common/components/FormItem/FormItemElement';
import { AuthButtonSubmit } from 'Auth/Auth.style';
import { AuthInput } from 'Auth/Auth.style';
import { Form } from 'antd';

export const RecoveryFormItemElement = styled(FormItemElement)`
    width: 100%;
    max-width: 420px;
`;

export const RecoveryAuthInput = styled(AuthInput)`
    width: 100%;
`;

export const RecoveryAuthButonSubmit = styled(AuthButtonSubmit)`
    margin: 0 !important;
`;

export const RecoveryForm = styled(Form)`
  width: 40%;
`;

