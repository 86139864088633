/**
 * @file
 * Contains Notification Component Styles.
 */
import styled from 'styled-components';
import WarningIcon from '_common/assets/icons/dashboard-warning-icon.svg';

export const NotificationContainer = styled.div`
  border-radius: 10px;
  background: ${(props) => props.type};
  padding: ${(props) => props.key ? '40px 20px' : '0px 0px'};
  color: ${(props) => props.theme.colors.white};
  position: relative;
  margin-bottom: ${(props) => props.key ? '20px' : '0px'};
  animation: scale 0.5s ease;
  transition: all 1.5s ease;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const NotificationTitle = styled.div`
  font-weight: 700;
  position: relative;
  margin-left: 24px;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -26px;
    width: 20px;
    height: 20px;
    background: url(${WarningIcon}) center center no-repeat;
  }
`;

export const NotificationCloseIconWrapper = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
`;
