/**
 * @file
 * Contains Finance Component.
 */
import React from 'react';
import MainLayout from '_common/layouts/Main.layout';
import { Row, Col } from 'antd';
import { FinanceContainer } from './Finance.style';
import FinanceReport from './FinanceReport';
// import FinanceTypeTable from './FinanceTypeTable';
// import FinanceFilters from './FinanceFilters';

const Finance = () => {
  return (
    <MainLayout>
      <FinanceContainer>
        <Row gutter={[20, 0]}>
          {/* <Col xs={24}> */}
          {/*  <FinanceFilters /> */}
          {/* </Col> */}
          {/* <Col xs={14}> */}
          {/*  <FinanceTypeTable /> */}
          {/* </Col> */}
          <Col xs={10}>
            <FinanceReport />
          </Col>
        </Row>
      </FinanceContainer>
    </MainLayout>
  );
};

export default Finance;
