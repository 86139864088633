/**
 * @file
 * Contains Weekly Report Component.
 */
import React, { useEffect, useState } from 'react';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import themeConfig from 'config';
import {
  DashboardTurnoverMainTitleWrapper,
  DashboardTurnoverMainTitle,
  DashboardTurnoverTitleContainer,
  DashboardTurnoverSubTitle,
  DashboardTurnoverWrapper,
  WeeklyReportTable,
  WeeklyReportTableWrapper,
  TableTotalRow,
  TableTotalCell,
  DashboardIconWrapper,
} from './Dashboard.style';
import SpinnerElement from '_common/components/Spinner/SpinnerElement';
import CloseWeeklyReportIcon from '_common/assets/renderSvgIcons/CloseWeeklyReportIcon';
import OpenWeeklyReportIcon from '_common/assets/renderSvgIcons/OpenWeeklyReportIcon';
import Utils from '_common/utils';

const DashboardWeeklyReport = (props) => {
  const { t, generalReportStore, toggleReport, showReport, selectedProfileId } = props;

  /**
   * Weekly Report State.
   */
  const [dashboardWeeklyReport, setDashboardWeeklyReport] = useState({});

  /**
   * Summary Weekly Report State.
   */
  const [summaryReport, setSummaryReport] = useState({});

  /**
   * Is KYC flag.
   */
  const [isKYC, setIsKYC] = useState(true);

  /**
   * Fetching Dashboard Weekly Report from API.
   */
  useEffect(() => {
    (async () => {
      try {
        await generalReportStore.getDashboardWeeklyReportAction(selectedProfileId);
      } catch (e) {
        console.error(e);
      }
    })();
  }, [generalReportStore, selectedProfileId]);

  /**
   * Catching Dashboard Weekly Report data form store.
   */
  useEffect(() => {
    const data = generalReportStore.getDashboardWeeklyReport;
    if (data) {
      setDashboardWeeklyReport(data?.by_day);
      setSummaryReport(data?.summary);
    }
  }, [generalReportStore.getDashboardWeeklyReport]);

  /**
   * Check if KYC enabled.
   */
  useEffect(() => {
    const data = Object.values(dashboardWeeklyReport);
    const kycFlag = data.some(item => 'balance' in item);

    setIsKYC(kycFlag);
  }, [dashboardWeeklyReport]);

  /**
   * Report Table Data.
   */
  const reportTableData = Object.entries(dashboardWeeklyReport).map((day) => ({
    date: moment.unix(day[0]).calendar(null, {
      lastWeek: 'dddd',
      lastDay: 'dddd',
      sameDay: '[Today]',
    }),
    n: day[1]?.n,
    turnover: day[1]?.amount,
    key: day[0],
  }));

  /**
   * Full Report Table Data.
   */
  const fullReportTableData = Object.entries(dashboardWeeklyReport).map((day) => {
    return {
      date: moment.unix(day[0]).calendar(null, {
        lastWeek: 'dddd',
        lastDay: 'dddd',
        sameDay: '[Today]',
      }),
      n: day[1]?.n,
      amount_deposit: day[1]?.amount_deposit,
      amount_withdraw: day[1]?.amount_withdraw,
      ...('balance' in day[1] && ({ balance: day[1]?.balance })),
      n_success: day[1]?.n_success,
      n_failed: day[1]?.n_failed,
      n_pending: day[1]?.n_pending,
      ...('kyc_success' in day[1] && ({ kyc_success: day[1]?.kyc_success })),
      ...('kyc_failed' in day[1] && ({ kyc_failed: day[1]?.kyc_failed })),
      key: day[0],
    };
  });

  /**
   * Small Report Columns.
   */
  const columnsReport = [
    {
      title: 'DAY',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'TRANSFERS',
      dataIndex: 'n',
      key: 'n',
    },
    {
      title: 'TURNOVER',
      dataIndex: 'turnover',
      key: 'turnover',
      align: 'right',
      render: (text, record) => Utils.trimAmount(record.turnover),
    },
  ];

  /**
   * Small Report Columns.
   */
  const columnsFullReport = [
    {
      title: 'DAY',
      dataIndex: 'date',
      key: 'date',
      width: 83,
    },
    {
      title: 'TRANSFERS',
      dataIndex: 'n',
      key: 'n',
      align: 'right',
      width: 70,
    },
    {
      title: 'DEPOSIT',
      dataIndex: 'amount_deposit',
      key: 'amount_deposit',
      width: 138,
      align: 'right',
      render: (text, record) => Utils.trimAmount(record.amount_deposit),
    },
    {
      title: 'WITHDRAW',
      dataIndex: 'amount_withdraw',
      key: 'amount_withdraw',
      width: 134,
      align: 'right',
      render: (text, record) => Utils.trimAmount(record.amount_withdraw),
    },
    ...(isKYC ? [
      {
        title: 'BALANCE',
        dataIndex: 'balance',
        key: 'balance',
        width: 118,
        align: 'right',
        render: (text, record) => Utils.trimAmount(record.balance),
      },
    ] : []),
    {
      title: 'SUCCESS TRANSFERS',
      dataIndex: 'n_success',
      key: 'n_success',
      align: 'right',
      width: 118,
    },
    {
      title: 'DECLINED TRANSFERS',
      dataIndex: 'n_failed',
      key: 'n_failed',
      align: 'right',
      width: 140,
    },
    {
      title: 'PENDING TRANSFERS',
      dataIndex: 'n_pending',
      key: 'n_pending',
      align: 'right',
      width: 133,
    },
    ...(isKYC ? [
      {
        title: 'SUCCES. Questionnaire',
        dataIndex: 'kyc_success',
        key: 'kyc_success',
        align: 'right',
      },
    ] : []),
    ...(isKYC ? [
      {
        title: 'DECLINED Questionnaire',
        dataIndex: 'kyc_failed',
        align: 'right',
        key: 'kyc_failed',
      },
    ] : []),
  ];

  /**
   * Render Report Table Footer.
   * @param {boolean} showReports.
   * Show report.
   */
  const renderReportFooter = (showReports) => (
    <>
      <TableTotalRow>
        <TableTotalCell>TOTAL</TableTotalCell>
        {showReports ? (
          <>
            <TableTotalCell align="right">{summaryReport?.n}</TableTotalCell>
            <TableTotalCell align="right">{Utils.trimAmount(summaryReport?.amount_deposit)}</TableTotalCell>
            <TableTotalCell align="right">{Utils.trimAmount(summaryReport?.amount_withdraw)}</TableTotalCell>
            {isKYC && (
              <TableTotalCell align="right">{Utils.trimAmount(summaryReport?.balance)}</TableTotalCell>
            )}
            <TableTotalCell align="right">{summaryReport?.n_success}</TableTotalCell>
            <TableTotalCell align="right">{summaryReport?.n_failed}</TableTotalCell>
            <TableTotalCell align="right">{summaryReport?.n_pending}</TableTotalCell>
            {isKYC && (
              <TableTotalCell align="right">{summaryReport?.kyc_success}</TableTotalCell>
            )}
            {isKYC && (
              <TableTotalCell align="right">{summaryReport?.kyc_failed}</TableTotalCell>
            )}
          </>
        ) : (
          <>
            <TableTotalCell align="right">{summaryReport?.n}</TableTotalCell>
            <TableTotalCell align="right">{Utils.trimAmount(summaryReport?.amount)}</TableTotalCell>
          </>
        )}
      </TableTotalRow>
    </>
  );

  /**
   * App Theme
   */
  const { theme } = themeConfig[process.env.REACT_APP_THEME] || themeConfig.DEFAULT;

  return (
    <DashboardTurnoverWrapper className={showReport && 'opened'}>
      <SpinnerElement spinning={generalReportStore.getIsLoadingWeeklyReport}>
        <DashboardTurnoverMainTitleWrapper>
          <DashboardTurnoverTitleContainer>
            <DashboardTurnoverMainTitle>{t('dashboard.weeklyReport.title')}</DashboardTurnoverMainTitle>
            <DashboardTurnoverSubTitle>
              {t('dashboard.weeklyReport.firstSubTitle')}
              {moment().subtract(6, 'd').format('DD-MM-YYYY')}
              {t('dashboard.weeklyReport.secondSubTitle')}
            </DashboardTurnoverSubTitle>
          </DashboardTurnoverTitleContainer>
            {/*<DashboardIconWrapper>*/}
            {/*  {showReport ? (*/}
            {/*    <CloseWeeklyReportIcon color={theme.colors.white} onClick={toggleReport} />*/}
            {/*  ) : (*/}
            {/*    <OpenWeeklyReportIcon color={theme.colors.white} onClick={toggleReport} />*/}
            {/*  )}*/}
            {/*</DashboardIconWrapper>*/}
        </DashboardTurnoverMainTitleWrapper>
        <WeeklyReportTableWrapper>
          <WeeklyReportTable
            columns={showReport ? columnsFullReport : columnsReport}
            dataSource={showReport ? fullReportTableData : reportTableData}
            pagination={false}
            summary={() => renderReportFooter(showReport)}
          />
        </WeeklyReportTableWrapper>
      </SpinnerElement>
    </DashboardTurnoverWrapper>
  );
};

DashboardWeeklyReport.propTypes = {
  toggleReport: PropTypes.func.isRequired,
  showReport: PropTypes.bool.isRequired,
};

export default compose(
  inject('generalReportStore'),
  withTranslation()
)(observer(DashboardWeeklyReport));
