/**
 * @file
 * Custom hook usePermissions.
 * Check permissions.
 */

import { useContext } from 'react';
import { StoresContext } from '../../index';

const usePermissions = (scopes) => {
  /**
   * Use context for stores.
   */
  const { profileStore } = useContext(StoresContext);

  const userPermissions = profileStore.getCurrentProfilePermissions || [];
  const scopesCatalog = new Set(scopes);

  let accessFlag = userPermissions.some((permission) => scopesCatalog.has(permission));

  if (scopes?.length === 0) {
    accessFlag = true;
  }

  return [accessFlag];
}

export default (usePermissions);
