/**
 * @file
 * Contains Auth Style Component
 */
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Button, Col, Input, Row, Tabs } from 'antd';

export const LoginWrapper = styled.div`
  width: 40vw;
  height: 100vh;
  opacity: ${(props) => (props.showLogin ? '1' : '0')};
  z-index: ${(props) => (props.showLogin ? '99' : '1')};

  transition: all 0.5s;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: ${(props) => props.theme.colors.extraLightGrey};

  @media (min-width: 1400px) {
    width: 40vw;
  }
`;

export const RegistrationWrapper = styled.div`
  position: absolute;
  width: 40vw;
  height: 100%;
  top: 0;
  right: ${(props) => (props.showLogin ? '50%' : '0')};
  opacity: ${(props) => (props.showLogin ? '0' : '1')};
  transform: scale(${(props) => (props.showLogin ? '0' : '1')});
  transition: all 0.5s ease-in-out;
  z-index: ${(props) => (props.showLogin ? '1' : '99')};
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${(props) => props.theme.colors.extraLightGrey};
`;

export const RegistrationContainer = styled.div`
  height: 100vh;
  right: -100%;
  transition: all 0.5s ease-in-out;
  opacity: 0;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: ${(props) => props.theme.colors.mainColor};
  }

  &.active {
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 1;
  }
`;

export const RegistrationAgreementWrapper = styled.div`
  color: ${(props) => props.theme.colors.darkGrey};
  font-size: 14px;
  width: 100%;
  text-align: center;

  a {
    display: inline-block;
    margin-left: 6px;
    margin-bottom: 0;
  }
`;

export const AuthRow = styled(Row)``;

export const AuthCol = styled(Col)`
  background: url(${(props) => props.background}) center center no-repeat;
  background-size: cover;
  background-color: ${(props) => props.theme.colors.mainColor};
  height: 100%;
`;

export const AuthIconWrapper = styled.div`
  text-align: center;

  svg {
    width: 120px;
  }
  
  @media (min-width: 1400px) {

    svg {
      width: 152px;
    }
  }
`;

export const AuthFormWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: 20px auto;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const AuthLoginTabs = styled(Tabs)`
  &.ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
    width: 100%;
    flex-grow: 1;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${(props) => props.theme.colors.black};
    font-weight: 600;
    font-size: 20px;
  }

  &.ant-tabs-top > .ant-tabs-nav,
  .ant-tabs-bottom > .ant-tabs-nav,
  .ant-tabs-top > div > .ant-tabs-nav,
  .ant-tabs-bottom > div > .ant-tabs-nav {
    margin: 44px 0 47px;

    @media (min-width: 1400px) {
      margin: 52px;
    }
  }

  .ant-tabs-tab {
    width: 100%;
    margin-right: 0;
    text-align: center;
    color: ${(props) => props.theme.colors.darkGrey};
    background: transparent;

    &:hover {
      color: ${(props) => props.theme.colors.mainColor};
    }
  }

  .ant-tabs-tab-btn {
    width: 100%;
  }

  .ant-tabs-ink-bar {
    background: ${(props) => props.theme.colors.mainColor};
  }

  &.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar,
  .ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar {
    display: none;
  }

  .ant-tabs-tabpane {
    position: relative;
  }
`;

export const AuthLoginTab = styled(Tabs.TabPane)``;

export const AuthInput = styled(Input)`
  &.ant-input {
    border-radius: 10px;
    padding: 0 30px;
    line-height: 50px;
    font-weight: 400;
    color: ${(props) => props.theme.colors.mainColor};
    font-size: 14px;
    
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      transition: background-color 5000s ease-in-out 0s;
      -webkit-text-fill-color: ${(props) => props.theme.colors.mainColor};
    }
  }
`;

export const SubTabTitle = styled.div`
  color: #a4a6b3;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.3;
  text-align: center;
  margin-bottom: 50px;
`;

export const AuthInputPassword = styled(Input.Password)`
  &.ant-input-affix-wrapper > input[type='text'].ant-input {
    font-weight: 400;
    color: ${(props) => props.theme.colors.mainColor};
    font-size: 14px;

    svg {
      fill: green !important;
    }
    
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      -webkit-text-fill-color: ${(props) => props.theme.colors.mainColor};
    }
  }

  &.ant-input-affix-wrapper {
    border-radius: 10px;
    padding: 0 30px;
    font-size: 14px;
    line-height: 50px;
    border-color: ${(props) => props.theme.colors.lightGrey} !important;

    .anticon,
    .anticon-eye,
    .ant-input-password-icon {
      svg {
        fill: ${(props) => props.theme.colors.mainColor};
      }
    }

    .anticon-eye-invisible {
      svg {
        fill: ${(props) => props.theme.colors.darkGrey};
      }
    }
    
    .ant-input {
      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus {
        transition: background-color 5000s ease-in-out 0s;
      }
    }
  }
`;

export const AuthLink = styled(Link)`
  color: ${(props) => props.theme.colors.mainColor};
  text-align: center;
  display: block;
  text-decoration: underline;
  margin-bottom: 20px;

  &:hover {
    color: ${(props) => props.theme.colors.mainColor};
  }
`;

export const AuthButtonSubmit = styled(Button)`
  &.ant-btn {
    color: ${(props) => props.theme.colors.black};
    border: none;
    border-radius: 5px;
    padding: 7px 0;
    background: ${(props) =>
      props.themename === 'default'
        ? props.theme.background.linearGradient
        : props.theme.colors.backgroundMain};
    display: block;
    margin: 20px auto;
    height: 45px;
    transition: all 0.5s ease;
    width: 100%;
    font-size: 14px;
    font-weight: 700;

    &:hover {
      color: ${(props) => props.theme.colors.white};
    }
  }

  &.ant-btn[disabled],
  .ant-btn[disabled]:hover,
  .ant-btn[disabled]:focus,
  .ant-btn[disabled]:active {
    background: ${(props) => props.theme.colors.darkWhite};
    color: ${(props) => props.theme.colors.grey};
    box-shadow: ${(props) => props.theme.shadow.button};
    border: none;
    font-weight: 700;
  }
`;

export const RegButtonSubmit = styled(AuthButtonSubmit)`
  &.ant-btn {
    margin-top: 0;
    font-weight: 700;
    font-size: 14px;
  }
`;

export const AuthLinksWrapper = styled.div`
  display: flex;
  width: 500px;
  flex-shrink: 0;
  justify-content: space-around;
  align-items: center;
  margin: 0 auto 20px;
  padding: 10px 0 10px 0;
  border-top: 1px solid ${(props) => props.theme.colors.darkGrey};
  position: relative;

  @media (min-width: 1400px) {
    padding: 10px 0 4px 0;
  }
`;

export const AuthLinkItem = styled.a`
  color: ${(props) => props.theme.colors.darkGrey};
  font-weight: 300;
  font-size: 14px;

  @media (min-width: 1260px) {
    font-size: 14px;
  }

  &:hover {
    color: ${(props) => props.theme.colors.mainColor};
  }
`;

export const ErrorMessage = styled.div`
  text-align: center;
  color: ${(props) => props.theme.colors.errorColor};
  z-index: 99;
  width: 100%;
  margin: 20px 4px;
`;

export const Description = styled.div`
  font-size: 16px;
  text-align: center;
  line-height: 28px;
  margin: 20px 0 35px;
  font-weight: 600;
  max-width: 450px;

  @media (min-width: 1200px) {
    font-size: 20px;
  }
`;

export const AuthFormItemWrapper = styled.div`
  height: 50px;
  margin-bottom: 15px;
`;

export const AuthFormItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 350px;
`;

export const ForgotPasswordContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const ForgotPasswordLink = styled.a`
  font-weight: 500;
  color: ${(props) => props.theme.colors.darkGrey};
  &:hover {
    color: ${(props) => props.theme.colors.mainColor};
  }
`;
