/**
 * @file
 * Contains Deposit Graphic Component.
 */
import React, { useEffect, useState } from 'react';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import { Area } from 'recharts';
import GraphicContainer from '_common/components/CurvedGraphic/GraphicContainer';
import CurvedGraphic from '_common/components/CurvedGraphic/CurvedGraphic';
import NoDataGraphic from '_common/components/NoDataGraphic/NoDataGraphic';
import { withTranslation } from 'react-i18next';
import Utils from '_common/utils';
import SpinnerElement from '_common/components/Spinner/SpinnerElement';
import { DashboardGraphicTitle, DashboardGraphicContainer } from './Dashboard.style';

const DepositGraphic = (props) => {
  const { t, generalReportStore, dashboardStore } = props;

  const { timeFormatByTimestamp, isToday } = Utils;

  /**
   * Amount Data State.
   */
  const [amountData, setAmountData] = useState({});

  /**
   * Amount Graphic Dara State.
   */
  const [amountGraphicData, setAmountGraphicData] = useState([]);

  /**
   * Transaction Type Store.
   */
  const [transacstionType, setTransactionsType] = useState('');

  /**
   * Get Date Report Data from Store.
   */
  useEffect(() => {
    const fetchData = async () => {
      generalReportStore.setIsLoading(true);
      try {
        const data = await generalReportStore.getDateReportData;
        if (data) setAmountData(data?.breakdown?.by_time);
        generalReportStore.setIsLoading(false);
      } catch (e) {
        generalReportStore.setIsLoading(false);
        console.error(e);
      }
    };

    fetchData();
  }, [generalReportStore.getDateReportData]);

  /**
   * Set Curved Graphic Data to Store.
   */
  useEffect(() => {
    if (amountData) {
      const pickedData = Object.entries(amountData).map((data) => {
        return {
          yValue: Math.round(data[1].amount),
          yUnit: data[1].currency,
          xValue: timeFormatByTimestamp(data[0], isToday(amountData) ? 'HH:mm' : 'DD MMM'),
          xUnit: '',
        };
      });

      setAmountGraphicData(pickedData);
    }
  }, [amountData]);

  /**
   * Get Transactions Type from Store.
   */
  useEffect(() => {
    const data = dashboardStore.getTransactionsType;
    if (data) setTransactionsType(data);
  }, [dashboardStore.getTransactionsType]);

  return (
    <SpinnerElement spinning={generalReportStore.getIsLoading}>
      <DashboardGraphicContainer>
        {amountGraphicData.length ? (
          <>
            <DashboardGraphicTitle mt={4}>
              {transacstionType !== 'in' ? (
                <span>{t('dashboard.graphic.depositTransactions.title')}</span>
              ) : (
                <span>{t('dashboard.graphic.transactionsWithdraw.title')}</span>
              )}
            </DashboardGraphicTitle>
            <GraphicContainer height={200} width="100%">
              <CurvedGraphic data={amountGraphicData}>
                <Area type="monotone" dataKey="yValue" stroke="#82ca9d" fill="url(#splitColor)" />
              </CurvedGraphic>
            </GraphicContainer>
          </>
        ) : (
          <NoDataGraphic />
        )}
      </DashboardGraphicContainer>
    </SpinnerElement>
  );
};

export default compose(inject('generalReportStore', 'dashboardStore'), withTranslation())(observer(DepositGraphic));
