/**
 * @file Contains Report table menu button styles.
 */

import React from 'react';
import styled from 'styled-components';

export const ReportTableMenuButtonContainer = styled.div`
	position: relative;
	padding: 10px;
	border: 1px solid ${(props) => props.darkBorder ? props.theme.colors.menuButtonDarkBorderColor : props.theme.colors.menuButtonBorderColor};
	width: 38px;
	height: 24px;
  border-radius: 5px;
	margin: 0 auto;
`;

export const ReportTableDotsContainer = styled.div`
	display: flex;
	align-items: center;
	
  div {
    width: 4px;
    height: 4px;
    background: ${(props) => props.theme.colors.menuButtonDotColor};
    border-radius: 50%;
  }

  div:first-child {

  }

  div:nth-child(2) {
		margin-left: 3px;
  }

  div:nth-child(3) {
	  margin-left: 3px;
  }
`;

