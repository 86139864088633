import React from 'react';
import FreshChat from 'react-freshchat';
import { FRESH_CHAT_TOKEN } from '_common/constants/apiEndpoints.constant';

const FreshchatToggle = (props) => {
  const { userData: { id, traits: { email } } } = props;

  return(
    <FreshChat
      token={FRESH_CHAT_TOKEN}
      host={'https://armenotech-688670492163507591-98ea62a208e9a5117105469.freshchat.com'}
      externalId={id}
      onInit={widget => {
        widget.user.setProperties({
          email: email,
        })
      }}
  />)
};

export default FreshchatToggle;
