/**
 * @file
 * Contains Reports Table component view.
 */
import React, { useState, useEffect } from 'react';
import Utils from '_common/utils';
import { Col, Pagination, Row, Table as AntdTable } from 'antd';
import Table from '_common/components/TableElement/TableElement';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import { TRANSACTIONS_TABLE_CONFIG, TRANSACTIONS_VIEW_MODE } from '_common/constants/reports.constant';
import themeConfig from 'config';
import {
	PaginationStyle,
	TransactionsPaginationButton,
	ShowMoreButtonWrapperStyle,
} from './Reports.style';
import PaginationNextArrowIcon from '_common/assets/renderSvgIcons/PaginationNextArrow';
import PaginationPrevArrowIcon from '_common/assets/renderSvgIcons/PaginationPrevArrow';
import { format, fromUnixTime } from 'date-fns';
import ButtonElement from '_common/components/Button/ButtonElement';
import RefundTable from './RefundTable';
import TableColumnsSettings from '_common/components/TableColunmsSettings/TableColumnsSettings';
import LocalStorageService from '_common/services/localStorage.service';
import { columnsSettingsHandler } from '_common/utils/tableItems.util';
import RefundTransactionModal from './RefundTransactionModal';
import { useBrowserSettingsFlow } from '_common/hooks/api/kratos/useSettingsFlow.hook';
import { isTwoFactorAuthEnabled } from '_common/utils/twoFactorAuth.util';
import PartialRefundTransactionModal from './PartialRefundTransactionModal';
import { REPORTS_TABLE_COLUMNS_CONFIG } from './config/columsConfig';

const ReportsTable = (props) => {
  const { generalReportStore, t, reportStore, profileStore } = props;

  const { getTableType, setOrderBy } = reportStore;

  const { timeFormatByTimestamp } = Utils;

  const { transactionsPerPage, dayFormat, weekFormat, monthFormat } = TRANSACTIONS_TABLE_CONFIG;

	const browserSettingsFlowQuery = useBrowserSettingsFlow(
		'',
		(error) => {
			const { data } = error?.response;

			if (!!data?.error?.id && data?.error?.id === 'session_aal2_required') {
				setIsTwoFactorAuthActive(true);
			}
		},
		false,
	);

  const [columnTypeVolumes, setColumnTypeVolumes] = useState([]);

  /**
   * Transactions list data.
   */
  const [transactionsList, setTransactionsList] = useState([]);

  /**
   * Transactions list data.
   */
  const [transactionsTotal, setTransactionsTotal] = useState([]);

  /**
   * Table Pagination Length State,
   */
  const [paginationLength, setPaginationLength] = useState(0);

  /**
   * Is show more button loading.
   */
  const [isShowMoreBtnLoading, setIsShowMoreBtnLoading] = useState(false);

  /**
   * Fields sort type.
   */
  const [fieldsSortType, setFieldsSortType] = useState({
    created_at: 'ASC',
  });

  /**
   * Columns sort.
   */
  const [columnsSettings, setColumnsSettings] = useState(LocalStorageService.getItem('tableColumnsSettings')?.[getTableType] || {});

	/**
	 * Transaction id visible
	 */
	const [isTransactionIdVisible, setIsTransactionIdVisible] = useState(false);

	/**
	 * Is copied to clipboard.
	 */
	const [isCopiedToClipboard, setIsCopiedToClipboard] = useState(false);

	/**
	 * Selected transaction id.
	 */
	const [transactionId, setTransactionId] = useState(false);

	/**
	 * Selected transaction info.
	 */
	const [selectedTransactionInfo, setSelectedTransactionInfo] = useState({});

	/**
	 * Transaction refund modal open.
	 */
	const [isTransactionRefundModalOpen, setIsTransactionRefundModalOpen] = useState(false);

	/**
	 * Transaction partial refund modal open.
	 */
	const [isTransactionPartialRefundModalOpen, setIsTransactionPartialRefundModalOpen] = useState(false);

	/**
	 * Is two factor auth active.
	 */
	const [isTwoFactorAuthActive, setIsTwoFactorAuthActive] = useState(false);

	/**
	 * Is enable two factor auth visible.
	 */
	const [isEnableTwoFactorAuthVisible, setIsEnableTwoFactorAuthVisible] = useState(false);

	/**
	 * Is two factor authentication activation info visible.
	 */
	useEffect(() => {
		(async () => {
			try {
				const browserSettingsResponse = await browserSettingsFlowQuery.refetch();
				const isTwoFactorEnabled = isTwoFactorAuthEnabled(browserSettingsResponse, isTwoFactorAuthActive, browserSettingsResponse.isSuccess);
				setIsEnableTwoFactorAuthVisible(!isTwoFactorEnabled);
			} catch (e) {
				console.error(e);
			}
		})();
	}, []);

  /**
   * Get table data.
   */
  useEffect(() => {
    if (generalReportStore.getTransactionsReportData?.data) {
      const resultsBy = reportStore.getResultsByValue;
      let data = generalReportStore.getTransactionsReportData.data.map((item, index) => {
        return { key: item.payment_group + index, ...item };
      });

      if (reportStore.getTableType === 'volumes') {
        const { columns } = generalReportStore.getTransactionsReportData;
        const config = [
          {
            title: t('transaction.table.header.method'),
            dataIndex: 'key',
            key: 'key',
            fixed: 'left',
            width: 100,
            sorter: true,
            render: (text, record) => record.payment_group,
          },
        ];

        columns.forEach((item) => {
          let titleFormat = timeFormatByTimestamp(Number(item), dayFormat);

          if (resultsBy === 'week') {
            titleFormat = timeFormatByTimestamp(Number(item), weekFormat);
            titleFormat = titleFormat.replace(/([ek])/g, '').split(',', 1);
          }

          if (resultsBy === 'month') {
            titleFormat = timeFormatByTimestamp(Number(item), monthFormat);
          }

          config.push({
            title: titleFormat,
            dataIndex: item,
            key: item,
            width: 80,
            sorter: false,
            render: (text, record) => (record[item] ? Utils.trimNumber(Number(record[item])) : 0),
          });
        });

        setColumnTypeVolumes(config);
      } else if (reportStore.getTableType !== 'transactions') {
        data = generalReportStore.getTransactionsReportData.data.map((item, index) => ({
          key: (item?.transaction_id || 0) + (item?.payment_group || 0) + (item?.created_at || 0) + index,
          ...item,
        }));
      }

      setTransactionsList(data);
    }

    if (generalReportStore.getTransactionsReportData?.transactions && reportStore.getTableType === 'transactions') {
      const data = generalReportStore.getTransactionsReportData.transactions.map((item, index) => {
        return { key: item.payment_group + index, ...item };
      });

      setTransactionsList(data);
    }

    if (generalReportStore.getTransactionsReportData.total) {
      setTransactionsTotal(generalReportStore.getTransactionsReportData.total);
    }
    if (generalReportStore.getTransactionsReportData?.registry) {
      setTransactionsList(generalReportStore.getTransactionsReportData?.registry);
    }
    if (!Object.keys(generalReportStore.getTransactionsReportData || {})?.length) {
      setTransactionsList([])
    }

    //Set pagination
    if (generalReportStore.getTransactionsReportData.data) {
      setPaginationLength(generalReportStore.getTransactionsReportData?.total?.n || 0);
    }
  }, [generalReportStore.getTransactionsReportData]);

  /**
   * Download registry file handler.
   * @param {object} record.
   * File data.
   */
  const downloadRegistryFileHandler = async (record) => {
    try {
      const profileId = profileStore.getSelectedProfileId;
      const dateFormat = "yyyy-MM-dd'T'HH:mm:ss'Z'";
      const params = {
        sort_by: "ASC",
        ...record?.date_from && { date_from: format(record?.date_from, dateFormat) },
        ...record?.date_to && { date_to: format(record?.date_to, dateFormat) },
      }
      generalReportStore.setFilterParams(params);
      await generalReportStore.getTransactionListCsvV2(profileId);
      generalReportStore.setFilterParams({});
    } catch (e) {
      console.error(e);
    }
  };

	/**
	 * Is refund button visible.
	 *
	 * @param {Object} record - transaction data.
	 */
	const isRefundButtonVisible = (record) => {
		const isMerchantHasFinancePermission = profileStore.getMerchantPermissions[profileStore.getSelectedProfileId].includes('finance');
		const isMerchantHasAdminPermission = profileStore.getMerchantPermissions[profileStore.getSelectedProfileId].includes('admin');
		const states = ['done', 'partially_refunded', 'refund_rejected', 'refunded'];

		return (record.refund_supported || record.autorefund_supported || record.refund_partial_supported) &&
			record.kind === 'deposit' &&
			states.includes(record?.state) &&
			(isMerchantHasFinancePermission || isMerchantHasAdminPermission);
	};

	const columnsByType = REPORTS_TABLE_COLUMNS_CONFIG(
		t,
		transactionId,
		setSelectedTransactionInfo,
		isRefundButtonVisible,
		setIsTransactionPartialRefundModalOpen,
		setIsTransactionRefundModalOpen,
		setIsTransactionIdVisible,
		isCopiedToClipboard,
		setIsCopiedToClipboard,
		setTransactionId,
		isTransactionIdVisible,
		downloadRegistryFileHandler,
		reportStore,
	);

  /**
   * Calculate x scroll.
   */
  const calculateXscroll = () => {
    const data = reportStore.getTableType === 'volumes' ? columnTypeVolumes : columnsByType[getTableType];

    return data.reduce((acc, value) => {
      if (value.width) {
        return acc + value.width;
      }

      return acc;
    }, 0);
  };

  /**
   * Pagination change handler.
   * @param {number} page.
   * Number.
   */
  const paginationChangeHandler = (page) => {
    const offset = (page - 1) * transactionsPerPage;

    reportStore.setCurrentOffset(offset);
    reportStore.setCurrentPage(page);
  };

  /**
   * Pagination Prev, Next Buttons Render
   */
  const itemRender = (current, type, originalElement) => {
    if (type === 'prev') {
      return (
        <TransactionsPaginationButton direction="prev">
          <PaginationPrevArrowIcon color={theme.colors.iconColor} /> {t('transaction.table.footer.button.prev')}
        </TransactionsPaginationButton>
      );
    }
    if (type === 'next') {
      return (
        <TransactionsPaginationButton direction="next">
          {t('transaction.table.footer.button.next')} <PaginationNextArrowIcon color={theme.colors.iconColor} />
        </TransactionsPaginationButton>
      );
    }
    return originalElement;
  };

  /**
   * Table footer render.
   */
  // const renderTableSummary = () => {
  //   if ('amount' in (generalReportStore.getTransactionsReportData?.total || {})) {
  //     const { amount, fee } = transactionsTotal;
  //     const { from, to } = reportStore.getPeriod;
  //     const dataLength = generalReportStore.getTransactionsReportData?.data?.length;
  //     const formatedFrom = timeFormatByTimestamp(from, dayFormat);
  //     const formatedTo = timeFormatByTimestamp(to, dayFormat);
  //
  //     let period = '';
  //     if (formatedFrom === formatedTo) {
  //       period = `${formatedFrom}`;
  //     } else {
  //       period = `${formatedFrom} - ${formatedTo}`;
  //     }
  //
  //     if (dataLength) {
  //       if (reportStore.getTableType === 'volumes') {
  //         return (
  //           <>
  //             <TableSummaryRow>
  //               <TableSummaryCell index={0}>{t('transaction.table.footer.total')}</TableSummaryCell>
  //               {Object.entries(transactionsTotal).map(([id, value]) => (
  //                 <TableSummaryCell index={1} key={id}>
  //                   {Utils.trimNumber(Number(value))}
  //                 </TableSummaryCell>
  //               ))}
  //             </TableSummaryRow>
  //           </>
  //         );
  //       }
  //
  //       return (
  //         <>
  //           <TableSummaryRow>
  //             <TableSummaryCell>{t('transaction.table.footer.total')}</TableSummaryCell>
  //             <TableSummaryTransCell colSpan={2}>
  //               <span>{t('transaction.table.footer.period')}</span> {period}
  //             </TableSummaryTransCell>
  //             <TableSummaryTransCell colSpan={reportStore.getTableType === 'statistic' ? 2 : 4} />
  //             <TableSummaryTransCell colSpan={2}>
  //               <span>{t('transaction.table.footer.amount')}</span>
  //               {Utils.trimNumber(amount, 3)
  //                 .toString()
  //                 .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
  //             </TableSummaryTransCell>
  //             <TableSummaryTransCell colSpan={2}>
  //               <span>{t('transaction.table.footer.fee')}</span>
  //               {Utils.trimNumber(fee, 3)
  //                 .toString()
  //                 .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
  //             </TableSummaryTransCell>
  //           </TableSummaryRow>
  //         </>
  //       );
  //     }
  //   }
  //
  //   return null;
  // };

  /**
   * On table change handler.
   * @param {object} pagination.
   * Pagination data.
   * @param {object} filters.
   * Filters data.
   * @param {object} sorter.
   * Sorter data.
   */
  const onTableChangeHandler = async (pagination, filters, sorter) => {
    const { field, order } = sorter;

    // Do not send request if items data length less than 'transactionsPerPage'.
    if (generalReportStore.getTransactionsReportData?.total?.n > transactionsPerPage) {
      if (order === undefined) {
        setOrderBy({});
      } else {
        setOrderBy({
          [field]: order.replace('end', '')
        });
      }
    }

    if (field === 'created_at') {
      const sortType = (order || 'descend').replace('end', '').toUpperCase();
      setFieldsSortType({
        created_at: sortType,
      });
      generalReportStore.setTransactionsCreatedSortType(sortType);
      if (reportStore.getTableType !== TRANSACTIONS_VIEW_MODE.transactions) {
        await getMoreTransactions(true);
      }
    }
  };

  /**
   * App Theme
   */
  const { theme } = themeConfig[process.env.REACT_APP_THEME] || themeConfig.DEFAULT;

  const getMoreTransactions = async (root = false) => {
    try {
      const profileId = profileStore.getSelectedProfileId;
      setIsShowMoreBtnLoading(true);

      const {
        transactions: prevTransactions,
      } = generalReportStore.getTransactionsReportData;
      const {
        created_at: startedFrom,
      } = prevTransactions[prevTransactions.length - 1];
      const {
        from: selectedDateFrom,
        to: selectedDateTo,
      } = reportStore.getPeriod;

      if(reportStore.getTableType === TRANSACTIONS_VIEW_MODE.transactions) {
        generalReportStore.setFilterParams({
          ...generalReportStore.filterParams,
          ...(!root && { start_from: startedFrom - 1}),
        });
        await generalReportStore.getTransactionsListV2(profileId);
      } else {
        const data = {
          date_range: {
            from: fromUnixTime(selectedDateFrom),
            to: fromUnixTime(selectedDateTo),
          },
          date_column: 'created_at',
          sort_by: fieldsSortType?.created_at || 'ASC',
          limit: transactionsPerPage,
          ...(!root && { start_from: startedFrom - 1 }),
        };

        await generalReportStore.getTransactionsList(data, profileId);
      }

      setIsShowMoreBtnLoading(false);
    } catch (error) {
      setIsShowMoreBtnLoading(false);
      console.error(error);
    }
  };

  return (
    <>
      <Row>
        <Col xs={24}>
          <TableColumnsSettings
            columnsList={reportStore.getTableType === 'volumes' ? columnTypeVolumes : columnsByType[getTableType].filter((item) => item?.key)}
            setColumnsSettings={setColumnsSettings}
            tableTypeKey={getTableType}
          />
          <Table
            table-layout="fixed"
            // rowKey="id"
            columns={
              columnsSettingsHandler(reportStore.getTableType === 'volumes' ? columnTypeVolumes : columnsByType[getTableType], columnsSettings)
            }
            // components={vt}
            dataSource={transactionsList}
            pagination={false}
            // summary={(tableData) => renderTableSummary(tableData)}
            scroll={{ x: calculateXscroll() }}
            onChange={onTableChangeHandler}
            loading={reportStore.getIsTableLoading}
            className={`main-table tab-${reportStore.getTableType}`}
            expandable={{
              expandedRowClassName: () => 'expand-row-light',
              expandedRowRender: (record, index) => <RefundTable data={record}/>,
              rowExpandable: record => !!record?.refunds?.payments?.length,
            }}
          />
          {(
            reportStore.getTableType === TRANSACTIONS_VIEW_MODE.transactions
            && generalReportStore.getTransactionsReportData?.show_more
          ) && (
            <ShowMoreButtonWrapperStyle>
              <ButtonElement
                onClick={() => getMoreTransactions()}
                loading={isShowMoreBtnLoading}
                clear="true"
              >
                Show more
              </ButtonElement>
            </ShowMoreButtonWrapperStyle>
          )}

          {reportStore.getTableType !== TRANSACTIONS_VIEW_MODE.transactions && (
            <PaginationStyle>
              <Pagination
                hideOnSinglePage
                onChange={paginationChangeHandler}
                defaultPageSize={transactionsPerPage}
                current={reportStore.getCurrentPage}
                total={paginationLength}
                showSizeChanger={false}
                itemRender={itemRender}
              />
            </PaginationStyle>
          )}
        </Col>
      </Row>
	    <RefundTransactionModal
	      isModalOpen={isTransactionRefundModalOpen}
	      setIsModalOpen={setIsTransactionRefundModalOpen}
	      selectedTransactionInfo={selectedTransactionInfo}
	      isEnableTwoFactorAuthVisible={isEnableTwoFactorAuthVisible}
	    />
	    <PartialRefundTransactionModal
		    isModalOpen={isTransactionPartialRefundModalOpen}
		    setIsModalOpen={setIsTransactionPartialRefundModalOpen}
		    selectedTransactionInfo={selectedTransactionInfo}
		    isEnableTwoFactorAuthVisible={isEnableTwoFactorAuthVisible}
	    />
    </>
  );
};

export default compose(
  withTranslation(),
  inject('generalReportStore', 'reportStore', 'profileStore')
)(observer(ReportsTable));
