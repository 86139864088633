/**
 * @file
 * Contains navbar dashboard icon.
 */
import React from 'react';

const NavBarDashboardIcon = (props) => {
  const { color } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
      <path
        d="M24.3284 10.8749C24.3279 10.8743 24.3273 10.8738 24.3267 10.8732L14.1277 0.674507C13.6929 0.239587 13.115 0 12.5002 0C11.8854 0 11.3074 0.239397 10.8724 0.674317L0.67873 10.8678C0.675296 10.8713 0.671862 10.8749 0.668429 10.8783C-0.224301 11.7762 -0.222775 13.233 0.672816 14.1286C1.08198 14.538 1.62239 14.7751 2.20019 14.7999C2.22365 14.8022 2.2473 14.8033 2.27115 14.8033H2.67764V22.3089C2.67764 23.7941 3.88607 25.0026 5.37167 25.0026H9.36187C9.76626 25.0026 10.0944 24.6746 10.0944 24.2701V18.3857C10.0944 17.7079 10.6456 17.1566 11.3234 17.1566H13.6769C14.3547 17.1566 14.9059 17.7079 14.9059 18.3857V24.2701C14.9059 24.6746 15.2339 25.0026 15.6384 25.0026H19.6286C21.1142 25.0026 22.3227 23.7941 22.3227 22.3089V14.8033H22.6996C23.3142 14.8033 23.8922 14.5639 24.3273 14.129C25.2239 13.2319 25.2242 11.7726 24.3284 10.8749Z"
        fill={color}/>
    </svg>);
};

export default NavBarDashboardIcon;
