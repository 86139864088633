/**
 * @file
 * Contains amount icon.
 */
import React from 'react';

const AmountIcon = (props) => {
  const { color } = props;

  return (
    <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2 6H0V12H2V6Z" fill={color ? color : '#3F4047'} />
      <path d="M5 0H3V12H5V0Z" fill={color ? color : '#3F4047'} />
      <path d="M8 5H6V12H8V5Z" fill={color ? color : '#3F4047'} />
      <path d="M11 1H9V12H11V1Z" fill={color ? color : '#3F4047'} />
      <path d="M14 4H12V12H14V4Z" fill={color ? color : '#3F4047'} />
    </svg>
  )
};

export default AmountIcon;

