/**
 * @file
 * Contains approved icon.
 */
import React from 'react';

const ApprovedIcon = (props) => {
  const { color, gradientColor } = props;

  return (
    <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1051_130)">
        <path fillRule="evenodd" clipRule="evenodd" d="M13.9748 2.22384C14.3053 2.48901 14.35 2.96194 14.0746 3.28014L6.50027 12.0301C6.36014 12.192 6.15573 12.2897 5.93707 12.2992C5.71841 12.3088 5.50566 12.2293 5.35088 12.0803L0.806309 7.70534C0.502064 7.41244 0.502064 6.93757 0.806309 6.64468C1.11055 6.35178 1.60383 6.35178 1.90808 6.64468L5.84942 10.4389L12.8776 2.31987C13.153 2.00166 13.6443 1.95867 13.9748 2.22384Z" fill="url(#paint0_linear_1051_130)"/>
      </g>
      <defs>
        <linearGradient id="paint0_linear_1051_130" x1="13.3196" y1="2.86078" x2="-2.63378" y2="14.6184" gradientUnits="userSpaceOnUse">
          <stop stopColor={gradientColor}/>
          <stop offset="0.75" stopColor={color}/>
        </linearGradient>
        <clipPath id="clip0_1051_130">
          <rect width="14.5426" height="14" fill="white" transform="translate(0.387695)"/>
        </clipPath>
      </defs>
    </svg>
  );
};

export default ApprovedIcon;
