/**
 * @file
 * Contains Questionnaire Levels Component.
 */
import React, { useEffect, useState } from 'react';
import { compose } from 'recompose';
import { inject } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { ReactComponent as KycNextArrow } from '_common/assets/icons/kyc-continue-icon.svg';
import { ReactComponent as KycPrevArrow } from '_common/assets/icons/kyc-back-icon.svg';
import { Form, Spin, Radio, Space } from 'antd';
import QuestionnaireBackground from '_common/assets/img/questionnaire-background.jpg';
import {
  QuestionnaireLevelsContainer,
  QuestionnaireLevelsWrapper,
  QuestionnaireLevelsTitle,
  QuestionnaireLevelsFooter,
  QuestionnaireNavigationButton,
  QuestionnaireSubmitButton,
  QuestionnaireSteps,
  QuestionnaireInput,
  InputElement,
  QuestionnaireHeading,
  FormWrapper,
  FormItemElementWrapper,
  RadioElementWrapper,
  StellarDescription,
  StellarAddress,
} from './Questionnaire.style';
import QuestionnaireLevelsStatus from './QuestionnaireLevelsStatus';
import QuestionnaireLevelOne from './levels/Level-1';
import QuestionnaireLevelTwo from './levels/Level-2';
import QuestionnaireLevelThree from './levels/Level-3';
import QuestionnaireLevelFour from './levels/Level-4';
import FormItemElement from '_common/components/FormItem/FormItemElement';
import links from '_common/routes/urls';
import themeConfig from '../config';
import LocalStorageService from '_common/services/localStorage.service';

const Questionnaire = (props) => {
  const { t, kycStore, profileStore, history } = props;

  const theme = themeConfig[process.env.REACT_APP_THEME] || themeConfig.DEFAULT;

  /**
   * Use form object;
   */
  const [form] = Form.useForm();

  /**
   * Stellar field value.
   */
  const [stellarFieldValue, setStellarFieldValue] = useState('');

  /**
   * Is loading submit button flag.
   */
  const [isLoadingSubmitBtn, setIsLoadingSubmitBtn] = useState(false);

  /**
   * Set default stellar field value.
   */
  useEffect(()=> {
    setStellarFieldValue('create');
    form.setFieldsValue({
      stellar: 'create',
    });
  }, []);

  /**
   * On form values change handler.
   * @param {object} changedValues.
   * All form fields values.
   */
  const onFormValuesChangeHandler = (changedValues) => {
    const { stellar } = changedValues;
    if (stellar) setStellarFieldValue(stellar);
  };

  /**
   * Submit form handler.
   * @param {object} data.
   * Form values.
   */
  const submitFormHandler = async (data) => {
    const {
      stellar,
      ...restData
    } = data;

    try {
      LocalStorageService.clearStore();
      setIsLoadingSubmitBtn(true);
      await profileStore.updateProfileDataAction(restData);
      await profileStore.getProfileDataAction();
      history.push(links.dashboard);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoadingSubmitBtn(false);
    }
  };

  return (
    <QuestionnaireLevelsContainer background={theme?.assets?.img?.questionnaireBg}>
      <QuestionnaireHeading>
        {t('questionnaire.heading.title')}
      </QuestionnaireHeading>
      <Form
        form={form}
        onFinish={submitFormHandler}
        onValuesChange={onFormValuesChangeHandler}
        initialValues={{

        }}
      >
        <FormWrapper>
          {/*<FormItemElementWrapper>*/}
          {/*  <FormItemElement*/}
          {/*    name="first_name"*/}
          {/*    validateTrigger="onSubmit"*/}
          {/*    rules={[*/}
          {/*      {*/}
          {/*        required: true,*/}
          {/*        message: t('input.field.required'),*/}
          {/*      },*/}
          {/*    ]}*/}
          {/*  >*/}
          {/*    <InputElement placeholder={t('questionnaire.levels.input.placeholder.firstName')} />*/}
          {/*  </FormItemElement>*/}
          {/*</FormItemElementWrapper>*/}
          {/*<FormItemElementWrapper>*/}
          {/*  <FormItemElement*/}
          {/*    name="last_name"*/}
          {/*    validateTrigger="onSubmit"*/}
          {/*    rules={[*/}
          {/*      {*/}
          {/*        required: true,*/}
          {/*        message: t('input.field.required'),*/}
          {/*      },*/}
          {/*    ]}*/}
          {/*  >*/}
          {/*    <InputElement placeholder={t('questionnaire.levels.input.placeholder.lastName')} />*/}
          {/*  </FormItemElement>*/}
          {/*</FormItemElementWrapper>*/}
          <FormItemElementWrapper>
            <FormItemElement
              name="company_name"
              validateTrigger="onSubmit"
              rules={[
                {
                  required: true,
                  message: t('input.field.required'),
                },
              ]}
            >
              <InputElement placeholder={t('questionnaire.levels.input.placeholder.companyName')} />
            </FormItemElement>
          </FormItemElementWrapper>
        </FormWrapper>
        {stellarFieldValue === 'create' ? (
          <StellarDescription />
        ) : (
          <StellarAddress>
            <FormWrapper>
              <FormItemElementWrapper>
                <FormItemElement
                  name="stellar_address"
                  validateTrigger="onSubmit"
                  rules={[
                    {
                      required: true,
                      message: t('input.field.required'),
                    },
                  ]}
                >
                  <InputElement placeholder={t('questionnaire.stellar.address')} />
                </FormItemElement>
              </FormItemElementWrapper>
            </FormWrapper>
          </StellarAddress>
        )}
        <QuestionnaireLevelsFooter>
          <div></div>
          <QuestionnaireSubmitButton
            htmlType="submit"
            loading={isLoadingSubmitBtn}
          >
            {t('questionnaire.button.next')} <KycNextArrow />
          </QuestionnaireSubmitButton>
        </QuestionnaireLevelsFooter>







        {/*<RegButtonSubmit*/}
        {/*  htmlType="submit"*/}
        {/*  // loading={isButtonLoading}*/}
        {/*>*/}
        {/*  {t('login.button.register')}*/}
        {/*</RegButtonSubmit>*/}
      </Form>

      {/*<QuestionnaireLevelsWrapper>*/}
      {/*  {level < 4 && <QuestionnaireLevelsTitle>{t('questionnaire.levels.title')}</QuestionnaireLevelsTitle>}*/}
      {/*  <QuestionnaireLevelsStatus level={level} />*/}
      {/*  <QuestionnaireSteps>*/}
      {/*    <>*/}
      {/*      {Object.keys(userInfoData).length ? (*/}
      {/*        <Form onFinish={submitUserData} initialValues={userInfoData}>*/}
      {/*          <QuestionnaireLevelOne currentLevel={level} />*/}
      {/*          <QuestionnaireLevelTwo currentLevel={level} />*/}
      {/*          <QuestionnaireLevelThree currentLevel={level} />*/}
      {/*          <QuestionnaireLevelFour phone={phoneNumber} currentLevel={level} />*/}
      {/*          <QuestionnaireLevelsFooter>*/}
      {/*            <QuestionnaireNavigationButton back="true" onClick={backLevelHandler}>*/}
      {/*              <KycPrevArrow /> {t('questionnaire.button.back')}*/}
      {/*            </QuestionnaireNavigationButton>*/}
      {/*            {level !== 3 && (*/}
      {/*              <QuestionnaireNavigationButton onClick={nextLevelHandler} alignleft="auto">*/}
      {/*                {t('questionnaire.button.next')} <KycNextArrow />*/}
      {/*              </QuestionnaireNavigationButton>*/}
      {/*            )}*/}
      {/*            {level === 3 && (*/}
      {/*              <QuestionnaireSubmitButton type="submit" alignleft="auto">*/}
      {/*                {t('questionnaire.button.next')} <KycNextArrow />*/}
      {/*              </QuestionnaireSubmitButton>*/}
      {/*            )}*/}
      {/*          </QuestionnaireLevelsFooter>*/}
      {/*        </Form>*/}
      {/*      ) : (*/}
      {/*        <Spin />*/}
      {/*      )}*/}
      {/*    </>*/}
      {/*  </QuestionnaireSteps>*/}
      {/*</QuestionnaireLevelsWrapper>*/}
    </QuestionnaireLevelsContainer>
  );
};

export default compose(inject('kycStore', 'profileStore'), withTranslation())(Questionnaire);
