/**
 * @file
 * Contains Navigator Component.
 */
import React, { createRef } from 'react';
import { useHistory } from 'react-router';
import links from '_common/routes/urls';
import RegistrationIsSuccessfully from 'Auth/RegistrationIsSuccessfully.view';
import PrivateRoute from '_common/routes/PrivateRoute';
import PublicRoute from '_common/routes/PublicRoute';
import RedirectRoute from '_common/routes/RedirectRote';
import Dashboard from 'Dashboard/Dashboard.view';
import Questionnaire from 'Questionnaire/Questionnaire.view';
import Reports from 'Reports/Reports.view';
import Users from 'Users/Users.view';
import Finance from 'Finance/Finance.view';
import Settings from 'Settings/Settings.view';
import FinanceAccounts from 'Finance/Accounts/FinanceAccounts.view';
import KYC from 'KYC/KYC.view';
import KYCLevels from 'KYC/KycLevels.view';
import Auth from 'Auth/Auth.view';
import Verification from 'Auth/Verification/Verification.View';
import Recovery from 'Auth/Recovery/Recovery.view';
import NewPassword from 'Auth/Recovery/NewPassword/NewPassword.View';
import { Switch } from 'react-router-dom';
import SomethingWentWrong from '_common/components/SomthengWentWrong/SomethingWentWrong';

export const historyRef = createRef();

const Navigator = () => {
  historyRef.current = useHistory();

  return (
    <Switch>
      <PublicRoute exact path={links.public.auth} component={Auth} />
      <PublicRoute exact path={links.public.verification} component={Verification} />
      <PublicRoute exact path={links.public.recovery} component={Recovery} />
      <PublicRoute exact path={links.public.error} component={SomethingWentWrong} />
      <PrivateRoute exact path={links.newPassword} component={NewPassword} />

      <PrivateRoute exact path={links.dashboard} component={Dashboard} />
      <PrivateRoute
        exact
        path={links.registrationIsSuccessfully}
        component={RegistrationIsSuccessfully}
      />
      <PrivateRoute exact path={links.questionnaire} component={Questionnaire} />
      <PrivateRoute
        exact
        path={links.reports}
        component={Reports}
        accessScopes={[
          'merchant.finances.transactions.read',
          'merchant.finances.reports.download',
        ]}
      />
      <PrivateRoute
        exact
        path={links.finance}
        component={Finance}
        accessScopes={['merchant.finances.balances.read']}
      />
	    <PrivateRoute
		    exact
		    path={links.users}
		    component={Users}
		    accessScopes={['iam.read']}
	    />
      <PrivateRoute exact path={links.settings} component={Settings} accessScopes={[]} />
      <PrivateRoute exact path={links.financeAccounts} component={FinanceAccounts} />
      <PrivateRoute exact path={links.kyc} component={KYC} />
      <PrivateRoute exact path={links.kycLevels} component={KYCLevels} />

      <RedirectRoute/>
      {/*<Redirect to={links.public.auth} />*/}
    </Switch>
  );
};

export default Navigator;
