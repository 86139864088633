/**
 * @file
 * Contains Accounts Component.
 */
import React from 'react';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import urls from '_common/routes/urls';
import themeConfig from 'config';
import {
  AccountsContainer,
  AccountsWrapper,
  AccountContainer,
  AccountWrapper,
  AccountTabs,
  AccountTab,
  AccountName,
  AccountFooter,
  AccountValue,
  AccountAmount,
  AccountSettings,
  AccountEditWrapper,
  AccountDeleteWrapper,
  FinanceAccountsHeader,
  FinanceHeaderAccountsTitle,
  AddAccountButton,
  BackToFinanceLink,
  NoAccountsContainer,
  NoAccountsWrapper,
  NoAccountsTitle,
  NoAccountsSubTitle,
} from './FinanceAccounts.style';
import AddAccount from './AddAccount';
import EditIcon from '_common/assets/renderSvgIcons/EditIcon';
import DeleteIcon from '_common/assets/renderSvgIcons/Deleteicon';
import ModalArrowBackIcon from '_common/assets/renderSvgIcons/ModalArrowBackIcon';

const Accounts = (props) => {
  const { t, accounts, addAccountHandler, addAccount } = props;

  /**
   * App Theme
   */
  const { theme } = themeConfig[process.env.REACT_APP_THEME] || themeConfig.DEFAULT;

  return (
    <>
      {addAccount ? (
        <AddAccount />
      ) : (
        <AccountsContainer>
          <FinanceAccountsHeader>
            <FinanceHeaderAccountsTitle>{t('finance.accounts.title')}</FinanceHeaderAccountsTitle>
            {accounts.length && (
              <AddAccountButton onClick={addAccountHandler}>{t('finance.accounts.button.addAccount')}</AddAccountButton>
            )}
          </FinanceAccountsHeader>
          {accounts.length ? (
            <AccountTabs>
              <AccountTab tab={t('finance.accounts.button.tab.recent')} key="recent">
                {accounts.map((account) => (
                  <AccountContainer key={account.value}>
                    <AccountWrapper type={account.type}>
                      <AccountName>{account.name}</AccountName>
                      <AccountFooter>
                        <AccountValue>
                          <AccountAmount>
                            {account.value} <span>{account.currency}</span>
                          </AccountAmount>
                          <AccountSettings>
                            <AccountEditWrapper>
                              <EditIcon />
                            </AccountEditWrapper>
                            <AccountDeleteWrapper>
                              <DeleteIcon />
                            </AccountDeleteWrapper>
                          </AccountSettings>
                        </AccountValue>
                      </AccountFooter>
                    </AccountWrapper>
                  </AccountContainer>
                ))}
              </AccountTab>
              <AccountTab tab={t('finance.accounts.button.tab.crypto')} key="crypto">
                {accounts.map((account) => (
                  <AccountsWrapper key={account.value}>
                    {account.type === 'crypto' && (
                      <AccountContainer key={account.value}>
                        <AccountWrapper type={account.type}>
                          <AccountName>{account.name}</AccountName>
                          <AccountFooter>
                            <AccountValue>
                              <AccountAmount>
                                {account.value} <span>{account.currency}</span>
                              </AccountAmount>
                              <AccountSettings>
                                <AccountEditWrapper>
                                  <EditIcon />
                                </AccountEditWrapper>
                                <AccountDeleteWrapper>
                                  <DeleteIcon />
                                </AccountDeleteWrapper>
                              </AccountSettings>
                            </AccountValue>
                          </AccountFooter>
                        </AccountWrapper>
                      </AccountContainer>
                    )}
                  </AccountsWrapper>
                ))}
              </AccountTab>
              <AccountTab tab={t('finance.accounts.button.tab.bank')} key="bank">
                {accounts.map((account) => (
                  <AccountsWrapper key={account.value}>
                    {account.type === 'bank' && (
                      <AccountContainer key={account.value}>
                        <AccountWrapper type={account.type}>
                          <AccountName>{account.name}</AccountName>
                          <AccountFooter>
                            <AccountValue>
                              <AccountAmount>
                                {account.value} <span>{account.currency}</span>
                              </AccountAmount>
                              <AccountSettings>
                                <AccountEditWrapper>
                                  <EditIcon />
                                </AccountEditWrapper>
                                <AccountDeleteWrapper>
                                  <DeleteIcon />
                                </AccountDeleteWrapper>
                              </AccountSettings>
                            </AccountValue>
                          </AccountFooter>
                        </AccountWrapper>
                      </AccountContainer>
                    )}
                  </AccountsWrapper>
                ))}
              </AccountTab>
            </AccountTabs>
          ) : (
            <NoAccountsContainer>
              <NoAccountsWrapper>
                <NoAccountsTitle>{t('finance.accounts.noAccounts.title')}</NoAccountsTitle>
                <NoAccountsSubTitle>{t('finance.accounts.noAccounts.subTitle')}</NoAccountsSubTitle>
                <AddAccountButton onClick={addAccountHandler}>
                  {t('finance.accounts.button.addAccount')}
                </AddAccountButton>
              </NoAccountsWrapper>
            </NoAccountsContainer>
          )}
          <BackToFinanceLink to={urls.finance}>
            <ModalArrowBackIcon /> {t('finance.accounts.button.backToFinance')}
          </BackToFinanceLink>
        </AccountsContainer>
      )}
    </>
  );
};

export default compose(withTranslation())(Accounts);
