/**
 * @file
 * Contains Kyc Level Status Component.
 */
import React from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import {
  QuestionnaireLevelsStatusWrapper,
  QuestionnaireLevelStatusWrapper,
  QuestionnaireLevelWrapper,
  QuestionnaireLevelStatus,
  QuestionnaireLevelDescr,
  QuestionnaireStatusLine,
} from './Questionnaire.style';

const QuestionnaireLevelsStatus = (props) => {
  // eslint-disable-next-line no-unused-vars
  const { t, level } = props;

  return (
    <>
      {level < 4 && (
        <QuestionnaireLevelsStatusWrapper level={level}>
          <QuestionnaireStatusLine />
          <QuestionnaireLevelStatusWrapper>
            <QuestionnaireLevelWrapper>
              <QuestionnaireLevelStatus level={1} currentLevel={level}>
                1
              </QuestionnaireLevelStatus>
              <QuestionnaireLevelDescr level={1} currentLevel={level}>
                {t('questionnaire.levels.status.firstItem')}
              </QuestionnaireLevelDescr>
            </QuestionnaireLevelWrapper>
          </QuestionnaireLevelStatusWrapper>
          <QuestionnaireLevelStatusWrapper>
            <QuestionnaireLevelWrapper>
              <QuestionnaireLevelStatus level={2} currentLevel={level}>
                2
              </QuestionnaireLevelStatus>
              <QuestionnaireLevelDescr level={2} currentLevel={level}>
                {t('questionnaire.levels.status.secondItem')}
              </QuestionnaireLevelDescr>
            </QuestionnaireLevelWrapper>
          </QuestionnaireLevelStatusWrapper>
          <QuestionnaireLevelStatusWrapper>
            <QuestionnaireLevelWrapper>
              <QuestionnaireLevelStatus level={3} currentLevel={level}>
                3
              </QuestionnaireLevelStatus>
              <QuestionnaireLevelDescr level={3} currentLevel={level}>
                {t('questionnaire.levels.status.thirdItem')}
              </QuestionnaireLevelDescr>
            </QuestionnaireLevelWrapper>
          </QuestionnaireLevelStatusWrapper>
        </QuestionnaireLevelsStatusWrapper>
      )}
    </>
  );
};

export default compose(withTranslation())(QuestionnaireLevelsStatus);
