/**
 * @file
 * Contains Quantity Graphic Component.
 */
import React, { useEffect, useState } from 'react';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import { Area } from 'recharts';
import GraphicContainer from '_common/components/CurvedGraphic/GraphicContainer';
import CurvedGraphic from '_common/components/CurvedGraphic/CurvedGraphic';
import NoDataGraphic from '_common/components/NoDataGraphic/NoDataGraphic';
import { withTranslation } from 'react-i18next';
import Utils from '_common/utils';
import SpinnerElement from '_common/components/Spinner/SpinnerElement';
import { DashboardGraphicTitle, DashboardGraphicContainer } from './Dashboard.style';

const AmountGraphic = (props) => {
  const { t, generalReportStore } = props;

  const { timeFormatByTimestamp, isToday } = Utils;

  /**
   * Quantity Data State.
   */
  const [quantityData, setQuantityData] = useState({});

  /**
   * Quantity Graphic Dara State.
   */
  const [quantityGraphicData, setQuantityGraphicData] = useState([]);

  /**
   * Get Date Report Data from Store.
   */
  useEffect(() => {
    const fetchData = async () => {
      generalReportStore.setIsLoading(true);
      try {
        const data = await generalReportStore.getDateReportData;
        if (data) setQuantityData(data?.breakdown?.by_time);
        generalReportStore.setIsLoading(false);
      } catch (e) {
        console.error(e);
      }
    };

    fetchData();
  }, [generalReportStore.getDateReportData]);

  /**
   * Set Curved Graphic Data to Store.
   */
  useEffect(() => {
    if (quantityData) {
      const pickedData = Object.entries(quantityData).map((data) => {
        return {
          yValue: Math.round(data[1].amount / data[1].n) || 0,
          yUnit: data[1].currency,
          xValue: timeFormatByTimestamp(data[0], isToday(quantityData) ? 'HH:mm' : 'DD MMM'),
          xUnit: '',
        };
      });

      setQuantityGraphicData(pickedData);
    }
  }, [quantityData]);

  return (
    <SpinnerElement spinning={generalReportStore.getIsLoading}>
      <DashboardGraphicContainer>
        {quantityGraphicData.length ? (
          <>
            <DashboardGraphicTitle mt={4}>{t('dashboard.graphic.avgBill.title')}</DashboardGraphicTitle>
            <GraphicContainer height={200} width="100%">
              <CurvedGraphic data={quantityGraphicData}>
                <Area type="monotone" dataKey="yValue" stroke="#50BEBE" fillOpacity={1} fill="url(#splitColor)" />
              </CurvedGraphic>
            </GraphicContainer>
          </>
        ) : (
          <NoDataGraphic />
        )}
      </DashboardGraphicContainer>
    </SpinnerElement>
  );
};

export default compose(inject('generalReportStore'), withTranslation())(observer(AmountGraphic));
