/**
 * @file Contains enable two factor auth component.
 */

import React from 'react';
import { compose } from 'recompose';
import { observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import ButtonElement from '_common/components/Button/ButtonElement';
import {
	RefundEnableTwoFactorWrapper,
	RefundEnableTwoFactorInfoContainer,
	RefundEnableTwoFactorButtonsContainer,
	RefundEnableTwoFactorInfoTitle,
	RefundEnableTwoFactorInfoSubtitle,
	RefundEnableTwoFactorButtonsWrapper,
} from './Reports.style';
import { useHistory } from 'react-router';
import links from '_common/routes/urls';

const ReportsEnableTwoFactorAuth = (props) => {
	const { setIsModalOpen, t } = props;

	const history = useHistory();

	/**
	 * Redirect to settings page.
	 *
	 * @function - redirectToSettings.
	 */
	const redirectToSettings = () => {
		history.push({
			pathname: links.settings,
			state: {
				data: 'security',
			},
		});
	};

	return (
		<RefundEnableTwoFactorWrapper>
			<RefundEnableTwoFactorInfoContainer>
				<RefundEnableTwoFactorInfoTitle>
					{t('transaction.modal.enableTwoFactor.title.twoFactorIsNotEnabled')}
				</RefundEnableTwoFactorInfoTitle>
				<RefundEnableTwoFactorInfoSubtitle>
					{t('transaction.modal.enableTwoFactor.title.twoFactorMustBeEnabled')}
				</RefundEnableTwoFactorInfoSubtitle>
			</RefundEnableTwoFactorInfoContainer>
			<RefundEnableTwoFactorButtonsContainer>
				<RefundEnableTwoFactorButtonsWrapper>
					<ButtonElement
						onClick={() => setIsModalOpen(false)}
						clear={true}
					>
						{t('transaction.modal.enableTwoFactor.button.cancel')}
					</ButtonElement>
					<ButtonElement
						onClick={() => redirectToSettings()}
					>
						{t('transaction.modal.enableTwoFactor.button.enableTwoFactor')}
					</ButtonElement>
				</RefundEnableTwoFactorButtonsWrapper>
			</RefundEnableTwoFactorButtonsContainer>
		</RefundEnableTwoFactorWrapper>
	)
};

export default compose(
	withTranslation()
)(observer(ReportsEnableTwoFactorAuth));
