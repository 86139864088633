/**
 * @file
 * Contains Something went wrong component.
 */

import React from 'react';
import {
  Title,
  Description,
  Wrapper,
  IconWrapper,
  ButtonWrapper,
} from './SomethingWentWrong.style';
import ButtonElement from '_common/components/Button/ButtonElement';
import RejectIcon from '_common/assets/renderSvgIcons/RejectIcon';
import themeConfig from 'config';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import links from '_common/routes/urls';

function SomethingWentWrong (props) {
  const {
    t,
  } = props;

  const history = useHistory();
  const { location } = history;

  /**
   * App Theme
   */
  const { theme } = themeConfig[process.env.REACT_APP_THEME] || themeConfig.DEFAULT;

  /**
   *
   */
  const onClick = () => {
    if(location?.pathname === links.public.error) {
      history.push(links.public.auth);
    } else {
      document.location.reload()
    }
  }

  return (
    <Wrapper>
      <div>
        <Title>{t('somethingWentWrong.title')}</Title>
        <Description>{t('somethingWentWrong.description')}</Description>
      </div>
      <IconWrapper>
        <RejectIcon color={theme.colors.iconColor}  />
      </IconWrapper>
      <ButtonWrapper>
        <ButtonElement
          onClick={onClick}
        >
          {t('somethingWentWrong.button')}
        </ButtonElement>
      </ButtonWrapper>
    </Wrapper>
  );
}

export default compose(withTranslation())(SomethingWentWrong);
