/**
 * @file Contains two factor auth util.
 */

import { kratosService } from '_common/services/kratos.service';
import twoFactorAuthService from '_common/services/twoFactorAuth.service';

export const twoFactorAuth = async (twoFactorAuthToken, flowId, totpCode, csfrToken, profileId) => {
	try {
		if (!totpCode && twoFactorAuthToken) {
			const response = await kratosService.fetchLoginFlow('', 'aal2', true);
			const flowId = response?.id;

			const csfrTokenData = response?.ui?.nodes?.filter((data) => {
				if (data.attributes.name === 'csrf_token') {
					return data;
				}
			});
			const csfrToken = csfrTokenData[0]?.attributes?.value;

			if (flowId) {
				const data = {
					'2fa_token': twoFactorAuthToken,
					flow_id: flowId,
				};
				await twoFactorAuthService.linkTwoFactorAuthFlowId(data);
			}

			return {
				flowId,
				csfrToken,
				twoFactorAuthToken,
			};
		}

		await kratosService.fetchUpdateLoginFlow({
			flow: flowId,
			updateLoginFlowBody: {
				csrf_token: csfrToken,
				method: 'totp',
				totp_code: totpCode,
			},
		});
	} catch (e) {
		console.error(e);
		const { data } = e.response;

		if (data.ui.messages[0].text === 'The provided authentication code is invalid, please try again.') {
			throw new Error('two_factor_auth_error');
		} else {
			throw new Error(e);
		}
	}
};
