/**
 * @file
 * Contains theme settings.
 */

import defaultTheme from './default/defaultTheme';
import apsTheme from './aps/apsTheme';
import mamlakaTheme from './mamlaka/mamlakaTheme';
import tempoTheme from './tempo/tempoTheme';

const themes = {
  default: defaultTheme,
  aps: apsTheme,
  mamlaka: mamlakaTheme,
  tempo: tempoTheme,
};

export default themes;
