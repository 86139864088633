/**
 * @file Contains user roles constant.
 */

export const USER_ROLES_MAPPING = {
	admin: {
		description: 'user.role.description.admin',
	},
	finance: {
		description: 'user.role.description.finance',
	},
	support: {
		description: 'user.role.description.support',
	},
	viewer: {
		description: 'user.role.description.viewer',
	},
};
