/**
 * @file
 * Contains Public Default redirect route component.
 */

import React from 'react';
import { Route } from 'react-router-dom';
import links from '_common/routes/urls';
import { Redirect } from 'react-router';

const RedirectRoute = () => (
  <Route
    render={({ location }) =>
      ["/developers"].includes(
        location.pathname
      ) ? null : (
        <Redirect to={links.public.auth} />
      )
    }
  />
);

export default RedirectRoute;
