/**
 * @file
 * Contains Download icon.
 */
import React from 'react';

const DownloadIcon = (props) => {
  const { color } = props;

  return (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path d="M24.1425 14.4863C23.6297 14.4863 23.2139 14.9021 23.2139 15.4149V20.9863C23.2139 21.4992 22.7982 21.9149 22.2853 21.9149H3.71399C3.20114 21.9149 2.7854 21.4992 2.7854 20.9863V15.4149C2.7854 14.9021 2.36967 14.4863 1.85681 14.4863C1.34396 14.4863 0.928223 14.9021 0.928223 15.4149V20.9863C0.928223 22.5248 2.17543 23.7721 3.71394 23.7721H22.2854C23.8239 23.7721 25.0711 22.5248 25.0711 20.9863V15.4149C25.0711 14.9021 24.6554 14.4863 24.1425 14.4863Z"
              fill={color ? color : 'black'}/>
        <path d="M18.6382 13.4577C18.2784 13.1102 17.7079 13.1102 17.3481 13.4577L14.2885 16.5154V-1.67151C14.2885 -2.18436 13.8727 -2.6001 13.3599 -2.6001C12.847 -2.6001 12.4313 -2.18436 12.4313 -1.67151V16.5155L9.37346 13.4577C9.00457 13.1014 8.41674 13.1116 8.06048 13.4805C7.71291 13.8403 7.71291 14.4108 8.06048 14.7707L12.7033 19.4135C13.0655 19.7766 13.6535 19.7773 14.0165 19.4152C14.0171 19.4146 14.0176 19.4141 14.0182 19.4135L18.661 14.7707C19.0174 14.4018 19.0071 13.814 18.6382 13.4577Z"
              fill={color ? color : 'black'}/>
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="26" height="26" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  )
};

export default DownloadIcon;
