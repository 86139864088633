/**
 * @file
 * Contains Questionnaire Level 1 component.
 */
import React, { useEffect, useState } from 'react';
import { Row, Col, Spin } from 'antd';
import FormItemElement from '_common/components/FormItem/FormItemElement';
import { compose } from 'recompose';
import { inject } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { getJwtAccessToken } from '_common/services/jwtToken.service';
import { editString } from '_common/utils/stringEdit.util';
import {
  QuestionnaireStep,
  QuestionnaireInput,
  QuestionnaireSelect,
  QuestionnaireMultipleSelect,
  QuestionnaireOption,
} from '../Questionnaire.style';

const QuestionnaireLevelTwo = (props) => {
  const { t, currentLevel, kycStore } = props;

  /**
   * Question Parameters State
   */
  const [questionsData, setQuestionsData] = useState({});

  /**
   * Fetch Question Parameters Data.
   */
  useEffect(() => {
    const fetchUserInfoData = async () => {
      try {
        kycStore.setIsLoading(true);
        const userInfo = await kycStore.getProfileQuestionnaireDataAction(getJwtAccessToken());

        const questions = {
          industry: userInfo.industry,
          companyLocation: userInfo.company_location,
          monthlyProcessing: userInfo.monthly_processing_volumes_eur,
          currentProcessing: userInfo.current_processing_methods,
          regionsTarget: userInfo.regions_target,
        };
        setQuestionsData(questions);
      } catch (e) {
        throw new Error(e);
      }
    };

    fetchUserInfoData();
  }, [kycStore]);

  return (
    <QuestionnaireStep level={2} currentLevel={currentLevel}>
      {Object.keys(questionsData).length ? (
        <>
          <Row gutter={[100, 50]}>
            <Col xs={8}>
              <FormItemElement questionnairelabel label={questionsData.industry.description} name="industry">
                <QuestionnaireSelect
                  popupClassName="kyc-select-dropdown"
                  placeholder={questionsData.industry.description}
                >
                  {questionsData.industry.choices.map((choice) => (
                    <QuestionnaireOption value={choice} key={choice}>
                      {editString(choice)}
                    </QuestionnaireOption>
                  ))}
                </QuestionnaireSelect>
              </FormItemElement>
            </Col>
            <Col xs={8}>
              <FormItemElement
                questionnairelabel
                label={questionsData.companyLocation.description}
                name="company_location"
              >
                <QuestionnaireSelect
                  popupClassName="kyc-select-dropdown"
                  placeholder={questionsData.companyLocation.description}
                >
                  {questionsData.companyLocation.choices.map((choice) => (
                    <QuestionnaireOption value={choice} key={choice}>
                      {editString(choice)}
                    </QuestionnaireOption>
                  ))}
                </QuestionnaireSelect>
              </FormItemElement>
            </Col>
            <Col xs={8}>
              <FormItemElement
                questionnairelabel
                label={questionsData.monthlyProcessing.description}
                name="monthly_processing_volumes_eur"
              >
                <QuestionnaireSelect
                  popupClassName="kyc-select-dropdown"
                  placeholder={questionsData.monthlyProcessing.description}
                >
                  {questionsData.monthlyProcessing.choices.map((choice) => (
                    <QuestionnaireOption value={choice} key={choice}>
                      {editString(choice)}
                    </QuestionnaireOption>
                  ))}
                </QuestionnaireSelect>
              </FormItemElement>
            </Col>
          </Row>
          <Row gutter={[100, 50]}>
            <Col xs={8}>
              <FormItemElement
                questionnairelabel
                label={questionsData.currentProcessing.description}
                name="current_processing_methods"
              >
                <QuestionnaireSelect
                  popupClassName="kyc-select-dropdown"
                  placeholder={questionsData.currentProcessing.description}
                >
                  {questionsData.currentProcessing.choices.map((choice) => (
                    <QuestionnaireOption value={choice} key={choice}>
                      {editString(choice)}
                    </QuestionnaireOption>
                  ))}
                </QuestionnaireSelect>
              </FormItemElement>
            </Col>
            <Col xs={8}>
              <FormItemElement questionnairelabel label={questionsData.industry.description} name="regions_target">
                <QuestionnaireMultipleSelect
                  mode="multiple"
                  popupClassName="kyc-multiple-select-dropdown"
                  placeholder={questionsData.regionsTarget.description}
                >
                  {questionsData.regionsTarget.choices.map((choice) => (
                    <QuestionnaireOption value={choice} key={choice}>
                      {editString(choice)}
                    </QuestionnaireOption>
                  ))}
                </QuestionnaireMultipleSelect>
              </FormItemElement>
            </Col>
            <Col xs={8}>
              <FormItemElement
                questionnairelabel
                label={t('questionnaire.levels.input.placeholder.websiteUrl')}
                name="website_url"
              >
                <QuestionnaireInput placeholder={t('questionnaire.levels.input.placeholder.websiteUrl')} />
              </FormItemElement>
            </Col>
          </Row>
        </>
      ) : (
        <Spin />
      )}
    </QuestionnaireStep>
  );
};

export default compose(inject('kycStore'), withTranslation())(QuestionnaireLevelTwo);
