/**
 * @file
 * Contains kyc Level Component.
 */
import React, { useState } from 'react';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import UploadElement from '_common/components/UploadElement/UploadElement';
import ModalElement from '_common/components/Modal/ModalElement';
import UploadIcon from '_common/assets/icons/kyc-upload-icon.svg';
import {
  KycStepWrapper,
  KycUploadBlock,
  KycUploadBlockTitle,
  KycUploadButtonWrapper,
  KycUploadFooterTitle,
  KycUploadModalTitle,
} from '../Kyc.style';
import KycContentHeader from '../KycContentHeader';

const KycLevel = (props) => {
  const { t, level, currentLevel, description, Icon, cardTitle } = props;

  /**
   * Modal Show handler State.
   */
  const [popupShow, setPopupShow] = useState(false);

  /**
   * Show Modal Handler.
   */
  const showPopup = () => {
    setPopupShow(!popupShow);
  };

  const maskStyle = {
    background: 'rgba(56, 134, 189, 0.25)',
    backdropFilter: 'blur(2px)',
  };

  return (
    <KycStepWrapper level={level} currentLevel={currentLevel + 1}>
      <KycContentHeader Icon={Icon} description={description} />
      <KycUploadBlock>
        <KycUploadBlockTitle>{cardTitle}</KycUploadBlockTitle>
        <KycUploadButtonWrapper>
          <ModalElement
            showPopup={showPopup}
            showModal={popupShow}
            icon={UploadIcon}
            buttonText="Select & upload"
            buttonType="upload"
            footer={null}
            maskStyle={maskStyle}
          >
            <KycUploadModalTitle>{t('kyc.modal.title')}</KycUploadModalTitle>
            <UploadElement />
            <KycUploadFooterTitle>{t('kyc.modal.footer.text')}</KycUploadFooterTitle>
          </ModalElement>
        </KycUploadButtonWrapper>
      </KycUploadBlock>
    </KycStepWrapper>
  );
};

export default compose(withTranslation())(KycLevel);
