/**
 * @file
 * Contains stores.
 */

import GeneralReportStore from '_common/stores/generalReport.store';
import DashboardStore from '_common/stores/dashboard.store';
import GlobalStore from '_common/stores/global.store';
import FinanceStore from '_common/stores/finance.store';
import KycStore from '_common/stores/kyc.store';
import ProfileStore from '_common/stores/profile.store';
import PaymentStore from '_common/stores/payment.store';
import ReportStore from '_common/stores/report.store';
import UsersStore from '_common/stores/users.store';

export {
  GeneralReportStore,
  DashboardStore,
  GlobalStore,
  FinanceStore,
  ProfileStore,
  PaymentStore,
  ReportStore,
	UsersStore,
};

const globalStore = new GlobalStore();
const generalReportStore = new GeneralReportStore();
const dashboardStore = new DashboardStore();
const financeStore = new FinanceStore();
const kycStore = new KycStore();
const profileStore = new ProfileStore();
const paymentStore = new PaymentStore();
const reportStore = new ReportStore();
const usersStore = new UsersStore();

export default {
  globalStore,
  generalReportStore,
  dashboardStore,
  financeStore,
  kycStore,
  profileStore,
  paymentStore,
  reportStore,
	usersStore,
};
