/**
 * @file
 * Contains Request Options Store..
 */

import { observable, action, computed } from 'mobx';

class GlobalStore {
  /**
   * Global Options Data.
   */
  @observable
  transactionDataParams = {};

  /**
   * Get Global Options Data.
   */
  @computed
  get getTransactionType() {
    return this.transactionDataParams;
  }

  /**
   * Fetch Date Report Data.
   */
  @action
  addTransactionTypeAction = (data) => {
    this.transactionDataParams = data;
  };
}

export default GlobalStore;
