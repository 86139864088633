/**
 * @file Contains two factor auth component.
 */

import React, { useState, useEffect } from 'react';
import {
	TwoFactorAuthContainer,
	TwoFactorAuthSubmitButtonWrapper,
	TwoFactorAuthInputWrapper,
	TwoFactorAuthQrCodeWrapper,
	TwoFactorAuthQrCodeLabel,
	TwoFactorAuthInputLabel,
	TwoFactorAuthNodes,
	TwoFactorAuthNodeCode,
	TwoFactorAuthNodeCodeContainer,
	TwoFactorAuthNodeCodeText,
	TwoFactorAuthNodeCodeWrapper,
} from './TwoFactorAuth.style';
import FormItemElement from '_common/components/FormItem/FormItemElement';
import InputElement from '_common/components/Input/InputElement';
import ButtonElement from '_common/components/Button/ButtonElement';
import { useTranslation } from 'react-i18next';
import TooltipElement from '_common/components/Tooltip/TooltipElement';
import TotpInputElement from '_common/components/TotpInputElement/TotpInputElement';

const TwoFactorAuth = (props) => {
	const { nodes, form } = props;

	const { t } = useTranslation();

	const [isCopyToClipboardActive, setIsCopyToClipboardActive] = useState(false);

	/**
	 * Copy to clipboard
	 * @function - copyToClipboard.
	 * @param {string} text - text to copy to clipboard
	 */
	const copyToClipboard = (text) => {
		navigator.clipboard.writeText(text);
		setIsCopyToClipboardActive(true);

		setTimeout(() => {
			setIsCopyToClipboardActive(false);
		}, 1000);
	};

	/**
	 * Totp errors.
	 */
	const totpCodeError = form.getFieldError('totp_code');

	/**
	 * Render two factor auth nodes.
	 * @function - renderTwoFactorNodes.
	 */
	const renderTwoFactorNodes = (node) => {
		const label = node?.meta?.label?.text || '';
		const { src, type, name, value } = node?.attributes;

		if (value) {
			form.setFieldsValue({ [name]: value });
		}

		if (node.type === 'input' && type && name) {
			const hidden = type === 'hidden';

			return (
				type === 'submit' ? (
					<TwoFactorAuthSubmitButtonWrapper>
						<FormItemElement
							name={name}
							hidden
						>
							<InputElement />
						</FormItemElement>
						<ButtonElement
							clear={name === 'totp_unlink' && 'clear'}
							htmlType="submit"
						>
							{name === 'totp_unlink' ? t('settings.security.twoFactorAuth.button.disableTwoFactorAuth') : t('settings.security.twoFactorAuth.button.authenticate')}
						</ButtonElement>
					</TwoFactorAuthSubmitButtonWrapper>
				) : (
					<TwoFactorAuthInputWrapper
						hidden={hidden}
					>
						<TwoFactorAuthInputLabel>
							{t('settings.security.twoFactorAuth.input.label.enterCode')}
						</TwoFactorAuthInputLabel>
						<FormItemElement
							name={name}
							rules={[
								{
									required: true,
									message: t('input.field.required'),
								},
							]}
							errorDisplay={'top'}
						>
							<TotpInputElement
								numInputs={6}
								inputStyle={totpCodeError.length ? 'otp-input error' : 'otp-input'}
							/>
						</FormItemElement>
					</TwoFactorAuthInputWrapper>
				)
			)
		}

		if (
			node.type === 'img' &&
			'src' in node.attributes &&
			'height' in node.attributes &&
			'width' in node.attributes
		) {
			return (
				<TwoFactorAuthQrCodeWrapper>
					<img src={src} alt=""/>
				</TwoFactorAuthQrCodeWrapper>
			)
		}
	};


	/**
	 * Render two factor auth node code.
	 * @function - renderTwoFactorNodeCode.
	 */
	const renderTwoFactorNodeCode = (node) => (
		node.type === 'text' && node?.attributes && node?.attributes.id === 'totp_secret_key' && (
			<TooltipElement visible={isCopyToClipboardActive} placement="top" title={`Copied`}>
				<TwoFactorAuthNodeCodeContainer
					onClick={() => copyToClipboard(node?.attributes?.text?.text)}
				>
					<TwoFactorAuthNodeCodeText>
						{t('settings.security.twoFactorAuth.enable.tapToCopyCode')}
					</TwoFactorAuthNodeCodeText>
					<TwoFactorAuthNodeCodeWrapper>
						{node?.attributes?.text?.text}
					</TwoFactorAuthNodeCodeWrapper>
					<TwoFactorAuthNodeCodeText>
						{t('settings.security.twoFactorAuth.enable.pasteCode')}
					</TwoFactorAuthNodeCodeText>
				</TwoFactorAuthNodeCodeContainer>
			</TooltipElement>
		)
	);

	return (
		<TwoFactorAuthContainer>
			<TwoFactorAuthNodes>
				{nodes.map((node) => (
					renderTwoFactorNodes(node)
				))}
			</TwoFactorAuthNodes>
			<TwoFactorAuthNodeCode>
				{nodes.map((node) => (
					renderTwoFactorNodeCode(node)
				))}
			</TwoFactorAuthNodeCode>
		</TwoFactorAuthContainer>
	)
};

export default TwoFactorAuth;
