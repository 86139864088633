/**
 * @file Contains Enable two factor auth component.
 */

import React from 'react';
import { useTranslation } from 'react-i18next';
import ButtonElement from '_common/components/Button/ButtonElement';
import {
	EnableTwoFactorAuthTitle,
	EnableTwoFactorAuthSubTitle,
	EnableTwoFactorButtonContainer,
} from './Users.style';

const EnableTwoFactorAuth = (props) => {
	const { redirectFn } = props;

	const { t } = useTranslation();

	return (
		<div>
			<EnableTwoFactorAuthTitle>
				{t('user.modal.title.enableTwoFactor')}
			</EnableTwoFactorAuthTitle>
			<EnableTwoFactorAuthSubTitle>
				{t('user.modal.subTitle.enableTwoFactor')}
			</EnableTwoFactorAuthSubTitle>
			<EnableTwoFactorButtonContainer>
				<ButtonElement onClick={() => redirectFn()}>
					{t('user.modal.button.twoFactorAuth.enable')}
				</ButtonElement>
			</EnableTwoFactorButtonContainer>
		</div>
	)
};

export default EnableTwoFactorAuth;
