import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { kratosService } from '_common/services/kratos.service';

/**
 *
 * @param {string} [flowId]
 * @param {function} [onError]
 * @param {boolean} [enabled]
 *
 */
export const useBrowserSettingsFlow = (flowId, onError, enabled) => {
  const [flow, setFlow] = useState(flowId);

  return useQuery({
    queryKey: ['kratos-settings', flow],
    queryFn: () => kratosService.fetchSettingsFlow(flow),
    onError,
    onSettled: (res, error) => {
      const fid = res?.id ?? error?.response?.data?.id;
      if (!flow && fid) {
        setFlow(fid);
      }
    },
	  enabled: enabled,
	  retry: false,
  });
};
