/**
 * @file Contains edit modal component.
 */

import React, { useEffect } from 'react';
import ModalElement from '_common/components/Modal/ModalElement';
import CloseModalIcon from '_common/assets/renderSvgIcons/CloseModalIcon';
import SpinnerElement from '_common/components/Spinner/SpinnerElement';
import EnableTwoFactorAuth from './EnableTwoFactorAuth';
import { Form } from 'antd';
import { UsersModalButtonsContainer, UsersModalInputWrapper, UsersModalTitle } from './Users.style';
import { FilterColumn } from 'globalStyles/globalView.style';
import FormItemElement from '_common/components/FormItem/FormItemElement';
import InputElement from '_common/components/Input/InputElement';
import TreeSelectElement from '_common/components/TreeSelect/TreeSelectElement';
import DropdownArrowIcon from '_common/assets/renderSvgIcons/DropdownArrowIcon';
import ButtonElement from '_common/components/Button/ButtonElement';
import TwoFactorAuthConfirmation from './TwoFactorAuthConfirmation';

const EditUserModal = (props) => {

	const {
		closeModal,
		selectedUser,
		isModalOpen,
		isLoading,
		isEnableTwoFactorAuthVisible,
		redirectToSettings,
		isConfirmTwoFactorAuthVisible,
		saveUserData,
		usersRoles,
		confirmTwoFactorAuthForm,
		setIsConfirmTwoFactorAuthVisible,
		confirmTwoFactorAuth,
		t,
	} = props;

	const [editUserForm] = Form.useForm();

	useEffect(() => {
		if (isModalOpen) {
			editUserForm.setFieldsValue({
				email: selectedUser.email,
				user_id: selectedUser.id,
				roles: selectedUser.roles,
			});
		}
	}, [isModalOpen]);

	/**
	 * Close modal handler.
	 *
	 * @function - closeModalHandler.
	 */
	const closeModalHandler = () => {
		editUserForm.resetFields();
		closeModal();
	};

	return (
		<ModalElement
			showModal={isModalOpen}
			showPopup={() => closeModalHandler()}
			closeIcon={<CloseModalIcon />}
			width={395}
			footer={null}
			destroyOnClose={true}
			maskClosable={false}
		>
			<SpinnerElement spinning={isLoading}>
				{isEnableTwoFactorAuthVisible ? (
					<EnableTwoFactorAuth redirectFn={() => redirectToSettings()} />
				) : (
					<div>
						{!isConfirmTwoFactorAuthVisible ? (
							<Form
								form={editUserForm}
								onFinish={(values) => saveUserData(values)}
							>
								<div>
									<UsersModalTitle>
										{t('user.search.table.modal.title.editUser')}
									</UsersModalTitle>
									<FilterColumn mb={22} pr={1}>
										<UsersModalInputWrapper>
											<FormItemElement
												name="email"
												validateTrigger="onChange"
												rules={[
													{
														required: true,
														message: t('input.field.required'),
													},
												]}
											>
												<InputElement
													design='light'
													placeholder={t('user.search.input.email')}
													disabled={true}
													small
													border
												/>
											</FormItemElement>
										</UsersModalInputWrapper>
									</FilterColumn>
									<FormItemElement
										hidden
										name="user_id"
									>
										<InputElement />
									</FormItemElement>
									<FilterColumn mb={22} pr={1}>
										<FormItemElement
											name="roles"
											validateTrigger="onChange"
											rules={[
												{
													required: true,
													message: t('input.field.required'),
												},
											]}
										>
											<TreeSelectElement
												design='light'
												small
												maxTagCount={2}
												ph_fz={12}
												allowClear
												showArrow
												multiple
												placeholder={t('user.input.roles.placeholder')}
												popupClassName='tree-select-custom-dropdown lite'
												suffixIcon={<DropdownArrowIcon />}
												switcherIcon={<DropdownArrowIcon />}
												data={usersRoles}
												treeNodeLabelProp
											/>
										</FormItemElement>
									</FilterColumn>
									<UsersModalButtonsContainer>
										<ButtonElement
											clear='true'
											height={30}
											onClick={() => closeModalHandler()}
										>
											{t('user.search.table.modal.button.cancel')}
										</ButtonElement>
										<ButtonElement
											height={30}
											htmlType="submit"
										>
											{t('user.search.table.modal.button.save')}
										</ButtonElement>
									</UsersModalButtonsContainer>
								</div>
							</Form>
						) : (
							<Form
								form={confirmTwoFactorAuthForm}
								onFinish={() => confirmTwoFactorAuth(editUserForm)}
								validateTrigger='onSubmit'
							>
								<TwoFactorAuthConfirmation
									isConfirmTwoFactorVisible={setIsConfirmTwoFactorAuthVisible}
									form={editUserForm}
								/>
							</Form>
						)}
					</div>
				)}
			</SpinnerElement>
		</ModalElement>
	)
};

export default EditUserModal;
