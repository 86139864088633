/**
 * @file
 * Contains Method Diagram Component.
 */

import React, { useEffect, useState } from 'react';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import PieGraphicElement from '_common/components/PieGraphic/PieGraphicElement';
import RadioGroupGraphic from '_common/components/RadioGroupGraphic/RadioGroupGraphic';
import TooltipElement from '_common/components/Tooltip/TooltipElement';
import RadioGraphic from '_common/components/RadioGroupGraphic/RadioGraphic';
import NoDataDiagram from '_common/components/NoDataDiagram/NoDataDiagram';
import SpinnerElement from '_common/components/Spinner/SpinnerElement';
import { DashboardGraphicTitle, DashboardGraphicTitleWrapper, DashboardGraphicWrapper } from './Dashboard.style';
import PercentIcon from '../_common/assets/renderSvgIcons/PercentIcon';
import AmountIcon from '../_common/assets/renderSvgIcons/AmountIcon';

const MethodDiagram = (props) => {
  const { t, generalReportStore } = props;

  /**
   * Toggle Method Handler Value State.
   */
  const [toggleMethod, setToggleMethod] = useState('percent');

  /**
   * Methods Graphics State.
   */
  const [reportByMethodGraphic, setReportByMethodGraphic] = useState([]);

  /**
   * Get Date Report Data from Store.
   */
  useEffect(() => {
    if (Object.keys(generalReportStore.getDateReportData || {})?.length) {
      const checkData = generalReportStore.getDateReportData?.breakdown?.by_method;

      if (checkData) {
        const byMethod = Object.entries(checkData).map(([key, value]) => (
          {
            name: key,
            percent: value?.amount_percent,
            amount: Math.round(value?.amount),
            currency: value?.currency,
          }
        ));
        setReportByMethodGraphic(byMethod);
      } else {
        setReportByMethodGraphic([]);
      }
    }
  }, [generalReportStore.getDateReportData]);

  /**
   * Select Method Handler.
   */
  const selectMethodHandler = (e) => {
    const { value } = e.target;
    setToggleMethod(value);
  };

  return (
    <SpinnerElement spinning={generalReportStore.getIsLoading}>
      <DashboardGraphicWrapper>
          {reportByMethodGraphic?.length ? (
            <>
              <DashboardGraphicTitleWrapper>
                <DashboardGraphicTitle>{t('dashboard.graphic.method.title')}</DashboardGraphicTitle>
                  <RadioGroupGraphic value={toggleMethod} onChange={(e) => selectMethodHandler(e)}>
                    <TooltipElement trigger="hover" placement="top" title="Percent">
                      <RadioGraphic value="percent" icon={<PercentIcon />} />
                    </TooltipElement>
                    <TooltipElement trigger="hover" placement="top" title="Amount">
                      <RadioGraphic value="amount" icon={<AmountIcon />} />
                    </TooltipElement>
                  </RadioGroupGraphic>
                </DashboardGraphicTitleWrapper>
              <PieGraphicElement methodToggle={toggleMethod} data={reportByMethodGraphic} />
            </>
          ) : (
            <>
              <DashboardGraphicTitleWrapper>
                <DashboardGraphicTitle>{t('dashboard.graphic.method.title')}</DashboardGraphicTitle>
              </DashboardGraphicTitleWrapper>
              <NoDataDiagram />
            </>
          )}
      </DashboardGraphicWrapper>
    </SpinnerElement>
  );
};

export default compose(inject('generalReportStore'), withTranslation())(observer(MethodDiagram));
