/**
 * @file
 * Contains Radios Group Graphic Element
 */
import React from 'react';
import { Radio, RadioButtonIcon } from './RadioGroupGraphic.style';

const RadioGraphic = (props) => {
  const { options, icon, ...rest } = props;

  return (
    <Radio {...rest}>
      <RadioButtonIcon>
        {icon}
      </RadioButtonIcon>
    </Radio>
  );
};

export default RadioGraphic;
