/**
 * @file
 * Contains Modal Element Style Component.
 */
import React from 'react';
import styled, { css } from 'styled-components';
import { Button, Modal } from 'antd';

export const ModalElementWrapper = styled.div`
  position: relative;
  
  ${(props) => props.buttontype && css `
    &:before {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 6px;
      width: 20px;
      height: 20px;
      background: url(${(props) => props.icon}) center center no-repeat;
      background-size: cover;
      z-index: 9;
    }
  `}
`;

export const ModalIcon = styled.div`
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 6px;
    background: url(${(props) => props.icon}) center center no-repeat;
    background-size: cover;
    z-index: 9;
`;

export const ModalButton = styled(({ ...rest }) => <Button {...rest} />)`
  &.ant-btn {
    background: ${(props) => props.theme.colors.backgroundMain};
    color: ${(props) => props.theme.colors.white};
    font-size: 14px;
    width: 100%;
    border-radius: 10px;
    padding: 10px 10px 10px 24px;
    height: auto;
    
    span {
      font-weight: 700;
    }
    
    &:hover {
      border: 1px solid ${(props) => props.theme.colors.mainColor};
      color: ${(props) => props.theme.colors.mainColor};
      background: ${(props) => props.theme.colors.white};
    }
    
    &:focus {
      background: ${(props) => props.theme.colors.mainColor};
      color: ${(props) => props.theme.colors.white};
    }
  }
  
  ${(props) => props.buttontype && css `
    &.ant-btn {
      color: ${(props) => props.theme.colors.white};
      background: none;
      display: flex;
      align-items: center;
      border: none;
      border-radius: 0;
      position: ${(props) => props.position ? 'absolute' : 'relative'};
      top: 50%;
      transform: ${(props) => props.position ? 'translateY(-50%)' : 'none'};
      height: auto;
      padding: 12px 12px 12px 34px;
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
      width: ${(props) => props.width ? '100%' : 'auto'};
      text-align: center;
      width: ${(props) => props.width && props.width};
      
      span {
        font-weight: 300;
        text-align: center;
      }
      
      svg {
        margin-right: 4px;
        
        @media (min-width:1440px) {
          margin-right: 20px;
        }
      }
    
      &:hover, &:focus {
        background: none;
        color: ${props.theme.colors.white};
        border: none;
      }
    }
  `}
`;

export const ModalContent = styled(Modal)`
  width: ${(props) => props.width ? `${props.width}px` : '650px !important'};

  .ant-modal-title {
		font-size: 20px;    
  }

  .ant-modal-header  {
      background-color: ${(props) => props.theme.colors.white}; 
  }

	.ant-modal-content {
		background-color: ${(props) => props.theme.colors.white};
  }
`;
