import React, { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import Loader from '_common/components/Loader/Loader';
import { observer } from 'mobx-react';
import Navigator from '_common/components/Navigator/Navigator';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { ErrorBoundary } from 'react-error-boundary';
import SomethingWentWrong from '_common/components/SomthengWentWrong/SomethingWentWrong';
import { hotjar } from 'react-hotjar';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

/**
 * Init HotJar analytics.
 */
if (process.env.REACT_APP_ENVIRONMENT_NAME === 'production') {
  const HJID = 3396977;
  const HJSV = 6;
  hotjar.initialize(HJID, HJSV);
}

/**
 * Init Sentry.
 */
if (['production', 'pre-production', 'stage'].includes(process.env.REACT_APP_ENVIRONMENT_NAME)) {
  Sentry.init({
    dsn: 'https://c7378473905543dc8593f97403c35391@o514570.ingest.sentry.io/5952279',
    integrations: [new Integrations.BrowserTracing()],
    environment: process.env.REACT_APP_ENVIRONMENT_NAME,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

/**
 * Setup query client.
 */
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function App() {

  /**
   * Main error handler.
   * @param {object} error.
   * Error.
   * @param {object} info.
   * Stack trace data.
   */
  const mainErrorHandler = (error, info) => {
    Sentry.captureException(error, {
      error,
      stackTrace: info.componentStack,
    });
  };

  return (
    <ErrorBoundary FallbackComponent={SomethingWentWrong} onError={mainErrorHandler}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <Suspense fallback={<Loader />}>
            <Navigator />
          </Suspense>
        </BrowserRouter>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </ErrorBoundary>
  );
}

export default observer(App);
