/**
 * @file Contains invite modals component.
 */
import ModalElement from '_common/components/Modal/ModalElement';
import CloseModalIcon from '_common/assets/renderSvgIcons/CloseModalIcon';
import SpinnerElement from '_common/components/Spinner/SpinnerElement';
import EnableTwoFactorAuth from './EnableTwoFactorAuth';
import { Form } from 'antd';
import { UsersModalButtonsContainer, UsersModalInputWrapper, UsersModalTitle } from './Users.style';
import { FilterColumn } from 'globalStyles/globalView.style';
import FormItemElement from '_common/components/FormItem/FormItemElement';
import InputElement from '_common/components/Input/InputElement';
import TreeSelectElement from '_common/components/TreeSelect/TreeSelectElement';
import DropdownArrowIcon from '_common/assets/renderSvgIcons/DropdownArrowIcon';
import ButtonElement from '_common/components/Button/ButtonElement';
import TwoFactorAuthConfirmation from './TwoFactorAuthConfirmation';
import React from 'react';

const InviteUsersModal = (props) => {

	const [inviteUserForm] = Form.useForm();

	const {
		closeModal,
		isModalOpen,
		isLoading,
		isEnableTwoFactorAuthVisible,
		redirectToSettings,
		isConfirmTwoFactorAuthVisible,
		addUsersData,
		usersRoles,
		confirmTwoFactorAuthForm,
		setIsConfirmTwoFactorAuthVisible,
		confirmTwoFactorAuth,
		t,
	} = props;

	/**
	 * Close modal handler.
	 *
	 * @function - closeModalHandler.
	 */
	const closeModalHandler = () => {
		inviteUserForm.resetFields();
		closeModal();
	};

	return (
		<ModalElement
			showModal={isModalOpen}
			showPopup={() => closeModalHandler()}
			closeIcon={<CloseModalIcon />}
			width={395}
			footer={null}
			destroyOnClose={true}
			maskClosable={false}
		>
			<SpinnerElement spinning={isLoading}>
				{isEnableTwoFactorAuthVisible ? (
					<EnableTwoFactorAuth redirectFn={() => redirectToSettings()} />
				) : (
					<div>
						{!isConfirmTwoFactorAuthVisible ? (
							<Form
								form={inviteUserForm}
								onFinish={(values) => addUsersData(values, inviteUserForm)}
								validateTrigger='onSubmit'
							>
								<div>
									<UsersModalTitle>
										{t('user.search.table.modal.title.inviteNewUser')}
									</UsersModalTitle>
									<FilterColumn mb={22} pr={1}>
										<UsersModalInputWrapper>
											<FormItemElement
												name='email'
												rules={[
													{
														required: true,
														message: t('input.field.required'),
													},
													{
														type: 'email',
														message: t('input.field.notValid.email'),
													}
												]}
											>
												<InputElement
													design='light'
													placeholder={t('user.search.input.emailAddress')}
													border
													small
												/>
											</FormItemElement>
										</UsersModalInputWrapper>
									</FilterColumn>
									<FilterColumn mb={22} pr={1}>
										<UsersModalInputWrapper>
											<FormItemElement
												name="full_name"
												validateTrigger="onChange"
												rules={[
													{
														required: true,
														message: t('input.field.required'),
													},
												]}
											>
												<InputElement
													design='light'
													placeholder={t('user.search.input.fullName')}
													border
													small
												/>
											</FormItemElement>
										</UsersModalInputWrapper>
									</FilterColumn>
									<FilterColumn mb={22} pr={1}>
										<FormItemElement
											name="roles"
											validateTrigger="onChange"
											rules={[
												{
													required: true,
													message: t('input.field.required'),
												},
											]}
										>
											<TreeSelectElement
												design='light'
												small
												maxTagCount={2}
												ph_fz={12}
												allowClear
												showArrow
												multiple
												placeholder={t('user.input.roles.placeholder')}
												popupClassName='tree-select-custom-dropdown lite'
												suffixIcon={<DropdownArrowIcon />}
												switcherIcon={<DropdownArrowIcon />}
												data={usersRoles}
												treeNodeLabelProp
											/>
										</FormItemElement>
										<UsersModalButtonsContainer>
											<ButtonElement
												clear='true'
												height={30}
												onClick={() => closeModalHandler()}
											>
												{t('user.search.table.modal.button.cancel')}
											</ButtonElement>
											<ButtonElement
												height={30}
												htmlType="submit"
											>
												{t('user.search.table.modal.button.invite')}
											</ButtonElement>
										</UsersModalButtonsContainer>
									</FilterColumn>
								</div>
							</Form>
						) : (
							<Form
								form={confirmTwoFactorAuthForm}
								onFinish={() => confirmTwoFactorAuth(inviteUserForm)}
								validateTrigger='onSubmit'
							>
								<TwoFactorAuthConfirmation
									isConfirmTwoFactorVisible={setIsConfirmTwoFactorAuthVisible}
									form={inviteUserForm}
								/>
							</Form>
						)}
					</div>
				)}
			</SpinnerElement>
		</ModalElement>
	)
};

export default InviteUsersModal;
