import styled, { keyframes } from 'styled-components';
import { Col, Row, Table } from 'antd';

export const DashboardContainer = styled.div``;

export const WeeklyReportRow = styled(Row)`
  position: relative;
`;

export const DashboardFiltersWrapper = styled.div`
  margin-bottom: 14px;
  
  @media (min-width: 1400px) {
    margin-bottom: 27px;
  }
`;

export const DashboardStatisticItem = styled.div`
  //box-shadow: 15px 15px 38px rgba(0, 0, 0, 0.13), -15px -15px 38px rgba(226, 223, 223, 0.49);
  padding: 9px 14px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  height: 65px;
  background-color: ${(props) => props.theme.colors.extraLightGrey};
`;

export const StatisticInfoWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const StatisticInfoQuantity = styled.h3`
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 0;
  position: relative;
  display: inline-block;
  line-height: 140%;
`;

export const StatisticInfoDescription = styled.div`
  margin-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StatisticInfoDescriptionTitle = styled.div`
  color: ${(props) => props.theme.colors.silverDark};
  font-size: 14px;
  line-height: 160%;
  font-weight: 500;
`;

export const StatisticInfoDifference = styled.div`
  font-weight: 700;
  font-size: 12px;
  cursor: default;

  .ant-statistic-content {
    font-size: 14px;
  }
`;

export const DashboardGraphicsWrapper = styled.div`
   position: relative;
`;

export const DashboardGraphicWrapper = styled.div`
  padding: 17px 17px 39px 17px;
  background: ${(props) => props.theme.white};
  border-radius: 10px;
  box-shadow: 15px 15px 38px rgba(0, 0, 0, 0.13), -15px -15px 38px rgba(226, 223, 223, 0.49);
  position: relative;
  height: 100%;
  min-height: 336px;
  
  @media (min-width: 1400px) {
    min-height: 395px;
  }
`;

export const DashboardGraphicContainer = styled.div`
  padding: 17px;
  background: ${(props) => props.theme.white};
  border-radius: 10px;
  box-shadow: 15px 15px 38px rgba(0, 0, 0, 0.13), -15px -15px 38px rgba(226, 223, 223, 0.49);
  position: relative;
  height: 100%;
`;

export const DashboardGraphicTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (min-width: 1400px) {
    margin-bottom: 34px;
  }
`;

export const DashboardGraphicTitle = styled.h3`
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 22px;
  margin-top: ${(props) => props.mt ? `${props.mt}px` : '0px'};
  margin-bottom: 0;
`;

export const DashboardTurnoverWrapper = styled.div`
  background: ${(props) => props.theme.colors.extraLightGrey};
  color: ${(props) => props.theme.colors.dark};
  position: absolute;
  right: 0;
  top: 0;
  padding: 18px 20px;
  border-radius: 10px;
  width: calc(100% - 33.33333333% - 12px);
  transition: width 0.5s ease;
  min-height: 336px;
  border: 1px solid ${(props) => props.theme.colors.black};
  
  @media (min-width: 1400px) {
    width: calc(100% - 33.33333333% - 8px);
    min-height: 395px;
  }
  
  &.opened {
    width: 100%;
  }
  
  .ant-spin-nested-loading {
    position: initial;
    
    .ant-spin-container {
      position: initial;
    }
  }

`;

export const TableCol = styled(Col)`
  position: static !important;
`;

export const DashboardTurnoverTitleWrapper = styled.div`
  font-size: 12px;
  margin-bottom: 10px;
  text-transform: uppercase;

  .ant-row {
    .ant-col {
      text-align: center;

      &:first-child {
        text-align: left;
      }

      &:last-child {
        text-align: right;
      }
    }
  }
`;

export const DashboardTurnoverMainTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 16px 0;

  svg {
    cursor: pointer;
  }
`;

export const DashboardTurnoverMainTitle = styled.div`
  font-weight: 700;
  color: ${(props) => props.theme.black};
  border-bottom: 1px solid ${(props) => props.theme.black};
  margin-bottom: 4px;
`;

export const DashboardTurnoverTitleContainer = styled.div``;

export const DashboardIconWrapper = styled.div`
  position: absolute;
  right: 12px;
  top: 15px;
`;

export const DashboardTurnoverSubTitle = styled.div`
  color: ${(props) => props.theme.colors.dark};
  font-size: 14px;
`;

export const DashboardBalanceItem = styled.div`
  background: ${(props) => props.theme.colors.mainColor};
  border-radius: 10px;
  padding: 10px 7px 10px 10px;
  height: 65px;
`;

export const DashboardBalanceTitleWapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${(props) => props.theme.colors.dark};
`;

export const DashboardBalanceTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  
  @media (min-width: 1400px) {
    font-size: 16px;
  }
`;

export const DashboardBalanceArrow = styled.div`
  svg {
    cursor: pointer;
  }
`;

export const DashboardBalanceContent = styled.div`
  color: ${(props) => props.theme.colors.dark};
  font-size: 20px;
  font-weight: 500;
`;

export const BalanceInfoWrapper = styled.div`
  display: flex;
  position: relative;
`;

export const BalanceInfo = styled.div`
  font-size: 14px;
  font-weight: ${(props) => props.weight ? props.weight : 0};
  position: relative; 
  display: flex;
  line-height: 1.5;
  
  @media (min-width: 1400px) {
    font-size: 18px;
  }
`;

export const WeeklyReportTableWrapper = styled.div`
  //position: absolute;
  //left: 50%;
  //top: 58%;
  //transform: translate(-50%, -50%);
  //width: calc(100% - 36px);
  //
  //@media (min-width: 1400px) {
  //  top: 56%;
  //}
`;

export const WeeklyReportTable = styled(Table)`
  border: none;
  
  .ant-table {
    background: rgba(0, 0, 0, 0.0) !important;
    
    .ant-table-container {
      &:after {
        display: none;
      }
    }
    
    .ant-table-content {
      .ant-table-summary {
        background: transparent !important;
        > tr {
          td {

          }
        }
      }
    }
  }
  
  .ant-table-tbody > tr.ant-table-row-level-0:hover > td {
    background: none;
  }
  
  .ant-table-thead > tr > th {
    background: rgba(0, 0, 0, 0.0) !important;
    background: none;
    color: ${(props) => props.theme.colors.dark};
    padding: 2px;
    text-align: center;
    border: none;
    border-bottom: 1px solid ${(props) => props.theme.colors.dark};
    font-size: 14px;
    font-weight: 500;
    
    &:before {
      display: none;
    }
    
    &:first-child {
      text-align: left;
    }
    
    &:last-child {
      text-align: right;
    };
  }
  
  .ant-table-tbody > tr > td {
    background: rgba(0, 0, 0, 0.0) !important;
    color: ${(props) => props.theme.colors.dark};
    padding: 4px 4px 1px;
    text-align: center;
    border-bottom: 1px solid ${(props) => props.theme.colors.dark};
    font-size: 16px;
    cursor: pointer;
    font-weight: 500;
    line-height: 2;
    
    &:first-child {
      text-align: left;
    }
    
    &:last-child {
      text-align: right;
      border-right: none !important;
    };
    
    &:nth-child(even) {
      border-left: 1px solid ${(props) => props.theme.colors.dark};
      border-right: 1px solid ${(props) => props.theme.colors.dark};
    }
  }
  
  .ant-table tfoot > tr > th, .ant-table tfoot > tr > td {
    border-bottom: none;
    font-weight: 800;
    font-size: 16px;
  }
`;

export const TableTotalRow = styled(Table.Summary.Row)`
  padding: 4px;
  border-bottom: none;
`;

export const TableTotalCell = styled(Table.Summary.Cell)`
  background: rgba(0, 0, 0, 0.0) !important;
  color: ${(props) => props.theme.colors.dark};
  padding: 4px !important;
  text-align: ${(props) => props?.align ? props?.align : 'center'};
  font-size: 16px;
  font-weight: 800;
  
  &:first-child {
    text-align: left;
  }
  
  &:last-child {
    text-align: right;
  }
`;

export const DashboardRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 12px;
  position: relative;
  
  @media (min-width: 1400px) {
    margin-bottom: 20px;
  }
`;

export const DashboardStatisticCol = styled.div`
  width: ${(props) => props.width ? `${props.width}px` : '190px'};
  height: ${(props) => props.height ? `${props.height}px` : 'auto'};
  margin-left: 20px;
  
  @media (min-width: 1400px) {
    width: ${(props) => props.wXXL ? `${props.wXXL}px` : '190px'};
  }
  
  &:first-child {
    margin-left: 0;
  }
`;

export const DashboardCol = styled.div`
  position: static !important;
  
  &:last-child {
    margin-right: 0;
  }
  
  @media (min-width: 992px) {
    width: ${(props) => props.lg ? `calc((${(props.lg / 24) * 100}%) - 16px)` : '100%'};
    margin-right: 20px;
  }

  @media (min-width: 1200px) {
    width: ${(props) => props.xl ? `calc((${(props.xl / 24) * 100}%) - 16px)` : '100%'};
    margin-right: 20px;
  }

  @media (min-width: 1400px) {
    width: ${(props) => props.xxl ? `calc(${(props.xxl / 24) * 100}% - 16px)` : '100%'};
    margin-right: 20px;
  }
`;
