/**
 * @file Contains users styles.
 */

import styled from 'styled-components';

export const UsersContainer = styled.div`
	margin-top:  calc(94px - 44px);
		
	@media (min-width: 1400px) {
    margin-top:  calc(146px - 44px);
	}
`;

export const UsersFiltersContainer = styled.div`
	margin-bottom: 30px;
		
	input {
		height: 30px !important;
		line-height: 30px !important;
	}
		
	button {
		line-height: 30px !important;
		padding: 0 !important;
	}
`;

export const UsersTableContainer = styled.div`
  tbody.ant-table-tbody:before {
      display: none;
  }

  tbody.ant-table-tbody tr td {
		height: 36px;
  }
`;

export const UsersEditButton = styled.div`
	color: ${(props) => props.theme.colors.iconColor};
	cursor: pointer;
`;

export const UsersModalInputWrapper = styled.div`
	
`;

export const UsersModalButtonsContainer = styled.div`
	display: flex;
	align-items: center;
	margin-top: 22px;
	justify-content: space-between;
		
	button {
		font-size: 12px !important;
		line-height: 30px !important;
		padding: 0 !important;
			
		&:first-child {
			margin-right: 15px;
		}	
	}	
`;

export const UserTableDataRoles = styled.div`

`;

export const UserTableDataEmail = styled.div`
	text-align: left;
	padding-left: 6px;
`;

export const RevokeModalTitle = styled.div`
	font-size: 14px;
	white-space: pre-line;
	margin-top: 40px;
	text-align: center;
`;

export const RevokeModalButtonsContainer = styled.div`
	margin: 30px auto 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
		
	button {
    font-size: 12px !important;
    line-height: 30px !important;
    padding: 0 !important;
			
			&:first-child {
					margin-right: 15px;
			}
	}
`;

export const ConfirmTwoFactorAuthContainer = styled.div`
	margin-top: 16px;
		
	button {
    margin-top: 18px;
	}

  & .ant-form-item-has-error {
	  input {
      border: 2px solid ${(props) => props.theme.colors.red};
	  }
  }
`;

export const DropdownIconContainer = styled.div`
	cursor: pointer;
	width: 20px;
		
	svg {
		width: 4px;
		
		path {
			fill: ${(props) => props.theme.colors.lightBlack};
		}
	}
`;

export const UsersModalTitle = styled.div`
	margin-bottom: 14px;
  font-size: 20px;
  font-weight: 700;
`;

export const ConfirmTwoFactorAuthTitle = styled.div`
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
`;

export const ConfirmTwoFactorAuthSubTitle = styled.div`

`;

export const EnableTwoFactorAuthTitle = styled.div`
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
		margin-bottom: 14px;
`;

export const EnableTwoFactorAuthSubTitle = styled.div`
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 135px;
`;

export const EnableTwoFactorButtonContainer = styled.div`
	width: 160px;
	font-size: 16px;
	margin: 0 auto;
`;

export const ConfirmTwoFactorAuthButtonContainer = styled.div`
  margin: 135px auto 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ConfirmTwoFactorAuthButtonCancel = styled.div`
	width: 154px;
	margin-right: 15px;
		
	button {
		margin-top: 0;
	}
`;

export const ConfirmTwoFactorAuthButtonAuthenticate = styled.div`
	width: 181px;
	
  button {
    margin-top: 0;
  }
`;

export const UsersRoleDropdownContainer = styled.div`

`;

export const UsersRoleDropdownTitle = styled.div`
	text-transform: capitalize;
	font-size: 14px;
`;

export const UsersRoleDropdownDescription = styled.div`
	font-size: 12px;
`;
