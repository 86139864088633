/**
 * @file Contains users component.
 */
import React, { useState, useEffect } from 'react';
import { compose } from 'recompose';
import { observer, inject } from 'mobx-react';
import MainLayout from '_common/layouts/Main.layout';
import { withTranslation } from 'react-i18next';
import {
	UsersContainer,
	UsersTableContainer,
	UsersRoleDropdownContainer,
	UsersRoleDropdownTitle,
	UsersRoleDropdownDescription,
	DropdownIconContainer,
	UserTableDataEmail,
	UserTableDataRoles,
} from './Users.style';
import Table from '_common/components/TableElement/TableElement';
import { Form } from 'antd';
import PermissionsGate from '_common/components/PermissionsGate/PermissionsGate';
import usePermissions from '_common/hooks/usePermissions.hook';
import { twoFactorAuth } from '_common/utils/twoFacrotAuth.util';
import { useMutation } from '@tanstack/react-query';
import { delayUtil } from '_common/utils/delay.util';
import { sortBA } from '_common/utils/sortingAB.utils';
import links from '_common/routes/urls';
import { useBrowserSettingsFlow } from '_common/hooks/api/kratos/useSettingsFlow.hook';
import { useQueryClient, useQuery } from '@tanstack/react-query';
import { isTwoFactorAuthEnabled } from '_common/utils/twoFactorAuth.util';
import { notification } from 'antd';
import { USER_ROLES_MAPPING } from '_common/constants/userRoles.constant';
import InviteUsersModal from './InviteUsersModal';
import EditUserModal from './EditModal';
import DeleteUserModal from './DeleteUserModal';
import UserFilters from './UserFilters';
import { USERS_TABLE_ITEMS } from '_common/utils/tableItems.util';

const Users = (props) => {

	const { profileStore: { getSelectedProfileId }, usersStore, profileStore, history } = props;

	const queryClient = useQueryClient();

	const usersQuery = useQuery(
		['users-data'],
		async () => await usersStore.getMerchantUsers(getSelectedProfileId),
		{
			enabled: false,
		}
	);

	const browserSettingsFlowQuery = useBrowserSettingsFlow(
		'',
		(error) => {
			const { data } = error?.response;

			if (!!data?.error?.id && data?.error?.id === 'session_aal2_required') {
				setIsTwoFactorAuthActive(true);
			}
		},
		false,
	);

	/**
	 * Report users edit permission flag.
	 */
	const [usersEditPermissionFlag] = usePermissions(['iam.write']);

	/**
	 * Read users permission flag.
	 */
	const [userReadPermissionFlag] = usePermissions(['iam.read']);

	const { t } = props;

	const [confirmTwoFactorAuthForm] = Form.useForm();

	/**
	 * Is invite modal open.
	 */
	const [isInviteUserModalOpen, setIsInviteUserModalOpen] = useState(false);

	/**
	 * Is invite user loading.
	 */
	const [isInviteUserLoading, setIsInviteUserLoading] = useState(false);

	/**
	 * Is edit modal open.
	 */
	const [isEditModalOpen, setIsEditModalOpen] = useState(false);

	/**
	 * Is edit user loading.
	 */
	const [isEditUserLoading, setIsEditUserLoading] = useState(false);

	/**
	 * Is users data loading.
	 */
	const [isUsersDataLoading, setIsUsersDataLoading]  = useState(false);

	/**
	 * Is revoke modal open.
	 */
	const [isRevokeModalOpen, setIsRevokeModalOpen] = useState(false);

	/**
	 * Is revoke data loading.
	 */
	const [isRevokeDataLoading, setIsRevokeDataLoading] = useState(false);

	/**
	 * Is two factor auth confirm button visible.
	 */
	const [isConfirmTwoFactorAuthVisible, setIsConfirmTwoFactorAuthVisible] = useState(false);

	/**
	 * Is two factor auth active.
	 */
	const [isTwoFactorAuthActive, setIsTwoFactorAuthActive] = useState(false);

	/**
	 * Users data.
	 */
	const [usersData, setUsersData] = useState([]);

	/**
	 * Users roles.
	 */
	const [usersRoles, setUsersRoles] = useState([]);

	/**
	 * Two factor auth token.
	 */
	const [twoFactorAuthToken, setTwoFActorAuthToken] = useState('');

	/**
	 * Csfr token.
	 */
	const [csfrToken, setCsfrToken] = useState('');

	/**
	 * Two factor flow id.
	 */
	const [twoFactorFlowId, setTwoFactorFlowId] = useState('');

	/**
	 * Selected user.
	 */
	const [selectedUser, setSelectedUser] = useState({});

	/**
	 * Profile id.
	 */
	const [profileId, setProfileId] = useState('');

	/**
	 * Is enable two factor auth visible.
	 */
	const [isEnableTwoFactorAuthVisible, setIsEnableTwoFactorAuthVisible] = useState(false);

	/**
	 * Invite user mutation.
	 */
	const inviteUserMutation = useMutation({
		mutationFn: async () => await usersStore.inviteUsersData(getSelectedProfileId, {}, twoFactorAuthToken),
		retry: 5,
	});

	/**
	 * Edit user mutation.
	 */
	const editUserMutation = useMutation({
		mutationFn: async () => await usersStore.editUsersData(getSelectedProfileId, {}, twoFactorAuthToken),
		retry: 5,
	});

	/**
	 * Get users data.
	 */
	useEffect(() => {
		if (userReadPermissionFlag) {
			(async () => {
				setIsUsersDataLoading(true);
				try {
					const browserSettingsResponse = await browserSettingsFlowQuery.refetch();
					const isTwoFactorEnabled = isTwoFactorAuthEnabled(browserSettingsResponse, isTwoFactorAuthActive, browserSettingsResponse.isSuccess);
					setIsEnableTwoFactorAuthVisible(!isTwoFactorEnabled);

					const { data } = await usersQuery.refetch();
					const { roles } = await usersStore.getUsersRoles();

					const userRoles = roles.reduce((roles, role) => {
						const userRoleData = {
							key: role,
							title: () => (
								<UsersRoleDropdownContainer>
									<UsersRoleDropdownTitle>
										{role}
									</UsersRoleDropdownTitle>
									{!!USER_ROLES_MAPPING?.[role] && (
										<UsersRoleDropdownDescription>
											{t(USER_ROLES_MAPPING?.[role]?.description)}
										</UsersRoleDropdownDescription>
									)}
								</UsersRoleDropdownContainer>
							),
							label: role,
							value: role,
						};

						return [...roles, userRoleData];
					}, []);

					const { iam_users } = data;
					const sortedUsers = sortBA(iam_users, 'updated_at', 'date');
					setUsersData(sortedUsers);
					setUsersRoles(userRoles);
				} catch (e) {
					console.error(e);
				} finally {
					setIsUsersDataLoading(false);
				}
			})();
		} else {
			history.push(links.dashboard);
		}
	}, [getSelectedProfileId, userReadPermissionFlag]);

	/**
	 * Set selected profile id.
	 */
	useEffect(() => {
		const { selectedProfileId } = profileStore;
		setProfileId(selectedProfileId);
	}, [profileStore]);

	/**
	 * Table dropdown menu items.
	 */
	const usersTableItemData = [
		{
			label: <div
					onClick={() => {
						setIsEditModalOpen(!isEditModalOpen);
					}}
				>
					{t('user.search.table.column.data.edit')}
				</div>,
			key: 'edit',
		},
		{
			label: <div
					onClick={() => {
						setIsRevokeModalOpen(!isRevokeModalOpen);
					}}
				>
					{t('user.search.table.column.data.delete')}
				</div>,
			key: 'delete',
		},
	];

	/**
	 * Users table columns data.
	 */
	const usersTableData = USERS_TABLE_ITEMS(
		t,
		usersEditPermissionFlag,
		setSelectedUser,
		usersTableItemData,
		DropdownIconContainer,
		UserTableDataEmail,
		UserTableDataRoles
	);

	/**
	 * Close  modal handler.
	 *
	 * @function - closeModalHandler.
	 */
	const closeModalHandler = (setModalState, modalState) => {
		setModalState(!modalState);
		setTwoFActorAuthToken('');
		setCsfrToken('');
		setTwoFactorFlowId('');
		setIsConfirmTwoFactorAuthVisible(false);
	};

	/**
	 * Search users data.
	 *
	 * @async
	 * @function - searchUserData.
	 * @param {object} values - form values.
	 */
	const searchUserData = (values) => {
		const { name, email } = values;

		const { iam_users } = queryClient.getQueryData(['users-data']);

		if (name || email) {
			const emailValue = email ? email.toLowerCase() : '';
			const nameValue = name ? name.toLowerCase() : '';

			const filteredData = iam_users.filter((user) => {
				const userEmail = user.email.toLowerCase();
				const userName = user.full_name.toLowerCase();

				if (emailValue && nameValue) {
					return userEmail.includes(emailValue) && userName.includes(nameValue);
				} else {
					return emailValue ? userEmail.includes(emailValue) : userName.includes(nameValue);
				}
			});
			setUsersData(filteredData);
		} else {
			setUsersData(iam_users);
		}
	};

	/**
	 * Redirect to settings page.
	 *
	 * @function - redirectToSettings.
	 */
	const redirectToSettings = () => {
		history.push({
			pathname: links.settings,
			state: {
				data: 'security',
			},
		});
	};

	/**
	 * Save users data.
	 *
	 * @async
	 * @function - saveUsersData.
	 */
	const saveUsersData = async (data) => {
		setIsEditUserLoading(true);
		try {
			await usersStore.editUsersData(getSelectedProfileId, data);
			setIsUsersDataLoading(false);
			setIsEditModalOpen(false);
		} catch (e) {
			console.error(e);

			const twoFactorToken = e.data['2fa_token'];

			if (twoFactorToken) {
				const respHook = await twoFactorAuth(twoFactorToken);

				const { csfrToken, flowId, twoFactorAuthToken } = respHook;

				setTwoFActorAuthToken(twoFactorAuthToken);
				setCsfrToken(csfrToken);
				setTwoFactorFlowId(flowId);

				setIsConfirmTwoFactorAuthVisible(true);
			}
		} finally {
			setIsEditUserLoading(false);
		}
	};

	/**
	 * Add users data.
	 *
	 * @async
	 * @function - addUsersData.
	 */
	const addUsersData = async (data, form) => {
		setIsInviteUserLoading(true);
		try {
			await usersStore.inviteUsersData(getSelectedProfileId, data);
			setIsInviteUserModalOpen(false);
			form.resetFields();
		} catch (e) {
			console.error(e);

			const twoFactorToken = e.data['2fa_token'];

			if (twoFactorToken) {
				const respHook = await twoFactorAuth(twoFactorToken);

				const { csfrToken, flowId, twoFactorAuthToken } = respHook;

				setTwoFActorAuthToken(twoFactorAuthToken);
				setCsfrToken(csfrToken);
				setTwoFactorFlowId(flowId);

				setIsConfirmTwoFactorAuthVisible(true);
			}
		} finally {
			setIsUsersDataLoading(false);
			setIsInviteUserLoading(false);
		}
	};

	/**
	 * Revoke user handler.
	 *
	 * @async
	 * @function - revokeUserHandler.
	 */
	const revokeUserHandler = async (data) => {
		setIsRevokeDataLoading(true);
		try {
			const { user_id, full_name } = data;

			const revokeUserData = {
				roles: [],
				user_id,
				full_name,
			};

			await usersStore.editUsersData(getSelectedProfileId, revokeUserData);
			setIsRevokeModalOpen(false);
			setIsUsersDataLoading(false);
		} catch (e) {
			console.error(e);

			const twoFactorToken = e.data['2fa_token'];

			if (twoFactorToken) {
				const respHook = await twoFactorAuth(twoFactorToken);

				const { csfrToken, flowId, twoFactorAuthToken } = respHook;

				setTwoFActorAuthToken(twoFactorAuthToken);
				setCsfrToken(csfrToken);
				setTwoFactorFlowId(flowId);

				setIsConfirmTwoFactorAuthVisible(true);
			}
		} finally {
			setIsRevokeDataLoading(false);
		}
	};

	/**
	 * Confirm two factor auth.
	 *
	 * @async
	 * @function - confirmTwoFactorAuth.
	 */
	const confirmTwoFactorAuth = async (mutation, form, loaderIndicator, isModalOpen, notificationMessage) => {
		loaderIndicator(true);
		try {
			const { totp } = form.getFieldsValue();
			await twoFactorAuth('', twoFactorFlowId, totp, csfrToken, getSelectedProfileId);

			await delayUtil(7000);

			await mutation.mutateAsync();

			setIsUsersDataLoading(true);
			const usersDataQuery = await usersQuery.refetch();
			const { iam_users } = usersDataQuery?.data;
			const sortedUsers = sortBA(iam_users, 'updated_at', 'date');
			setUsersData(sortedUsers);
			isModalOpen(false);
			setIsConfirmTwoFactorAuthVisible(false);
			form.resetFields();

			notificationMessage && notification.success({
				message: t(notificationMessage),
				duration: 5,
			})
		} catch(e) {
			console.error(e);

			form.resetFields(['totp']);
			form.setFields([
				{
					name: 'totp',
					errors: [t('user.error.invalidCode')],
				}
			]);
		} finally {
			setIsUsersDataLoading(false);
			loaderIndicator(false);
		}
	};

	return (
		<MainLayout>
			<PermissionsGate scopes={['iam.read']}>
				<UsersContainer>
					<UserFilters
						searchUserData={(values) => searchUserData(values)}
						setUsersData={setUsersData}
						usersEditPermissionFlag={usersEditPermissionFlag}
						setIsInviteUserModalOpen={setIsInviteUserModalOpen}
						t={t}
					/>
					<UsersTableContainer>
						<Table
							columns={usersTableData}
							dataSource={usersData}
							pagination={false}
							loading={isUsersDataLoading}
							rowClassName={(_, index) => index % 2 === 0 ? 'table-row-light' : 'table-row-dark'}
						/>
					</UsersTableContainer>
					<InviteUsersModal
						closeModal={() => closeModalHandler(setIsInviteUserModalOpen, isInviteUserModalOpen)}
						isModalOpen={isInviteUserModalOpen}
						isLoading={isInviteUserLoading || inviteUserMutation.isLoading}
						isEnableTwoFactorAuthVisible={isEnableTwoFactorAuthVisible}
						redirectToSettings={() => redirectToSettings()}
						isConfirmTwoFactorAuthVisible={isConfirmTwoFactorAuthVisible}
						addUsersData={(values, inviteUserForm) => addUsersData(values, inviteUserForm)}
						usersRoles={usersRoles}
						confirmTwoFactorAuthForm={confirmTwoFactorAuthForm}
						setIsConfirmTwoFactorAuthVisible={setIsConfirmTwoFactorAuthVisible}
						confirmTwoFactorAuth={(form) => confirmTwoFactorAuth(inviteUserMutation, form, setIsInviteUserLoading, setIsInviteUserModalOpen, 'user.notification.userAdded')}
						t={t}
					/>
					<EditUserModal
						closeModal={() => closeModalHandler(setIsEditModalOpen, isEditModalOpen)}
						selectedUser={selectedUser}
						isModalOpen={isEditModalOpen}
						isLoading={isEditUserLoading}
						isEnableTwoFactorAuthVisible={isEnableTwoFactorAuthVisible}
						redirectToSettings={() => redirectToSettings()}
						isConfirmTwoFactorAuthVisible={isConfirmTwoFactorAuthVisible}
						saveUserData={(values) => saveUsersData(values)}
						usersRoles={usersRoles}
						confirmTwoFactorAuthForm={confirmTwoFactorAuthForm}
						setIsConfirmTwoFactorAuthVisible={setIsConfirmTwoFactorAuthVisible}
						confirmTwoFactorAuth={(form) => confirmTwoFactorAuth(editUserMutation, form, setIsEditUserLoading, setIsEditModalOpen, 'user.notification.userUpdate')}
						t={t}
					/>
					<DeleteUserModal
						closeModal={() => closeModalHandler(setIsRevokeModalOpen, isRevokeModalOpen)}
						selectedUser={selectedUser}
						isModalOpen={isRevokeModalOpen}
						isLoading={isRevokeDataLoading}
						isEnableTwoFactorAuthVisible={isEnableTwoFactorAuthVisible}
						redirectToSettings={() => redirectToSettings()}
						isConfirmTwoFactorAuthVisible={isConfirmTwoFactorAuthVisible}
						deleteUserHandler={(values) => revokeUserHandler(values)}
						confirmTwoFactorAuthForm={confirmTwoFactorAuthForm}
						setIsConfirmTwoFactorAuthVisible={setIsConfirmTwoFactorAuthVisible}
						confirmTwoFactorAuth={(form) => confirmTwoFactorAuth(editUserMutation, form, setIsRevokeDataLoading, setIsRevokeModalOpen, 'user.notification.userDelete')}
						t={t}
						userGuid={profileId}
						email={selectedUser.email}
					/>
				</UsersContainer>
			</PermissionsGate>
		</MainLayout>
	)
};

export default compose(
	withTranslation(),
	inject('profileStore', 'usersStore'),
)(observer(Users));
