/**
 * @file Contains input amount element.
 */

import React, { useState } from 'react';
import { InputAmountContainer, InputAmountWrapper, InputAmount, InputAmountCurrency } from './InputAmountElement.style';

const InputAmountElement = (props) => {
	const [value, setValue] = useState('');

	const { currency, onChange, ...rest } = props;

	/**
	 * On change handler.
	 *
	 * @function - onChangeHandler
	 * @param {object} e - input properties.
	 */
	const onChangeHandler = (e) => {
		const { value } = e.target;
		const regex = /^\d*\.?\d{0,2}$/;

		if (regex.test(value)) {
			setValue(value);
			onChange(value);
		}
	};

	return (
		<InputAmountContainer>
			<InputAmountWrapper>
				<InputAmount
					onChange={(e) => onChangeHandler(e)}
					placeholder="0"
					value={value}
					autoComplete="off"
					{...rest}
				/>
			</InputAmountWrapper>
			<InputAmountCurrency>
				{currency || ''}
			</InputAmountCurrency>
		</InputAmountContainer>
	)
};

export default InputAmountElement;
