/**
 * @file
 * Contains string compare export.
 */
export const compareAB = (a, b) => {
  if (a > b) {
    return -1;
  }
  if (a < b) {
    return 1;
  }
  return 0;
};

/**
 * Sort from b to a.
 *
 * @function - sortBA.
 * @param {array} data - sort data.
 * @param {string} key - sort bu key.
 * @param {string} param - sort by param.
 */
export const sortBA = (data, key, param) => {
	if (!!key) {
		if (param) {
			if (param === 'date') {
				return data.sort((a, b) => {
					const sortA = Date.parse(a[key]);
					const sortB = Date.parse(b[key]);
					return sortB - sortA;
				});
			}
		} else {
			return data.sort((a, b) => {
				return a[key] - b[key];
			});
		}
	} else {
		return data.sort((a, b) => {
			return b - a;
		});
	}
};
