/**
 * @file
 * Contains Table Column Settings style.
 */
import styled from 'styled-components';
import { Checkbox } from 'antd';
import React from 'react';

export const SettingsWrap = styled.div`
  font-size: 18px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

export const PopoverWrap = styled.div`
  width: 20px;
`;

export const Item = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;
  &:not(:first-child) {
    border-top: 1px solid ${(props) => props.theme.colors.lightGrey};
  }
`;

export const CheckboxItem = styled(({...rest}) => <Checkbox {...rest} />)`
  flex: 1;
  padding: 0 10px;
  .ant-checkbox {
    &.ant-checkbox-checked {
      &:after {
        border: 1px solid ${(props) => props.theme.colors.mainColor};
      }
      .ant-checkbox-inner {
        background-color: ${(props) => props.theme.colors.mainColor};
      }
    }
    .ant-checkbox-inner {
      border-color: ${(props) => props.theme.colors.mainColor};
    }
  }
`;

export const DragBtn = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  font-size: 18px;
  
  svg {
    fill: ${(props) => props.mainColor};
  }
`;

export const PopoverHeader = styled.span`
  .ant-btn {
    width: 50px;
    margin-left: 10px;
  }
`;
