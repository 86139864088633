/**
 * @file
 * Contains Questionnaire Level 1 component.
 */
import React, { useState } from 'react';
import { ReactComponent as SuccessfullySubmitIcon } from '_common/assets/icons/succesfull-submit-icon.svg';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import {
  QuestionnaireLevelsTitle,
  QuestionnaireStep,
  QuestionnaireSubmitedIconWrapper,
  QuestionnaireConfirmationTitle,
  QuestionnairePhoneNumber,
  QuestionnaireResetLink,
  QuestionnaireVerificationCodeWrapper,
  VerificationTitle,
  QuestionnaireVerificationCode,
  VerificaitionInput,
} from '../Questionnaire.style';

const QuestionnaireLevelFour = (props) => {
  const { t, currentLevel, phone } = props;

  /**
   * Verification State.
   */
  const [phoneCode, setPhoneCode] = useState('');

  /**
   * Handle Verification.
   */
  const handleVerificationCode = (code) => {
    setPhoneCode(code);
  };

  return (
    <QuestionnaireStep level={4} currentLevel={currentLevel}>
      <QuestionnaireLevelsTitle>{t('questionnaire.submitted.title')}</QuestionnaireLevelsTitle>
      <QuestionnaireSubmitedIconWrapper>
        <SuccessfullySubmitIcon />
      </QuestionnaireSubmitedIconWrapper>
      <QuestionnaireConfirmationTitle>{t('questionnaire.submitted.codeConfirm')}</QuestionnaireConfirmationTitle>
      <QuestionnairePhoneNumber>{phone}</QuestionnairePhoneNumber>
      <QuestionnaireResetLink to="/">{t('questionnaire.submitted.wrongPhone')}</QuestionnaireResetLink>
      <QuestionnaireVerificationCodeWrapper>
        <VerificationTitle>{t('questionnaire.submitted.enterCode')}</VerificationTitle>
        <QuestionnaireVerificationCode>
          <VerificaitionInput
            value={phoneCode}
            onChange={handleVerificationCode}
            inputStyle="verification-input"
            isInputNum
          />
        </QuestionnaireVerificationCode>
        <QuestionnaireResetLink to="/">{t('questionnaire.submitted.resendCode')}</QuestionnaireResetLink>
      </QuestionnaireVerificationCodeWrapper>
    </QuestionnaireStep>
  );
};

export default compose(withTranslation())(QuestionnaireLevelFour);
