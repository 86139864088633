/**
 * @file
 * Contains Pie Graphic Element Component.
 */
import React, { useState } from 'react';
import {
  PieGraphicWrapper,
  GlobalStyle,
} from '_common/components/PieGraphic/PieGraphicElement.style';
import { PieChart, Pie, ResponsiveContainer, Tooltip, Cell, Legend } from 'recharts';
import PropTypes from 'prop-types';
import Utils from '_common/utils';

const PieGraphicElement = (props) => {
  const { methodToggle, data, ...rest } = props;

  /**
   * Opacity State.
   */
  const [opacity, setOpacity] = useState('');

  const colors = ['#51C0BF', '#FD6585', '#FD9E4B', '#3886BD', '#78787A', '#FECC60'];

  /**
   * On Mouse Enter Handler.
   * @param {object} eventData.
   * Event data.
   */
  const handleMouseEnter = (eventData) => {
    const { value } = eventData;

    setOpacity(value);
  };

  /**
   * On Mouse Leave Handler.
   */
  const handleMouseLeave = () => {
    setOpacity('');
  };

  /**
   * Custom tool tip.
   */
  const CustomTooltip = (data) => {
    const { active, payload } = data;

    if (active && payload && payload.length) {
      const currency = payload[0]?.payload?.payload?.currency;
      const unit = payload[0].dataKey === 'percent' ? '%' : ` ${currency}`;

      return (
        <div className="method-pie-graphic-tooltip">
          {`${payload[0].name}: ${Utils.trimNumber(payload[0].value)}${unit}`}
        </div>
      );
    }

    return null;
  };

  return (
    <PieGraphicWrapper {...rest}>
      <GlobalStyle />
      <ResponsiveContainer width="100%" height={256}>
        <PieChart>
          <Pie data={data} dataKey={methodToggle} animationDuration={500}>
            {data.map((entry, index) => (
              <Cell
                key={`cell-${entry.name}`}
                opacity={entry.name === opacity ? '0.8' : '1'}
                outerRadius={180}
                fill={colors[index]}
                strokeWidth={0}
              />
            ))}
          </Pie>
          <Legend iconType="square" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} />
          <Tooltip content={<CustomTooltip />} />
        </PieChart>
      </ResponsiveContainer>
    </PieGraphicWrapper>
  );
};

PieGraphicElement.propTypes = {
  data: PropTypes.instanceOf(Array).isRequired,
};

export default PieGraphicElement;
