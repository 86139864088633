/**
 * @file Contains two factor auth styles.
 */

import styled from 'styled-components';

export const TwoFactorAuthContainer = styled.div`
	display: flex;
`;

export const TwoFactorAuthNodes = styled.div`
	width: 360px;
`;

export const TwoFactorAuthNodeCode = styled.div`
	margin-left: 77px;
`;

export const TwoFactorAuthNodeCodeContainer = styled.div`
	border-radius: 22px;
	border: 1px dashed ${(props) => props.theme.colors.warningBorderColor};
	background: ${(props) => props.theme.colors.warningBackgroundColor};
	padding: 32px 30px;
	max-width: 508px;
`;


export const TwoFactorAuthNodeCodeWrapper = styled.div`
	text-align: center;
	margin-bottom: 38px;
	margin-top: 56px;
	font-size: 18px;
	font-weight: 800;
	color: ${(props) => props.theme.colors.mainColor};
  word-break: break-all;
  white-space: pre-wrap;

  @media (min-width: 992px) {
    font-size: 20px;
  }
`;

export const TwoFactorAuthNodeCodeText = styled.div`
	text-align: center;
	font-size: 16px;
`;

export const TwoFactorAuthSubmitButtonWrapper = styled.div`
	margin-bottom: 20px;
	
  button {
    width: 156px !important;
	  border-radius: 10px !important;
	  padding: 13px 35px !important;
	  font-size: 16px !important;
	  font-weight: 700 !important;
  }
`;

export const TwoFactorAuthInputWrapper = styled.div`
	margin-bottom: 48px;
	height: ${(props) => props.hidden ? '0px' : 'auto'};
`;

export const TwoFactorAuthInputLabel = styled.div`
	font-size: 16px;
	margin-bottom: 48px;
`;

export const TwoFactorAuthQrCodeWrapper = styled.div`
	margin-bottom: 29px;
	width: 199px;
	height: 199px;
	
	img	{
		border: 1px solid ${(props) => props.theme.colors.mainColor};
    border-radius: 20px;
		width: 100%;
		height: 100%;
	}
`;

export const TwoFactorAuthQrCodeLabel = styled.div`
	text-align: center;
	font-size: 18px;
	font-weight: 700;
`;
