/**
 * @file
 * Contains Questionnaire Level 1 component.
 */
import React, { useEffect, useState } from 'react';
import { Row, Col, Spin } from 'antd';
import KycCheckboxCheckedIconSquare from '_common/assets/icons/kyc-checkbox-checked-square.svg';
import links from '_common/routes/urls';
import { compose } from 'recompose';
import { inject } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import FormItemElement from '_common/components/FormItem/FormItemElement';
import { getJwtAccessToken } from '_common/services/jwtToken.service';
import { editString } from '_common/utils/stringEdit.util';
import {
  QuestionnaireStep,
  QuestionnaireFinalSelect,
  QuestionnaireOption,
  QuestionnaireTextAreaInput,
  QuestionnaireAgreementTitle,
  QuestionnaireCheckbox,
  QuestionnaireTermsAgreement,
  QuestionnaireAgreementContainer,
  QuestionnaireLink,
} from '../Questionnaire.style';

const QuestionnaireLevelThree = (props) => {
  const { t, currentLevel, kycStore } = props;

  /**
   * Question Parameters State
   */
  const [questionsData, setQuestionsData] = useState({});

  /**
   * Fetch Question Parameters Data.
   */
  useEffect(() => {
    const fetchUserInfoData = async () => {
      try {
        kycStore.setIsLoading(true);
        const userInfo = await kycStore.getProfileQuestionnaireDataAction(getJwtAccessToken());

        const questions = {
          businessChallenges: userInfo.business_challenges,
          liveExpectedDate: userInfo.live_expected_date,
          businessChallengesNeeds: userInfo.business_challenges_and_needs,
        };
        setQuestionsData(questions);
      } catch (e) {
        throw new Error(e);
      }
    };

    fetchUserInfoData();
  }, [kycStore]);

  return (
    <QuestionnaireStep lastStep="true" level={3} currentLevel={currentLevel}>
      {Object.keys(questionsData).length ? (
        <>
          <Row gutter={[100, 50]}>
            <Col xs={12}>
              <FormItemElement
                questionnairelabel
                label={questionsData.businessChallenges.description}
                name="business_challenges"
              >
                <QuestionnaireFinalSelect
                  popupClassName="kyc-select-dropdown"
                  placeholder={questionsData.businessChallenges.description}
                >
                  {questionsData.businessChallenges.choices.map((choice) => (
                    <QuestionnaireOption value={choice} key={choice}>
                      {editString(choice)}
                    </QuestionnaireOption>
                  ))}
                </QuestionnaireFinalSelect>
              </FormItemElement>
            </Col>
            <Col xs={12}>
              <FormItemElement
                questionnairelabel
                label={questionsData.liveExpectedDate.description}
                name="liveExpectedDate"
              >
                <QuestionnaireFinalSelect
                  popupClassName="kyc-select-dropdown"
                  placeholder={questionsData.liveExpectedDate.description}
                >
                  {questionsData.liveExpectedDate.choices.map((choice) => (
                    <QuestionnaireOption value={choice} key={choice}>
                      {editString(choice)}
                    </QuestionnaireOption>
                  ))}
                </QuestionnaireFinalSelect>
              </FormItemElement>
            </Col>
          </Row>
          <Row>
            <Col xs={24}>
              <FormItemElement
                questionnairelabel
                label={questionsData.businessChallengesNeeds.description}
                name="business_challenges_and_needs"
              >
                <QuestionnaireTextAreaInput placeholder={questionsData.businessChallengesNeeds.description} />
              </FormItemElement>
            </Col>
          </Row>
          <Row>
            <Col xs={24}>
              <QuestionnaireAgreementContainer>
                <QuestionnaireAgreementTitle>{t('questionnaire.levels.agreementTitle')}</QuestionnaireAgreementTitle>
                <QuestionnaireTermsAgreement>
                  <QuestionnaireCheckbox icon={KycCheckboxCheckedIconSquare}>
                    {t('questionnaire.levels.agree')}
                  </QuestionnaireCheckbox>
                  <span>
                    <QuestionnaireLink to={links.terms}>
                      {t('questionnaire.levels.termsAndConditions')}
                    </QuestionnaireLink>
                  </span>
                </QuestionnaireTermsAgreement>
                <QuestionnaireTermsAgreement>
                  <QuestionnaireCheckbox icon={KycCheckboxCheckedIconSquare}>
                    {t('questionnaire.levels.receiveNews')}
                  </QuestionnaireCheckbox>
                </QuestionnaireTermsAgreement>
              </QuestionnaireAgreementContainer>
            </Col>
          </Row>
        </>
      ) : (
        <Spin />
      )}
    </QuestionnaireStep>
  );
};

export default compose(inject('kycStore'), withTranslation())(QuestionnaireLevelThree);
