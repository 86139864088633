/**
 * @file
 * Contains bank icon icon.
 */
import React from 'react';

const BankIcon = (props) => {
  const { color } = props;

  return (
    <svg width="42" height="50" viewBox="0 0 42 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path d="M32.5195 26.2051H37.4411V40.234H32.5195V26.2051Z" fill={color ? color : 'black'} />
        <path d="M23.1992 26.2051H28.1211V40.234H23.1992V26.2051Z" fill={color ? color : 'black'}/>
        <path d="M13.8789 26.2051H18.8008V40.234H13.8789V26.2051Z" fill={color ? color : 'black'}/>
        <path d="M4.55859 26.2051H9.48015V40.234H4.55859V26.2051Z" fill={color ? color : 'black'}/>
        <path d="M39.0812 21.8105V20.3457H2.91797V21.8105C2.91797 22.6196 3.4688 23.2754 4.14844 23.2754H37.8508C38.5301 23.2754 39.0812 22.6196 39.0812 21.8105Z" fill={color ? color : 'black'}/>
        <path d="M40.7696 43.1641H1.23242C0.55278 43.1641 0.00195312 43.8198 0.00195312 44.6289V48.5352C0.00195312 49.3443 0.55278 50 1.23242 50H40.7696C41.4492 50 42 49.3443 42 48.5352V44.6289C42 43.8198 41.4492 43.1641 40.7696 43.1641Z" fill={color ? color : 'black'}/>
        <path d="M1.23134 17.4164H40.7704C41.3209 17.4164 41.8044 16.9811 41.9544 16.3506C42.104 15.72 41.8842 15.0475 41.4154 14.7041L21.6459 0.217438C21.2505 -0.0724792 20.7516 -0.0724792 20.3558 0.217438L0.586301 14.7041C0.117505 15.0478 -0.102313 15.72 0.0476501 16.3506C0.197614 16.9811 0.681149 17.4164 1.23134 17.4164ZM20.1645 8.61588H21.8375C22.5172 8.61588 23.068 9.27162 23.068 10.0807C23.068 10.8898 22.5172 11.5456 21.8375 11.5456H20.1645C19.4849 11.5456 18.9341 10.8898 18.9341 10.0807C18.9341 9.27162 19.4849 8.61588 20.1645 8.61588Z" fill={color ? color : 'black'}/>
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="42" height="50" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  )
};

export default BankIcon;
