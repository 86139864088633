/**
 * @file
 * Contains Add Account Component.
 */
import React, { useState } from 'react';
import { Form } from 'antd';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import FormItemElement from '_common/components/FormItem/FormItemElement';
import themeConfig from 'config';
import {
  AddAccountWrapper,
  AddAccountTitle,
  AddAccountContainer,
  AddAccountSelector,
  AddAccountDescription,
  AddAccountFormContainer,
  AddAccountForm,
  SelectAccountElement,
  SelectAccountOption,
  AccountInputElement,
  BackToAccounts,
} from './FinanceAccounts.style';
import { ButtonWrapper, ModalButton, ModalButtonWrapper, ModalButtonIcon } from '../Finance.style';
import AccountAdded from './AccountAdded';
import ModalArrowBackIcon from '_common/assets/renderSvgIcons/ModalArrowBackIcon';
import ModalContinueIcon from '_common/assets/renderSvgIcons/ModalContinueIcon';
import BankIcon from '_common/assets/renderSvgIcons/BankIcon';
import CryptoIcon from '_common/assets/renderSvgIcons/CyrptoIcon';

const AddAccount = (props) => {
  const { t, addAccountHandler, addAccount } = props;

  /**
   * Use form object.
   */
  const [bankAccount, cryptoAccount] = Form.useForm();

  /**
   * Add Bank Account State.
   */
  const [addBankAccount, setAddBankAccount] = useState(true);

  /**
   * Add Bank Account State.
   */
  const [addCryptoAccount, setAddCryptoAccount] = useState(false);

  /**
   * Bank Next Button State.
   */
  const [bankButton, setBankButton] = useState(true);

  /**
   * Crypto Next Button State.
   */
  const [cryptoButton, setCryptoButton] = useState(true);

  /**
   * Account Added State.
   */
  const [accountAdded, setAccountAdded] = useState(true);

  /**
   * Add Bank Account Handler.
   */
  const addBankAccountHandler = () => {
    setAddBankAccount(true);
    setAddCryptoAccount(false);
  };

  /**
   * Add Crypto Account Handler.
   */
  const addCryptoAccountHandler = () => {
    setAddCryptoAccount(true);
    setAddBankAccount(false);
  };

  /**
   * Bank Account Values Change Handler.
   */
  const bankValuesChangeHandler = (changedValues, allValues) => {
    const { bankCountry, bankCurrency, bankIban, bankSwift } = allValues;

    if (bankCountry && bankCurrency && bankIban && bankSwift) {
      setBankButton(false);
    } else {
      setBankButton(true);
    }
  };

  /**
   * Crypto Account Values Change Handler.
   */
  const cryptoValuesChangeHandler = (changedValues, allValues) => {
    const { crypto, cryptoUsername, cryptoCurrency, cryptoAccountNumber } = allValues;

    if (crypto && cryptoUsername && cryptoCurrency && cryptoAccountNumber) {
      setCryptoButton(false);
    } else {
      setCryptoButton(true);
    }
  };

  /**
   * Account Added Handler.
   */
  const accountAddedHandler = () => {
    setAccountAdded(!accountAdded);
  };

  /**
   * App Theme
   */
  const { theme } = themeConfig[process.env.REACT_APP_THEME] || themeConfig.DEFAULT;

  return (
    <>
      {addAccount && (
        <>
          {accountAdded ? (
            <AddAccountWrapper>
              <AddAccountTitle>{t('finance.accounts.addAccount.title')}</AddAccountTitle>
              <AddAccountContainer>
                <AddAccountSelector active={addBankAccount} onClick={addBankAccountHandler}>
                  <BankIcon color={theme.colors.iconColor} />
                  <AddAccountDescription>{t('finance.accounts.addAccount.bank')}</AddAccountDescription>
                </AddAccountSelector>
                <AddAccountSelector active={addCryptoAccount} onClick={addCryptoAccountHandler}>
                  <CryptoIcon color={theme.colors.iconColor} />
                  <AddAccountDescription>{t('finance.accounts.addAccount.crypto')}</AddAccountDescription>
                </AddAccountSelector>
              </AddAccountContainer>
              <AddAccountFormContainer>
                {addBankAccount && (
                  <AddAccountForm>
                    <Form form={bankAccount} onValuesChange={bankValuesChangeHandler}>
                      <FormItemElement name="bankCountry">
                        <SelectAccountElement placeholder={t('finance.accounts.addAccount.bank.placeholder.country')}>
                          <SelectAccountOption value="France">France</SelectAccountOption>
                          <SelectAccountOption value="Italy">Italy</SelectAccountOption>
                        </SelectAccountElement>
                      </FormItemElement>
                      <FormItemElement name="bankCurrency">
                        <SelectAccountElement placeholder={t('finance.accounts.addAccount.bank.placeholder.currency')}>
                          <SelectAccountOption value="usd">USD</SelectAccountOption>
                          <SelectAccountOption value="eur">EUR</SelectAccountOption>
                        </SelectAccountElement>
                      </FormItemElement>
                      <FormItemElement name="bankIban">
                        <AccountInputElement placeholder={t('finance.accounts.addAccount.bank.placeholder.iban')} />
                      </FormItemElement>
                      <FormItemElement name="bankSwift">
                        <AccountInputElement placeholder={t('finance.accounts.addAccount.bank.placeholder.swift')} />
                      </FormItemElement>
                      <ButtonWrapper align="right">
                        <ModalButtonWrapper onClick={accountAddedHandler}>
                          <ModalButtonIcon>
                            <ModalContinueIcon />
                          </ModalButtonIcon>
                          <ModalButton marginspan={20} disabled={bankButton}>
                            {t('finance.modal.withdraw.button.continue')}
                          </ModalButton>
                        </ModalButtonWrapper>
                      </ButtonWrapper>
                    </Form>
                  </AddAccountForm>
                )}
                {addCryptoAccount && (
                  <AddAccountForm>
                    <Form name={cryptoAccount} onValuesChange={cryptoValuesChangeHandler}>
                      <FormItemElement>
                        <FormItemElement name="crypto">
                          <SelectAccountElement
                            placeholder={t('finance.accounts.addAccount.crypto.placeholder.crypto')}
                          >
                            <SelectAccountOption value="BTC">BTC</SelectAccountOption>
                          </SelectAccountElement>
                        </FormItemElement>
                        <FormItemElement name="cryptoUsername">
                          <SelectAccountElement
                            placeholder={t('finance.accounts.addAccount.crypto.placeholder.cryptoUserName')}
                          >
                            <SelectAccountOption value="user1">user1</SelectAccountOption>
                            <SelectAccountOption value="user2">user2</SelectAccountOption>
                          </SelectAccountElement>
                        </FormItemElement>
                        <FormItemElement name="cryptoCurrency">
                          <SelectAccountElement
                            placeholder={t('finance.accounts.addAccount.crypto.placeholder.currency')}
                          >
                            <SelectAccountOption value="EUR">EUR</SelectAccountOption>
                            <SelectAccountOption value="USD">USD</SelectAccountOption>
                          </SelectAccountElement>
                        </FormItemElement>
                      </FormItemElement>
                      <FormItemElement name="cryptoAccountNumber">
                        <AccountInputElement
                          placeholder={t('finance.accounts.addAccount.crypto.placeholder.accountNumber')}
                        />
                      </FormItemElement>
                      <ButtonWrapper align="right">
                        <ModalButtonWrapper>
                          <ModalButtonIcon>
                            <ModalContinueIcon />
                          </ModalButtonIcon>
                          <ModalButton marginspan={20} onClick={accountAddedHandler} disabled={cryptoButton}>
                            {t('finance.modal.withdraw.button.continue')}
                          </ModalButton>
                        </ModalButtonWrapper>
                      </ButtonWrapper>
                    </Form>
                  </AddAccountForm>
                )}
              </AddAccountFormContainer>
              <BackToAccounts onClick={addAccountHandler}>
                <ModalArrowBackIcon /> {t('finance.accounts.button.backToAccounts')}
              </BackToAccounts>
            </AddAccountWrapper>
          ) : (
            <AccountAdded addAccountHandler={addAccountHandler} />
          )}
        </>
      )}
    </>
  );
};

export default compose(withTranslation())(AddAccount);
