/**
 * @file Contains debounce util.
 */

export const debounceUtil = (callback, delay) => {
	let timer;
	return (value) => {
		clearTimeout(timer);
		timer = setTimeout(() => {
			callback(value);
		}, delay);
	};
};
