/**
 * @file
 * Contains Reports constants.
 */

/**
 * Transactions table config.
 */
export const TRANSACTIONS_TABLE_CONFIG = {
  startPage: 1,
  startOffset: 0,
  transactionsPerPage: 20,
  defaultSelectedType: 'all',
  defaultResultsByValue: 'day',
  defaultViewMode: 'transactions',
  dayFormat: 'DD/MM/YYYY',
  weekFormat: '[Week] WW, YYYY',
  monthFormat: 'MM/YYYY',
};

/**
 * Filters fields condition mapping.
 */
export const FILTERS_FIELDS_CONDITION_MAPPING = {
  // transactions: ['transactionsViewMode', 'date', 'type', 'paymentMethod', 'download-csv', 'countryList', 'status'],
  transactions: ['transactionsViewMode', 'date', 'download-csv', 'idSearch', 'assetsSearch', 'kindsSearch', 'statesSearch', 'paymentGroupsSearch'],
  volumes: ['transactionsViewMode', 'date', 'resultsBy', 'type', 'paymentMethod'],
  statistic: ['transactionsViewMode', 'date', 'resultsBy', 'type', 'paymentMethod'],
  registry: ['transactionsViewMode', 'month', 'period-type'],
  reports: [],
};

/**
 * Transactions view mode mapping.
 */
export const TRANSACTIONS_VIEW_MODE = {
  transactions: 'transactions',
  volumes: 'volumes',
  statistic: 'statistic',
  registry: 'registry',
  reports: 'reports',
}

	/**
	 * Refund transaction info
	 */
	export const REFUND_TRANSACTION_INFO = {
		transactionId: {
			title: 'transaction.modal.transactionInfo.transactionId',
			key: 'transaction_id',
			copyToClipboard: true,
		},
		date: {
			title: 'transaction.modal.transactionInfo.date',
			key: 'created_at',
		},
		type: {
			title: 'transaction.modal.transactionInfo.type',
			key: 'kind',
		},
		payment_method: {
			title: 'transaction.modal.transactionInfo.paymentMethod',
			key: 'payment_group',
		},
		amount: {
			title: 'transaction.modal.transactionInfo.refundAmount',
			key: 'amount_body',
			currencyKey: 'credited_currency',
			style: {
				fontWeight: 700,
			}
		},
	};



/**
 * Partial refund transaction info
 */
export const PARTIAL_REFUND_TRANSACTION_INFO = {
	transactionId: {
		title: 'transaction.modal.transactionInfo.transactionId',
		key: 'transaction_id',
		copyToClipboard: true,
	},
	date: {
		title: 'transaction.modal.transactionInfo.date',
		key: 'created_at',
	},
	type: {
		title: 'transaction.modal.transactionInfo.type',
		key: 'kind',
	},
	payment_method: {
		title: 'transaction.modal.transactionInfo.paymentMethod',
		key: 'payment_group',
	},
};
