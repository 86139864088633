import React from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox } from 'antd';

import themeConfig from 'config';
import { getBrandData } from '_common/utils/brandData.util';
import { CREATE_PASSWORD_RULES, EMAIL_RULES } from './Auth.constants';
import {
  AuthFormItemsWrapper,
  AuthFormItemWrapper,
  AuthFormWrapper,
  AuthIconWrapper,
  AuthInput,
  AuthInputPassword,
  Description,
  RegButtonSubmit,
  RegistrationWrapper,
} from './Auth.style';
import FormItemElement from '_common/components/FormItem/FormItemElement';
import AuthLinks from './AuthLinks';

const theme = themeConfig[process.env.REACT_APP_THEME] || themeConfig.DEFAULT;

export const Registration = ({ enabled, loading }) => {
  const { privacyPolicyLink } = getBrandData(process.env.REACT_APP_THEME);
  const { t } = useTranslation();
  return (
    <RegistrationWrapper showLogin={enabled}>
      <AuthFormWrapper>
        <AuthIconWrapper>
          <theme.assets.icons.loginLogo />
        </AuthIconWrapper>
        <Description>{t('register.form.description')}</Description>
        <AuthFormItemsWrapper>
          <AuthFormItemWrapper>
            <FormItemElement rules={EMAIL_RULES(t)} name='traits.email' normalize={(value) => value.trim()}>
              <AuthInput placeholder={t('register.placeholder.email')} autoComplete="nope" />
            </FormItemElement>
          </AuthFormItemWrapper>
          <AuthFormItemWrapper>
            <FormItemElement rules={CREATE_PASSWORD_RULES(t)} name='password'>
              <AuthInputPassword placeholder={t('login.placeholder.password')} autoComplete="new-password" />
            </FormItemElement>
          </AuthFormItemWrapper>
          <AuthFormItemWrapper>
            <FormItemElement
              rules={[
                {
                  validator: async (_, checked) => {
                    if (!checked) {
                      return Promise.reject(
                        new Error(t('register.agree.privacy.error')),
                      );
                    }
                  },
                },
              ]}
              name='privacy'
              valuePropName='checked'>
              <Checkbox className="checkbox-theme-color">
                {t('register.agree.checkbox')}{' '}
                <a href={privacyPolicyLink?.url} target='_blank'>
                  {t(privacyPolicyLink?.title)}
                </a>
              </Checkbox>
            </FormItemElement>
          </AuthFormItemWrapper>
          <RegButtonSubmit htmlType='submit' loading={loading} themename={theme?.name}>
            {t('login.button.register')}
          </RegButtonSubmit>
        </AuthFormItemsWrapper>
      </AuthFormWrapper>
      {theme?.authLinks && (
        <AuthLinks />
      )}
    </RegistrationWrapper>
  );
};

