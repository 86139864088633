/**
 * @file
 * Contains Modal Button Element Component.
 */
import React from 'react';
import { ModalButton } from './ModalElement.style';

const ModalButtonElement = (props) => {
  const { disabled, ...rest } = props;

  return <ModalButton disabled={disabled} {...rest} />;
};

export default ModalButtonElement;
