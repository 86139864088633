/**
 * @file
 * Contains calendar filled icon.
 */
import React from 'react';

const CalendarFilledIcon = (props) => {
  const { color } = props;

  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Group">
        <path id="Vector"
          d="M22.2741 0H19.9377V2.33645C19.9377 2.80374 19.5483 3.11526 19.1589 3.11526C18.7695 3.11526 18.3801 2.80374 18.3801 2.33645V0H5.919V2.33645C5.919 2.80374 5.52959 3.11526 5.14019 3.11526C4.75078 3.11526 4.36137 2.80374 4.36137 2.33645V0H2.02492C0.856698 0 0 1.01246 0 2.33645V5.14019H24.9221V2.33645C24.9221 1.01246 23.5203 0 22.2741 0ZM0 6.7757V21.028C0 22.4299 0.856698 23.3645 2.1028 23.3645H22.352C23.5981 23.3645 25 22.352 25 21.028V6.7757H0ZM6.93146 19.8598H5.06231C4.75078 19.8598 4.43925 19.6262 4.43925 19.2368V17.2897C4.43925 16.9782 4.6729 16.6667 5.06231 16.6667H7.00935C7.32087 16.6667 7.6324 16.9003 7.6324 17.2897V19.2368C7.55452 19.6262 7.32087 19.8598 6.93146 19.8598ZM6.93146 12.8505H5.06231C4.75078 12.8505 4.43925 12.6168 4.43925 12.2274V10.2804C4.43925 9.96885 4.6729 9.65732 5.06231 9.65732H7.00935C7.32087 9.65732 7.6324 9.89097 7.6324 10.2804V12.2274C7.55452 12.6168 7.32087 12.8505 6.93146 12.8505ZM13.162 19.8598H11.215C10.9034 19.8598 10.5919 19.6262 10.5919 19.2368V17.2897C10.5919 16.9782 10.8255 16.6667 11.215 16.6667H13.162C13.4735 16.6667 13.785 16.9003 13.785 17.2897V19.2368C13.785 19.6262 13.5514 19.8598 13.162 19.8598ZM13.162 12.8505H11.215C10.9034 12.8505 10.5919 12.6168 10.5919 12.2274V10.2804C10.5919 9.96885 10.8255 9.65732 11.215 9.65732H13.162C13.4735 9.65732 13.785 9.89097 13.785 10.2804V12.2274C13.785 12.6168 13.5514 12.8505 13.162 12.8505ZM19.3925 19.8598H17.4455C17.134 19.8598 16.8224 19.6262 16.8224 19.2368V17.2897C16.8224 16.9782 17.0561 16.6667 17.4455 16.6667H19.3925C19.7041 16.6667 20.0156 16.9003 20.0156 17.2897V19.2368C20.0156 19.6262 19.7819 19.8598 19.3925 19.8598ZM19.3925 12.8505H17.4455C17.134 12.8505 16.8224 12.6168 16.8224 12.2274V10.2804C16.8224 9.96885 17.0561 9.65732 17.4455 9.65732H19.3925C19.7041 9.65732 20.0156 9.89097 20.0156 10.2804V12.2274C20.0156 12.6168 19.7819 12.8505 19.3925 12.8505Z"
          fill={color}/>
      </g>
    </svg>
  )
};

export default CalendarFilledIcon;
