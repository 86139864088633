/**
 * @file
 * Contains Questionnaire styles.
 */
import styled from 'styled-components';
import { Button, Input, Select, Checkbox } from 'antd';
import OtpInput from 'react-otp-input';
import { Link } from 'react-router-dom';

export const QuestionnaireLevelsContainer = styled.div`
  background: url(${(props) => props.background}) center center no-repeat, ${(props) => props.theme.colors.backgroundMain};
  background-size: cover;
  padding: 25vh 20px 0;
  min-height: 100vh;
  position: relative;
`;

export const QuestionnaireLevelsWrapper = styled.div`
  height: 100%;
  margin: 0 auto;
  
  @media (min-width: 1400px) {
    width: 1175px;
  }
`;

export const QuestionnaireLevelsTitle = styled.div`
  color: ${(props) => props.theme.colors.white};
  text-align: center;
  padding-bottom: 50px;
  font-size: 24px;
`;

export const QuestionnaireLevelsFooter = styled.div`
  padding-bottom: 28px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 840px;
  width: 100%;
  position: relative;
  
  &:before {
    content: '';
    width: 100%;
    left: 0;
    bottom: 0;
    position: absolute;
    height: 1px;
    background-color: ${(props) => props.theme.colors.white};
  }
`;

export const QuestionnaireNavigationButton = styled(Button)`
  &.ant-btn {
    background: none;
    border: none;
    color: ${(props) => props.theme.colors.white};
    display: flex;
    align-items: center;
    margin-left: ${(props) => props.alignleft};
    padding: 5px 0;
    
    span {
      margin-left: ${(props) => props.back ? '10px' : '0px'};
    }
    
    &:hover {
      background: none;
      color: ${(props) => props.theme.colors.white};
    }
    
    &:focus {
      background: none;
      color: ${(props) => props.theme.colors.white};
    }
    
    svg {
      margin-left: ${(props) => props.back ? '0px' : '10px'};
    }
  }
`;

export const QuestionnaireSubmitButton = styled(Button)`
  border: none;
  background: none !important;
  color: ${(props) => props.theme.colors.white} !important;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0;
  font-size: 24px;
  font-weight: 400;

  &.ant-btn.ant-btn-loading::before {
    background: none;
  }
  
  svg {
    margin-left: 10px;
  }
`;

export const QuestionnaireSteps = styled.div``;

export const QuestionnaireStep = styled.div`
  visibility: ${(props) => props.level === props.currentLevel ? 'visible' : 'hidden'};
  transform: ${(props) => props.level === props.currentLevel ? 'scale(1)' : 'scale(0)'};
  position: ${(props) => props.level === props.currentLevel ? 'relative' : 'absolute'};
  left: ${(props) => props.level === props.currentLevel ? '0' : '-100%'};
  margin-bottom: 215px;
  
  &:last-child {
    margin-bottom: 0;
  }
  
  ${(props) => props.lastStep === "true" && `
    margin-bottom: 46px;
  `}
`;

export const QuestionnaireInput = styled(Input)`
  &.ant-input {
    border-radius: 10px !important;
    padding: 14px 20px;
    font-weight: 700;
    margin: 0 auto;
    display: block;
    
    @media (min-width: 1400px) {
      width: 324px;
      height: 50px;
    }
  }
  
  &.ant-input:placeholder-shown {
    font-weight: 300;
  }
`;

export const QuestionnaireSelect = styled(Select)`
  &.ant-select {
   margin: 0 auto;
   display: block;
   position: relative;
   
   @media (min-width: 1400px) {
    width: 324px !important;
    height: 50px !important;
   }
   
   &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
     border-radius: 10px;
   }
  }

  .ant-select-arrow {
    border-color: ${(props) => props.theme.colors.dark};
    border-style: solid;
    border-width: 2px 0 0 2px;
    height: 8px;
    width: 8px;
    transform: rotate(-135deg);
    transition: transform 0.2s ease;
    margin-right: 0;
    top: 50% !important;

    span {
      display: none;
    }
  }
  
  &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    padding: 6px 20px;
    height: auto;
    font-weight: 300;
  }
  
  &.ant-select-single.ant-select-show-arrow .ant-select-selection-item, .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    font-weight: 700;
  }
`;

export const QuestionnaireFinalSelect = styled(Select)`
  &.ant-select {
   margin: 0 auto;
   display: block;
   
   @media (min-width: 1400px) {
    width: 534px !important;
    height: 63px !important;
   }
   
   &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
     border-radius: 10px;
   }
  }

  .ant-select-arrow {
    border-color: ${(props) => props.theme.colors.dark};
    border-style: solid;
    border-width: 2px 0 0 2px;
    height: 8px;
    width: 8px;
    transform: rotate(-135deg);
    transition: transform 0.2s ease;
    margin-right: 0;
    top: 36% !important;

    span {
      display: none;
    }
  }
  
  &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    padding: 6px 20px;
    height: auto;
    font-weight: 300;
  }
  
  &.ant-select-single.ant-select-show-arrow .ant-select-selection-item, .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    font-weight: 700;
  }
`;

export const QuestionnaireOption = styled(Select.Option)`
  background: red;
`;

export const QuestionnaireLevelsStatusWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 800px;
  margin: 0 auto 40px;
  position: relative;
`;

export const QuestionnaireStatusLine = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  
  &:before {
    content: '';
    position: absolute;
    top: 16px;
    left: 50%;
    transform: translateX(-50%);
    width: 600px;
    height: 2px;
    background: ${(props) => props.theme.colors.grey};
  }
`;

export const QuestionnaireLevelStatusWrapper = styled.div``;

export const QuestionnaireLevelWrapper = styled.div`
  color: ${(props) => props.theme.colors.white};
`;

export const QuestionnaireLevelStatus = styled.div`
  border: 1px solid ${(props) => props.theme.colors.white};
  color: ${(props) => props.currentLevel >= props.level ? props.theme.colors.white : props.theme.colors.mainColor};
  background: ${(props) => props.currentLevel >= props.level ? props.theme.colors.mainColor : props.theme.colors.white};
  border-radius: 50%;
  width: 30px;
  height: 30px;
  margin: 0 auto 20px;
  text-align: center;
  position: relative;
  line-height: 30px;
  font-weight: 700;
  z-index: 9;
  transition: all 0.5s ease;
`;

export const QuestionnaireMultipleSelect = styled(Select)`
  width: 100%;
  border-radius: 10px;
  
  &.ant-select-multiple .ant-select-selection-placeholder {
    left: 20px;
  }
  
  &.ant-select-show-search.ant-select-multiple .ant-select-selector {
    padding: 6px 20px;
    border-radius: 10px;
  }
  
  &.ant-select-multiple .ant-select-selection-item {
    background: none;
    border: none;
  }
  
  &.ant-select-multiple .ant-select-selection-item-content {
    font-weight: 700;
  }
`;

export const QuestionnaireLevelDescr = styled.div`
  color: ${(props) => props.currentLevel >= props.level ? props.theme.colors.white : props.theme.colors.grey};
  font-size: 14px;
`;

export const QuestionnaireTextAreaInput = styled(Input.TextArea)`
  &.ant-input {
    border-radius: 10px;
    resize: none;
    padding: 20px;
    margin-bottom: 20px;
    
    @media (min-width: 1400px) {
      width: 1175px;
      height: 206px !important;
      display: block;
      margin: 0 auto 20px;
    }
  }
`;

export const QuestionnaireAgreementContainer = styled.div`
  margin-bottom: 20px;
`;

export const QuestionnaireAgreementTitle = styled.h3`
  font-weight: 300;
  font-size: 14px;
  color: ${(props) => props.theme.colors.white};
`;

export const QuestionnaireTermsAgreement = styled.div`
  margin-bottom: 20px;
  
  &:last-child {
    margin-bottom: 0;
  }
`;

export const QuestionnaireLink = styled(Link)`
  color: ${(props) => props.theme.colors.white};
  font-size: 14px;
  
  &:hover {
    color: ${(props) => props.theme.colors.extraLightGrey};
  }
`;

export const QuestionnaireCheckbox = styled(Checkbox)`
  &.ant-checkbox-wrapper {
    color: ${(props) => props.theme.colors.extraLightGrey};
  }

  .ant-checkbox {
    background: none;
    border-radius: 4px;
    margin-right: 10px;
    
    &:before {
      content: '';
      border: 2px solid ${(props) => props.theme.colors.white};
      position: absolute;
      top: 0;
      left: 0;
      width: 20px;
      height: 20px;
      border-radius: 2px;
    }
    
    &.ant-checkbox-input {
      opacity: 0;
      position: absolute;
      cursor: pointer;
      height: 0;
      width: 0;
    }
    
    .ant-checkbox-inner {
      opacity: 0;
    }
    
    & input[type='checkbox'] {
      opacity: 0;
    }
    
    &-checked {
      border: none;
    }
  }
  
  .ant-checkbox-checked::before {
    content: '';
    position: absolute;
    top: 0;
    left: -2px;
    z-index: 99;
    width: 24px;
    height: 20px;
    background: url(${(props) => props.icon}) center center no-repeat;
    background-size: cover;
    border: none;
  }
  
  .ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner {
    border: none;
  }
`;

export const QuestionnaireSubmitedIconWrapper = styled.div`
  margin: 0 auto 20px;
  width: 171px;
`;

export const QuestionnaireConfirmationTitle = styled.div`
  color: ${(props) => props.theme.colors.white};
  text-align: center;
`;

export const QuestionnairePhoneNumber = styled.div`
  margin-bottom: 20px;
  color: ${(props) => props.theme.colors.white};
  font-weight: 700;
  text-align: center;
  font-size: 36px;
`;

export const QuestionnaireResetLink = styled(Link)`
  text-decoration: underline;
  display: block;
  text-align: center;
  color: ${(props) => props.theme.colors.white};
  margin-bottom: 20px;
  
  &:hover {
    color: ${(props) => props.theme.colors.white};
  }
`;

export const QuestionnaireVerificationCodeWrapper = styled.div`
  color: ${(props) => props.theme.colors.white};
  text-align: center;
`;

export const QuestionnaireVerificationCode = styled.div`
  margin: 0 auto 20px;
  width: 200px;
`;

export const VerificationTitle = styled.h3`
  color: ${(props) => props.theme.colors.white};
  margin-bottom: 20px;
`;

export const VerificaitionInput = styled(OtpInput)`
  .verification-input{
    width: 40px !important;
    height: 40px !important;
    margin-left: 10px !important;
    border-radius: 10px;
    border: none;
    box-shadow: ${(props) => props.theme.shadow.verificationInput};
    color: ${(props) => props.theme.colors.mainColor};
    font-size: 20px;
    font-weight: 700;
  }
`;

export const FormWrapper = styled.div`
  max-width: 400px;
  width: 100%;
  margin: 0 auto;
`;

export const QuestionnaireHeading = styled.div`
  color: ${(props) => props.theme.colors.white};
  font-weight: 700;
  font-size: 36px;
  line-height: 1.4;
  margin-bottom: 50px;
  text-align: center;
`;

export const FormItemElementWrapper = styled.div`
  height: 100px;
`;

export const InputElement = styled(Input)`
  &.ant-input {
    height: 60px;
    border: 1px solid #A4A6B3;
    border-radius: 10px;
    padding: 0 30px;
    line-height: 60px;
    font-size: 14px;
    font-weight: 400;
  }

   &::placeholder {
     line-height: 60px;
     font-size: 14px;
     font-weight: 400;
   }
`;

export const RadioElementWrapper = styled.div`
  .ant-radio-wrapper {
    color: ${(props) => props.theme.colors.white};
    font-weight: 400;
    font-size: 24px;
  }
  
  .ant-radio {
    
    .ant-radio-inner {
      width: 22px;
      height: 22px;
      background: transparent;
      border: 1px solid ${(props) => props.theme.colors.white};
      margin-right: 4px;
      &:after {
        margin-top: 0;
        margin-left: 0;
      }
    }
    
    &.ant-radio-checked {
      .ant-radio-inner {
        &:after {
          margin-top: 0;
          margin-left: 0;
          width: 12px;
          height: 12px;
          transform: translate(-50%, -50%);
          background: ${(props) => props.theme.colors.mainColor};
        }
      }
    }
  }
`;

export const StellarDescription = styled.div`
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.white};
  text-align: center;
  font-size: 14px;
  font-weight: 200;
  letter-spacing: 0.045em;
  line-height: 24px;
  margin: 78px auto;
  max-width: 576px;
  width: 100%;
`;

export const StellarAddress = styled.div`
  margin: 30px 0 112px;
`;
