/**
 * @file
 * Contains Final Level Component.
 */
import React from 'react';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import {
  KycStepWrapper,
  KycFinalStepTitle,
  KycFinalStepSubTitle,
  KycContentHeaderButton,
  KycFinalStepButtonsWrapper,
} from '../Kyc.style';

const KycFinalLevel = (props) => {
  const { t, level, currentLevel } = props;

  return (
    <KycStepWrapper level={level} currentLevel={currentLevel + 1}>
      <KycFinalStepTitle>{t('kyc.step.final.title')}</KycFinalStepTitle>
      <KycFinalStepSubTitle>{t('kyc.step.final.subTitle')}</KycFinalStepSubTitle>
      <KycFinalStepButtonsWrapper>
        <KycContentHeaderButton width="50%" textalign="center">
          {t('kyc.step.final.button.back')}
        </KycContentHeaderButton>
        <KycContentHeaderButton width="50%" textalign="center">
          {t('kyc.step.final.button.send')}
        </KycContentHeaderButton>
      </KycFinalStepButtonsWrapper>
    </KycStepWrapper>
  );
};

export default compose(withTranslation())(KycFinalLevel);
