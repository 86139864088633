/**
 * @file
 * Contains modal arrow back icon.
 */
import React from 'react';

const ModalArrowBack = (props) => {
  const { color } = props;

  return (
    <svg width="28" height="25" viewBox="0 0 28 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d)">
        <path d="M18.4463 10.0078L4.75971 10.0078" stroke={color ? color : '#3886BD'} strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M8.87598 15L4.84281 10.8694C4.37394 10.3892 4.37347 9.62273 4.84174 9.14196L8.87598 5" stroke={color ? color : '#3886BD'} strokeLinecap="round" strokeLinejoin="round"/>
      </g>
      <defs>
        <filter id="filter0_d" x="0.159684" y="0.668962" width="27.7257" height="23.7701" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
          <feOffset dx="2.55403" dy="2.55403"/>
          <feGaussianBlur stdDeviation="3.19253"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
        </filter>
      </defs>
    </svg>
  )
};

export default ModalArrowBack;

