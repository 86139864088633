/**
 * @file
 * Contains Upload Element Component.
 */
import React from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import { ReactComponent as UploadFileIcon } from '_common/assets/icons/kyc-upload-file-icon.svg';
import { UploadDragger, UploadDescription, UploadExtension, UploadSize, UploadTitle } from './UploadElement.style';

const UploadElement = (props) => {
  const { t } = props;

  return (
    <UploadDragger>
      <UploadTitle>{t('upload.element.title')}</UploadTitle>
      <UploadFileIcon />
      <UploadDescription>{t('upload.element.description')}</UploadDescription>
      <UploadExtension>{t('upload.element.extension')}</UploadExtension>
      <UploadSize>{t('upload.element.size')}</UploadSize>
    </UploadDragger>
  );
};

export default compose(withTranslation())(UploadElement);
