/**
 * @file
 * Contains Dashboard store.
 */

import { observable, action, computed, runInAction } from 'mobx';
import DashboardService from '../services/report.service';

class FinanceStore {
  /**
   * Data Is Loading.
   */
  @observable
  isLoading = false;

  /**
   * Weekly Report Data.
   */
  @observable
  financeFilterTable = {};

  /**
   * Get Data Is Loading.
   */
  @computed
  get getIsLoading() {
    return this.isLoading;
  }

  /**
   * Get dashboard data from store.
   */
  @computed
  get getFinanceReport() {
    return this.financeFilterTable;
  }

  /**
   * Set is table loading.
   * @param {object} data.
   * Finance report data.
   * @param {string} profileId.
   * Profile id.
   */
  @action
  getFinanceReportAction = async (data, profileId) => {
    try {
      const financeReport = await DashboardService.getDateReport(data, profileId);

      runInAction(() => {
        this.financeFilterTable = financeReport;
      });
    } catch (e) {
      throw new Error(e);
    }
  };
}

export default FinanceStore;
