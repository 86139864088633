/**
 * @file
 * Contains TreeSelect Element Component.
 *
 */
import React from 'react';
import { TreeSelectWrapper, TreeSelect, IconWrapper } from './TreeSelectElement.style';

const TreeSelectElement = (props) => {
  const { iconComponent, getRef, data, design, small, ...rest } = props;
  /**
   * Ref to input.
   * @param {object} ref.
   * Ref data.
   */
  const inputRef = (ref) => {
    if (getRef) {
      getRef(ref);
    }
  };

  return (
    <TreeSelectWrapper {...rest}>
      <IconWrapper {...rest}>
        {iconComponent}
      </IconWrapper>
      <TreeSelect small={small} iconComponent={iconComponent} design={design} treeData={data} innerRef={inputRef} {...rest} />
    </TreeSelectWrapper>
  );
};

export default TreeSelectElement;
