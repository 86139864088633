import styled from 'styled-components';

export const NodesWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
`;

export const SubmitNode = styled.button`
  font-weight: 600;
  font-size: 12px;
  text-decoration: underline;
  display: flex;
  justify-content: flex-end;
  background: none;
  outline: none;
  border: 0;
  cursor: pointer;
`;

export const SubmitNodeWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;
