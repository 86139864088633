/**
 * @file
 * Contains navbar reports icon.
 */
import React from 'react';

const NavBarLogoutIcon = (props) => {
  const { color } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
      <path
        d="M24.9215 11.061C24.8684 10.9339 24.7924 10.8182 24.6955 10.7213L21.5713 7.59719C21.1641 7.19091 20.5057 7.19091 20.0984 7.59719C19.6911 8.00448 19.6911 8.6639 20.0984 9.07017L21.4453 10.4171H15.6262C15.0501 10.4171 14.5845 10.8838 14.5845 11.4588C14.5845 12.0339 15.0501 12.5005 15.6262 12.5005H21.4453L20.0983 13.8475C19.691 14.2548 19.691 14.9142 20.0983 15.3205C20.3015 15.5246 20.5681 15.6257 20.8348 15.6257C21.1015 15.6257 21.3682 15.5247 21.5713 15.3205L24.6955 12.1963C24.7924 12.1005 24.8684 11.9848 24.9215 11.8567C25.0267 11.6026 25.0267 11.3151 24.9215 11.061Z"
        fill={color}/>
      <path
        d="M17.7093 14.5842C17.1333 14.5842 16.6676 15.0509 16.6676 15.6259V20.8345H12.5007V4.16689C12.5007 3.70749 12.1986 3.30122 11.7579 3.16893L8.14002 2.08347H16.6676V7.29212C16.6676 7.86715 17.1333 8.33383 17.7093 8.33383C18.2854 8.33383 18.751 7.86715 18.751 7.29212V1.04176C18.751 0.466677 18.2854 0 17.7093 0H1.04171C1.00421 0 0.970857 0.0156259 0.934429 0.0197765C0.885451 0.0250014 0.840673 0.0333027 0.793796 0.044778C0.684414 0.0729046 0.585434 0.115632 0.492753 0.173936C0.469851 0.188536 0.441725 0.189562 0.419848 0.206213C0.411449 0.212512 0.408324 0.223987 0.399974 0.230238C0.286442 0.319794 0.191661 0.429175 0.122907 0.559407C0.108307 0.587534 0.105182 0.61776 0.0937554 0.646912C0.0604039 0.726067 0.023976 0.803171 0.0114753 0.890676C0.00625036 0.921928 0.0156259 0.95108 0.0146004 0.981306C0.013575 1.00216 0 1.02091 0 1.04171V21.8763C0 22.3732 0.351046 22.8003 0.837548 22.8972L11.2548 24.9806C11.3225 24.9952 11.3913 25.0015 11.459 25.0015C11.6975 25.0015 11.9319 24.9192 12.1194 24.765C12.36 24.5671 12.5007 24.2722 12.5007 23.9597V22.918H17.7093C18.2854 22.918 18.751 22.4513 18.751 21.8763V15.6259C18.751 15.0509 18.2854 14.5842 17.7093 14.5842Z"
        fill={color}/>
    </svg>)
};

export default NavBarLogoutIcon;
