/**
 * @file
 * Contains Finance Accounts Components.
 */
import styled, { css } from 'styled-components';
import { Button, Tabs, Select, Input } from 'antd';
import { Link } from 'react-router-dom';

export const FinanceAccountsWrapper = styled.div`
  background: ${(props) => props.theme.colors.darkWhite};
  padding: 20px 80px;
`;

export const FinanceAccountsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

export const FinanceHeaderAccountsTitle = styled.div`
  color: ${(props) => props.theme.colors.mainColor};
  font-weight: 700;
  position: relative;
  display: inline-block;
  padding-bottom: 4px;
  
  &:after {
     content: '';
     position: absolute;
     bottom: 0;
     left: 0;
     width: 100%;
     height: 2px;
     background: ${(props) => props.theme.colors.mainColor};
  }
`;

export const AddAccountButton = styled(Button)`
  &.ant-btn {
    background: ${(props) => props.theme.colors.mainColor};
    color: ${(props) => props.theme.colors.white};
    border-radius: 10px;
    font-weight: 700;
    padding: 9px 10px;
    height: auto !important;
    text-transform: uppercase;
    font-size: 14px;
    
    &:hover {
      color: ${(props) => props.theme.colors.mainColor};
      border: 1px solid ${(props) => props.theme.colors.mainColor};
    }
  }
`;

export const BackToFinanceLink = styled(Link)`
   color: ${(props) => props.theme.colors.mainColor};
   text-decoration: underline;
   display: flex;
   align-items: center;
   
   &:hover {
    color: ${(props) => props.theme.colors.mainColor};
   }
`;

export const BackToAccounts = styled(Button)`
  &.ant-btn {
    background: none;
    display: flex;
    align-items: center;
    color: ${(props) => props.theme.colors.mainColor};
    text-decoration: underline;
    border: none;
    font-size: 16px;
    
    &:hover {
      background: none;
      color: ${(props) => props.theme.colors.mainColor};
      text-decoration: underline;
    }
  }
`;

export const AccountsContainer = styled.div``;

export const AccountsWrapper = styled.div``;

export const AccountContainer = styled.div`
  border: 1px solid ${(props) => props.theme.colors.dark};
  padding: 20px;
  margin: 20px 0 20px 0;
  border-radius: 10px;
  position: relative;
`;

export const AccountWrapper = styled.div`
  background: ${(props) => props.theme.colors.white};
  padding: 20px;
  border-radius: 10px;
  
  &:before {
    content: '${(props) => props.type} account';
    position: absolute;
    top: -10px;
    text-transform: uppercase;
    background: ${(props) => props.theme.colors.darkWhite};
    padding: 0 10px;
  }
`;

export const AccountTabs = styled(Tabs)`
  &.ant-tabs > .ant-tabs-nav, .ant-tabs > div > .ant-tabs-nav {
    background: ${(props) => props.theme.colors.lightGrey};
  }
  
  .ant-tabs-tab {
    color: ${(props) => props.theme.colors.silver};
    padding: 10px 20px;
    font-weight: 700;
    
    &:hover {
      color: ${(props) => props.theme.colors.darkLight};
    }
  }
  
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${(props) => props.theme.colors.mainColor};
    font-weight: 700;
  }
  
  &.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar, .ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar {
    display: none;
  }
`;

export const AccountTab = styled(Tabs.TabPane)`
  min-height: 840px;
`;

export const AccountName = styled.div`
  text-transform: capitalize;
  margin-bottom: 10px;
`;

export const AccountFooter = styled.div``;

export const AccountValue = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const AccountAmount = styled.div`
  span {
    font-weight: 700;
    margin-left: 6px;
  }
`;

export const AccountSettings = styled.div`
  display: flex;
  align-items: center;
`;

export const AccountEditWrapper = styled.div`
  cursor: pointer;
`;

export const AccountDeleteWrapper = styled.div`
  margin-left: 10px;
  cursor: pointer;
`;

export const AddAccountWrapper = styled.div``;

export const NoAccountsContainer = styled.div`
  height: 60vh;
  position: relative;
`;

export const NoAccountsWrapper = styled.div`
  text-align: center;
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const NoAccountsTitle = styled.div`
  margin-bottom: 20px;
  color: ${(props) => props.theme.colors.silver};
  font-weight: 700;
`;

export const NoAccountsSubTitle = styled.div`
  margin-bottom: 80px;
`;

export const AddAccountTitle = styled.div`
  text-align: center;
  color: ${(props) => props.theme.colors.darkLight};
  font-size: 18px;
  padding-top: 50px;
  padding-bottom: 40px;
`;

export const AddAccountContainer = styled.div`
  width: 420px;
  margin: 0 auto 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const AddAccountSelector = styled.div`
  background: ${(props) => props.theme.colors.white};
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
  border: 1px solid ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.darkGrey};
  transition: all 0.5s ease;
  width: 200px;
  height: 200px;
  position: relative;
  
  &:hover {
    border: 1px solid ${(props) => props.theme.colors.mainColor};
  }
  
  svg {
    margin-bottom: 10px;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    
    path {
      fill: ${(props) => props.theme.colors.darkGrey};
      transition: fill 0.5s ease;
    }
  }
  
  ${props => props.active && css`
    border: 1px solid ${(props) => props.theme.colors.mainColor};
    color: ${(props) => props.theme.colors.dark};
    box-shadow: ${(props) => props.theme.shadow.box};
    
      svg {
        margin-bottom: 10px;
        path {
          fill: ${(props) => props.theme.colors.mainColor};
        }
      }
  `}
`;

export const AddAccountDescription = styled.div`
  position: absolute;
  top: 64%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
`;

export const AddAccountFormContainer = styled.div`
  width: 420px;
  margin: 0 auto;
`;

export const AddAccountForm = styled.div``;

export const SelectAccountElement = styled(Select)`
  width: 100%;
  padding: 17px 20px;
  background: ${(props) => props.theme.colors.white};
  border-radius: 10px;
  margin-bottom: 20px !important;
  
  &.ant-select {
    width: 100%;
  }

  &.ant-select-selector {
    border: none;
  }
  
  &.ant-select-selection-item {
    color: red;
  }

  &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
  .ant-select-selector {
    padding: 14px;
    font-size: 16px;
    height: 50px;
    box-sizing: border-box;
    background: none;
    outline: none;
    border: none;
    
    @media (min-width:1440px) {
      height: 55px;
    }

    ${props => props.icon && css`
        padding: 14px 14px 14px 30px;
    `};

    .ant-select-selection-placeholder {
      opacity: 1;
      line-height: 26px;
    }
  }
  
  &.ant-select-open {
    outline: transparent !important;

    .ant-select-arrow {
      border-color: ${(props) => props.theme.colors.dark};
      border-style: solid;
      border-width: 2px 0 0 2px;
      height: 8px;
      width: 8px;
      transform: rotate(45deg);
      
      @media (min-width: 1440px) {
        width: 10px;
        height: 10px;
      }
    }
  }

  .ant-select-arrow {
    border-color: ${(props) => props.theme.colors.dark};
    border-style: solid;
    border-width: 2px 0 0 2px;
    height: 8px;
    width: 8px;
    transform: rotate(-135deg);
    transition: transform 0.2s ease;
    margin-right: 0;
      
    @media (min-width: 1440px) {
      width: 10px;
      height: 10px;
    }

    span {
      display: none;
    }
  }

  &.ant-select-single .ant-select-selector {
    .ant-select-selection-item {
      line-height: 26px;
      padding: 0;
      font-weight: 700;
      font-size: 16px;
      color: ${(props) => props.theme.colors.mainColor};
    }
    .ant-select-selection-search {
      left: 0;
      right: 0;
    }
  }

  .ant-select-selection-search-input {
    padding: 0 21px;
  }

  .ant-select-dropdown {
    padding: 40px 0;
  }

  .ant-select-selection-item {
    svg {
      display: none;
    }
  }
`;

export const SelectAccountOption = styled(Select.Option)``;

export const AccountInputElement = styled(Input)`
  &.ant-input {
    background: ${(props) => props.theme.colors.white};
    color: ${(props) => props.theme.colors.mainColor};
    border: none;
    border-radius: 10px;
    padding: 17px 20px;
    margin-bottom: 20px;
  }
`;

export const AccountAddedContainer = styled.div`
  height: 50vh;
  position: relative;
`;

export const AccountAddedWrapper = styled.div`
  text-align: center;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const AccountAddedTitle = styled.div`
  color: ${(props) => props.theme.colors.mainColor};
  font-weight: 700;
  margin-bottom: 20px;
`;

export const AccountAddedSubTitle = styled.div`
  color: ${(props) => props.theme.colors.darkLight};
  margin-bottom: 80px;
`;
