/**
 * @file
 * Contains Dashboard component view.
 */
import React, { useEffect, useState } from 'react';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import MainLayout from '_common/layouts/Main.layout';
import SpinnerElement from '_common/components/Spinner/SpinnerElement';
import DashboardFilters from './DashboardFilters';
import DashboardStatistics from './DashboardStatistics';
import DashboardGraphics from './DashboardGraphics';
import { DashboardContainer } from './Dashboard.style';
import usePermissions from '_common/hooks/usePermissions.hook';

const Dashboard = (props) => {
  const { generalReportStore, profileStore } = props;

  /**
   * Data loading flag
   */
  const [ loading, setLoading ] = useState(false);

  /**
   * Balances access flag.
   */
  const [balancesAccess] = usePermissions(['merchant.finances.balances.read']);

  /**
   * Load Balance report
   */
  const loadBalanceReport = async () => {
    setLoading(true);
    try {
      await generalReportStore.getBalanceReportAction(profileStore.getSelectedProfileId);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  }

  /**
   * Load balance data on load or id change
   */
  useEffect(() => {
    !!balancesAccess && loadBalanceReport();
  }, [profileStore.getSelectedProfileId])

  return (
    <MainLayout>
      <DashboardContainer>
        <DashboardFilters />
        <SpinnerElement spinning={generalReportStore.getIsLoading}>
          <>
            <DashboardStatistics loading={loading}/>
            <DashboardGraphics />
          </>
        </SpinnerElement>
      </DashboardContainer>
    </MainLayout>
  );
};

export default compose(inject('generalReportStore', 'profileStore'), withTranslation())(observer(Dashboard));
