/**
 * @file
 * Contains Dashboard store.
 */

import { observable, action, computed } from 'mobx';

class DashboardStore {
  /**
   * Filters data.
   */
  @observable
  filtersData = '';

  /**
   * Transactions Type.
   */
  @observable
  transactionsType = '';

  /**
   * Get Date Report Data.
   */
  @computed
  get getTransactionsType() {
    return this.transactionsType;
  }

  /**
   * Get filters data.
   */
  @computed
  get getFiltersData() {
    return this.filtersData;
  }

  /**
   * Set Transactions Type.
   */
  @action
  setTransactionTypeAction = (type) => {
    this.transactionsType = type;
  };

  /**
   * Set filters data.
   */
  @action
  setFiltersData = (type) => {
    this.filtersData = type;
  };
}

export default DashboardStore;
