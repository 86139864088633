/**
 * @file
 * Contains Payment Store.
 */

import { observable, computed, action, runInAction } from 'mobx';
import PaymentService from '_common/services/paymant.service';

class PaymentStore {
  /**
   * Payment methods data.
   */
  @observable
  paymentMethodsData = {};

  /**
   * Country list data.
   */
  @observable
  countryListData = [];

  /**
   * Status list data.
   */
  @observable
  statusListData = [];

  /**
   * Dictionaries list data.
   */
  @observable
  dictionariesListData = {};

  /**
   * Get payment methods data.
   */
  @computed
  get getPaymentMethodsData() {
    return this.paymentMethodsData;
  }

  /**
   * Get country list data.
   */
  @computed
  get getCountryListData() {
    return this.countryListData;
  }

  /**
   * Get status list data.
   */
  @computed
  get getStatusListData() {
    return this.statusListData;
  }

  /**
   * Get Dictionaries list data.
   */
  @computed
  get getDictionariesListData() {
    return this.dictionariesListData;
  }

  /**
   * Get payment methods data action.
   * @param {object} params.
   * Params data.
   * @param {string} profileId.
   * Profile id.
   */
  @action
  getPaymentMethodsDataAction = async (params, profileId) => {
    try {
      const response = await PaymentService.getPaymentMethods(params, profileId);

      runInAction(() => {
        this.paymentMethodsData = response;
      });
      return response;
    } catch (e) {
      throw new Error(e);
    }
  };

  /**
   * Get payment methods data action.
   * @param {object} params.
   * Params data.
   * @param {string} profileId.
   * Profile id.
   */
  @action
  getCountryListDataAction = async (params, profileId) => {
    try {
      const response = await PaymentService.getCountryListData(params, profileId);

      runInAction(() => {
        this.countryListData = response;
      });
      return response;
    } catch (e) {
      throw new Error(e);
    }
  };

  /**
   * Get payment methods status action.
   * @param {object} params.
   * Params data.
   * @param {string} profileId.
   * Profile id.
   */
  @action
  getStatusListDataAction = async (params, profileId) => {
    try {
      const response = await PaymentService.getStatusListData(params, profileId);

      runInAction(() => {
        this.countryListData = response;
      });
      return response;
    } catch (e) {
      throw new Error(e);
    }
  };

  /**
   * Get transactions dictionaries action.
   */
  @action
  getTransactionsDictionariesDataAction = async (profileId) => {
    try {
      const response = await PaymentService.getTransactionsDictionariesData(profileId);
      const reMappedResponse = (response?.data?.TransactionsDictionaries || []).reduce((acc, item) =>
        ({ ...acc,  [item?.type]: item?.records || item?.values }),
        {}
      );
      runInAction(() => {
        this.dictionariesListData = reMappedResponse;
      });
    } catch (e) {
      throw new Error(e);
    }
  };
}

export default PaymentStore;
