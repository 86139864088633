/**
 * @file
 * Contains Finance Report Table.
 */
import React, { useEffect, useState } from 'react';
import {
  FinanceReportTableWrapper,
  TableSummaryRow,
  TableSummaryCell,
  SummaryAmountWrap,
} from './Finance.style';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import SpinnerElement from '_common/components/Spinner/SpinnerElement';
import { Table } from 'antd';
import Utils from '_common/utils';

const FinanceReportTable = (props) => {

  const { t, generalReportStore, profileStore } = props;

  /**
   * Report data.
   */
  const [reportData, setReportData] = useState([]);

  /**
   * Is loading flag.
   */
  const [loading, isLoading] = useState(false);

  const columns = [
    {
      title: t('finance.table.amount'),
      dataIndex: 'balance',
      key: 'balance',
      width: 150,
      align: 'right',
      render: (text, record) => Utils.trimAmount(record.balance),
    },
    {
      title: t('finance.table.currency'),
      dataIndex: 'asset',
      key: 'asset',
      align: 'right',
      width: 150,
    },
    // {
    //   title: t('Reserve'),
    //   dataIndex: 'reserve',
    //   key: 'reserve',
    //   width: 150,
    //   sorter: true,
    // },
    // {
    //   title: t('Available'),
    //   dataIndex: 'available',
    //   key: 'transaction_id',
    //   width: 150,
    //   sorter: true,
    // },
  ];

  /**
   * Get data.
   */
  useEffect(() => {
    (async () => {
      try {
        isLoading(true);
        const profileId = profileStore.getSelectedProfileId;
        const reportDataResponse = await generalReportStore.getBalanceReportAction(profileId);
        const reportData = (reportDataResponse?.data || []).map((item, index) => ({
          key: item.asset_key + index,
          ...item,
        }));

        setReportData({
          ...reportDataResponse,
          data: reportData,
        });
      } catch (e) {
        console.error(e);
      } finally {
        isLoading(false);
      }
    })();
  }, [
    profileStore.getSelectedProfileId,
  ]);

  /**
   * Table footer render.
   */
  const renderTableSummary = () => {
    return (
      <TableSummaryRow>
        <TableSummaryCell>
          {t('finance.table.totalAmount').toUpperCase()} {reportData.total_currency}
          <SummaryAmountWrap>{Utils.trimAmount(reportData?.total)}</SummaryAmountWrap>
        </TableSummaryCell>
      </TableSummaryRow>
    );
  };

  return (
    <SpinnerElement spinning={loading}>
      <FinanceReportTableWrapper>
        <Table
          table-layout="fixed"
          columns={columns}
          dataSource={reportData.data}
          pagination={false}
          summary={(tableData) => renderTableSummary(tableData)}
        />
      </FinanceReportTableWrapper>
    </SpinnerElement>
  );
};

export default compose(
  withTranslation(),
  inject('generalReportStore', 'profileStore')
)(observer(FinanceReportTable));
