/**
 * @file Contains input amount element styles.
 */

import React from 'react';
import styled from 'styled-components';
import { Badge as AntdBadge } from 'antd';

export const InputAmountContainer = styled.div`
	display: flex;
	align-items: center;
	height: 33px;
`;

export const InputAmountWrapper = styled.div`
	height: 100%;
`;

export const InputAmount = styled(({ ...rest }) => <input {...rest} />)`
	text-align: right;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
	border-right: none;
	border-top: 1px solid ${(props) => props.theme.colors.inputAmountBorderColor};
	border-left: 1px solid ${(props) => props.theme.colors.inputAmountBorderColor};
	border-bottom: 1px solid ${(props) => props.theme.colors.inputAmountBorderColor};
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  height: 100%;
  padding-right: 8px;
	
	&:focus {
    outline: none;
  }
`;

export const InputAmountCurrency = styled.div`
	background: ${(props) => props.theme.colors.inputAmountBorderColor};
	color: ${(props) => props.theme.colors.black};
	border-top-right-radius: 8px;
	border-bottom-right-radius: 8px;
	height: 100%;
	font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
	padding: 6px 10px;
`;
