/**
 * @file
 * Contains Questionnaire Level 1 component.
 */
import React, { useState, useEffect } from 'react';
import { Row, Col, Spin } from 'antd';
import FormItemElement from '_common/components/FormItem/FormItemElement';
import { compose } from 'recompose';
import { inject } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { editString } from '_common/utils/stringEdit.util';
import { getJwtAccessToken } from '_common/services/jwtToken.service';
import InputElement from '_common/components/Input/InputElement';
import {
  QuestionnaireStep,
  QuestionnaireInput,
  QuestionnaireSelect,
  QuestionnaireOption,
} from '../Questionnaire.style';

const QuestionnaireLevelOne = (props) => {
  const { t, currentLevel, kycStore } = props;

  /**
   * Question Parameters Data State.
   */
  const [questionsData, setQuestionsData] = useState({});

  /**
   * Fetch Question Parameters Data.
   */
  useEffect(() => {
    const fetchUserInfoData = async () => {
      try {
        kycStore.setIsLoading(true);
        const userInfo = await kycStore.getProfileQuestionnaireDataAction(getJwtAccessToken());

        const questions = {
          jobTitle: userInfo.job_title,
          country: userInfo.country,
          findUs: userInfo.find_us,
        };
        setQuestionsData(questions);
      } catch (e) {
        throw new Error(e);
      }
    };

    fetchUserInfoData();
  }, [kycStore]);

  return (
    <QuestionnaireStep level={1} currentLevel={currentLevel}>
      {Object.keys(questionsData).length ? (
        <>
          <Row gutter={[100, 50]}>
            <Col xs={8}>
              <FormItemElement
                questionnairelabel
                label={t('questionnaire.levels.input.placeholder.firstName')}
                name="first_name"
              >
                <InputElement small questionnaireInput placeholder={t('questionnaire.levels.input.placeholder.firstName')} />
              </FormItemElement>
            </Col>
            <Col xs={8}>
              <FormItemElement
                questionnairelabel
                label={t('questionnaire.levels.input.placeholder.lastName')}
                name="last_name"
              >
                <QuestionnaireInput placeholder={t('questionnaire.levels.input.placeholder.lastName')} />
              </FormItemElement>
            </Col>
            <Col xs={8}>
              <FormItemElement
                questionnairelabel
                label={t('questionnaire.levels.input.placeholder.companyName')}
                name="company_name"
              >
                <QuestionnaireInput placeholder={t('questionnaire.levels.input.placeholder.companyName')} />
              </FormItemElement>
            </Col>
          </Row>
          <Row gutter={[100, 50]}>
            <Col xs={8}>
              <FormItemElement
                questionnairelabel
                label={t('questionnaire.levels.input.placeholder.email')}
                name="email"
              >
                <QuestionnaireInput placeholder={t('questionnaire.levels.input.placeholder.email')} />
              </FormItemElement>
            </Col>
            <Col xs={8}>
              <FormItemElement
                questionnairelabel
                label={t('questionnaire.levels.input.placeholder.phone')}
                name="phone_number"
              >
                <QuestionnaireInput placeholder={t('questionnaire.levels.input.placeholder.phone')} />
              </FormItemElement>
            </Col>
            <Col xs={8}>
              <FormItemElement
                questionnairelabel
                label={t('questionnaire.levels.input.placeholder.skype')}
                name="skype_id"
              >
                <QuestionnaireInput placeholder={t('questionnaire.levels.input.placeholder.skype')} />
              </FormItemElement>
            </Col>
          </Row>
          <Row gutter={[100, 0]}>
            <Col xs={8}>
              <FormItemElement questionnairelabel label={questionsData.jobTitle.description} name="job_title">
                <QuestionnaireSelect
                  popupClassName="kyc-select-dropdown"
                  placeholder={questionsData.jobTitle.description}
                >
                  {questionsData.jobTitle.choices.map((choice) => (
                    <QuestionnaireOption value={choice} key={choice}>
                      {editString(choice)}
                    </QuestionnaireOption>
                  ))}
                </QuestionnaireSelect>
              </FormItemElement>
            </Col>
            <Col xs={8}>
              <FormItemElement questionnairelabel label={questionsData.country.description} name="country">
                <QuestionnaireSelect
                  popupClassName="kyc-select-dropdown"
                  placeholder={questionsData.country.description}
                >
                  {questionsData.country.choices.map((choice) => (
                    <QuestionnaireOption value={choice} key={choice}>
                      {editString(choice)}
                    </QuestionnaireOption>
                  ))}
                </QuestionnaireSelect>
              </FormItemElement>
            </Col>
            <Col xs={8}>
              <FormItemElement questionnairelabel label={questionsData.findUs.description} name="find_us">
                <QuestionnaireSelect
                  popupClassName="kyc-select-dropdown"
                  placeholder={questionsData.findUs.description}
                >
                  {questionsData.findUs.choices.map((choice) => (
                    <QuestionnaireOption value={choice} key={choice}>
                      {editString(choice)}
                    </QuestionnaireOption>
                  ))}
                </QuestionnaireSelect>
              </FormItemElement>
            </Col>
          </Row>
        </>
      ) : (
        <Spin />
      )}
    </QuestionnaireStep>
  );
};

export default compose(inject('kycStore'), withTranslation())(QuestionnaireLevelOne);
