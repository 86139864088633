/**
 * @file
 * Contains transaction icon.
 */
import React from 'react';

const TransactionsIcon = (props) => {
  const { color, gradientColor } = props;

  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.01 4.5C19.01 5.12 18.63 5.66 18.09 5.88V18.99H3L7.22 12.26C7.08437 12.0295 7.01193 11.7674 7.01 11.5C7.01 10.67 7.68 10 8.51 10C9.34 10 10.01 10.67 10.01 11.5C10.01 11.63 9.99 11.75 9.96 11.87L11.4 12.5C11.67 12.2 12.07 12 12.51 12C12.69 12 12.86 12.04 13.02 12.09L16.6 5.68C16.24 5.41 16.01 4.98 16.01 4.5C16.01 3.67 16.68 3 17.51 3C17.7 3 17.87 3.04 18.04 3.1L18.09 3.01V3.12C18.63 3.34 19.01 3.88 19.01 4.5ZM17.09 17.99V6.85L13.8 12.74C13.93 12.97 14.01 13.22 14.01 13.5C14.01 14.33 13.34 15 12.51 15C11.68 15 11.01 14.33 11.01 13.5L11.02 13.43L9.39 12.71C9.14 12.89 8.84 13 8.51 13C8.33 13 8.16 12.96 8 12.9L4.8 17.99H17.09Z" fill={gradientColor ? 'url(#transactions_0_linear)' : color} />
      <defs>
        <linearGradient id="transactions_0_linear" x1="17.9149" y1="4.26483" x2="-4.15388" y2="16.4694" gradientUnits="userSpaceOnUse">
          <stop stopColor={gradientColor} />
          <stop offset="0.75" stopColor={color} />
        </linearGradient>
      </defs>
    </svg>
  )
};

export default TransactionsIcon;
