import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@tanstack/react-query';

import { Form, notification } from 'antd';

import { kratosClient } from '_common/services/apiClient.service';

import themeConfig from 'config';
import Loader from '_common/components/Loader/Loader';
import { AuthIconWrapper, AuthRow } from '../Auth.style';
import {
  SendCodeAgain,
  VerificationBackLink,
  VerificationBottomMenu,
  VerificationEmailLabel,
  VerificationFormWrapper,
  VerificationInputsWrapper,
} from '../Verification/Verification.Style';
import { AUTH_TYPES, EMAIL_RULES } from 'Auth/Auth.constants';
import { handleOathkeeperError } from '_common/services/error.service';
import AuthLinks from '../AuthLinks';
import { RecoveryAuthButonSubmit, RecoveryAuthInput, RecoveryForm, RecoveryFormItemElement, RecoveryLayout } from './Recovery.style';
import AuthScreenAction from '_common/components/AuthScreensAction/AuthScreenAction';

const theme = themeConfig[process.env.REACT_APP_THEME] || themeConfig.DEFAULT;

const Recovery = ({ history }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const urlSearchParams = new URLSearchParams(window.location.search);
  const flowId = urlSearchParams.get('flow');

  const alreadyHaveAFlow = !!flowId;

  /**
   * catch 422 error or else show a notification
   * @param {import('axios').AxiosError} e
   */
  const onError = (e) => {
    if (
      e.isAxiosError &&
      e.response.data &&
      e.response.data.error &&
      e.response.data.error.code === 422
    ) {
      notification.success({
        message: t('recovery.success'),
      });
      history.push('/auth/recovery/newpassword');
    } else {
      handleOathkeeperError(e);
    }
  };

  const getRecoveryFlowQuery = useQuery(
    ['getRecoveryFlow'],
    async () => {
      const res = await kratosClient.getRecoveryFlow({
        id: flowId,
      });
      return res.data;
    }, {
      retry: false,
      enabled: alreadyHaveAFlow,
      staleTime: 10 * 1000 * 60,
      onError: () => {
        urlSearchParams.delete('flow');
        const newUrl = `${window.location.origin}${window.location.pathname}${urlSearchParams}`;
        history.push({}, '', newUrl);
        notification.warning({
          message: t('verification.expired'),
        });
      },
    });


  /**
   * create a new recovery flow
   */
  const createRecoveryFlowQuery = useQuery(['createRecoveryFlow'], async () => {
    const { data } = await kratosClient.createBrowserRecoveryFlow();
    return data;
  }, {
    enabled: !alreadyHaveAFlow,
    retry: false,
    staleTime: 10 * 1000 * 60,
  });

  /**
   * this mutation requests an email with a recovery code
   */
  const emailMutation = useMutation(
    async () => {
      /**
       * get anti-csrf token information
       */
      const csrfNode = createRecoveryFlowQuery.data?.ui?.nodes?.find(
        (node) => node.attributes.name === 'csrf_token',
      );

      const res = await form.validateFields();
      await kratosClient.updateRecoveryFlow({
        flow: createRecoveryFlowQuery.data.id,
        updateRecoveryFlowBody: {
          method: 'code',
          csrf_token: csrfNode.attributes.value,
          email: res.email,
        },
      });
    },
    {
      onSuccess: () => {
        notification.success({
          message: t('recovery.email.success'),
        });
      },
    },
  );

  /**
   * this mutation submits a recovery code
   */
  const codeMutation = useMutation(
    async () => {

      const csrfNode = createRecoveryFlowQuery.data?.ui?.nodes?.find(
        (node) => node.attributes.name === 'csrf_token',
      );

      const flowId = alreadyHaveAFlow ? getRecoveryFlowQuery.data.id : createRecoveryFlowQuery.data.id;

      const res = await form.validateFields();
      const kratosResponse = await kratosClient.updateRecoveryFlow({
        flow: flowId,
        updateRecoveryFlowBody: {
          method: 'code',
          csrf_token: alreadyHaveAFlow ? '' : csrfNode.attributes.value,
          code: res.code,
        },
      });

      // the backend sends 200 code when the verification code in invalid, so we have to do that
      const messages = kratosResponse.data?.ui?.messages;
      if (messages.length > 0) {
        const { text } = messages.pop();
        notification.warning({
          message: text,
        });
      }
    },
    {
      onError: onError,
    },
  );


  const WithANewFlow = () => {
    return (
      <>
        <VerificationInputsWrapper>
          <VerificationEmailLabel>{t('verification.email.cta')} </VerificationEmailLabel>
          <RecoveryFormItemElement rules={EMAIL_RULES(t)} name='email'>
            <RecoveryAuthInput placeholder={t('verification.placeholder.email')} />
          </RecoveryFormItemElement>
          {emailMutation.isSuccess && (
            <SendCodeAgain onClick={emailMutation.mutate}>{t('recovery.password.cta.sendCodeAgain')}</SendCodeAgain>
          )}
          {emailMutation.isSuccess && (
            <RecoveryFormItemElement name='code'>
              <RecoveryAuthInput placeholder={t('verification.placeholder.code')} />
            </RecoveryFormItemElement>
          )}
        </VerificationInputsWrapper>
        <VerificationBottomMenu>
          <VerificationBackLink href='/auth?type=login'>{t('recovery.password.cta.backToLogin')}</VerificationBackLink>
          <RecoveryAuthButonSubmit
            htmlType='submit'
            onClick={emailMutation.isSuccess ? codeMutation.mutate : emailMutation.mutate}
            loading={emailMutation.isSuccess ? codeMutation.isLoading : emailMutation.isLoading}
          >
	          {t('recovery.password.cta.submit')}
          </RecoveryAuthButonSubmit>
        </VerificationBottomMenu>
      </>
    );
  };

  const WithAnExistingFlow = () => {
    return (
      <>
        <VerificationInputsWrapper>
          <VerificationEmailLabel>{t('recovery.otpCode')}</VerificationEmailLabel>
          <RecoveryFormItemElement name='code'>
            <RecoveryAuthInput placeholder={t('verification.placeholder.code')} />
          </RecoveryFormItemElement>
        </VerificationInputsWrapper>
        <VerificationBottomMenu>
          <VerificationBackLink href='/auth?type=login'>{t('recovery.backToLogin')}</VerificationBackLink>
          <RecoveryAuthButonSubmit
            htmlType='submit'
            onClick={codeMutation.mutate}
          >
            {
              emailMutation.isSuccess ? t('recovery.verify') : t('recovery.submit')
            }
          </RecoveryAuthButonSubmit>
        </VerificationBottomMenu>
      </>
    );
  };

  if (createRecoveryFlowQuery.isLoading && !alreadyHaveAFlow) {
    return <Loader />;
  }

  if (getRecoveryFlowQuery.isLoading && alreadyHaveAFlow) {
    return <Loader />;
  }

  return (
    <AuthRow>
      <RecoveryForm layout='vertical' form={form}>
        <VerificationFormWrapper>
          <AuthIconWrapper>
            <theme.assets.icons.loginLogo />
          </AuthIconWrapper>
          {alreadyHaveAFlow ? <WithAnExistingFlow /> : <WithANewFlow />}
          {theme?.authLinks && (
            <AuthLinks />
          )}
        </VerificationFormWrapper>
      </RecoveryForm>
      <AuthScreenAction type={AUTH_TYPES.login} noRegBtn={true}/>
    </AuthRow>
  );
};

export default Recovery;
