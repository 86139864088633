/**
 * @file
 * Contains Loader Component styles.
 */
import React from 'react';
import styled from 'styled-components';
import { Spin as AntdSpin } from 'antd';

export const Spin = styled(({ ...rest }) => <AntdSpin {...rest} />)`

  .ant-spin-dot-item {
    background: ${(props) => props.theme.colors.backgroundMain};
  }
`;
