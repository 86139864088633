/**
 * @file
 * Contains Kyc Content Footer Component.
 */
import React from 'react';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { ReactComponent as KycNextArrow } from '_common/assets/icons/kyc-continue-icon.svg';
import { ReactComponent as KycPrevArrow } from '_common/assets/icons/kyc-back-icon.svg';
import { KycContentFooterWrapper, KycFooterNextButton, KycFooterBackButton } from './Kyc.style';

const KycContentFooter = (props) => {
  const { t, level, nextLevel, prevLevel } = props;

  return (
    <KycContentFooterWrapper>
      {level > 0 && (
        <KycFooterBackButton onClick={prevLevel}>
          <KycPrevArrow />
          {t('kyc.button.backStep')}
        </KycFooterBackButton>
      )}
      <KycFooterNextButton onClick={nextLevel}>
        {t('kyc.button.nextStep')} <KycNextArrow />
      </KycFooterNextButton>
    </KycContentFooterWrapper>
  );
};

export default compose(withTranslation())(KycContentFooter);
