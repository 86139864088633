/**
 * @file
 * Contains rejected icon.
 */
import React from 'react';

const RejectedIcon = (props) => {
  const { color, gradientColor } = props;

  return (
    <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M1.66305 1.22664C1.96733 0.933773 2.46061 0.933818 2.76482 1.22674L7.65917 5.93936L12.5555 1.22664C12.8598 0.933773 13.353 0.933818 13.6573 1.22674C13.9615 1.51966 13.9614 1.99453 13.6571 2.2874L8.7614 6.99957L13.6572 11.7127C13.9614 12.0056 13.9614 12.4805 13.6572 12.7733C13.353 13.0662 12.8597 13.0662 12.5554 12.7733L7.6601 8.06068L2.76477 12.7733C2.46052 13.0662 1.96724 13.0662 1.663 12.7733C1.35875 12.4805 1.35875 12.0056 1.663 11.7127L6.55786 7.00047L1.66295 2.2873C1.35873 1.99438 1.35878 1.5195 1.66305 1.22664Z" fill="url(#paint0_linear_1051_131)"/>
      <defs>
        <linearGradient id="paint0_linear_1051_131" x1="13.0337" y1="1.95513" x2="-3.82716" y2="11.6289" gradientUnits="userSpaceOnUse">
          <stop stopColor={gradientColor}/>
          <stop offset="0.75" stopColor={color}/>
        </linearGradient>
      </defs>
    </svg>
  );
};

export default RejectedIcon;
