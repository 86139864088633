/**
 * @file Contains Security component.
 */

import React, { useEffect, useState } from 'react';
import {
	SecurityContainer,
	SecurityTwoFactorAuthContainer,
	SecurityTwoFactorAuthTitle,
	SecurityEnableTwoFactorTitle,
	SecurityEnableTwoFactorSubTitle,
	SecurityDisableTwoFactorAuthContainer,
	SecurityDisableTwoFactorAuthTextContainer,
	SecurityDisableTwoFactorAuthTextTitle,
	SecurityDisableTwoFactorAuthTextSubTitle,
	SecurityDisableTwoFactorTitle,
	SecurityDisableTwoFactorSubTitle,
	SecurityEnableScanQrSubTitle,
} from './Settings.style';
import { useBrowserSettingsFlow } from '_common/hooks/api/kratos/useSettingsFlow.hook';
import TwoFactorAuth from '_common/components/TwoFactorAuth/TwoFactorAuth';
import { Form } from 'antd';
import SpinnerElement from '_common/components/Spinner/SpinnerElement';
import { useSettingsMutation } from '_common/hooks/api/kratos/useSettingsMutation.hook';
import { kratosService } from '_common/services/kratos.service';
import ButtonElement from '_common/components/Button/ButtonElement';
import FormItemElement from '_common/components/FormItem/FormItemElement';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import SwitchElement from '_common/components/SwitchElement/SwitchElement';
import TotpInputElement from '_common/components/TotpInputElement/TotpInputElement';

const Security = (props) => {

	const { t } = useTranslation();

	const [twoFactorAuthForm] = Form.useForm();

	const [confirmTwoFactorAuthForm] = Form.useForm();

	const queryClient = useQueryClient();

	const query = useBrowserSettingsFlow(
		'',
		(error) => {
			const { data } = error?.response;

			if (!!data?.error?.id && data?.error?.id === 'session_aal2_required') {
				setIsDisableTwoFactorAuthVisible(true);
			}
		},
		!queryClient.getQueryData(['kratos-settings']),
	);

	/**
	 * Two factor authentication.
	 */
	const [twoFactorAuthNodes, setTwoFactorAuthNodes] = useState([]);

	/**
	 * Is disable two factor auth visible.
	 */
	const [isDisableTwoFactorAuthVisible, setIsDisableTwoFactorAuthVisible] = useState(false);

	/**
	 * Is disable two factor visible.
	 */
	const [isDisableTwoFactorVisible, setIsDisableTwoFactorVisible] = useState(false);

	/**
	 * Is two factor disable button visible.
	 */
	const [isTwoFactorDisableButtonVisible, setIsDisableButtonVisible] = useState(false);

	/**
	 * Use settings mutation hook.
	 */
	const mutation = useSettingsMutation({
		flowId: query?.data?.id,
		onError: (error) => {
			twoFactorAuthForm.resetFields(['totp_code']);
			twoFactorAuthForm.setFields([
				{
					name: 'totp_code',
					errors: [t('settings.documentation.twoFactorAuth.error.invalidCode')],
				},
			]);
		},
		onSuccess: () => {
			twoFactorAuthForm.resetFields(['totp_code']);
		},
	});

	/**
	 * Totp errors.
	 */
	const totpCodeError = twoFactorAuthForm.getFieldError('totp_code');

	/**
	 * Get two factor auth nodes.
	 */
	useEffect(() => {
		if (query?.data?.ui?.nodes) {
			const nodes = query.data.ui.nodes.filter((node) => node.group === 'totp' || node.group === 'default');
			setTwoFactorAuthNodes(nodes);
		}

		if (query?.data) {
			const totpCodeNodesData  = query?.data?.ui?.nodes.filter((data) => data?.group === 'totp');
			totpCodeNodesData?.[0]?.attributes?.name === 'totp_unlink' ? setIsDisableTwoFactorAuthVisible(true) : setIsDisableTwoFactorAuthVisible(false);

			if (totpCodeNodesData?.[0]?.attributes?.name === 'totp_unlink' && !isDisableTwoFactorVisible) {
				setIsDisableButtonVisible(true);
			}
		}
	}, [query.data]);

	/**
	 * Confirm two factor authentication.
	 *
	 * @function
	 * @async - confirmTwoFactorAuth.
	 */
	const confirmTwoFactorAuth = async (data) => {
		try {
			const { totp } = data;

			const response = await kratosService.fetchLoginFlow('', 'aal2');
			const { id } = response;

			const csfrTokenData = response?.ui?.nodes?.filter((data) => data?.attributes?.name === 'csrf_token');
			const csfrToken = csfrTokenData[0]?.attributes?.value;

			await kratosService.fetchUpdateLoginFlow({
				flow: id,
				updateLoginFlowBody: {
					csrf_token: csfrToken,
					method: 'totp',
					totp_code: totp,
				},
			});

			setIsDisableButtonVisible(true);
			await query.refetch();
			setIsDisableTwoFactorAuthVisible(false);
		} catch (e) {
			console.error(e);
			confirmTwoFactorAuthForm.resetFields();
			confirmTwoFactorAuthForm.setFields([
				{
					name: 'totp',
					errors: [t('settings.documentation.twoFactorAuth.error.invalidCode')],
				}
			]);
		}
	};

	/**
	 * Two factor auth handler.
	 *
	 * @function - twoFactorAuthHandler.
	 */
	const twoFactorAuthHandler = (value) => !value ? setIsDisableTwoFactorVisible(true) : setIsDisableTwoFactorVisible(false);

	return (
		<SpinnerElement spinning={query.isLoading || mutation.isLoading}>
			<SecurityContainer border={query.isLoading || mutation.isLoading}>
				{!!isDisableTwoFactorAuthVisible ? (
					<div>
						{!!isDisableTwoFactorVisible ? (
							<>
								<SecurityTwoFactorAuthContainer>
									<SecurityDisableTwoFactorTitle>
										{t('settings.security.twoFactorAuth.title.disableTwoFactorAuth')}
									</SecurityDisableTwoFactorTitle>
									<SecurityDisableTwoFactorSubTitle>
										{t('settings.security.twoFactorAuth.subTitle.activateFeature')}
									</SecurityDisableTwoFactorSubTitle>
									{!!isTwoFactorDisableButtonVisible ? (
										<Form
											form={twoFactorAuthForm}
											onFinish={(values) => mutation.mutate(values)}
										>
											<TwoFactorAuth
												nodes={twoFactorAuthNodes}
												form={twoFactorAuthForm}
											/>
										</Form>
									) : (
										<>
											<SecurityTwoFactorAuthTitle>
												{t('settings.security.twoFactorAuth.input.label.enterCode')}
											</SecurityTwoFactorAuthTitle>
											<Form
												form={confirmTwoFactorAuthForm}
												onFinish={(data) => confirmTwoFactorAuth(data)}
											>
												<FormItemElement
													name="totp"
													rules={[{ required: true, message: t('input.field.required') }]}
													errorDisplay={'top'}
												>
													<TotpInputElement
														numInputs={6}
														inputStyle={totpCodeError.length ? 'otp-input error' : 'otp-input'}
													/>
												</FormItemElement>
												<ButtonElement
													htmlType="submit"
												>
													{t('settings.documentation.twoFactorAuth.button.confirm')}
												</ButtonElement>
											</Form>
										</>
									)}
								</SecurityTwoFactorAuthContainer>
							</>
						) : (
							<SecurityDisableTwoFactorAuthContainer>
								<SecurityDisableTwoFactorAuthTextContainer>
									<SecurityDisableTwoFactorAuthTextTitle>
										{t('settings.security.twoFactorAuth.title.twoFactorAuthentication')}
									</SecurityDisableTwoFactorAuthTextTitle>
									<SecurityDisableTwoFactorAuthTextSubTitle>
										{t('settings.security.twoFactorAuth.subTitle.activateFeature')}
									</SecurityDisableTwoFactorAuthTextSubTitle>
								</SecurityDisableTwoFactorAuthTextContainer>
								<div>
									<SwitchElement
										onChange={(value) => twoFactorAuthHandler(value)}
										defaultChecked={isDisableTwoFactorAuthVisible}
									/>
								</div>
							</SecurityDisableTwoFactorAuthContainer>
						)}
					</div>
				) : (
					!!twoFactorAuthNodes.length && (
						<div>
							<SecurityEnableTwoFactorTitle>
								{t('settings.security.twoFactorAuth.title.enableTwoFactorAuth')}
							</SecurityEnableTwoFactorTitle>
							<SecurityEnableTwoFactorSubTitle>
								{t('settings.security.twoFactorAuth.text.receiveCode')} <a
								href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en"
								target="_blank">
								{t('settings.security.twoFactorAuth.text.googleAuthenticator')}</a>, {t('settings.security.twoFactorAuth.text.authy')}
							</SecurityEnableTwoFactorSubTitle>
							<SecurityEnableScanQrSubTitle>
								{t('settings.security.twoFactorAuth.text.scanQr')}
							</SecurityEnableScanQrSubTitle>
							<Form
								form={twoFactorAuthForm}
								onFinish={(values) => mutation.mutate(values)}
								onKeyPress={(e) => {
									if ((query.isLoading || mutation.isLoading) && e.key === 'Enter') {
										e.preventDefault();
									}
								}}
							>
								<TwoFactorAuth
									nodes={twoFactorAuthNodes}
									form={twoFactorAuthForm}
								/>
							</Form>
						</div>
					)
				)}
			</SecurityContainer>
		</SpinnerElement>
	)
};

export default Security;
