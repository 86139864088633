/**
 * @file Contains Copy to clipboard icon.
 */

import React from 'react';

const CopyToClipboardIcon = () => {
	return (
		<svg width="13" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M4.87435 2.03125C3.15421 2.03125 1.75977 3.64023 1.75977 5.625V10.0668C1.75977 10.3257 1.94165 10.5355 2.16602 10.5355C2.39038 10.5355 2.57227 10.3257 2.57227 10.0668V5.625C2.57227 4.15799 3.60294 2.96875 4.87435 2.96875H8.67298C8.89734 2.96875 9.07923 2.75888 9.07923 2.5C9.07923 2.24112 8.89734 2.03125 8.67298 2.03125H4.87435Z"
				fill="#00CC70"/>
			<path fillRule="evenodd" clipRule="evenodd"
			      d="M9.9674 4.24579C8.21188 4.0194 6.41183 4.0194 4.65632 4.24579C4.15621 4.31028 3.75433 4.7644 3.69538 5.34592C3.48717 7.39997 3.48717 9.47502 3.69538 11.5291C3.75433 12.1106 4.15621 12.5647 4.65632 12.6292C6.41183 12.8556 8.21188 12.8556 9.9674 12.6292C10.4675 12.5647 10.8694 12.1106 10.9283 11.5291C11.1365 9.47502 11.1365 7.39997 10.9283 5.34592C10.8694 4.7644 10.4675 4.31028 9.9674 4.24579ZM4.74656 5.17749C6.4421 4.95883 8.18161 4.95883 9.87715 5.17749C10.0057 5.19407 10.1068 5.31138 10.1213 5.45483C10.3222 7.43652 10.3222 9.43848 10.1213 11.4202C10.1068 11.5636 10.0057 11.6809 9.87715 11.6975C8.18161 11.9162 6.4421 11.9162 4.74656 11.6975C4.618 11.6809 4.51692 11.5636 4.50238 11.4202C4.30151 9.43847 4.30151 7.43652 4.50238 5.45483C4.51692 5.31138 4.618 5.19407 4.74656 5.17749Z"
			      fill="#00CC70"/>
		</svg>
	)
};

export default CopyToClipboardIcon;
