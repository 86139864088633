/**
 * @file
 * Contains Dashboard component.
 */
import React from 'react';
import { HeaderContainer } from '_common/components/Header/Header.style';
import InputSearchIcon from '_common/assets/icons/search.svg';
import HeaderLoginInfo from '_common/components/Header/HeaderLoginInfo';
import InputElement from '_common/components/Input/InputElement';
import NotificationElement from '_common/components/Notification/Notification';
import HeaderTransactionSearchBar from '_common/components/Header/HeaderTransactionSearchBar';
import { Col, Row } from 'antd';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import { useLocation } from 'react-router';
import { TRANSACTIONS_VIEW_MODE } from '_common/constants/reports.constant';

const Header = (props) => {
  const {
    t,
    reportStore,
  } = props;

  /**
   * Current route location.
   */
  const location = useLocation();

  // TODO add content to notifications.
  const notificationsData = [];

  return (
    <HeaderContainer>
        <HeaderLoginInfo />
        {(location.pathname === '/reports' && reportStore.getTableType === TRANSACTIONS_VIEW_MODE.transactions) && (
          <HeaderTransactionSearchBar />
        )}
    </HeaderContainer>
  );
};

export default compose(
  withTranslation(),
  inject('reportStore')
)(observer(Header));
