/**
 * @file
 * Contains global components styles.
 */
import styled from 'styled-components';

export const GlobalViewContainer = styled.div``;

export const FiltersRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-top: ${(props) => props?.mt ? `${props.mt}px` : '0px'};
`;

export const FilterColumn = styled.div`
  width: ${(props) => props.width ? `${props.width + props.pr}px` : '100%'};
  padding-right: ${(props) => props.pr ? `${props.pr}px` : '6px'};
  margin-left: ${(props) => props.ml ? props.ml : '0px'};
  margin-bottom: ${(props) => props.mb ? `${props.mb}px` : '0px'};
  
  &:last-child {
    padding-right: 0;
		margin-bottom: 0;
    width: ${(props) => props.width ? `${props.width}px` : '100%'};
  }
  .ant-form-item-control-input {
    min-height: auto;
  }
`;
