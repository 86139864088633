/**
 * @file
 * Contains Radio Group Graphic styles.
 */
import React from 'react';
import styled from 'styled-components';
import { Radio as AntdRadio } from 'antd';

export const RadioGroup = styled(({ props, ...rest }) => <AntdRadio.Group props {...rest} />)``;

export const Radio = styled(({ props, ...rest }) => <AntdRadio props {...rest} />)`
  &.ant-radio-wrapper {
    box-shadow: ${(props) => props.theme.shadow.box};
    width: 26px;
    height: 26px;
    border-radius: 4px;
    position: relative;
    overflow: hidden;
    
    @media (min-width: 1400px) {
      width: 30px;
      height: 30px;
    }

    &:last-child {
      margin-right: 0;
    }

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 20px;
      height: 20px;
      background: url(${(props) => props.icon}) center center no-repeat;
      opacity: 0.5;
      transition: all 0.5s ease;
    }

    &.ant-radio-input {
      opacity: 0;
    }

    .ant-radio {
      opacity: 0;
      height: 100%;
      width: 100%;
    }
  }

  &.ant-radio-wrapper-checked {
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 4px;
      background: ${(props) => props.theme.colors.mainColor};
      
      @media (min-width: 1400px) {
        bottom: 0px;
        height: 3px;
      }
    }

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 20px;
      height: 20px;
      background: url(${(props) => props.icon}) center center no-repeat;
      opacity: 1;
    }
  }
`;

export const RadioButtonIcon = styled.div`
  position: absolute;
  top: calc(50% + 2px);
  left: 50%;
  transform: translate(-50%, -50%);
`;
