/**
 * @file
 * Contains dropdown arrow icon.
 */
import React from 'react';

const DropdownArrowIcon = (props) => {
  const { color } = props;

  return (
    <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2 0L7 5L12 0L14 1L7 8L0 1L2 0Z" fill="#363740"/>
    </svg>
  )
};

export default DropdownArrowIcon;

