/**
 * @file
 * Contains no data icon.
 */
import React from 'react';

const NoDataIcon = () => {
  return (
    <svg width="160" height="142" viewBox="0 0 160 142" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_i)">
        <path d="M134.086 8.21387C142.158 14.969 148.629 23.4163 153.036 32.9516C157.443 42.4869 159.677 52.8744 159.579 63.3709L90.4939 62.7582C89.5001 62.7494 88.9669 61.5864 89.6087 60.8273L134.086 8.21387Z" fill="#D3D3D6" fillOpacity="0.2"/>
      </g>
      <g filter="url(#filter1_i)">
        <path d="M142 71C142 87.1392 136.501 102.797 126.411 115.392C116.32 127.988 102.239 136.769 86.4882 140.29C70.7377 143.811 54.2577 141.86 39.7643 134.76C25.2709 127.66 13.629 115.833 6.75728 101.23C-0.114439 86.6272 -1.80588 70.1186 1.96173 54.4254C5.72934 38.7322 14.7312 24.7908 27.4836 14.899C40.236 5.00719 55.978 -0.244741 72.1152 0.00875891C88.2524 0.262259 103.822 6.00607 116.257 16.2936L71 71H142Z" fill="#D8D8DB" fillOpacity="0.2"/>
      </g>
      <defs>
        <filter id="filter0_i" x="89.3291" y="8.21387" width="70.2528" height="58.3651" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="3.2081"/>
          <feGaussianBlur stdDeviation="10"/>
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow"/>
        </filter>
        <filter id="filter1_i" x="0" y="0" width="142" height="145.208" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="3.2081"/>
          <feGaussianBlur stdDeviation="10"/>
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow"/>
        </filter>
      </defs>
    </svg>
  )
};

export default NoDataIcon;

