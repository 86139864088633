/**
 * @file
 * Contains credit card icon.
 */
import React from 'react';

const CreditCardIcon = (props) => {
  const { color } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="12" viewBox="0 0 15 12" fill="none">
      <path
        d="M11.875 0H3.125C2.2965 0.000992411 1.50222 0.330551 0.916387 0.916387C0.330551 1.50222 0.000992411 2.2965 0 3.125H15C14.999 2.2965 14.6694 1.50222 14.0836 0.916387C13.4978 0.330551 12.7035 0.000992411 11.875 0Z"
        fill={color}/>
      <path
        d="M0 8.125C0.000992411 8.9535 0.330551 9.74778 0.916387 10.3336C1.50222 10.9194 2.2965 11.249 3.125 11.25H11.875C12.7035 11.249 13.4978 10.9194 14.0836 10.3336C14.6694 9.74778 14.999 8.9535 15 8.125V4.375H0V8.125ZM4.375 7.8125C4.375 7.99792 4.32002 8.17918 4.217 8.33335C4.11399 8.48752 3.96757 8.60768 3.79627 8.67864C3.62496 8.74959 3.43646 8.76816 3.2546 8.73199C3.07275 8.69581 2.9057 8.60652 2.77459 8.47541C2.64348 8.3443 2.55419 8.17725 2.51801 7.9954C2.48184 7.81354 2.50041 7.62504 2.57136 7.45373C2.64232 7.28243 2.76248 7.13601 2.91665 7.033C3.07082 6.92998 3.25208 6.875 3.4375 6.875C3.68614 6.875 3.9246 6.97377 4.10041 7.14959C4.27623 7.3254 4.375 7.56386 4.375 7.8125Z"
        fill={color}/>
    </svg>)
};

export default CreditCardIcon;
