/**
 * @file
 * Contains reject icon.
 */
import React from 'react';

const RejectIcon = (props) => {
  const { color } = props;

  return (
    <svg width="180" height="181" viewBox="0 0 180 181" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M90 0.876404C40.23 0.876404 0 41.1064 0 90.8764C0 140.646 40.23 180.876 90 180.876C139.77 180.876 180 140.646 180 90.8764C180 41.1064 139.77 0.876404 90 0.876404ZM128.7 129.576C127.867 130.411 126.878 131.073 125.79 131.524C124.701 131.976 123.534 132.208 122.355 132.208C121.176 132.208 120.009 131.976 118.92 131.524C117.832 131.073 116.843 130.411 116.01 129.576L90 103.566L63.99 129.576C62.3072 131.259 60.0248 132.205 57.645 132.205C55.2652 132.205 52.9828 131.259 51.3 129.576C49.6172 127.894 48.6718 125.611 48.6718 123.231C48.6718 122.053 48.9039 120.886 49.3549 119.798C49.8058 118.709 50.4668 117.72 51.3 116.886L77.31 90.8764L51.3 64.8664C49.6172 63.1836 48.6718 60.9012 48.6718 58.5214C48.6718 56.1416 49.6172 53.8592 51.3 52.1764C52.9828 50.4936 55.2652 49.5482 57.645 49.5482C60.0248 49.5482 62.3072 50.4936 63.99 52.1764L90 78.1864L116.01 52.1764C116.843 51.3432 117.832 50.6822 118.921 50.2313C120.01 49.7803 121.177 49.5482 122.355 49.5482C123.533 49.5482 124.7 49.7803 125.789 50.2313C126.878 50.6822 127.867 51.3432 128.7 52.1764C129.533 53.0096 130.194 53.9988 130.645 55.0875C131.096 56.1762 131.328 57.343 131.328 58.5214C131.328 59.6998 131.096 60.8666 130.645 61.9553C130.194 63.044 129.533 64.0332 128.7 64.8664L102.69 90.8764L128.7 116.886C132.12 120.306 132.12 126.066 128.7 129.576Z" fill="#F2F2F2"/>
    </svg>
  )
};

export default RejectIcon;
