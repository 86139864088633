import styled from 'styled-components';

export const AddUserInfoWrapper = styled.div``;

export const AddUserInfoTitle = styled.div`
	margin-bottom: 14px;
  font-size: 20px;
  font-weight: 700;
`;

export const AddUserInfoSubTitle = styled.p`
  margin-top: 5px;
  font-size: 16px;
  color: ${(props) => props.theme.colors.darkLight};
`;

export const AddNameModalButtonsContainer = styled.div`
	display: flex;
	align-items: center;
	margin-top: 22px;
	justify-content: space-between;
  gap: 15px;
		
	button {
		font-size: 12px !important;
		padding: 0 !important;
    margin: 0 !important;
			
		&:first-child {
			margin-right: 15px;
		}	
	}	
`;

export const FormItemWrap = styled.div`
  margin-bottom: 15px;
`;
