/**
 * @file Contains two factor auth confirmation component.
 */

import React from 'react';
import FormItemElement from '_common/components/FormItem/FormItemElement';
import InputElement from '_common/components/Input/InputElement';
import ButtonElement from '_common/components/Button/ButtonElement';
import {
	ConfirmTwoFactorAuthButtonContainer,
	ConfirmTwoFactorAuthContainer,
	ConfirmTwoFactorAuthTitle,
	ConfirmTwoFactorAuthSubTitle,
	ConfirmTwoFactorAuthButtonCancel,
	ConfirmTwoFactorAuthButtonAuthenticate,
} from './Users.style';
import { useTranslation } from 'react-i18next';
import TotpInputElement from '_common/components/TotpInputElement/TotpInputElement';

const TwoFactorAuthConfirmation = (props) => {

	const { isConfirmTwoFactorVisible, form } = props;

	const { t } = useTranslation();

	return (
		<div>
			<ConfirmTwoFactorAuthTitle>
				{t('user.modal.title.twoFactorAuth')}
			</ConfirmTwoFactorAuthTitle>
			<ConfirmTwoFactorAuthSubTitle>
				{t('user.modal.subTitle.enterTwoFactorAuthCode')}
			</ConfirmTwoFactorAuthSubTitle>
			<ConfirmTwoFactorAuthContainer>
				<FormItemElement
					name="totp"
					validateTrigger="onChange"
					rules={[
						{
							required: true,
							message: t('input.field.required'),
						},
					]}
				>
					<TotpInputElement
						numInputs={6}
						inputStyle={'otp-input w-40'}
					/>
				</FormItemElement>
				<ConfirmTwoFactorAuthButtonContainer>
					<ConfirmTwoFactorAuthButtonCancel>
						<ButtonElement
							clear='true'
							onClick={() => {
								isConfirmTwoFactorVisible(false);
								form.resetFields(['totp']);
							}}
						>
							{t('user.modal.button.twoFactorAuth.cancel')}
						</ButtonElement>
					</ConfirmTwoFactorAuthButtonCancel>
					<ConfirmTwoFactorAuthButtonAuthenticate>
						<ButtonElement htmlType="submit">
							{t('user.modal.button.twoFactorAuth')}
						</ButtonElement>
					</ConfirmTwoFactorAuthButtonAuthenticate>
				</ConfirmTwoFactorAuthButtonContainer>
			</ConfirmTwoFactorAuthContainer>
		</div>
	)
};

export default TwoFactorAuthConfirmation;
