/**
 * @file
 * Contains result by day icon.
 */
import React from 'react';

const ResultByDayIcon = (props) => {
  const { color } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
      <path
        d="M9.37515 9.77484C9.37515 9.07037 9.0953 8.39476 8.59717 7.89663C8.09904 7.3985 7.42342 7.11865 6.71896 7.11865C6.0145 7.11865 5.33888 7.3985 4.84075 7.89663C4.34262 8.39476 4.06277 9.07037 4.06277 9.77484C4.06277 10.0235 4.16154 10.2619 4.33736 10.4377C4.51317 10.6135 4.75162 10.7123 5.00025 10.7123C5.24889 10.7123 5.48734 10.6135 5.66315 10.4377C5.83896 10.2619 5.93773 10.0235 5.93773 9.77484C5.93773 9.56764 6.02004 9.36893 6.16655 9.22242C6.31306 9.07591 6.51176 8.99361 6.71896 8.99361C6.92616 8.99361 7.12486 9.07591 7.27137 9.22242C7.41788 9.36893 7.50019 9.56764 7.50019 9.77484C7.50019 10.1655 6.75521 10.7242 6.15647 11.1729C5.17525 11.9092 4.06277 12.7435 4.06277 14.0716C4.06277 14.3202 4.16154 14.5587 4.33736 14.7345C4.51317 14.9103 4.75162 15.0091 5.00025 15.0091H8.43767C8.6863 15.0091 8.92475 14.9103 9.10056 14.7345C9.27638 14.5587 9.37515 14.3202 9.37515 14.0716C9.37515 13.823 9.27638 13.5845 9.10056 13.4087C8.92475 13.2329 8.6863 13.1341 8.43767 13.1341H6.68771C6.88146 12.9754 7.09083 12.8216 7.28457 12.6735C8.26267 11.9373 9.37515 11.1029 9.37515 9.77484Z"
        fill={color}/>
      <path
        d="M14.0624 6.88428C13.8138 6.88428 13.5753 6.98305 13.3995 7.15886C13.2237 7.33467 13.1249 7.57312 13.1249 7.82175V10.6342H12.6562C12.4491 10.6339 12.2506 10.5514 12.1041 10.405C11.9577 10.2586 11.8753 10.06 11.875 9.85296V7.82175C11.875 7.57312 11.7762 7.33467 11.6004 7.15886C11.4246 6.98305 11.1861 6.88428 10.9375 6.88428C10.6888 6.88428 10.4504 6.98305 10.2746 7.15886C10.0988 7.33467 10 7.57312 10 7.82175V9.85296C10.0008 10.5572 10.2809 11.2323 10.7789 11.7302C11.2768 12.2282 11.952 12.5083 12.6562 12.5091H13.1249V14.0716C13.1249 14.3202 13.2237 14.5587 13.3995 14.7345C13.5753 14.9103 13.8138 15.0091 14.0624 15.0091C14.311 15.0091 14.5495 14.9103 14.7253 14.7345C14.9011 14.5587 14.9999 14.3202 14.9999 14.0716V7.82175C14.9999 7.57312 14.9011 7.33467 14.7253 7.15886C14.5495 6.98305 14.311 6.88428 14.0624 6.88428Z"
        fill={color}/>
      <path
        d="M13.9332 1.7013L13.1063 2.52815C11.8802 1.14827 10.1884 0.268998 8.35451 0.0586419C6.52064 -0.151714 4.67362 0.32162 3.16695 1.38805C1.66029 2.45447 0.59983 4.03908 0.188547 5.83856C-0.222736 7.63805 0.0440509 9.526 0.937843 11.1411C0.997552 11.2488 1.07789 11.3436 1.17426 11.4203C1.27063 11.4969 1.38115 11.5538 1.49951 11.5877C1.61788 11.6216 1.74176 11.6319 1.8641 11.618C1.98643 11.604 2.10482 11.5661 2.2125 11.5064C2.32018 11.4467 2.41505 11.3663 2.49168 11.27C2.56832 11.1736 2.62522 11.0631 2.65914 10.9447C2.69306 10.8263 2.70334 10.7025 2.68938 10.5801C2.67543 10.4578 2.63751 10.3394 2.5778 10.2317C1.90217 9.0128 1.70354 7.58633 2.02047 6.2292C2.3374 4.87208 3.14728 3.68112 4.2929 2.88753C5.43852 2.09393 6.83814 1.75431 8.22007 1.93458C9.602 2.11486 10.8676 2.80218 11.7713 3.86312L11.067 4.56748C10.9796 4.65488 10.9201 4.76623 10.896 4.88745C10.8719 5.00867 10.8843 5.13431 10.9316 5.2485C10.9788 5.36268 11.0589 5.46028 11.1617 5.52896C11.2644 5.59763 11.3852 5.6343 11.5088 5.63433H14.375C14.5408 5.63433 14.6997 5.56848 14.8169 5.45127C14.9342 5.33407 15 5.1751 15 5.00934V2.14316C15 2.01957 14.9633 1.89876 14.8946 1.79601C14.826 1.69326 14.7284 1.61317 14.6142 1.56588C14.5 1.51859 14.3743 1.50621 14.2531 1.53031C14.1319 1.55442 14.0206 1.61392 13.9332 1.7013Z"
        fill={color}/>
    </svg>)
};

export default ResultByDayIcon;
