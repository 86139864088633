import React from 'react';
import {
  ReportsStatusContainer,
  ReportsStatusIconContainer,
  ReportsStatusTextContainer,
  ReportsStatusWrapper,
} from './Reports.style';
import { ReactComponent as StatusDoneIcon} from '_common/assets/icons/status-done.svg';
import { ReactComponent as StatusPendingIcon} from '_common/assets/icons/status-pending.svg';
import { ReactComponent as StatusExpiredIcon} from '_common/assets/icons/status-expired.svg';
import { ReactComponent as StatusCanceledIcon} from '_common/assets/icons/status-canceled.svg';
import { ReactComponent as StatusRefundedIcon} from '_common/assets/icons/status-refunded.svg';
import { ReactComponent as QuestionMarkCircled } from '_common/assets/icons/question-mark-circled.svg';
import TooltipElement from '_common/components/Tooltip/TooltipElement';

const statusColors = {
  Done: '#94FC66',
  Completed: '#94FC66',
  Pending: '#FFB800',
  Expired: '#FF8585',
  Canceled: '#D5A9FF',
  Refunded: '#40DFFF',
  Chargeback: '#40DFFF',
};

const statusIcons = {
  Done: <StatusDoneIcon />,
  Completed: <StatusDoneIcon />,
  Pending: <StatusPendingIcon />,
  Expired: <StatusExpiredIcon />,
  Canceled: <StatusCanceledIcon />,
  Refunded: <StatusRefundedIcon />,
  Chargeback: <StatusRefundedIcon />,
};

const ReportsStatusRender = ({ record }) => {
  const str = record?.state?.replaceAll('_', ' ') || '';
  const status = str?.charAt(0)?.toUpperCase() + str?.slice(1);
  const icon = statusIcons[status];
  const color = statusColors[status];

  if (!icon || !color) {
    return null;
  }

  return (
    <ReportsStatusContainer>
      <ReportsStatusWrapper color={color}>
        <ReportsStatusIconContainer>{icon}</ReportsStatusIconContainer>
        <ReportsStatusTextContainer>{status}</ReportsStatusTextContainer>
      </ReportsStatusWrapper>
      {status.toLowerCase() === 'canceled' && !!record?.unified_error_message && (
        <TooltipElement title={record?.unified_error_message}>
          <QuestionMarkCircled/>
        </TooltipElement>
      )}
    </ReportsStatusContainer>
  );
}

export default ReportsStatusRender;
