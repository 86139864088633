/**
 * @file
 * Contains delete icon icon.
 */
import React from 'react';

const DeleteIcon = (props) => {
  const { color } = props;

  return (
    <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.5625 2.5H12.125V1.875C12.125 0.839453 11.2855 0 10.25 0H7.75C6.71445 0 5.875 0.839453 5.875 1.875V2.5H2.4375C1.57457 2.5 0.875 3.19957 0.875 4.0625V5.3125C0.875 5.6577 1.1548 5.9375 1.5 5.9375H16.5C16.8452 5.9375 17.125 5.6577 17.125 5.3125V4.0625C17.125 3.19957 16.4254 2.5 15.5625 2.5ZM7.125 1.875C7.125 1.53047 7.40547 1.25 7.75 1.25H10.25C10.5945 1.25 10.875 1.53047 10.875 1.875V2.5H7.125V1.875Z" fill={color ? color : '#A4A6B3'} />
      <path d="M2.06054 7.1875C1.94902 7.1875 1.86015 7.2807 1.86546 7.39211L2.38109 18.2141C2.42874 19.2156 3.2514 20 4.25374 20H13.7451C14.7475 20 15.5701 19.2156 15.6178 18.2141L16.1334 7.39211C16.1387 7.2807 16.0499 7.1875 15.9384 7.1875H2.06054ZM11.4994 8.75C11.4994 8.40469 11.7791 8.125 12.1244 8.125C12.4698 8.125 12.7494 8.40469 12.7494 8.75V16.875C12.7494 17.2203 12.4698 17.5 12.1244 17.5C11.7791 17.5 11.4994 17.2203 11.4994 16.875V8.75ZM8.37445 8.75C8.37445 8.40469 8.65413 8.125 8.99945 8.125C9.34476 8.125 9.62445 8.40469 9.62445 8.75V16.875C9.62445 17.2203 9.34476 17.5 8.99945 17.5C8.65413 17.5 8.37445 17.2203 8.37445 16.875V8.75ZM5.24945 8.75C5.24945 8.40469 5.52913 8.125 5.87445 8.125C6.21976 8.125 6.49945 8.40469 6.49945 8.75V16.875C6.49945 17.2203 6.21976 17.5 5.87445 17.5C5.52913 17.5 5.24945 17.2203 5.24945 16.875V8.75Z" fill={color ? color : '#A4A6B3'} />
    </svg>
  )
};

export default DeleteIcon;
