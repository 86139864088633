/**
 * @file
 * Contains Search Bar Element Component Styles.
 */
import React from 'react';
import styled, { css } from 'styled-components';
import { Input as AntdInput } from 'antd';
import { Button as AntdButton } from 'antd';

export const SearchBarInput = styled(({ innerRef, isInvalid, ...rest }) => <AntdInput {...rest} />)`
  &.ant-input-affix-wrapper {
    padding: 0;
    background: none;
    border: 0;

    &:hover, &:focus {
      outline: none !important;
      box-shadow: none !important;
      background: ${(props) => props.theme.colors.darkGrey}1A;
      
      .ant-input-suffix {
        display: block;
        margin-top: 4px;
        width: 11px;
        height: 11px;
        padding-right: 16px;
      }
    }
    
    .ant-input {
      padding: 0 20px 4px 6px;
      font-size: 14px;
      box-sizing: border-box;
      animation: all 0.5s ease;
      right: 0;
      border: none;
      background: none;
  
      &:hover, &:focus {
        outline: none !important;
        box-shadow: none !important;
      }
    }
    
    .ant-input-suffix {
      display: none;
    }
  }
`;

export const SearchBarWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  
  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 1px;
    background: ${(props) => props.theme.colors.backgroundMain};
  }
`;

export const SearchButton = styled(({ ...rest }) => <AntdButton {...rest} />)`
  &.ant-btn {
    position: relative;
    bottom: 0;
    right: 1px;
    padding: 0;
    border: none;
    background: transparent;
    margin-left: 8px;
  }
`;
