import { kratosClient } from '_common/services/apiClient.service';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { onMutation } from '../../../services/kratos.service';

/**
 *
 * @param {function} [onError]
 * @param {function} [onSuccess]
 * @param {string} flowId
 */
export const useSettingsMutation = ({ onError, onSuccess, flowId }) => {
  const queryClient = useQueryClient();

  /**
   *
   * @param values {import('@ory/client').updateSettingsFlowBody}
   * @return {Promise<void>}
   */
  const submit = async (values) => {
    const res = await kratosClient.updateSettingsFlow({
      flow: flowId,
      updateSettingsFlowBody: values,
    });

    return res.data;
  };

  return useMutation({
    mutationFn: submit,
    onSuccess,
    onSettled: (res, error) => {
      const flowResponse = res ?? error?.response?.data;
      if (flowResponse) {
        queryClient.setQueryData(['kratos-settings', flowId], flowResponse);
      }

      return onMutation(res, error, '');
    },
    onError: onError,
  });
};
