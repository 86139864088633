/**
 * @file
 * Contains Account Added Component.
 */
import React from 'react';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import {
  AccountAddedContainer,
  AccountAddedWrapper,
  AccountAddedTitle,
  AccountAddedSubTitle,
  AddAccountButton,
} from './FinanceAccounts.style';

const AccountAdded = (props) => {
  const { t, addAccountHandler } = props;

  return (
    <AccountAddedContainer>
      <AccountAddedWrapper>
        <AccountAddedTitle>{t('finance.accounts.accountAdded.title')}</AccountAddedTitle>
        <AccountAddedSubTitle>{t('finance.accounts.accountAdded.subTitle')}</AccountAddedSubTitle>
        <AddAccountButton align="middle" onClick={addAccountHandler}>
          {t('finance.accounts.accountAdded.buttonText')}
        </AddAccountButton>
      </AccountAddedWrapper>
    </AccountAddedContainer>
  );
};

export default compose(withTranslation())(AccountAdded);
