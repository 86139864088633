/**
 * @file
 * Contains Statistic style.
 */
import React from 'react';
import styled from 'styled-components';
import { Statistic as AntdStatistic } from 'antd';

export const Statistic = styled(({ ...rest }) => <AntdStatistic {...rest} />)`
  .ant-statistic-content {
    font-size: 14px;
    color: ${(props) => (props.diff ? props.theme.colors.red : props.theme.colors.green)};
  }

  .ant-statistic-content-prefix {
    margin: 0;
  }
`;
