import { notification } from 'antd';

import { captureException } from '@sentry/react';

const SERVER_ERRORS = [500, 502, 503, 504, undefined];

/**
 *
 * @param {import('axios').AxiosError} e
 * @returns
 */
export const handleOathkeeperError = (e) => {
  if (e.isAxiosError) {

    if (e.response?.status === 401) {
      window.location.replace('/auth');
      return
    }
    if (e.response?.status === 400) {
      notification.warning({
        message: `The password is similar to the user identifier or found in the list of frequently used passwords.`,
      });
      Promise.reject(e)
      return
    }
    if (e.response?.status === 403) {
      const requestMethod = e.config.method;
      const restrictedResource = e.config.url;

	    if ('2fa_token' in e?.response?.data) {
				let error = new Error('2FA needed');
		    error.data = e?.response?.data;
		    throw error;
	    }

      notification.warning({
        message: `Access to ${requestMethod.toUpperCase()} ${restrictedResource} is restricted.`,
      });
      Promise.reject(e)
      return
    }

    if (SERVER_ERRORS.includes(e.response?.status)) {
      notification.warning({
        message: 'There is a problem with the server, please try again later.',
      });
    }
  }
  captureException(e);
  Promise.reject(e);
};
