/**
 * @file Contains Refund failed component.
 */

import React from 'react';
import {
	RefundErrorContainer,
	RefundErrorIconWrapper,
	RefundErrorSubtitle,
	RefundErrorTitle,
	RefundErrorButtonContainer,
} from './Reports.style';
import ButtonElement from '_common/components/Button/ButtonElement';
import RefundErrorIcon from '_common/assets/renderSvgIcons/RefundError.icon';

const RefundFailed = (props) => {
	const { closeModal, t } = props;

	return (
		<RefundErrorContainer>
			<RefundErrorIconWrapper>
				<RefundErrorIcon />
			</RefundErrorIconWrapper>
			<RefundErrorTitle>
				{t('transaction.modal.refund.failed.refundDidNotPrecessed')}
			</RefundErrorTitle>
			<RefundErrorSubtitle>
				{t('transaction.modal.refund.failed.tryAgain')}
			</RefundErrorSubtitle>
			<RefundErrorButtonContainer>
				<ButtonElement
					clear={true}
					height={50}
					onClick={() => closeModal()}
				>
					{t('transaction.modal.refund.failed.close')}
				</ButtonElement>
			</RefundErrorButtonContainer>
		</RefundErrorContainer>
	)
};

export default RefundFailed;
