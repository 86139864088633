/**
 * @file
 * Contains Upload Element Styles.
 */
import styled from 'styled-components';
import { Upload } from 'antd';

export const UploadDragger = styled(Upload.Dragger)`
  &.ant-upload.ant-upload-drag {
    background: ${(props) => props.theme.colors.white};
    text-align: center;
    border-radius: 5px;
    border: 1px dashed ${(props) => props.theme.colors.mainColor};
    margin-bottom: 20px;
  }
  
  
  svg {
    margin-bottom: 20px;
  }
`;

export const UploadModalTitle = styled.div`
  text-align: center;
  color: ${(props) => props.theme.colors.darkLight};
  margin-bottom: 20px;
`;

export const UploadTitle = styled.div`
  color: ${(props) => props.theme.colors.mainColor};
  font-weight: 900;
  font-size: 20px;
  margin-bottom: 20px;
  text-transform: uppercase;
`;

export const UploadDescription = styled.div`
  color: ${(props) => props.theme.colors.darkLight};
  margin-bottom: 20px;
`;

export const UploadExtension = styled.div`
  color: ${(props) => props.theme.colors.mainColor};
  font-weight: 700;
  margin-bottom: 20px;
`;

export const UploadSize = styled.div`
  color: ${(props) => props.theme.colors.darkLight};
`;
