/**
 * @file contains new authorization page, that uses Ory
 */

import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';

import { Form, notification } from 'antd';

import links from '_common/routes/urls';
import { AUTH_TYPES, CSRF_TOKEN_NAME, METHOD } from './Auth.constants';
import { LayoutView } from './Layout.view';
import { kratosClient } from '../_common/services/apiClient.service';

import { handleOathkeeperError } from '_common/services/error.service';

import { Login } from './Login.view';
import { Registration } from './Registration.view';
import { useTranslation } from 'react-i18next';
import Loader from '../_common/components/Loader/Loader';

const Auth = ({ history }) => {
  const { t } = useTranslation();
  const query = new URLSearchParams(window.location.search);
  const queryType = query.get('type');

  const [form] = Form.useForm();

  /**
   * Type of auth state
   */
  const [type, setType] = useState(queryType || AUTH_TYPES.login);

  /**
   * The type of auth is login
   */
  const isLogin = type === AUTH_TYPES.login;

  /**
   * Kratos user flow state
   */
  const { data: flow, isSuccess } = useQuery(
    ['authorizationFlow', type],
    async () => {
      if (type === AUTH_TYPES.login) {
        const { data } = await kratosClient.createBrowserLoginFlow();
        return data;
      } else if (type === AUTH_TYPES.register) {
        const { data } = await kratosClient.createBrowserRegistrationFlow();
        return data;
      }
    },
    {
      retry: 0,
      onError: (e) => {
        handleOathkeeperError(e);
      },
    },
  );

  /**
   * This mutation signs in/signs-up a user
   */
  const mutation = useMutation(
    async () => {
      // Get the value of csrf_token input
      const nodes = flow.ui.nodes;
      const [{ attributes }] = nodes.filter((n) => n.attributes.name === CSRF_TOKEN_NAME);
      const { value } = attributes;

      // Extract the flow id
      const flowUrl = new URL(flow.ui.action);
      const queryParams = flowUrl.searchParams;
      const flowId = queryParams.get('flow');

      const updateFlowBody = await form.validateFields();

      updateFlowBody['method'] = METHOD;
      updateFlowBody[CSRF_TOKEN_NAME] = value;
      if (type === AUTH_TYPES.login) {
        await kratosClient.updateLoginFlow({
          updateLoginFlowBody: updateFlowBody,
          flow: flowId,
        });
      }

      if (type === AUTH_TYPES.register) {
        const kratosResponse = await kratosClient.updateRegistrationFlow({
          flow: flowId,
          updateRegistrationFlowBody: updateFlowBody,
        });
        kratosResponse.data.continue_with.forEach((action) => {
          if (action.action === 'show_verification_ui') {
            notification.success({
              message: t('registration.success'),
              duration: 60,
            });
          }
        });
      }
    },
    {
      onError: (e) => {
        if (e.response?.data?.ui?.messages) {
          const { messages } = e.response.data?.ui;
          const { text } = messages?.pop();
          notification.warning({
            message: text,
          });
        } else {
          if(e.response?.status === 401) {
            notification.warning({
              message: e.response?.data?.error?.reason || e.response?.data?.error?.message,
            });
          } else if(e.response?.status === 400) {
            notification.warning({
              message: t('registration.error'),
            });
          } else {
            handleOathkeeperError(e);
          }
        }
      },
      onSuccess: () => {
        if (isLogin) {
          history.push(links.dashboard);
        } else {
          history.push(links.questionnaire);
        }
      },
    },
  );

  /**
   * Clear form on view change
   */
  useEffect(() => {
    form.resetFields();
  }, [isLogin])

  return (
    <LayoutView type={type} setType={setType} loading={mutation.isLoading}>
      <Form form={form} onFinish={mutation.mutate} autoComplete={isLogin ? "on" : "off"}>
        {isSuccess ? (
          <>
            {isLogin ? (
              <Login enabled={isLogin} loading={mutation.isLoading} history={history} />
            ) : (
              <Registration enabled={isLogin} loading={mutation.isLoading} />
            )}
          </>
        ) : (
          <Loader />
        )}
      </Form>
    </LayoutView>
  );
};

export default Auth;
