/**
 * @file Contains two factor auth util.
 */

import links from '../routes/urls';

/**
 * Is two factor auth enabled.
 *
 * @function - isTwoFactorAuthEnabled.
 * @param {object} data - kratos browser settings response.
 * @param {boolean} isTwoFactorActive - browser settings error about two factor needed.
 * @param {boolean} isBrowserSettingsSuccess - is browser settings request success.
 */
export const isTwoFactorAuthEnabled = (data, isTwoFactorActive, isBrowserSettingsSuccess) => {
	const totpCodeNodesData  = data?.data?.ui?.nodes.filter((data) => data?.group === 'totp');
	return !(!isTwoFactorActive && isBrowserSettingsSuccess && totpCodeNodesData?.[0]?.attributes?.name !== 'totp_unlink');
};
