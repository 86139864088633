/**
 * @file
 * Contains Round number util.
 */

/**
 * Round amount value in high side.
 * @param {number} value.
 * Amount value.
 * @param {number} digits.
 * Round to digits.
 * @return {number}
 * Rounded value.
 */
export const roundNumberHigh = (value, digits = 2) => {
  return Number((Math.ceil(value * Math.pow(10, digits)) / Math.pow(10, digits)).toFixed(digits));
};

/**
 * Round amount value in low side.
 * @param {number} value.
 * Amount value.
 * @param {number} digits.
 * Round to digits.
 * @return {number}
 * Rounded value.
 */
export const roundNumberLow = (value, digits = 2) => {
  return Number(Math.floor(value * Math.pow(10, digits)) / Math.pow(10, digits));
};
