/**
 * @file
 * Contains header search bar component/
 */
import React, { useEffect } from 'react';
import { HeaderSearchBarWrapper } from './Header.style';
import SearchBarElement from '_common/components/SearchBarElement/SearchBarElement';
import FormItemElement from '_common/components/FormItem/FormItemElement';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import { Form } from 'antd';
import themeConfig from '../../../config';

const HeaderTransactionSearchBar = (props) => {
  const { t, reportStore, generalReportStore, profileStore } = props;

  /**
   * Use form object.
   */
  const [form] = Form.useForm();

  /**
   * App Theme
   */
  const { theme } = themeConfig[process.env.REACT_APP_THEME] || themeConfig.DEFAULT;

  useEffect(() => {
    reportStore.setSearchBarForm(form);
  }, []);

  /**
   * Set desired id.
   */
  const setDesiredIdHandler = () => {
    reportStore.setSearchBarForm(form);
  };

  /**
   * Search transaction by id.
   */
  const searchTransactionHandler = async (values) => {
    reportStore.setIsTableLoading(true);
    try {
      const { transactionId } = values;
      if (transactionId) {
        const profileId = profileStore.getSelectedProfileId;
        const data = {
          offset: 0,
          method: [],
          tx_type: "all",
          order_by: {},
          country: [],
          transaction_guid: transactionId,
        };
        await generalReportStore.getTransactionsReport(data, profileId);
      } else {
        const profileId = profileStore.getSelectedProfileId;
        const {
          country,
          date_from,
          date_to,
          limit,
          method,
          offset,
          order_by,
          tx_type,
        } = reportStore.getTableRequestData;
        const data = {
          country,
          date_from,
          date_to,
          limit,
          method,
          order_by,
          offset,
          tx_type,
        };
        await generalReportStore.getTransactionsReport(data, profileId);
      }
    } catch (e) {
      console.error(e);
      reportStore.setIsTableLoading(false);
    } finally {
      reportStore.setIsTableLoading(false);
    }
  };

  /**
   * Close icon handler.
   */
  const closeIconHandler = async () => {
    reportStore.setIsTableLoading(true);
    try {
      form.setFields([
        {
          name: 'transactionId',
          value: '',
        },
      ]);
      const profileId = profileStore.getSelectedProfileId;
      const {
        country,
        date_from,
        date_to,
        limit,
        method,
        offset,
        order_by,
        tx_type,
      } = reportStore.getTableRequestData;
      const data = {
        country,
        date_from,
        date_to,
        limit,
        method,
        order_by,
        offset,
        tx_type,
      };
      await generalReportStore.getTransactionsReport(data, profileId);
    } catch (e) {
      console.error(e);
    } finally {
      reportStore.setIsTableLoading(false);
    }
  }

  return (
    <HeaderSearchBarWrapper>
      <Form form={form} onFinish={searchTransactionHandler}>
        <FormItemElement name="transactionId">
          {/*<SearchBarElement*/}
          {/*  theme={theme}*/}
          {/*  maxLength={37}*/}
          {/*  onChange={() => setDesiredIdHandler()}*/}
          {/*  closeIconHandler={closeIconHandler}*/}
          {/*  placeholder={t('header.input.searchByTransactionId')}*/}
          {/*/>*/}
        </FormItemElement>
      </Form>
    </HeaderSearchBarWrapper>
  )
};

export default compose(
  withTranslation(),
  inject('reportStore', 'generalReportStore', 'profileStore')
)(observer(HeaderTransactionSearchBar));
