/**
 * @file
 * Contains App Layout styles.
 */
import styled from 'styled-components';
import { Col, Row } from 'antd';

export const MainLayoutContainer = styled.div`
  padding: 20px 10px;
  box-sizing: border-box !important;
  position: relative;
`;

export const MainContent = styled.div`
  height: 100%;
`;

export const SidebarCol = styled(Col)`
  width: 96px !important;
  max-width: 140px !important;
  min-width: 96px !important;
  padding: 13px 6px !important;
  
  @media (min-width: 1400px) {
    min-width: 120px !important;
    padding: 13px 0 !important;
    margin-left: 10px;
  }
`;

export const ContentColMain = styled(Col)`
  width: calc(100% - 96px) !important;
  padding: 0 10px !important;
  
  @media (min-width: 1400px) {
    width: calc(100% - 120px) !important;
    padding: 0 40px 0 20px !important;
  }
`;

export const MainRow = styled(Row)`
  flex-wrap: nowrap !important;
`;
