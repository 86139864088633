/**
 * @file
 * Contains MainLayout Component.
 */
import React from 'react';
import Sidebar from '_common/components/Sidebar/Sidebar';
import Header from '_common/components/Header/Header.view';
import { MainLayoutContainer, MainContent, SidebarCol, ContentColMain, MainRow } from './MainLayout.style';
import { FRESH_CHAT_ACTIVE } from '_common/constants/apiEndpoints.constant';
import FreshchatToggle from '_common/components/Freshchat/Freshchat';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';

const MainLayout = (props) => {
  const { children, profileStore } = props;
  const { identity } = profileStore.getUserData;

  return (
    <MainLayoutContainer>
      {FRESH_CHAT_ACTIVE === 'true' && (<FreshchatToggle userData={identity}/>)}
      <MainRow>
        <SidebarCol>
          <Sidebar />
        </SidebarCol>
        <ContentColMain>
          <Header />
          <MainContent>{children}</MainContent>
        </ContentColMain>
      </MainRow>
    </MainLayoutContainer>
  );
};

export default compose(
  inject('profileStore')
)(observer(MainLayout));
