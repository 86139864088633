/**
 * @file
 * Contains Auth screen action Style Component
 */
import styled from 'styled-components';
import { Button } from 'antd';

export const AuthHandlerWrapper = styled.div`
  position: fixed;
  right: ${(props) => (props.showLogin ? '0' : '40%')};
  width: 60%;
  height: 100%;
  background: url(${(props) => props.background}) center center no-repeat,
    ${(props) => props.theme.colors.backgroundDarkMain};
  background-size: cover;
  transition: all 0.5s ease-in-out;
  z-index: 100;

  &.width {
    animation: width 0.5s ease-in-out;
  }

  @keyframes width {
    0% {
      width: 60%;
    }
    50% {
      width: 100%;
    }
    100% {
      width: 60%;
    }
  }
`;

export const AuthButton = styled(Button)`
  &.ant-btn {
    color: ${(props) => props.theme.colors.extraLightGrey};
    border: 1px solid ${(props) => props.theme.colors.extraLightGrey};
    border-radius: 5px;
    background: none;
    height: auto;
    padding: 0 15px;
    text-align: center;
    min-width: 255px;
    line-height: 43px;
    font-weight: 700;
    font-size: 14px;

    &:hover {
      color: ${(props) => props.theme.colors.mainColor};
      border: 1px solid ${(props) => props.theme.colors.white};
      background: ${(props) => props.theme.colors.white};
    }
  }
`;


export const AuthSubTitle = styled.p`
  font-weight: 400;
  font-size: 24px;
  line-height: 1.4;

  @media (min-width: 1200px) {
    margin-bottom: 29px;
  }

  @media (min-width: 1400px) {
    margin-bottom: 36px;
  }
`;


export const AuthTextWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1);
  width: 100%;
  text-align: center;
  color: ${(props) => props.theme.colors.white};

  @media (min-width: 1200px) {
    min-height: 186px !important;
  }

  @media (min-width: 1400px) {
    min-height: 186px !important;
  }

  &.fade {
    animation: fade 1s ease;
  }

  @keyframes fade {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;


export const AuthTitle = styled.h3`
  color: ${(props) => props.theme.colors.white};
  font-size: 32px;
  font-weight: 700;
  line-height: 1.4;

  @media (min-width: 1200px) {
    margin-bottom: 29px;
    margin-top: 20px;
  }

  @media (min-width: 1400px) {
    margin-bottom: 29px;
    margin-top: 6px;
  }
`;


export const RegisterButton = styled(Button)`
  &.ant-btn {
    position: absolute;
    right: 10px;
    top: 8px;
    color: ${(props) => props.theme.colors.lightBlack};
    border: none;
    background: none;
    text-shadow: 0 0 0 ${(props) => props.theme.colors.darkGrey};

    &:hover {
      color: ${(props) => props.theme.colors.lightBlack};
    }
      
    ${(props) => props?.loading && `
        pointer-events: none;
    `}
  }
`;
