/**
 * @file
 * Contains Finance Styles.
 */
import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { Col, Row, Table, Select, Radio, Button, Input, Form, Tooltip } from 'antd';

export const FinanceReportTableWrapper = styled.div`
  margin-bottom: 20px;
  margin-top: 58px;

  .ant-table {
    border-radius: 10px;
    background: ${(props) => props.theme.colors.extraLightGrey};
    border: 1px solid ${(props) => props.theme.colors.black};
    color: ${(props) => props.theme.colors.black};
    
    .ant-table-content {
      tr {
        & > th:last-child,
        & > td:last-child:not(:first-child) {
          border-right: none;
        }
      }
    }
  }

  .ant-table-cell {
    background: transparent !important;
    padding: 10px;
    font-size: 12px;
    font-weight: 500;
    border-right: 1px solid ${(props) => props.theme.colors.black};
    position: relative;
    text-align: center;
  }

  .ant-table-thead {
    .ant-table-cell {
      position: relative;
      font-size: 15px;
      font-weight: 500;
      border-bottom: 1px solid ${(props) => props.theme.colors.black};
      //border-right: 0;
      
    }
  }
  
  tbody.ant-table-tbody {
    tr.ant-table-row {
      td {
        border-bottom-color: ${(props) => props.theme.colors.black};
      }
    }
  }
  
  .ant-table-summary {
    .ant-table-cell {
      padding: 16px 8px;
      border-bottom: none;
      
      &:first-of-type {
        text-align: left;
      }
      
      &:last-of-type {
        display: flex;
        justify-content: space-between;
        font-weight: 500;
        font-size: 15px;
      }
    }
  }
`;

export const TableSummaryRow = styled(Table.Summary.Row)`
  
`;

export const TableSummaryCell = styled(Table.Summary.Cell)`
`;

export const TableSummaryTransCell = styled(TableSummaryCell)`
`;

export const FinanceContainer = styled.div``;

export const FinanceTypeTableContainer = styled.div``;

export const FinanceFiltersContainer = styled.div`
  margin-bottom: 16px;
`;

export const FinanceFiltersRow = styled(Row)`
  align-items: center;
`;

export const FinanceTableWrapper = styled.div`
  .ant-table-cell {
    padding: 8px;
    background: none;
  }

  .ant-table-thead {
    tr {
      th {
        background: ${(props) => props.theme.colors.darkLight};
        height: 48px;
        color: ${(props) => props.theme.colors.white};
        text-align: center;

        & > div {
          padding: 10px;
          height: 30px;
          display: flex;
          justify-content: center;

          .ant-table-column-sorters {
            color: ${(props) => props.theme.colors.white};
            font-size: 12px;
            width: 100%;
            padding: 0;
            display: flex;
            justify-content: center;
          }
        }

        &:hover {
          background: ${(props) => props.theme.colors.darkLight} !important;
        }
      }
    }
  }

  tbody.ant-table-tbody {
    margin-top: 11px;

    tr:nth-child(2n) {
      background: ${(props) => props.theme.colors.white};
    }

    tr {
      background: ${(props) => props.theme.colors.silverLight};
      cursor: pointer;

      &:nth-child(2n) {
        //box-shadow: ${(props) => props.theme.shadow.box};

        td {
          &.ant-table-cell-fix-left {
            background: ${(props) => props.theme.colors.white};
          }
        }
      }

      td {
        height: 40px;
        text-align: center;
        font-size: 12px;

        &.ant-table-cell-fix-left {
          background: ${(props) => props.theme.colors.silverLight};
        }
      }
    }
  }

  .ant-table-footer,
  .ant-table-summary {
    background: ${(props) => props.theme.colors.darkLight};

    .ant-table-cell-fix-left {
      background: ${(props) => props.theme.colors.darkLight};
    }
  }
`;

export const FinanceReportWrapper = styled.div``;

export const FinanceModalsWrapper = styled.div``;

export const FinanceReportGraphicWrapper = styled.div`
  padding: 25px 35px;
  border-radius: 10px;
  //box-shadow: ${(props) => props.theme.shadow.box};
`;

export const FinanceReportGraphicTitleWrapper = styled.div`
  margin-bottom: 20px;
`;

export const FinanceReportGraphicTitle = styled.h3`
  font-weight: 700;
  font-size: 14px;
`;

export const ModalStep = styled.div`
  visibility: ${(props) => props.step === props.currentStep ? 'visible' : 'hidden'};
  transform: ${(props) => props.step === props.currentStep ? 'scale(1)' : 'scale(0)'};
  position: ${(props) => props.step === props.currentStep ? 'relative' : 'absolute'};
  left: ${(props) => props.step === props.currentStep ? '0' : '-100%'};
`;

export const AddBankAccountBtn = styled.div`
  text-align: right;
  margin: 20px 0 60px;
  position: absolute;
  bottom: 20px;
  right: 20px;
`;

export const AddBankAccount = styled.p`
  color: ${(props) => props.theme.colors.mainColor};
  border-bottom: 1px solid ${(props) => props.theme.colors.mainColor};
  display: inline;
  cursor: pointer;
`;

export const ModalInputWrapper = styled.div`
  border: ${(props) => props.error ? '1px solid red' : 'none'};
  margin-bottom: 20px;
  border-radius: 10px;
`;

export const ModalInput = styled(Input)`
  &.ant-input {
    background: ${(props) => props.theme.colors.darkWhite};
    color: ${(props) => props.color === 'blue' ? props.theme.colors.mainColor : props.theme.colors.darkLight};
    font-weight: 700;
    border: none;
    border-radius: 10px;
    padding: 17px 20px;
    border: ${(props) => props.error ? '1px solid red' : 'none'};
    transition: color 1.5s ease;
    box-shadow: none;
    margin-bottom: 0;
    font-size: 16px;

    @media (min-width: 1400px) {
      height: 60px;
    }

    &:focus {
      border: none;
    }

    &:hover {
      border: none;
    }
  }

  &.ant-input:placeholder-shown {
    font-weight: 200;
    font-size: 16px;
  }
`;

export const ModalFormItem = styled(Form.Item)`
  &.ant-form-item {
    margin-bottom: 0;
  }

  .ant-form-item-label > label {
    font-weight: ${(props) => props.type === 'bank' ? '300' : '700'};
    color: ${(props) => props.type === 'bank' ? props.theme.colors.darkLight : props.theme.colors.mainColor}};
}
`;

export const ModalDownloadButtonWrapper = styled.div`
  text-align: center;
`;

export const FinanceTransferWrapper = styled.div`
  border: 1px dashed ${(props) => props.theme.colors.darkGrey};
  padding: 33px;
  position: relative;
  margin-bottom: 40px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const FinanceTransferTitle = styled.div`
  background: ${(props) => props.theme.colors.darkWhite};
  position: absolute;
  left: 20px;
  top: -17px;
  padding: 5px 12px;
  font-weight: 700;
`;

export const FinanceTransferData = styled.div`
  color: ${(props) => props.theme.colors.mainColor};
  font-weight: 700;
  font-style: italic;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const ModalButtonsWrapper = styled.div`
  display: flex;
  justify-content:space-between;
  align-items: center;
`;

export const ModalPrevWrapper = styled.div`
  color: ${(props) => props.theme.colors.mainColor};
  font-weight: 700;
  display: flex;
  align-items: center;
  cursor: pointer;

  span {
    margin-right: 10px;
  }
`;

export const FinalStepTitle = styled.div`
  color: ${(props) => props.theme.colors.mainColor};
  font-weight: 700;
  text-align: center;
  margin-bottom: 10px;
  font-size: 18px;
  margin-top: 80px;
`;

export const FinalStepSubTitle = styled.div`
  text-align: center;
  margin-bottom: 20px;
  font-size: 18px;
`;

export const ModalAvailableAmount = styled.div`
  color: ${(props) => props.theme.colors.darkGrey};
  text-align: right;
  margin-bottom: 20px;

  span {
    font-weight: 700;
    color: ${(props) => props.error ? 'red' : props.theme.colors.darkLight};
    font-size: 18px;
  }
`;

export const ModalExchangeWrapper = styled.div`
  display: flex;
  justify-content: space-around;
`;

export const ExchangeIconWrapper = styled.div``;

export const ExchangeRateWrapper = styled.div`
  margin-bottom: 20px;

  span {
    font-weight: 700;
    color: ${(props) => props.theme.colors.mainColor};
    vertical-align: middle;
  }
`;

export const ConvertedInput = styled(Input)`
  &.ant-input {
    background: ${(props) => props.theme.colors.darkGrey};
    color: ${(props) => props.theme.colors.darkWhite} !important;
    font-weight: 700;
    border: none;
    border-radius: 10px;
    padding: 17px 20px;
    margin-bottom: 20px;
  }

  &.ant-input:placeholder-shown {
    font-weight: 200;
    color: ${(props) => props.theme.colors.darkWhite} !important;
  }
`;

export const ModalTitleWrapper = styled.div``;

export const ModalTitle = styled.h3`
  font-weight: 700;
  border-bottom: 1px solid ${(props) => props.theme.colors.silverLight};
  padding-bottom: 5px;
  font-size: 24px;
`;

export const ModalSubTitle = styled.p`
  margin-top: 5px;
  font-size: 16px;
  color: ${(props) => props.theme.colors.darkLight};
`;

export const ModalElementWrapper = styled.div``;

export const ModalSelect = styled(Select)`
  width: 100%;
  padding: 17px 20px;
  background: ${(props) => props.theme.colors.darkWhite};
  border-radius: 10px;
  margin-bottom: 20px !important;
  height: 60px;

  &.ant-select {
    width: 100%;
  }

  &.ant-select-selector {
    border: none;
  }

  &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
  .ant-select-selector {
    padding: 14px;
    font-size: 16px;
    height: 50px;
    box-sizing: border-box;
    background: none;
    outline: none;
    border: none;

    @media (min-width:1440px) {
      height: 55px;
    }

    ${props => props.icon && css`
      padding: 14px 14px 14px 30px;
    `};

    .ant-select-selection-placeholder {
      opacity: 1;
      line-height: 26px;
      font-weight: 300;
    }
  }

  &.ant-select-open {
    outline: transparent !important;

    .ant-select-arrow {
      border-color: ${(props) => props.theme.colors.dark};
      border-style: solid;
      border-width: 2px 0 0 2px;
      height: 8px;
      width: 8px;
      transform: rotate(45deg);

      @media (min-width: 1440px) {
        width: 10px;
        height: 10px;
      }
    }
  }

  .ant-select-arrow {
    border-color: ${(props) => props.theme.colors.dark};
    border-style: solid;
    border-width: 2px 0 0 2px;
    height: 8px;
    width: 8px;
    transform: rotate(-135deg);
    transition: transform 0.2s ease;
    margin-right: 0;

    @media (min-width: 1440px) {
      width: 10px;
      height: 10px;
    }

    span {
      display: none;
    }
  }

  &.ant-select-single .ant-select-selector {
    .ant-select-selection-item,
    .ant-select-selection-placeholder {
      line-height: 26px;
      padding: 0;
      color: ${(props) => props.theme.colors.dark};
      font-weight: 700;
      font-size: 16px;
    }
    .ant-select-selection-search {
      left: 0;
      right: 0;
    }
  }

  .ant-select-selection-search-input {
    padding: 0 21px;
  }

  .ant-select-dropdown {
    padding: 40px 0;
  }

  .ant-select-selection-item {
    svg {
      display: none;
    }
  }

  &.ant-select-multiple {
    .ant-select-selector {
      padding-left: 0;
    }

    .ant-select-selection-placeholder {
      padding-left: 30px;
      color: ${(props) => props.theme.colors.mainColor};
      font-weight: 700;
      font-size: 12px;

      @media (min-width: 1440px) {
        font-size: 16px;
      }
    }
  }
`;

export const ModalSelectOption = styled(Select.Option)``;

export const ModalRadioWrapper = styled.div`
  background: ${(props) => props.theme.colors.darkWhite};
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
  height: ${(props) => props.height ? `${props.height}px` : 'auto'};
`;

export const RadioButton = styled(Radio)`
  .ant-radio-checked .ant-radio-inner {
    border-color: ${(props) => props.theme.colors.mainColor};
  }

  .ant-radio-inner::after {
    background-color: ${(props) => props.theme.colors.mainColor};
  }
`;

export const RadioButtonDescrWrapper = styled.div``;

export const RadioButtonDescr = styled.p`
  margin-left: 24px;
  margin-bottom: 0;
  color: ${(props) => props.theme.colors.darkGrey};
`;


export const ButtonWrapper = styled.div`
  text-align: ${(props) => props.align};
  font-size: 16px;

  span {
    margin-left: 80px;
  }
`;

export const ModalButtonWrapper = styled.div`
  display: inline-block;
  position: relative;
  cursor: pointer;
  margin-left: auto;
`;

export const ModalButtonIconWrapper = styled.div`
  content: '';
  position: absolute;
  top: calc(50% + 3px);
  left: 18px;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  background: url(${(props) => props.icon}) center center no-repeat;
  background-size: cover;
  z-index: 9;
`;

export const ModalButtonIcon = styled.div`
  content: '';
  position: absolute;
  top: calc(50% + 4px);
  left: 18px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  background: url() center center no-repeat;
  background-size: cover;
  z-index: 9;
`;

export const ModalButton = styled(Button)`
  &.ant-btn {
    color: ${(props) => props.theme.colors.grey};
    display: flex !important;
    align-items: center;
    border-radius: 10px !important;
    //box-shadow: ${(props) => props.theme.shadow.button} !important;
    padding: 10px 34px !important;
    margin: ${(props) => props.margin};
    position: relative;
    font-size: 16px;
    width: ${(props) => props.width ? `${props.width}px` : 'auto'};
    height: 40px;

    &:hover {
      background: ${(props) => props.theme.colors.mainColor};
      color: ${(props) => props.theme.colors.white};
    }
  }

  &.ant-btn > span {
    margin-left: ${(props) => props.marginspan ? `${props.marginspan}px` : '0'};
  }

  &.ant-btn, &.ant-btn:active {
    background: ${(props) => props.theme.colors.mainColor};
    color: ${(props) => props.theme.colors.white};
  }
`;

export const FinanceModalLinkWrapper = styled.div``;

export const FinanceModalLink = styled(Link)`
  display: block;
  vertical-align: middle;
  border-radius: 10px;
  text-align: center;
  padding: 10px;
  border: 1px solid ${(props) => props.theme.colors.mainColor};
  color: ${(props) => props.theme.colors.mainColor};
  font-weight: 700;
  transition: all 0.5s ease;

  &:hover {
    color: ${(props) => props.theme.colors.white};
    border: 1px solid ${(props) => props.theme.colors.mainColor};
    background: ${(props) => props.theme.colors.dark};
  }
`;

export const FinanceTableReport= styled.div`
  border-radius: 10px;
  background: ${(props) => props.theme.colors.dark};
  color: ${(props) => props.theme.colors.white};
  text-align: center;
`;

export const FinanceTableHeaderRow = styled(Row)`
  border-bottom: 2px solid ${(props) => props.theme.colors.white};
`;

export const FinanceTableHeaderCol = styled(Col)`
  padding: 14px;
  position: relative;
  font-size: 12px;

  &:last-child {
    &:after {
      content: '';
      display: none;
    }
  }

  &:after {
    content: '';
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    height: 10px;
    width: 2px;
    background: ${(props) => props.theme.colors.white};
  }
`;

export const FinanceTableFooterRow = styled(Row)``;

export const FinanceTableFooterCol = styled(Col)`
  padding: 16px;
  font-size: 12px;

  &:first-child {
    text-align: left;
    text-transform: uppercase;
  }

  &:last-child {
    text-align: right;
  }
`;

export const FinanceTableIconWrapper = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 4px;
  width: 36px;
  height: 30px;

  svg {
    cursor: pointer;
  }
`;

export const SummaryAmountWrap = styled.div`
`;

export const FinanceTableTooltip = styled(Tooltip)``;
