import React from 'react';
import { ResponsiveContainer } from './CurvedGraphic.style';

const GraphicContainer = (props) => {
  const { children } = props;
  return (
    <ResponsiveContainer height={200} width="100%">
      {children}
    </ResponsiveContainer>
  );
};

export default GraphicContainer;
