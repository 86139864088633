/**
 * @file
 * Contains Loader component.
 */
import React from 'react';
import { Spin } from './Loader.style';

const Loader = (props) => {
  const { color, spinning, ...rest } = props;

  return <Spin spinning={spinning} color={color} {...rest} />;
};

export default Loader;
