/**
 * @file
 * Contains Volumes by method icon.
 */
import React from 'react';

const VolumesByMethodIcon = (props) => {
  const { color } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
      <path
        d="M0 10.2544C0 9.70211 0.447715 9.25439 1 9.25439H2.08479C2.63708 9.25439 3.0848 9.70211 3.0848 10.2544V13.9708C3.0848 14.5231 2.63708 14.9708 2.0848 14.9708H1C0.447716 14.9708 0 14.5231 0 13.9708V10.2544Z"
        fill={color}/>
      <path
        d="M3.97661 7.16955C3.97661 6.61727 4.42432 6.16956 4.97661 6.16956H6.0614C6.61369 6.16956 7.0614 6.61727 7.0614 7.16956V13.9707C7.0614 14.523 6.61369 14.9707 6.0614 14.9707H4.97661C4.42432 14.9707 3.97661 14.523 3.97661 13.9707V7.16955Z"
        fill={color}/>
      <path
        d="M7.95322 4.08484C7.95322 3.53255 8.40093 3.08484 8.95322 3.08484H10.038C10.5903 3.08484 11.038 3.53255 11.038 4.08484V13.9708C11.038 14.5231 10.5903 14.9708 10.038 14.9708H8.95322C8.40093 14.9708 7.95322 14.5231 7.95322 13.9708V4.08484Z"
        fill={color}/>
      <path
        d="M11.9152 0.999999C11.9152 0.447715 12.3629 0 12.9152 0H14C14.5523 0 15 0.447715 15 1V13.9708C15 14.523 14.5523 14.9708 14 14.9708H12.9152C12.3629 14.9708 11.9152 14.523 11.9152 13.9708V0.999999Z"
        fill={color}/>
    </svg>)
};

export default VolumesByMethodIcon;
