/**
 * @file Contains Refund success component.
 */

import React, { useState, useEffect } from 'react';
import RefundSuccessIcon from '_common/assets/renderSvgIcons/RefundSuccess.icon';
import {
	RefundSuccessContainer,
	RefundSuccessIconWrapper,
	RefundSuccessTitle,
	RefundSuccessSubtitle,
	RefundSuccessDescription,
	RefundSuccessTransactionInfo,
	RefundSuccessTransactionInfoWrapper,
	RefundSuccessTransactionInfoTitle,
	RefundSuccessTransactionInfoValue,
	RefundSuccessButtonContainer,
	RefundIdContainer,
} from './Reports.style';
import ButtonElement from '_common/components/Button/ButtonElement';
import CopyToClipboardIcon from '_common/assets/renderSvgIcons/CopyToClipboard.icon';
import TooltipElement from '_common/components/Tooltip/TooltipElement';
import { roundNumberHigh, roundNumberLow } from '_common/utils/roundNumber.util';
import WarningIcon from '_common/assets/renderSvgIcons/Warning.icon';

const RefundSuccess = (props) => {
	const {
		selectedTransactionInfo,
		closeModal,
		refundId,
		transactionRefundFeeData,
		amount,
		t
	} = props;

	/**
	 * Is copy to clipboard active.
	 */
	const [isCopyToClipboardActive, setIsCopyToClipboardActive] = useState(false);

	/**
	 * Refund fee
	 */
	const [refundFee, setRefundFee] = useState(0);

	/**
	 * Refund amount
	 */
	const [refundAmount, setRefundAmount] = useState(0);

	/**
	 * Set refund fee and refund amount.
	 */
	useEffect(() => {

		const roundedRefundFee = roundNumberHigh(selectedTransactionInfo?.refund_fee);
		setRefundFee(roundedRefundFee);

		const roundedRefundAmount = roundNumberLow(selectedTransactionInfo?.refund_amount);
		setRefundAmount(roundedRefundAmount);
	}, [selectedTransactionInfo]);

	/**
	 * Copy to clipboard
	 * @function - copyToClipboard.
	 * @param {string} text - text to copy to clipboard
	 */
	const copyToClipboard = (text) => {
		navigator.clipboard.writeText(text);
		setIsCopyToClipboardActive(true);

		setTimeout(() => {
			setIsCopyToClipboardActive(false);
		}, 1000);
	};

	return (
		<RefundSuccessContainer>
			<RefundSuccessIconWrapper>
				<RefundSuccessIcon />
			</RefundSuccessIconWrapper>
			<RefundSuccessTitle>
				{t('transaction.modal.refund.success.refundInitiated')}
			</RefundSuccessTitle>
			<RefundSuccessSubtitle>
				{t('transaction.modal.refund.success.processingMayTakeSomeTime')}
			</RefundSuccessSubtitle>
			<RefundSuccessDescription>
				{t('transaction.modal.refund.success.toCheckStatus')}
			</RefundSuccessDescription>
			<RefundSuccessTransactionInfo>
				{!!refundId && (
					<RefundSuccessTransactionInfoWrapper>
						<RefundSuccessTransactionInfoTitle width={25}>
							{t('transaction.modal.refund.success.refundId')}
						</RefundSuccessTransactionInfoTitle>
						<RefundSuccessTransactionInfoValue width={75}>
							<RefundIdContainer>
								{refundId}
							</RefundIdContainer>
							<div>
								<TooltipElement visible={isCopyToClipboardActive} placement="top" title={`Copied`}>
									<div onClick={() => copyToClipboard(refundId)}>
										<CopyToClipboardIcon />
									</div>
								</TooltipElement>
							</div>
						</RefundSuccessTransactionInfoValue>
					</RefundSuccessTransactionInfoWrapper>
				)}
				<RefundSuccessTransactionInfoWrapper>
					<RefundSuccessTransactionInfoTitle>
						<div>
							{t('transaction.modal.refund.success.refundFee')}
						</div>
						<TooltipElement
							overlayClassName={'refund-amount'}
							title={t('transaction.modal.refund.success.tooltip.deductedFromMerchantBalance')}
						>
							<div>
								<WarningIcon />
							</div>
						</TooltipElement>
					</RefundSuccessTransactionInfoTitle>
					<RefundSuccessTransactionInfoValue>
						{Number.isInteger(transactionRefundFeeData?.contract_fee) ? `${transactionRefundFeeData?.contract_fee}.00` : transactionRefundFeeData?.contract_fee} {(selectedTransactionInfo.credited_currency || ':').split(':')[1]}
					</RefundSuccessTransactionInfoValue>
				</RefundSuccessTransactionInfoWrapper>
				{!!amount && (
					<RefundSuccessTransactionInfoWrapper>
						<RefundSuccessTransactionInfoTitle>
							<div>
								{t('transaction.modal.refund.success.refundAmount')}
							</div>
							<TooltipElement
								overlayClassName={'refund-amount'}
								title={t('transaction.modal.refund.success.tooltip.amountCustomerReceives')}
							>
								<div>
									<WarningIcon />
								</div>
							</TooltipElement>
						</RefundSuccessTransactionInfoTitle>
						<RefundSuccessTransactionInfoValue fontWeight={700}>
							{Number.isInteger(amount) ? `${amount}.00` : (amount.toString().split('.')[1].length === 1 ? `${amount}0` : amount)} {(selectedTransactionInfo.credited_currency || ':').split(':')[1]}
						</RefundSuccessTransactionInfoValue>
					</RefundSuccessTransactionInfoWrapper>
				)}
			</RefundSuccessTransactionInfo>
			<RefundSuccessButtonContainer>
				<ButtonElement
					clear={true}
					height={50}
					onClick={() => closeModal()}
				>
					{t('transaction.modal.refund.success.close')}
				</ButtonElement>
			</RefundSuccessButtonContainer>
		</RefundSuccessContainer>
	)
};

export default RefundSuccess;
