import React, { useEffect } from 'react';
import { Col, Row } from 'antd';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import Table from '_common/components/TableElement/TableElement';
import { TRANSACTIONS_VIEW_MODE } from '_common/constants/reports.constant';
import { DownloadCsvLink, Notes, TableTop } from './Reports.style';
import { ReactComponent as QuestionMarkCircled } from '_common/assets/icons/question-mark-circled.svg';
import TooltipElement from '_common/components/Tooltip/TooltipElement';
import DownloadIcon from '_common/assets/renderSvgIcons/DownloadIcon';
import themeConfig from 'config';
import Utils from '_common/utils';

const CsvReportsTable = (props) => {
  const { generalReportStore, t, reportStore, profileStore } = props;

  const { theme } = themeConfig[process.env.REACT_APP_THEME] || themeConfig.DEFAULT;

  const { timeFormatByTimestamp } = Utils;

  useEffect(() => {
    if (reportStore.getTableType === TRANSACTIONS_VIEW_MODE.reports) {
      (async () => {
        try {
          generalReportStore.setIsLoading(true);
	        const latestReportStatus = await getLatestReportStatus();

					if (latestReportStatus && latestReportStatus === 'in progress') {
						getLatestReportSuccessStatus();
					}
        } catch (e) {
          console.error(e);
        } finally {
          generalReportStore.setIsLoading(false);
        }
      })();
    }
  }, [reportStore.getTableType, profileStore.getSelectedProfileId]);

	/**
	 * Get latest report status.
	 */
	const getLatestReportStatus = async () => {
		try {
			const profileId = profileStore.getSelectedProfileId;
			const csvReportsDataList = await generalReportStore.getLoadCsvReportsList(profileId);
			const latestReport = csvReportsDataList.reduce((acc, curr) => acc.created_at > curr.created_at ? acc : curr);
			return latestReport.state;
		} catch (e) {
			throw new Error(e);
		}
	};

	/**
	 * Get latest report success status.
	 */
	const getLatestReportSuccessStatus = () => {
		const getReportsList = async () => {
			try {
				const latestReportStatus = await getLatestReportStatus();
				if (latestReportStatus && latestReportStatus === 'done') {
					clearInterval(intervalId);
				}
			} catch (e) {
				throw new Error(e);
			}
		};

		const intervalId = setInterval( async () => {
			await getReportsList();
		}, 4000);

		return () => clearInterval(intervalId);
	};

  /**
   * Download csv handler
   * @param {string} guid
   */
  const downloadCsvHandler = async (guid) => {
    const profileId = profileStore.getSelectedProfileId;
    try {
      await generalReportStore.getLoadCsvReport(profileId, guid);
    } catch (e) {

    }
  };

  /**
   * Status output render
   */
  const statusOutputRender = (record) => {
    const { state, guid} = record
    if(state === 'done') {
      return (<DownloadCsvLink onClick={() => downloadCsvHandler(guid)}><DownloadIcon
        color={theme.colors.mainColor} /> {t('transaction.table.registry.download')}</DownloadCsvLink>)
    }
    return t(state === 'in progress' ? 'reports.report.state.progress' : 'reports.report.state.error');
  }

  /**
   * Table columns
   */
  const tableColumns = [
    {
      title: t('reports.table.table.creation.date'),
      dataIndex: 'created_at',
      key: 'guid',
      width: '150px',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a?.created_at - b?.created_at,
      render: (_, record) => (<span>
        <div>{timeFormatByTimestamp(record.created_at, 'DD/MM/YYYY')}</div>
        <div>{timeFormatByTimestamp(record.created_at, 'HH:mm:ss')}</div>
      </span>),
    },
    {
      title: t('reports.table.table.name'),
      dataIndex: 'name',
      key: 'name',
      align: 'left',
      className: 'name-column',
      render: (_, record) => (<span>{record?.csv_name}</span>),
    },
    {
      dataIndex: 'label',
      key: 'label',
      width: '20%',
      render: (_, record) => (<span>{statusOutputRender(record)}</span>),
    },
  ];

  /**
   * Calculate x scroll.
   */
  const calculateXscroll = () => {
    return tableColumns.reduce((acc, value) => {
      if (value.width) {
        return acc + value.width;
      }

      return acc;
    }, 0);
  };

  return (
    <>
      <TableTop>
        <Notes>{t('reports.table.note')}</Notes>
        <TooltipElement trigger='hover' placement='topLeft' title={t('reports.table.top.tooltip')}><QuestionMarkCircled/></TooltipElement>
      </TableTop>
      <Row>
        <Col xs={24}>
          <Table
            table-layout='fixed'
            rowKey='guid'
            columns={
              tableColumns
            }
            dataSource={generalReportStore.getCsvReportsList}
            scroll={{ x: calculateXscroll() }}
            loading={generalReportStore.getIsLoading}
            className={`main-table tab-${reportStore.getTableType} csv-list`}
            rowClassName={(_, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark'}
            pagination={false}
          />
        </Col>
      </Row>
    </>
  );
};

export default compose(
  withTranslation(),
  inject('generalReportStore', 'reportStore', 'profileStore'),
)(observer(CsvReportsTable));
