/**
 * @file
 * Contains currency icon.
 */
import React from 'react';

const CurrencyIcon = (props) => {
  const { color } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="16" viewBox="0 0 15 16" fill="none">
      <path
        d="M6.58438 5.19086C6.66083 4.36006 6.95549 3.59291 7.41059 2.94422C7.04047 1.26261 5.53998 0 3.74943 0C1.68227 0 0 1.68227 0 3.75008C0 5.54949 1.27442 7.05589 2.96817 7.41649C3.8203 6.25528 5.10489 5.43465 6.58438 5.19086ZM2.24959 4.64257C2.24959 4.50607 2.35426 4.40074 2.49109 4.40074H3.26053V4.22093L3.1552 4.04112H2.49076C2.35393 4.04112 2.24926 3.9358 2.24926 3.7993C2.24926 3.6628 2.35393 3.55748 2.49076 3.55748H2.86908L2.05075 2.21218C2.00744 2.1377 1.96413 2.04484 1.96413 1.95789C1.96413 1.61697 2.28043 1.50541 2.49699 1.50541C2.75752 1.50541 2.87531 1.71016 2.91895 1.79678L3.74975 3.37799L4.5799 1.79678C4.62419 1.71016 4.74133 1.50541 5.0012 1.50541C5.21842 1.50541 5.5344 1.6173 5.5344 1.95789C5.5344 2.04484 5.49109 2.1377 5.4481 2.21186L4.62944 3.55748H5.00744C5.14361 3.55748 5.24893 3.6628 5.24893 3.7993C5.24893 3.9358 5.14361 4.04112 5.00744 4.04112H4.34431L4.23898 4.22093V4.40074H5.00777C5.14394 4.40074 5.24926 4.50607 5.24926 4.64257C5.24926 4.77907 5.14394 4.88439 5.00777 4.88439H4.23898V5.49798C4.23898 5.79558 4.05884 5.99409 3.74975 5.99409C3.43968 5.99409 3.2602 5.79558 3.2602 5.49798V4.88439H2.49076C2.35393 4.88439 2.24959 4.77907 2.24959 4.64257Z"
        fill={color}/>
      <path
        d="M11.2498 1.87488C9.35685 1.87488 7.79106 3.28645 7.53906 5.11145C8.25469 5.1167 8.93652 5.25812 9.5652 5.50749C9.60687 5.41168 9.69284 5.35164 9.82671 5.35164H9.95665C9.82671 5.16559 9.77093 4.93 9.77093 4.68818C9.77093 3.91972 10.4528 3.38029 11.3269 3.38029C12.413 3.38029 12.9393 3.96303 12.9393 4.44668C12.9393 4.72591 12.747 4.88078 12.474 4.88078C11.9286 4.88078 12.263 4.12447 11.4263 4.12447C11.0608 4.12447 10.7136 4.34168 10.7136 4.74429C10.7136 4.95527 10.8186 5.16592 10.918 5.35196H11.594C11.8479 5.35196 11.9785 5.44515 11.9785 5.64957C11.9785 5.85399 11.8483 5.94685 11.594 5.94685H11.1038C11.1228 5.99672 11.1356 6.04003 11.1356 6.09581C11.1356 6.19589 11.1106 6.29498 11.0719 6.39178C11.2747 6.55912 11.4617 6.74483 11.6392 6.93875C11.8112 6.97353 11.9644 7.01356 12.1462 7.01356C12.2509 7.01356 12.5065 6.94531 12.6046 6.94531C12.8336 6.94531 12.9642 7.11889 12.9642 7.34168C12.9642 7.69146 12.6512 7.84338 12.3286 7.86438C12.5426 8.22367 12.7207 8.6056 12.8523 9.00952C14.1192 8.40742 14.9999 7.11856 14.9999 5.6243C14.9992 3.55715 13.3169 1.87488 11.2498 1.87488Z"
        fill={color}/>
      <path
        d="M7.74792 11.1815V12.7158C8.21221 12.685 8.70111 12.4681 8.70111 11.9565C8.70111 11.4292 8.16529 11.2744 7.74792 11.1815Z"
        fill={color}/>
      <path
        d="M6.41595 9.34441C6.41595 9.73258 6.70306 9.95636 7.28383 10.0732V8.68555C6.75621 8.70162 6.41595 9.01104 6.41595 9.34441Z"
        fill={color}/>
      <path
        d="M7.49956 6.04675C4.91495 6.04675 2.81171 8.14967 2.81171 10.7343C2.81171 13.3189 4.91495 15.4215 7.49956 15.4215C10.0842 15.4215 12.1877 13.3192 12.1877 10.7343C12.1877 8.14934 10.0842 6.04675 7.49956 6.04675ZM7.74794 13.5059V13.9866C7.74794 14.1185 7.64622 14.2501 7.51465 14.2501C7.38307 14.2501 7.28234 14.1188 7.28234 13.9866V13.5059C5.97314 13.4751 5.3215 12.6922 5.3215 12.0792C5.3215 11.7701 5.50721 11.5916 5.80186 11.5916C6.66974 11.5916 5.99513 12.6613 7.28234 12.7155V11.0955C6.13458 10.8865 5.43831 10.3829 5.43831 9.5222C5.43831 8.46861 6.31373 7.9259 7.28234 7.89505V7.48228C7.28234 7.35004 7.38307 7.21847 7.51465 7.21847C7.64622 7.21847 7.74794 7.35004 7.74794 7.48228V7.89505C8.35135 7.91113 9.59132 8.28978 9.59132 9.05003C9.59132 9.35223 9.36623 9.52975 9.10308 9.52975C8.59941 9.52975 8.6063 8.70157 7.74827 8.68549V10.1578C8.77135 10.375 9.67729 10.6769 9.67729 11.8712C9.67729 12.9094 8.90161 13.4367 7.74794 13.5059Z"
        fill={color}/>
    </svg>
  )
};

export default CurrencyIcon;
