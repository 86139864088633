/**
 * @file
 * Contains Kyc Styles.
 */
import styled, { css } from 'styled-components';
import { Input, Form, Button, Col, Row, Upload } from 'antd';

export const KycContainer = styled.div`
  background: url(${(props) => props.background}) center center no-repeat, ${(props) => props.theme.colors.backgroundMain};
  background-size: cover;
  min-height: 100vh;
  position: relative;
`;

export const KycContentWrapper = styled.div`
  width: 400px;
  margin: 0 auto;
  text-align: center;
  padding: 80px 0;
  
  @media (min-width:1440px) {
    padding: 120px 0;
  }
`;

export const KycTitle = styled.div`
  color: ${(props) => props.theme.colors.white};
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 20px;
`;

export const KycSubTitle = styled.div`
  color: ${(props) => props.theme.colors.darkWhite};
  font-size: 16px;
`;

export const KycInput = styled(Input)`
  &.ant-input:placeholder-shown {
    border-radius: 5px;
    padding: 6px 10px;
  }
`;

export const KycFormItem = styled(Form.Item)`
  .ant-form-item-label > label {
    color: ${(props) => props.theme.colors.white};
    font-size: 14px;
    font-weight: 700;
  }
`;

export const KycButton = styled(Button)`
  &.ant-btn {
    background: none;
    width: 297px;
    border-radius: 50px;
    padding: 20px 80px;
    height: auto;
    font-size: 18px;
    color: ${(props) => props.theme.colors.white};
    text-transform: uppercase;
    font-weight: 700;
    
    &:hover {
      color: ${(props) => props.theme.colors.mainColor};
      border: 1px solid ${(props) => props.theme.colors.mainColor};
    }
  }
`;

export const KycFooter = styled.div`
  position: absolute;
  color: ${(props) => props.theme.colors.white};
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  width: 1200px;
  text-align: left;
`;

export const KycFooterButtonsWrapper = styled.div`
  padding: 10px 0;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: ${(props) => props.theme.colors.white};
  }
`;

export const KycFooterButton = styled(Button)`
  &.ant-btn {
    background: none;
    border: none;
    color: ${(props) => props.theme.colors.white};
    display: flex;
    align-items: center;
    padding: 0;
    
    span {
      margin-left: 10px;
    }
  }
`;

export const KycFooterDescription = styled.div`
  padding: 20px 0 30px;
  color: ${(props) => props.theme.colors.darkWhite};
  font-size: 14px;
`;

export const KycLevelsContainer = styled.div``;

export const KycLevelsCol = styled(Col)`
  min-height: 100vh !important;
  padding: 78px 47px;
`;

export const KycContentCol = styled(Col)`
  background: ${(props) => props.theme.colors.backgroundMain};
  min-height: 100vh !important;
  padding: 78px 47px;
`;

export const KycLevelsTitle = styled.h3`
  font-size: 24px;
  font-weight: 900;
  margin-bottom: 14px;
`;

export const KycLevelsSubTitle = styled.p`
  color: ${(props) => props.theme.colors.darkGrey};
  margin-bottom: 38px;
`;

export const KycLevelsStepsWrapper = styled.div`
  position: relative;
  overflow: hidden;
`;

export const KycLevelsStep = styled.div`
  margin-bottom: 50px;
  z-index: 9;
  position: relative;
  height: 30px;
  display: flex;
  align-items: center;
  font-weight: ${(props) => props.currentLevel >= props.level ? '700' : '300'};
  color: ${(props) => props.currentLevel >= props.level ? props.theme.colors.dark : props.theme.colors.grey};
  
  &:before {
    content: '';
    position: absolute;
    top: 20px;
    left: 15px;
    width: 1px;
    height: ${(props) => props.currentLevel >= props.level ? 'calc(100% + 50px)' : '0'};
    border: 1px dashed ${(props) => props.theme.colors.mainColor};
    transition: height .5s ease;
  }
  
  &:last-child {
    margin-bottom: 4px;
    
    &:before {
      content: '';
      display: none;
    }
  }
  
  span {
    margin-left: 50px;
  }
`;

export const KycStepNumber = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: ${(props) => props.currentLevel >= props.level ? props.theme.colors.mainColor : props.theme.colors.white};
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  transition: all .5s ease;
  
  ${(props) => props.currentLevel >= props.level ? `
    &:before {
      content: '';
      position: absolute;
      top: 10px;
      left: 10px;
      width: 10px;
      height: 10px;
      background: url(${props.icon}) center center no-repeat;
      background-size: cover;
      transform: rotateY(0deg);
      transition: all 1.5s ease;
    }
  ` : `
    &:before {
      display: none;
      transform: rotateY(90deg);
      transition: all 1.5s ease;
    }
  `}
  
  
`;

export const KycContentHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const KycContentHeaderDescription = styled.div`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.white};
  
  svg {
    margin-right: 10px;
  }
`;

export const KycContentHeaderButtonWrapper = styled.div``;

export const KycContentHeaderButton = styled(Button)`
  
  &.ant-btn {
    background: none;
    border: none;
    border-radius: 5px;
    color: ${(props) => props.theme.colors.white};
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    display: flex;
    align-items: center;
    height: auto;
    padding: 12px 27px;
    text-transform: uppercase;
    width: ${(props) => props.width && `calc(${props.width} - 16px)`};
    text-align: ${(props) => props.textalign};
    
    ${(props) => props.textalign && css `
      span {
        width: 100%;
      }
    `}
    
    svg {
      margin-right: 10px;
    }
    
    &:hover, &:focus {
      background: none;
      border: none;
      color: ${(props) => props.theme.colors.white};
    }
  }
`;

export const KycContentFooterWrapper = styled.div`
  position: absolute;
  bottom: 20px;
  left: 47px;
  right: 47px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const KycFooterNextButton = styled(Button)`
  margin-left: auto;
  position: relative;
  
  &.ant-btn {
    color: ${(props) => props.theme.colors.white};
    background: none;
    display: flex;
    align-items: center;
    border: none;
    padding: 10px 0;
    height: auto;
    width: 150px;
    text-align: right;
    
    svg {
      width: 36px;
    }
    
    span {
      width: 100%;
    }
    
    &:hover, &:focus {
      background: none;
      color: ${(props) => props.theme.colors.white};
      border-color: ${(props) => props.theme.colors.white};
    }
  }
`;

export const KycFooterBackButton = styled(Button)`
  &.ant-btn {
    color: ${(props) => props.theme.colors.white};
    background: none;
    display: flex;
    align-items: center;
    border: none;
    position: relative;
    padding: 10px 0;
    height: auto;
    width: 150px;
    text-align: left;
    
    svg {
      width: 36px;
    }
    
    span {
      width: 100%;
    }
    
    &:hover, &:focus {
      background: none;
      color: ${(props) => props.theme.colors.white};
      border-color: ${(props) => props.theme.colors.white};
    }
  }
`;

export const KycStepWrapper = styled.div`
  visibility: ${(props) => props.level === props.currentLevel ? 'visible' : 'hidden'};
  transform: ${(props) => props.level === props.currentLevel ? 'scale(1)' : 'scale(0)'};
  position: ${(props) => props.level === props.currentLevel ? 'relative' : 'absolute'};
  left: ${(props) => props.level === props.currentLevel ? '0' : '-100%'};
`;

export const BeneficiariesWrapper = styled.div`
  border: 1px solid ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.white};
  padding: 16px 26px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
`;

export const BeneficiariesTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  
  svg {
    margin-left: 16px;
  }
`;

export const BeneficiariesCardTitle = styled.div`
  font-weight: 900;
  margin-bottom: 6px;
`;

export const BeneficiariesCardSubTitle = styled.div`
  font-size: 14px;
`;

export const BeneficiariesCardRow = styled(Row)`
  margin-bottom: 20px;
`;

export const KycUploadButtonWrapper = styled.div``;

export const KycUploadButton = styled(Button)`
  &.ant-btn {
    color: ${(props) => props.theme.colors.white};
    background: none;
    display: flex;
    align-items: center;
    border: none;
    position: ${(props) => props.position ? 'absolute' : 'relative'};
    top: 50%;
    transform: ${(props) => props.position ? 'translateY(-50%)' : 'none'};
    height: auto;
    padding: 12px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    width: ${(props) => props.width ? '100%' : 'auto'};
    
    svg {
      margin-right: 4px;
      
      @media (min-width:1440px) {
        margin-right: 20px;
      }
    }
    
    &:hover, &:focus {
      background: none;
      color: ${(props) => props.theme.colors.white};
    }
  }
`;

export const KycUploadBlock = styled.div`
  position: relative;
  padding: 28px 25px;
  border: 1px solid ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.white};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
`;

export const KycUploadBlockTitle = styled.div`
  position: relative;
  margin-bottom: 30px;
`;

export const KycUploadModalTitle = styled.div`
  text-align: center;
  color: ${(props) => props.theme.colors.darkLight};
  margin-bottom: 20px;
`;

export const KycUploadFooterTitle = styled.div`
  color: ${(props) => props.theme.colors.darkLight};
  text-align: center;
`;

export const KycFinalStepTitle = styled.div`
  color: ${(props) => props.theme.colors.white};
  font-size: 24px;
  text-align: center;
  margin-bottom: 76px;
`;

export const KycFinalStepSubTitle = styled.div`
  color: ${(props) => props.theme.colors.white};
  font-size: 18px;
  text-align: center;
  width: 60%;
  margin: 0 auto 110px;
`;

export const KycFinalStepButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 60%;
  margin: 0 auto;
`;
