/**
 * @file
 * Contains Auth Links Component.
 */
import React from 'react';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { AuthLinkItem, AuthLinksWrapper } from './Auth.style';
import { getBrandData } from '_common/utils/brandData.util';

const AuthLinks = (props) => {
  const { t } = props;

  const {
    mainLandingLink,
    privacyPolicyLink,
    termsAndConditionsLink,
  } = getBrandData(process.env.REACT_APP_THEME);

  return (
    <AuthLinksWrapper className="auth-links">
      {!!mainLandingLink && !mainLandingLink?.hidden && (
        <AuthLinkItem href={mainLandingLink.url} target='_blanc'>
          {t(mainLandingLink.title)}
        </AuthLinkItem>
      )}
      {!!privacyPolicyLink && !privacyPolicyLink?.hidden && (
        <AuthLinkItem href={privacyPolicyLink.url} target='_blanc'>
          {t(privacyPolicyLink.title)}
        </AuthLinkItem>
      )}
      {!!termsAndConditionsLink && !termsAndConditionsLink?.hidden && (
        <AuthLinkItem href={termsAndConditionsLink.url} target='_blanc'>
          {t(termsAndConditionsLink.title)}
        </AuthLinkItem>
      )}
    </AuthLinksWrapper>
  );
};

export default compose(withTranslation())(AuthLinks);
