/**
 * @file
 * Contains Date Picker Component styles.
 */
import React from 'react';
import styled, { css } from 'styled-components';
import { DatePicker } from 'antd';
const { RangePicker } = DatePicker;

export const RangePickerEl = styled(({ ...rest }) => <RangePicker {...rest} />)`
  &.ant-picker {
    padding: ${(props) => props?.small ? '0 14px 0 36px' : '0 14px 0 54px'};
    width: 100%;
    background: none;
    border-radius: 5px;
    border-color: ${(props) => props.theme.colors.darkGrey};
    background: ${(props) => props.theme.colors.extraLightGrey};
    line-height: ${(props) => props?.small ? '28px' : '48px'};
    height: ${(props) => props?.small ? '28px' : '48px'};
    
    .ant-picker-input:first-child {
      cursor: pointer;
    }
    
    ${(props) => props.todayDate && css `
      .ant-picker-input {
        font-size: 12px;
        display: none;
        padding-left: 0;
        
        @media (min-width: 1140px) {
          padding-left: 2px;
        }
        
        &:first-child {
          display: flex;
          align-items: center;
          
          & input {
            text-align: left;
            font-size: 12px;
          }
          
          &:before {
            content: 'Today,';
            color: ${(props) => props.theme.colors.dark};
            margin-right: 4px;
            font-size: 12px;
            cursor: pointer;
          }
        }
        
        &:last-child {
          display: none;
        }
      }
     
      .ant-picker-range-separator {
        display: none;
      }
    `};
  }
  
  &.ant-picker:hover, .ant-picker-focused {
    box-shadow: none;
    border-color: ${(props) => props.theme.colors.darkGrey};
  }

  &.ant-picker-focused {
    border-color: ${(props) => props.theme.colors.darkGrey};
    box-shadow: none;
  }

  &.ant-picker-range {
    .ant-picker-clear {
      right: 5px;
    }
  }

  .ant-picker-input {
    
    &:last-child {
      display: none;
    }
    
    input {
      font-size: 12px;
      color: ${(props) => props.theme.colors.mainColorDark};
      cursor: pointer;
      text-align: center;
      line-height: 24px;

      &::placeholder {
        color: ${(props) => props.theme.colors.darkGrey};
        font-weight: 500;
      }
      &::-webkit-input-placeholder {
        color: ${(props) => props.theme.colors.darkGrey};
      }
      &:-moz-placeholder {
        color: ${(props) => props.theme.colors.darkGrey};
      }
      &::-moz-placeholder {
        color: ${(props) => props.theme.colors.darkGrey};
      }
      &:-ms-input-placeholder {
        color: ${(props) => props.theme.colors.darkGrey};
      }

    }
  }

  .ant-picker-range-separator {
    position: relative;
    
    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 2px;
      width: 6px;
      background: ${(props) => props.theme.colors.darkGrey};
    }
  }

  .ant-picker-suffix {
    display: none;
  }

  .ant-picker-panel-container {
    padding: 10px;
  }

  .ant-picker-active-bar {
    display: none;
  }
`;

export const DatePickerEl = styled(({ ...rest }) => <DatePicker {...rest} />)`
  &.ant-picker {
    padding: 7px 14px 7px 20px;
    width: 100%;
    display: flex;
    height: 48px;
    background: none;
    border-radius: 5px;
    border-color: ${(props) => props.theme.colors.darkGrey};
    background: ${(props) => props.value ? props.theme.colors.mainColorLight : 'transparent'};

    @media (min-width: 1140px) {
      padding: 7px 14px 7px 34px;
    }

    .ant-picker-input {
      &:first-child {
        cursor: pointer;
      }
    }
  }
    
  
  &.ant-picker:hover, .ant-picker-focused {
    box-shadow: none;
    border-color: ${(props) => props.theme.colors.darkGrey};
  }

  &.ant-picker-focused {
    border-color: ${(props) => props.theme.colors.darkGrey};
    box-shadow: none;
  }

  .ant-picker-range-separator {
    position: relative;
    
    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 2px;
      width: 6px;
      background: ${(props) => props.theme.colors.mainColor};
    }
  }

  .ant-picker-suffix {
    display: none;
  }
`;

export const DatePickerWrapper = styled.div`
  position: relative;

  ${(props) =>
    props.icon && css
    `
      &:before {
        content: '';
        display: block;
        position: absolute;
        width: 12px;
        height: 12px;
        top: 50%;
        left: 4px;
        transform: translateY(-50%);
        background: transparent url(${props.icon});
        background-size: 100%;
        z-index: 2;
        cursor: pointer;
        
        @media (min-width: 1140px) {
          left: 8px;
          width: 20px;
          height: 20px;
        }
      }`}
`;

export const IconWrapper = styled.div`
  svg {
    position: absolute;
    top: 50%;
    left:  ${(props) => props?.small ? '10px' : '14px'};
    width: ${(props) => props?.small ? '15px' : '25px'};
    transform: translateY(-50%);
    z-index: 9;
  }
`;
