/**
 * @file
 * Contains Permissions Gate.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import usePermissions from '_common/hooks/usePermissions.hook';

const PermissionsGate = ({
  children,
  scopes,
}) => {
  /**
   * Access flag.
   */
  const [accessFlag] = usePermissions(scopes);

  if (accessFlag) return <>{children}</>;

  return null;
};

PermissionsGate.propTypes = {
  scopes: PropTypes.array.isRequired,
};

PermissionsGate.defaultProps = {
  scopes: [],
};

export default compose(inject('profileStore'))(observer(PermissionsGate));
