/**
 * @file
 * Contain Table Element Styles.
 */
import styled from 'styled-components';
import { Table } from 'antd';

export const TableElement = styled(Table)`
  .ant-table {
    table {
      width: 100% !important;
    }

    .ant-table-expanded-row-fixed {
      margin: 0 !important;
    }
	  
	  .ant-table-cell-row-hover {
		  background: transparent;
	  }
  }

  .ant-table-cell {
    padding: 3px;
    background: ${(props) => props.theme.colors.extraLightGrey};
  }
  
  .ant-table-tbody .ant-table-row:not(.ant-table-expanded-row) .ant-table-cell {
    &:nth-last-child(n-1) {
      border-top: 1px solid ${(props) => props.theme.colors.grey} !important;
    }
  }
  
  .ant-table-tbody > tr > th {
    word-wrap: break-word;
    word-break: break-all;
  }

  .ant-table-thead {
    tr {
      &:first-child {
        border-top-left-radius: 5px !important;
        border-top-right-radius: 5px !important;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
      }
      
      th {
        background: ${(props) => props.theme.colors.dark};
        height: 28px;
        color: ${(props) => props.theme.colors.white};
        font-size: 10px;
        padding: 0;
        font-weight: 700;
        text-transform: uppercase;
        text-align: center;

        @media (min-width: 1400px) {
          font-size: 12px;
        }

        &:first-child {
          border-top-left-radius: 5px !important;
          border-bottom-left-radius: 5px;
        }

        &:last-child {
          border-top-right-radius: 5px !important;
          border-bottom-right-radius: 5px;
        }

        & > div {
          padding: 0 4px;
          height: 40px;
          display: flex;
          justify-content: center;
                    
          .ant-table-column-sorters {
            color: ${(props) => props.theme.colors.white};
            font-size: 12px;
            width: 100%;
            padding: 0;
            display: flex;
            justify-content: center;

            span {
              font-size: 10px;
              text-transform: uppercase;
              font-weight: 800;
              text-align: left;

              @media (min-width: 1400px) {
                font-size: 12px;
              }
            }
          }
        }
        
        :hover {
          background: ${(props) => props.theme.colors.mainColor} !important;
        }
      }
    }
  }
  
  .ant-table-thead > tr > th {
    &::before {
      content: '';
      position: absolute;
      right: 0;
      top: 25%;
      bottom: 25%;
      width: 1px;
      background-color: ${(props) => props.theme.colors.grey} !important;
    }

    &:last-child {
      &::before {
        display: none;
      }
    }
  }

 .ant-table-thead tr th > div {
   text-align: center;
   padding: 0 5px;
 }

  .ant-table td {
    &::before {
      content: '';
      position: absolute;
      right: 0;
      top: 25%;
      bottom: 25%;
      width: 1px;
      background-color: ${(props) => props.theme.colors.grey};
    }

    &:last-child {
      &::before {
        content: '';
        background-color: ${(props) => props.theme.colors.extraLightGrey} !important;
      }
    }
  }
  
  tbody.ant-table-tbody {
    tr {
      &:last-child > td {
        border-bottom: 1px solid ${(props) => props.theme.colors.grey};
      } 
      
      &.ant-table-row {
        &.table-row-light {
          background: ${(props) => props.theme.colors.extraLightGrey};
        }
        &.table-row-dark {
          background: ${(props) => props.theme.colors.grey};
        }
      }
      &.ant-table-expanded-row {
        .ant-table-cell{
          &:first-child {
            padding: 0 10px !important;
          }
        }

        & > td.ant-table-cell {
          position: relative;
          border-top-right-radius: 0 !important;
          border-top-left-radius: 0 !important;
          
          &:before {
            content: '';
            position: absolute;
            top: -5px;
            left: 0;
            width: 100%;
            height: 10px;
            ${(props) => props.theme.colors.extraLightGrey};
          }
        }

        &.expand-row-light {
          background: ${(props) => props.theme.colors.extraLightGrey};
        }
        &.expand-row-dark {
          background: ${(props) => props.theme.colors.grey};
          & > td.ant-table-cell {
            &:before {
              background: ${(props) => props.theme.colors.grey};
            }
          }
        }

        tr > td {
          border-left: none;
          border-right: none;
          border-bottom: 1px solid ${(props) => props.theme.colors.dark};
        }
      }
      
      td {
        text-align: center;
        font-size: 12px;
        border: none;
        
        &.ant-table-cell-fix-left {
          background: rgb(233, 231, 231);
        }
        
        &.name-column {
          padding-left: 50px;
        }

        &:first-child {
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
          border-left: 1px solid ${(props) => props.theme.colors.grey};
        }
        &:last-child {
          border-top-right-radius: 5px !important;
          border-bottom-right-radius: 5px;
          border-right: 1px solid ${(props) => props.theme.colors.grey};
        }
      }
    }
  }
  
  .ant-table-footer,
  .ant-table-summary {
    background: ${(props) => props.theme.colors.darkLight};
    width: 100%;

    .ant-table-cell {
      background: ${(props) => props.theme.colors.darkLight};
      font-size: 12px;
    }
  }

  .ant-table-body {
    padding: 0 !important;
    margin: 0 -10px 0 0 !important;

    &::-webkit-scrollbar {
      width: 10px;
      border-radius: 10px;
      background: rgba(255,255,255,0);
    }

    &::-webkit-scrollbar-thumb {
      background-color: ${(props) => props.theme.colors.darkGrey};
      border-radius: 10px;
      border: 2px solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
    }

    &::-webkit-scrollbar-track {
      background: rgba(255,255,255,0.1);
      display: none;
    }
  }
`;
