/**
 * @file
 * Contains Questionnaire Store.
 */

import KYCService from '_common/services/kyc.service';
import { action, computed, observable } from 'mobx';

class KycStore {
  /**
   * Data Is Loading.
   */
  @observable
  isLoading = false;

  /**
   * Get Data Is Loading.
   */
  @computed
  get getIsLoading() {
    return this.isLoading;
  }

  /**
   * Set is table loading.
   * @param {boolean} status.
   * Status flag.
   */
  @action
  setIsLoading = (status) => {
    this.isLoading = status;
  };

  /**
   * Get Profile Questionnaire Data.
   * @param {string} token.
   * User Token.
   */
  @action
  getProfileQuestionnaireDataAction = async (token) => {
    try {
      return await KYCService.getProfileQuestionnaireData(token);
    } catch (e) {
      throw new Error(e);
    }
  };

  /**
   * Post Profile Questionnaire Data.
   * @param {object} data.
   * User Data.
   * @param {string} token.
   * User Token.
   */
  @action
  postProfileQuestionnaireDataAction = async (data, token) => {
    try {
      return await KYCService.postProfileQuestionnaireData(data, token);
    } catch (e) {
      throw new Error(e);
    }
  };
}

export default KycStore;
