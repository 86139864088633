/**
 * @file
 * Contains Public route component.
 *
 *  * Public route contains next attributes:
 *  # exact {boolean} - path matches exactly
 *  # component {any} - component
 */

import React from 'react';
import { Route } from 'react-router-dom';
import Loader from '_common/components/Loader/Loader';
import PropTypes from 'prop-types';
import links from '_common/routes/urls';
import { Redirect } from 'react-router';
import { useWhoAmI } from '_common/hooks/useWhoAmI';

const PublicRoute = ({ component: Component, ...rest }) => {
  /**
   * This query checks if a user has an active session
   */
  const { isSuccess, isLoading, isError } = useWhoAmI();

  if (isLoading) {
    return <Loader />;
  }

  /**
   * If user is not authenticated, we show the public page
   */
  if (isError) {
    return <Route exact {...rest} render={(p) => <Component {...p} />} />;
  }

  /**
   * If user is authenticated, we redirect to the dashboard
   */
  if (isSuccess) {
    return <Redirect to={links.dashboard} />;
  }
};

PublicRoute.propTypes = {
  exact: PropTypes.bool.isRequired,
  path: PropTypes.string.isRequired,
};

export default PublicRoute;
