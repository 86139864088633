/**
 * @file
 * Contains No Data Graphic
 */
import styled from 'styled-components';

export const NoDataGraphicWrapper = styled.div`
  color: ${(props) => props.theme.colors.darkGrey};
  text-align: center;
  padding-top: 20px;
  position: relative;
`;

export const NoDataGraphicTitleWrap = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 80px;
`;

export const NoDataGraphicTitle = styled.div`
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 10px;
`;

export const NoDataGraphicSubTitle = styled.div``;

export const NoDataGraphicImgWrap = styled.div`
  overflow: hidden;
`;
