import {
  AuthHandlerWrapper,
  AuthButton,
  AuthSubTitle,
  AuthTextWrapper,
  AuthTitle,
  RegisterButton
} from './AuthHandlerWrapper.style';
import React, {useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import { AUTH_TYPES } from '../../../Auth/Auth.constants';
import themeConfig from '../../../config';

const AuthScreenAction = ({ type, setType, loading = false, noRegBtn = false }) => {
  const { t } = useTranslation();

  const isLogin = type === AUTH_TYPES.login;

  const theme = themeConfig[process.env.REACT_APP_THEME] || themeConfig.DEFAULT;

  const isAPS = process.env.REACT_APP_THEME === 'APS';

  /**
   * Animation State.
   */
  const [animation, setAnimation] = useState(false);

  /**
   * This effect sysnc the state type with query param auth
   */
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.set('type', type);
    const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
    window.history.replaceState({}, '', newUrl);
    return () => {
      window.history.replaceState({}, '', window.location.pathname);
    };
  }, [type]);

  const toggleType = () => {
    if (type === AUTH_TYPES.login) setType(AUTH_TYPES.register);
    if (type === AUTH_TYPES.register) setType(AUTH_TYPES.login);
    setAnimation(true);
  };

  return (
    <AuthHandlerWrapper
      className={animation ? 'width' : ''}
      onAnimationEnd={() => setAnimation(false)}
      showLogin={isLogin}
      background={theme?.assets?.img?.authBg}
    >
      {isLogin && theme?.loginRegScreen?.showHiddenRegButton && !noRegBtn && (
        <RegisterButton
          onClick={toggleType}
        >
          {t('login.button.registration')}
        </RegisterButton>
      )}
      <AuthTextWrapper className={animation ? 'fade' : ''}>
        {isAPS ? (
          <>
            <AuthTitle>{isLogin ? t('login.aps.title') : t('register.aps.title')}</AuthTitle>
            <AuthSubTitle>
              {isLogin ? t('login.aps.subtitle') : t('register.subtitle')}
            </AuthSubTitle>
            <AuthButton
              onClick={!isLogin ? toggleType : () => {
              }}
            >
              {isLogin ? (
                <a href={'https://aps.money/#contact'} target={'_blank'} referrerPolicy={'no-referrer'}>
                  {t('login.aps.button.register')}
                </a>
              ) : 'Login'}
            </AuthButton>
          </>
        ) : (
          <>
            <AuthTitle>{t(isLogin
                ? !!theme?.loginRegScreen?.customLoginTitle ? theme?.loginRegScreen?.customLoginTitle : 'login.title'
                : !!theme?.loginRegScreen?.customRegTitle ? theme?.loginRegScreen?.customRegTitle : 'register.title'
              , { appName: process.env.REACT_APP_NAME })}</AuthTitle>
            <AuthSubTitle>
              {t(isLogin
                ? !!theme?.loginRegScreen?.customLoginSubTitle ? theme?.loginRegScreen?.customLoginSubTitle : 'login.subtitle'
                : !!theme?.loginRegScreen?.customRegSubTitle ? theme?.loginRegScreen?.customRegSubTitle : 'register.subtitle'
              )}
            </AuthSubTitle>
            {isLogin ? (
              <>
                {!theme?.loginRegScreen?.hideContactBtn && (
                  <AuthButton onClick={toggleType}>{t('login.button.register')}</AuthButton>
                )}
              </>
            ) : (
              <AuthButton onClick={toggleType}>
                {t('login.placeholder.login')}
              </AuthButton>
            )}
          </>
        )}
      </AuthTextWrapper>
    </AuthHandlerWrapper>
  )
}

export default AuthScreenAction;
