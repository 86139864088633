/**
 * @file Contains Refund success icon.
 */

import React from 'react';

const RefundSuccessIcon = () => {

	return (
		<svg width="60" height="61" viewBox="0 0 60 61" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M29.5139 60.0141C25.4311 60.0141 21.5943 59.2394 18.0035 57.6899C14.4126 56.1404 11.2891 54.0375 8.63281 51.3813C5.97656 48.725 3.8737 45.6015 2.32422 42.0106C0.77474 38.4198 0 34.583 0 30.5002C0 26.4175 0.77474 22.5807 2.32422 18.9898C3.8737 15.3989 5.97656 12.2754 8.63281 9.61914C11.2891 6.96289 14.4126 4.86003 18.0035 3.31055C21.5943 1.76107 25.4311 0.986328 29.5139 0.986328C32.7112 0.986328 35.7364 1.45363 38.5894 2.38824C41.4424 3.32284 44.0741 4.62637 46.4844 6.29883L42.2049 10.6521C40.3356 9.47157 38.3435 8.54926 36.2283 7.8852C34.1131 7.22114 31.875 6.88911 29.5139 6.88911C22.9716 6.88911 17.4009 9.18873 12.8016 13.788C8.2024 18.3872 5.90278 23.958 5.90278 30.5002C5.90278 37.0425 8.2024 42.6132 12.8016 47.2125C17.4009 51.8117 22.9716 54.1113 29.5139 54.1113C36.0561 54.1113 41.6269 51.8117 46.2261 47.2125C50.8254 42.6132 53.125 37.0425 53.125 30.5002C53.125 29.6148 53.0758 28.7294 52.9774 27.844C52.879 26.9585 52.7315 26.0977 52.5347 25.2615L57.3307 20.4655C57.8718 22.0396 58.2899 23.6628 58.5851 25.3353C58.8802 27.0077 59.0278 28.7294 59.0278 30.5002C59.0278 34.583 58.253 38.4198 56.7036 42.0106C55.1541 45.6015 53.0512 48.725 50.395 51.3813C47.7387 54.0375 44.6152 56.1404 41.0243 57.6899C37.4334 59.2394 33.5966 60.0141 29.5139 60.0141ZM25.3819 44.0766L12.8385 31.5332L16.9705 27.4013L25.3819 35.8127L54.8958 6.22504L59.0278 10.357L25.3819 44.0766Z"
				fill="#00CC70"/>
		</svg>
	)
};

export default RefundSuccessIcon;
