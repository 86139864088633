/**
 * @file
 * Contains navbar reports icon.
 */
import React from 'react';

const NavBarReportsIcon = (props) => {
  const { color } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
      <path
        d="M22.4998 0C21.1212 0 19.9998 1.07181 19.9998 2.38936C19.9998 2.92378 20.1908 3.41264 20.5022 3.81108L16.4249 8.03951C16.2343 7.99485 16.038 7.96454 15.8332 7.96454C15.3214 7.96454 14.8459 8.11331 14.4493 8.36648L11.5418 6.28296C11.6156 6.05788 11.6665 5.82319 11.6665 5.57518C11.6665 4.25763 10.5451 3.18581 9.16657 3.18581C7.78803 3.18581 6.66659 4.25763 6.66659 5.57518C6.66659 5.82319 6.71754 6.05788 6.79131 6.28296L3.88385 8.36648C3.48718 8.11331 3.01174 7.96454 2.49997 7.96454C1.12143 7.96454 0 9.03635 0 10.3539C0 11.6714 1.12143 12.7433 2.49997 12.7433C3.87851 12.7433 4.99995 11.6714 4.99995 10.3539C4.99995 10.1059 4.949 9.87119 4.87522 9.64611L7.78269 7.56259C8.1793 7.81576 8.65479 7.96454 9.16657 7.96454C9.67834 7.96454 10.1538 7.81576 10.5504 7.56259L13.4579 9.64611C13.3841 9.87119 13.3332 10.1059 13.3332 10.3539C13.3332 11.6714 14.4546 12.7433 15.8332 12.7433C17.2117 12.7433 18.3331 11.6714 18.3331 10.3539C18.3331 9.81948 18.1421 9.33061 17.8307 8.93217L21.908 4.70375C22.0986 4.7484 22.2949 4.77872 22.4998 4.77872C23.8783 4.77872 24.9997 3.70691 24.9997 2.38936C24.9997 1.07181 23.8783 0 22.4998 0Z"
        fill={color}/>
      <path
        d="M10.8346 12.7437H7.50129C7.04069 12.7437 6.66797 13.0999 6.66797 13.5401V24.0002H11.6679V13.5401C11.6679 13.0999 11.2952 12.7437 10.8346 12.7437Z"
        fill={color}/>
      <path
        d="M17.4987 15.9287H14.1654C13.7047 15.9287 13.332 16.2849 13.332 16.7252V23.9994H18.332V16.7252C18.332 16.2849 17.9593 15.9287 17.4987 15.9287Z"
        fill={color}/>
      <path
        d="M24.1666 9.55811H20.8333C20.3727 9.55811 20 9.91433 20 10.3546V24.0005H24.9999V10.3546C24.9999 9.91433 24.6272 9.55811 24.1666 9.55811Z"
        fill={color}/>
      <path
        d="M4.16662 17.522H0.833324C0.372718 17.522 0 17.8782 0 18.3184V23.9998H4.99995V18.3184C4.99995 17.8782 4.62723 17.522 4.16662 17.522Z"
        fill={color}/>
    </svg>)
};

export default NavBarReportsIcon;
