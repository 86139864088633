/**
 * Contains filter icons mapping constant.
 */
import React from 'react';
import ApprovedIcon from '_common/assets/renderSvgIcons/ApprovedIcon';
import RejectedIcon from '_common/assets/renderSvgIcons/RejectedIcon';
import PendingIcon from '_common/assets/renderSvgIcons/PendingIcon';
import themeConfig from 'config';

/**
 * App Theme
 */
const { theme } = themeConfig[process.env.REACT_APP_THEME] || themeConfig.DEFAULT;

const STATUS_FILTER_ICONS = {
  canceled: <RejectedIcon color={theme.colors.iconColor} gradientColor={theme.colors.iconGradientColor} />,
  done: <ApprovedIcon color={theme.colors.iconColor} gradientColor={theme.colors.iconGradientColor} />,
  pending: <PendingIcon color={theme.colors.iconColor} gradientColor={theme.colors.iconGradientColor} />,
};

export default STATUS_FILTER_ICONS;
