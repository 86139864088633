/**
 * @file
 * Contains Private route component.
 *
 *  * Private route contains next attributes:
 *  # exact {boolean} - path matches exactly
 *  # path {string} - path
 *  # component {any} - component
 *  # accessScopes {array} - access scopes
 */

import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import Loader from '_common/components/Loader/Loader';
import links from '_common/routes/urls';
import { useWhoAmI } from '_common/hooks/useWhoAmI';
import { inject } from 'mobx-react';
import { useQuery } from '@tanstack/react-query';
import { destroyFreshChat } from '_common/utils/freshChat.util';

const PrivateRoute = ({ profileStore, component: Component, ...rest }) => {
  /**
   * This query verifies that the user has an active session
   */
  const { data, isSuccess, isLoading, isError } = useWhoAmI();

  if(isSuccess) {
    profileStore.setUserData(data);
  }

	const { isSuccess: isAccessDataFetched, isLoading: isAccessDataLoading, isError: isAccessDataError } = useQuery(
		['access'],
		profileStore.setAccessData,
		{
			enabled: isSuccess,
			retry: 0,
		},
	);

	const { isSuccess: isProfileFetched, isLoading: isProfileLoading } = useQuery(
		['profile'],
		profileStore.getProfileDataAction,
		{
			enabled: isAccessDataError,
		},
	);

  if (isError) {
    destroyFreshChat();
    return <Redirect to={links.public.auth} />;
  }

  if (isLoading || (isAccessDataError ? isProfileLoading : isAccessDataLoading)) {
    return <Loader />;
  }

  if (
    (isAccessDataFetched || isProfileFetched) &&
    rest.location?.pathname !== links.public.newPassword &&
    rest.location?.pathname !== links.questionnaire &&
    !profileStore.currentProfilePermissions
  ) {
    return <Redirect to={links.questionnaire} />;
  }

  if (isAccessDataFetched || isProfileFetched) {
    return <Route exact {...rest} render={(p) => <Component {...p} />} />;
  }
};

PrivateRoute.propTypes = {
  exact: PropTypes.bool.isRequired,
  path: PropTypes.string.isRequired,
  accessScopes: PropTypes.array,
};

export default inject('profileStore')(PrivateRoute);
