import React, { useState } from 'react';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import { Form } from 'antd';
import {
  AddNameModalButtonsContainer,
  AddUserInfoSubTitle,
  AddUserInfoTitle,
  AddUserInfoWrapper,
  FormItemWrap
} from './AddUserInfoModal.style';
import ModalElement from '_common/components/Modal/ModalElement';
import FormItemElement from '_common/components/FormItem/FormItemElement';
import InputElement from '_common/components/Input/InputElement';
import ButtonElement from '_common/components/Button/ButtonElement';
import { useWhoAmI } from '_common/hooks/useWhoAmI';

const AddUserInfoModal = (props) => {
  const {
    modalState,
    setModalState,
    onSuccessUpdate,
    profileStore,
    usersStore,
    t
  } = props;

  const { identity: { traits: { email }, id: user_id } } = profileStore.getUserData;

  const [form] = Form.useForm();

  const [userButtonLoading, setUserButtonLoading] = useState(false);
  const [shouldFetch, setShouldFetch] = useState(false);

  useWhoAmI(shouldFetch);

  const onAddNameFormSubmit = async (values) => {
    const { full_name } = values;
    const profileId = profileStore.getSelectedProfileId;
    try {
      if(!!full_name ) {
        setUserButtonLoading(true);
        await usersStore.setUserName({ full_name, email, user_id }, profileId);
        setShouldFetch(true);
        onSuccessUpdate();
      }
    } catch (e) {
      console.error(e);
    } finally {
      setUserButtonLoading(false);
      setModalState(false);
      form.resetFields();
    }
  }

  return (
    <ModalElement
      showModal={modalState}
      closeIcon={false}
      width={395}
      footer={null}
      destroyOnClose={true}
      maskClosable={false}
      className="no-close-icon"
    >
      <AddUserInfoWrapper>
        <AddUserInfoTitle>{t('add.user.info.title')}</AddUserInfoTitle>
        <AddUserInfoSubTitle>{t('add.user.info.sub.title')}</AddUserInfoSubTitle>
      </AddUserInfoWrapper>
      <Form
        form={form}
        onFinish={onAddNameFormSubmit}
        validateTrigger='onSubmit'
      >
        <FormItemWrap>
          <FormItemElement
            name='full_name'
            rules={[
              {
                required: true,
                message: t('input.field.required'),
              },
            ]}
          >
            <InputElement
              design='light'
              placeholder={t('add.user.full.name')}
              border
              small
            />
          </FormItemElement>
        </FormItemWrap>
        <AddNameModalButtonsContainer>
          <ButtonElement
            clear='true'
            onClick={() => {
              setModalState(false);
              form.resetFields();
            }}
          >
            {t('button.cancel')}
          </ButtonElement>
          <ButtonElement
            height={30}
            htmlType="submit"
            loading={userButtonLoading}
          >
            {t('button.save')}
          </ButtonElement>
        </AddNameModalButtonsContainer>
      </Form>
    </ModalElement>
  )
};

export default compose(
  withTranslation(),
  inject('profileStore', 'usersStore')
)(observer(AddUserInfoModal));
