/**
 * @file Contains Users filters component.
 */

import React from 'react';
import { UsersFiltersContainer } from './Users.style';
import { Form } from 'antd';
import { FilterColumn, FiltersRow } from 'globalStyles/globalView.style';
import FormItemElement from '_common/components/FormItem/FormItemElement';
import InputElement from '_common/components/Input/InputElement';
import ButtonElement from '_common/components/Button/ButtonElement';
import { useQueryClient } from '@tanstack/react-query';

const UsersFilters = (props) => {

	const {
		searchUserData,
		setUsersData,
		usersEditPermissionFlag,
		setIsInviteUserModalOpen,
		t,
	} = props;

	const queryClient = useQueryClient();

	const [searchUserForm] = Form.useForm();

	/**
	 * Clear users data.
	 *
	 * @function - clearUsersFilters.
	 */
	const clearUsersFilters = () => {
		searchUserForm.resetFields();
		const { iam_users } = queryClient.getQueryData(['users-data']);
		setUsersData(iam_users);
	};

	return (
		<UsersFiltersContainer>
			<Form
				form={searchUserForm}
				onFinish={(values) => searchUserData(values)}
			>
				<FiltersRow>
					<FilterColumn width={274} pr={6}>
						<FormItemElement
							name="email"
						>
							<InputElement
								placeholder={t('user.search.input.email')}
								allowClear={true}
								border
								small
							/>
						</FormItemElement>
					</FilterColumn>
					<FilterColumn width={274} pr={6}>
						<FormItemElement
							name="name"
						>
							<InputElement
								placeholder={t('user.search.input.name')}
								allowClear={true}
								border
								small
							/>
						</FormItemElement>
					</FilterColumn>
					<FilterColumn width={125} pr={6}>
						<ButtonElement
							clear='true'
							height={30}
							onClick={() => clearUsersFilters()}
						>
							{t('user.button.clear')}
						</ButtonElement>
					</FilterColumn>
					<FilterColumn width={125} pr={6}>
						<ButtonElement
							height={30}
							htmlType="submit"
						>
							{t('user.button.search')}
						</ButtonElement>
					</FilterColumn>
					{usersEditPermissionFlag && (
						<FilterColumn ml={'auto'} width={125} pr={6}>
							<ButtonElement
								onClick={() => setIsInviteUserModalOpen(true)}
								height={30}
							>
								{t('user.button.invite')}
							</ButtonElement>
						</FilterColumn>
					)}
				</FiltersRow>
			</Form>
		</UsersFiltersContainer>
	)
};

export default UsersFilters;
