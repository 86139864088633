/**
 * @file
 * Contains Kyc Service.
 */
import { pureClient } from './apiClient.service';
import { QUESTIONARY } from '../constants/apiEndpoints.constant';

/**
 * Get Profile Questionnaire Data.
 */
const getProfileQuestionnaireData = async (token) => {
  const headers = {
    'X-Authorization': token,
  };

  const response = await pureClient.get(QUESTIONARY, { headers });
  return response.data;
};

/**
 * Post Profile Questionnaire Data.
 */
const postProfileQuestionnaireData = async (data, token) => {
  const headers = {
    'X-Authorization': token,
  };

  const response = await pureClient.put(QUESTIONARY, data, { headers });
  return response;
};

export default {
  getProfileQuestionnaireData,
  postProfileQuestionnaireData,
};
