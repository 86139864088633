/**
 * @file
 * Contains Statistic Component.
 */
import React from 'react';
import { Statistic } from './Statistic.style';

const StatisticElement = (props) => {
  const { ...rest } = props;

  return <Statistic precision={0} {...rest} />;
};

export default StatisticElement;
