/**
 * @file
 * Contains time formatter utils.
 */

import moment from 'moment';
import { endOfDay, startOfDay } from 'date-fns';
import dayjs from 'dayjs';

/**
 * Format time to start of day by default GMT.
 * @param {number || Object} timestamp.
 * Timestamp.
 * @param {string} type.
 * Types: unix or moment object.
 */
export const timeFormatStartOfDay = (timestamp = moment(), type = 'unix') => {
  const utc = moment(timestamp).utc();
  const startOfDay = utc.startOf('day');

  if (type === 'momentObject') {
    return startOfDay.add(1, 'second');
  }

  return startOfDay.unix() * 1000;
};

/**
 * Format time to end of day by default GMT.
 * @param {number || Object} timestamp.
 * Timestamp.
 * @param {string} type.
 * Types: unix or moment object.
 */
export const timeFormatEndOfDay = (timestamp = moment(), type = 'unix') => {
  const utc = moment(timestamp).utc();
  const endOfDay = utc.endOf('day');

  if (type === 'momentObject') {
    return endOfDay;
  }

  return endOfDay.unix() * 1000;
};

/**
 * Format time by timestamp.
 * @param {number} timestamp.
 * Timestamp.
 * @param {string} format.
 * Time format.
 */
export const timeFormatByTimestamp = (timestamp, format = 'DD/MM/YYYY') => {
  let dateStamp = timestamp * 1000;
  if (timestamp?.toString().length === 13) {
    dateStamp = timestamp;
  }
  const date = dayjs(dateStamp);
  return date.format(format);
}

/**
 * Format time to start or end of day by default GMT.
 * @param {number || Object} memoDateObject.
 * Date object.
 * @param {string} day.
 * Part of the day
 */
export const getStartEndOfDayMilliTimestamp = (memoDateObject, day = 'start') => {
  const utc = moment(memoDateObject).utc();
  const ofDay = utc?.[day === 'start' ? 'startOf' : 'endOf']?.('day');
  return ofDay.unix() * 1000;
}

/**
 * Check is selected date is today.
 * @param {object} data.
 * Selected date.
 * @param {string} dateFormat.
 * Today date format.
 */
export const isToday = (data, dateFormat = 'DD MMM') => {
  const selectedDate = Object.entries(data).map((dataItem) => {
    return timeFormatByTimestamp(dataItem[0], dateFormat);
  });

  return selectedDate[0] === selectedDate[selectedDate.length - 1];
};

const addLeadingZero = (number) => {
  return number < 10 ? '0' + number : number;
}

/**
 * Return array of months between two dates.
 * @param {string} startDate.
 * Start date.
 * @param {string} endDate.
 * End date.
 */
export const getMonthsBetween = (startDate, endDate) => {
  let months = [];
  let currentDate = new Date(startDate);
  currentDate.setDate(1);

  while (currentDate < endDate) {
    const monthName = currentDate.toLocaleString('default', { month: 'long' });
    const monthIndex = currentDate.getMonth();
    const year = currentDate.getFullYear();

    const date_from = new Date(year, monthIndex, 1);
    const dateTo = new Date(year, monthIndex + 1, 0);
    months.push({
      label: `${year}-${addLeadingZero(monthIndex + 1)}`,
      date_from,
      date_to: endOfDay(dateTo),
    });
    currentDate.setMonth(currentDate.getMonth() + 1);
  }

  return months.reverse();
}

/**
 * Return array of months between two dates.
 * @param {string} monthDate.
 * Selected month.
 */
export const getDaysOfMonth = (monthDate) => {
  let currentDate = new Date(monthDate);
  currentDate.setDate(1);
  const monthIndex = currentDate.getMonth();
  const year = currentDate.getFullYear();
  const numDays = new Date(year, monthIndex + 1, 0).getDate();

  let days = [];
  for (let i = 1; i <= numDays; i++) {
    const showDay = `${year}-${addLeadingZero(monthIndex + 1)}-${addLeadingZero(i)}`;
    days.push({
      label: showDay,
      date_from: startOfDay(new Date(showDay)),
      date_to: endOfDay(new Date(showDay)),
    });
  }
  return days;
}

export const getDateFromIso = (date, format) => {
	return moment(date).format(format);
};
