import React from 'react';
import { Dropdown, Table as AntdTable } from 'antd';
import {
	ReportsTableTitleContainer,
	TableDropdownMenuContainer,
	TableTransactionIdColumn,
	TableTransactionIdColumnContainer,
	TableTransactionIdColumnCopyContainer,
	TableTransactionIdColumnVisibleContainer,
	TransactionRegistryDownloadLink,
  TransactionRegistryDownloadLinkIconWrapper,
  TransactionRegistryDownloadLinkText,
	TransactionRegistryDownloadTitle,
} from '../Reports.style';
import { copyToClipboardUtil } from '_common/utils/copyToClipboard.util';
import SuccessIcon from '_common/assets/renderSvgIcons/Success.icon';
import CopyIcon from '_common/assets/renderSvgIcons/Copy.icon';
import CrossedOutEyeIcon from '_common/assets/renderSvgIcons/CrossedOutEye.icon';
import EyeIcon from '_common/assets/renderSvgIcons/Eye.icon';
import TooltipElement from '_common/components/Tooltip/TooltipElement';
import Utils from '_common/utils';
import ReportTableMenuButton from '_common/components/ReportTableMenuButton/ReportTableMenuButton';
import DownloadIcon from '_common/assets/renderSvgIcons/DownloadIcon';
import { timeFormatByTimestamp } from '_common/utils/timeConvertor.util';
import { compareAB } from '_common/utils/sortingAB.utils';
import themeConfig from 'config';
import { TRANSACTIONS_TABLE_CONFIG } from '_common/constants/reports.constant';
import ReportsStatusRender from 'Reports/ReportsStatusRender';

export const REPORTS_TABLE_COLUMNS_CONFIG = (
	t,
	transactionId,
	setSelectedTransactionInfo,
	isRefundButtonVisible,
	setIsTransactionPartialRefundModalOpen,
	setIsTransactionRefundModalOpen,
	setIsTransactionIdVisible,
	isCopiedToClipboard,
	setIsCopiedToClipboard,
	setTransactionId,
	isTransactionIdVisible,
	downloadRegistryFileHandler,
	reportStore,
) => {

	/**
	 * App Theme
	 */
	const { theme } = themeConfig[process.env.REACT_APP_THEME] || themeConfig.DEFAULT;

	const { dayFormat, weekFormat, monthFormat } = TRANSACTIONS_TABLE_CONFIG;

	return {
		transactions: [
			AntdTable.EXPAND_COLUMN,
			{
				title: t('transaction.table.header.created'),
				dataIndex: 'created_at',
				key: 'created_at',
				width: '90px',
				ellipsis: true,
				defaultSortOrder: 'descend',
				sorter: (a, b) => a?.created_at - b?.created_at,
				render: (text, record) => <span>
          <div>{timeFormatByTimestamp(record.created_at, 'DD/MM/YYYY')}</div>
          <div>{timeFormatByTimestamp(record.created_at, 'HH:mm:ss')}</div>
        </span>,
				order: 1,
				removeColumnsFromCustomizeSettings: true,
			},
			{
				title: t('transaction.table.header.transactionId'),
				dataIndex: 'transaction_id',
				key: 'transaction_id',
				width: isTransactionIdVisible ? '330px' : '190px',
				render: (text, record) => (
					<TableTransactionIdColumn>
						<TableTransactionIdColumnContainer
							isOpen={(isTransactionIdVisible && transactionId === record.transaction_id)}
						>
							<p>
								{record.transaction_id}
							</p>
						</TableTransactionIdColumnContainer>
						<TableTransactionIdColumnCopyContainer
							onClick={() => {
								setTransactionId(record.transaction_id);
								copyToClipboardUtil(record.transaction_id);
								setIsCopiedToClipboard(true);

								setTimeout(() => {
									setIsCopiedToClipboard(false);
								}, 1000)
							}}
						>
							{(isCopiedToClipboard && transactionId === record.transaction_id) ? (
								<SuccessIcon/>
							) : (
								<CopyIcon/>
							)}
						</TableTransactionIdColumnCopyContainer>
						<TableTransactionIdColumnVisibleContainer>
							{(isTransactionIdVisible && transactionId === record.transaction_id) ? (
								<div onClick={() => setIsTransactionIdVisible(false)}>
									<CrossedOutEyeIcon/>
								</div>
							) : (
								<div
									onClick={() => {
										setTransactionId(record.transaction_id);
										setIsTransactionIdVisible(true);
									}}
								>
									<EyeIcon/>
								</div>
							)}
						</TableTransactionIdColumnVisibleContainer>
					</TableTransactionIdColumn>
				),
				order: 2,
			},
			{
				title: t('transaction.table.header.externalId'),
				dataIndex: 'merchant_external_id',
				key: 'merchant_external_id',
				width: '100px',
				order: 3,
			},
			{
				title: t('transaction.table.header.externalPayerId'),
				dataIndex: 'merchant_payer_id',
				key: 'merchant_payer_id',
				width: '100px',
				order: 4,
			},
      {
        title: t('transaction.table.header.status'),
        dataIndex: 'state',
        key: 'state',
        width: '120px',
        render: (_, record) => {
          if (record?.state && typeof record?.state === 'string') {
            return <ReportsStatusRender record={record}/>
          }

          return record?.state || '';
        },
        order: 5,
      },
			{
				title: t('transaction.table.header.method'),
				dataIndex: 'payment_group',
				key: 'payment_group',
				width: '90px',
				render: (text, record) => record.payment_group,
				order: 6,
			},
			{
				title: t('transaction.table.header.card_number'),
				dataIndex: 'card_number',
				key: 'card_number',
				width: '130px',
				render: (text, record) => record?.bank_card?.masked_number || '',
				order: 7,
			},
			{
				title: t('transaction.table.header.cardholder'),
				dataIndex: 'holder',
				key: 'holder',
				width: '120px',
				render: (text, record) => record?.bank_card?.holder || '',
				order: 8,
			},
			{
				title: t('transaction.table.header.payment_country'),
				dataIndex: 'payment_country',
				key: 'payment_country',
				width: '80px',
				render: (text, record) => record?.bank_card?.country || '',
				order: 9,
			},
			{
				title: t('transaction.table.header.type'),
				dataIndex: 'kind',
				key: 'kind',
				width: '65px',
				render: (text, record) => `${record?.kind?.[0].toUpperCase()}${record?.kind?.slice(1)}`,
				order: 10,
			},
			{
				title: t('transaction.table.header.country'),
				dataIndex: 'country',
				key: 'country',
				width: '80px',
				render: (text, record) => {
					return (!!record?.bank_card_country && record?.bank_card_country !== 'bank_card_not_provided_in_fields')
						? record.bank_card_country
						: (record.country === '' || record.country === undefined)
							? ''
							: record.country === 'WWC' ? 'Worldwide' : record.country;
				},
				order: 11,
			},
			{
				title: () => (
					<TooltipElement title={t('transaction.table.footer.amountBody.tooltip')}>
						<ReportsTableTitleContainer>
							{t('transaction.table.footer.amountBody')}
						</ReportsTableTitleContainer>
					</TooltipElement>
				),
				titleList: t('transaction.table.footer.amountBody'),
				showSorterTooltip: false,
				dataIndex: 'amount_body',
				key: 'amount_body',
				width: '88px',
				align: 'center',
				render: (text, record) => {
					const currency = (record?.body_currency || ':').split(':')[1];
					return `${Utils.trimAmount(record.amount_body || record.amount)} ${currency}`
				},
				order: 12,
			},
			{
				title: () => (
					<TooltipElement title={t('transaction.table.header.userFee.tooltip')}>
						<ReportsTableTitleContainer>
							{t('transaction.table.header.userFee')}
						</ReportsTableTitleContainer>
					</TooltipElement>
				),
				titleList: t('transaction.table.header.userFee'),
				showSorterTooltip: false,
				dataIndex: 'user_fee',
				key: 'user_fee',
				width: '58px',
        align: 'center',
				render: (text, record) => {
					const currency = (record?.body_currency || ':').split(':')[1];
					return `${Utils.trimAmount(record?.user_fee)} ${currency}`;
				},
				order: 13,
				hidden: true,
			},
			{
				title: () => (
					<TooltipElement title={t('transaction.table.header.merchantFee.tooltip')}>
						<ReportsTableTitleContainer>
							{t('transaction.table.header.merchantFee')}
						</ReportsTableTitleContainer>
					</TooltipElement>
				),
				titleList: t('transaction.table.header.merchantFee'),
				showSorterTooltip: false,
				dataIndex: 'merchant_fee',
				key: 'merchant_fee',
				width: '88px',
        align: 'center',
				render: (text, record) => {
					const currency = (record?.body_currency || ':').split(':')[1];
					return `${Utils.trimAmount(record?.merchant_fee)} ${currency}`;
				},
				order: 14,
			},
			{
				title: () => (
					<TooltipElement title={t('transaction.table.header.chargedAmount.tooltip')}>
						<ReportsTableTitleContainer>
							{t('transaction.table.header.chargedAmount')}
						</ReportsTableTitleContainer>
					</TooltipElement>
				),
				titleList: t('transaction.table.header.chargedAmount'),
				showSorterTooltip: false,
				dataIndex: 'charged_amount',
				key: 'charged_amount',
				width: '82px',
        align: 'center',
				render: (text, record) => {
					const currency = (record?.charged_currency || ':').split(':')[1];
					return `${Utils.trimAmount(record.charged_amount)} ${currency}`;
				},
				order: 15,
				hidden: true,
			},
			{
				title: () => (
					<TooltipElement title={t('transaction.table.header.creditedAmount.tooltip')}>
						<ReportsTableTitleContainer>
							{t('transaction.table.header.creditedAmount')}
						</ReportsTableTitleContainer>
					</TooltipElement>
				),
				titleList: t('transaction.table.header.creditedAmount'),
				showSorterTooltip: false,
				dataIndex: 'credited_amount',
				key: 'credited_amount',
				width: '82px',
        align: 'center',
				render: (text, record) => {
					const currency = (record?.credited_currency || ':').split(':')[1];
					return `${Utils.trimAmount(record.credited_amount)} ${currency}`;
				},
				order: 16,
			},
			{
				title: '',
				showSorterTooltip: false,
				dataIndex: 'refund',
				key: 'refund',
				width: '82px',
				align: 'center',
				render: (text, record, index) => {
					/**
					 * Transaction table menu items items.
					 */
					const items = [
						...record?.refund_supported ? [{
							label: <div
								onClick={() => setIsTransactionRefundModalOpen(true)}
							>
								{t('transaction.table.menu.item.transactionRefund')}
							</div>,
							key: 'refund',
						}] : [],
						...record?.refund_partial_supported ? [{
							label: <div
								onClick={() => setIsTransactionPartialRefundModalOpen(true)}
							>
								{t('transaction.table.menu.item.transactionPartialRefund')}
							</div>,
							key: 'partial-refund',
						}] : [],
					];

					return (
						isRefundButtonVisible(record) &&
						<Dropdown
							menu={{ items }}
							placement="bottomLeft"
							trigger={['click']}
							onClick={() => setSelectedTransactionInfo(record)}
						>
							<TableDropdownMenuContainer>
								<ReportTableMenuButton darkBorder={false} />
							</TableDropdownMenuContainer>
						</Dropdown>
					)
				},
				order: 17,
				removeColumnsFromCustomizeSettings: true,
			},
		],
		statistic: [
			{
				title: t('transaction.table.footer.date'),
				dataIndex: 'created_at',
				key: 'created_at',
				width: 100,
				sorter: (a, b) => compareAB(b.created_at, a.created_at),
				render: (text, record) => {
					const resultsBy = reportStore.getResultsByValue;
					let format = timeFormatByTimestamp(record.created_at, dayFormat);

					if (resultsBy === 'week') {
						format = timeFormatByTimestamp(record.created_at, weekFormat);
					}

					if (resultsBy === 'month') {
						format = timeFormatByTimestamp(record.created_at, monthFormat);
					}

					return <span>{format}</span>;
				},
			},
			{
				title: t('transaction.table.header.method'),
				dataIndex: 'payment_group',
				key: 'payment_group',
				width: 100,
				sorter: (a, b) => compareAB(b.payment_group, a.payment_group),
				render: (text, record) => record.payment_group,
			},
			{
				title: t('transaction.table.footer.nOfTrans'),
				dataIndex: 'n_transaction',
				key: 'n_transaction',
				width: 100,
				sorter: (a, b) => compareAB(b.n_transaction, a.n_transaction),
			},
			{
				title: t('transaction.table.footer.amountOfPayments'),
				dataIndex: 'amount_received',
				key: 'amount_received',
				width: 100,
				align: 'right',
				sorter: (a, b) => compareAB(b.amount, a.amount),
				render: (text, record) => Utils.trimAmount(record.amount),
			},
			{
				title: t('transaction.table.footer.fee'),
				dataIndex: 'commission_app',
				key: 'commission_app',
				width: 40,
				align: 'right',
				sorter: (a, b) => compareAB(b.commission_app, a.commission_app),
				render: (text, record) => Utils.trimAmount(record.commission_app),
			},
			{
				title: t('transaction.table.footer.averageBill'),
				dataIndex: 'average_bill',
				key: 'average_bill',
				width: 100,
				align: 'right',
				sorter: (a, b) => compareAB(b.average_bill, a.average_bill),
				render: (text, record) => Utils.trimAmount(record.average_bill),
			},
			{
				title: t('transaction.table.footer.conversion'),
				dataIndex: 'conversion',
				key: 'conversion',
				width: 100,
				sorter: (a, b) => compareAB(b.conversion, a.conversion),
			},
			{
				title: t('transaction.table.footer.successfulTransaction'),
				dataIndex: 'successed',
				key: 'successed',
				width: 120,
				sorter: (a, b) => compareAB(b.successed, a.successed),
			},
			{
				title: t('transaction.table.footer.declineTransaction'),
				dataIndex: 'canceled',
				key: 'canceled',
				width: 120,
				sorter: (a, b) => compareAB(b.canceled, a.canceled),
			},
		],
		registry: [
			{
				title: t('transaction.table.registry.register'),
				dataIndex: 'label',
				key: 'label',
				width: '225px',
				render: (text, record) => (
					<TransactionRegistryDownloadTitle>
						{`registry_${record.label}.csv`}
					</TransactionRegistryDownloadTitle>
				),
			},
			{
				title: '',
				dataIndex: 'date_from',
				key: 'date_from',
				render: (text, record) => (
					<TransactionRegistryDownloadLink
						onClick={() => downloadRegistryFileHandler(record)}
					>
						<TransactionRegistryDownloadLinkIconWrapper>
							<DownloadIcon color={theme.colors.mainColor}/>
						</TransactionRegistryDownloadLinkIconWrapper>
						<TransactionRegistryDownloadLinkText>
							{t('transaction.table.registry.download')}
						</TransactionRegistryDownloadLinkText>
					</TransactionRegistryDownloadLink>
				),
			},
		],
		reports: [
			{
				title: t('transaction.table.registry.register'),
				dataIndex: 'label',
				key: 'label',
				width: '225px',
				render: (text, record) => (
					<TransactionRegistryDownloadTitle>
						{`registry_${record.label}.csv`}
					</TransactionRegistryDownloadTitle>
				),
			},
			{
				title: '',
				dataIndex: 'date_from',
				key: 'date_from',
				render: (text, record) => (
					<TransactionRegistryDownloadLink
						onClick={() => downloadRegistryFileHandler(record)}
					>
						<TransactionRegistryDownloadLinkIconWrapper>
							<DownloadIcon color={theme.colors.iconColor}/>
						</TransactionRegistryDownloadLinkIconWrapper>
						<TransactionRegistryDownloadLinkText>
							{t('transaction.table.registry.download')}
						</TransactionRegistryDownloadLinkText>
					</TransactionRegistryDownloadLink>
				),
			},
		],
	};
}
