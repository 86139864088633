/**
 * @file
 * Contains Date Picker Component.
 */
import React from 'react';
import { DatePickerWrapper, DatePickerEl, IconWrapper, RangePickerEl } from './DatePickerElement.style';

const DatePickerElement = (props) => {
  const { todayDate, iconComponent, noRange = false, ...rest } = props;

  return (
    <DatePickerWrapper {...rest}>
      <IconWrapper {...rest}>
        {iconComponent}
      </IconWrapper>
      {noRange ? (
        <DatePickerEl
          inputReadOnly
          separator={false}
          order={false}
          allowClear={false}
          format="DD-M-YYYY"
          popupClassName="custom-date-dropdown"
          {...rest}
        />
      ) : (
        <RangePickerEl
          inputReadOnly
          separator={false}
          todayDate={todayDate}
          order={false}
          allowClear={false}
          format="DD-M-YYYY"
          popupClassName="custom-date-dropdown"
          {...rest}
        />
      )}
    </DatePickerWrapper>
  );
};

export default DatePickerElement;
