/**
 * @file
 * Contains Finance Accounts Component.
 */
import React, { useState } from 'react';
import MainLayout from '_common/layouts/Main.layout';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import Accounts from './Accounts';
import { FinanceAccountsWrapper } from './FinanceAccounts.style';
import AddAccount from './AddAccount';

const FinanceAccountsView = () => {
  /**
   * Add Account State.
   */
  const [addAccount, setAddAccount] = useState(false);

  // Add Account Handler.
  const addAccountHandler = () => {
    setAddAccount(!addAccount);
  };

  // TODO Fetch Accounts
  const accounts = [
    {
      type: 'bank',
      name: 'name of bank',
      value: 103412321155,
      currency: 'EUR',
    },
    {
      type: 'crypto',
      name: 'crypto name',
      value: 1034123213215,
      currency: 'BTC',
    },
    {
      type: 'bank',
      name: 'name of bank',
      value: 101232132155,
      currency: 'EUR',
    },
  ];

  return (
    <MainLayout>
      <FinanceAccountsWrapper>
        {addAccount ? (
          <AddAccount addAccountHandler={addAccountHandler} addAccount={addAccount} />
        ) : (
          <Accounts addAccountHandler={addAccountHandler} addAccount={addAccount} accounts={accounts} />
        )}
      </FinanceAccountsWrapper>
    </MainLayout>
  );
};

export default compose(withTranslation())(FinanceAccountsView);
