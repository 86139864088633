/**
 * @file
 * Contains No Data Diagram Styles,
 */
import styled from 'styled-components';

export const NoDataDiagramWrap = styled.div`
  color: ${(props) => props.theme.colors.darkGrey};
  text-align: center;
  padding-top: 20px;
`;

export const NoDataTitleWrap = styled.div`
  margin-bottom: 26px;
`;

export const NoDataTitle = styled.div`
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 10px;
`;

export const NoDataSubTitle = styled.div`
  font-size: 14px;
`;

export const NoDataImgWrap = styled.div``;
