/**
 * @file
 * Contains utilities export.
 */

import { getLanguage } from './languageDetector.util';
import { compareAB } from './sortingAB.utils';
import { trimNumber, formatNumber, trimAmount } from './number.util';
import {
  timeFormatStartOfDay,
  timeFormatEndOfDay,
  timeFormatByTimestamp,
  getStartEndOfDayMilliTimestamp,
  isToday,
  getMonthsBetween,
  getDaysOfMonth,
} from './timeConvertor.util';
import { roundNumberLow, roundNumberHigh } from './roundNumber.util';
import { calculatePercent } from './calculatePercent.util';
import { getBrandData } from './brandData.util';
import { debounceUtil } from './debounce.util';
import { validateAmountUtil } from './validateAmount.util';
import { destroyFreshChat } from './freshChat.util';

export default {
  getLanguage,
  compareAB,
  trimNumber,
  formatNumber,
  timeFormatStartOfDay,
  timeFormatEndOfDay,
  timeFormatByTimestamp,
  getStartEndOfDayMilliTimestamp,
  isToday,
  roundNumberLow,
  roundNumberHigh,
  calculatePercent,
  getBrandData,
  getMonthsBetween,
  getDaysOfMonth,
  trimAmount,
	debounceUtil,
	validateAmountUtil,
  destroyFreshChat,
};
