/**
 * @file
 * Contains navbar users icon.
 */
import React from 'react';

const NavBarUsersIcon = (props) => {
	const { color } = props;

	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_1924_697)">
				<path
					d="M7.5 13C6.60998 13 5.73995 12.7361 4.99993 12.2416C4.25991 11.7472 3.68314 11.0443 3.34254 10.2221C3.00195 9.39981 2.91283 8.49501 3.08647 7.6221C3.2601 6.74918 3.68868 5.94736 4.31802 5.31802C4.94736 4.68869 5.74918 4.2601 6.62209 4.08647C7.49501 3.91284 8.39981 4.00195 9.22208 4.34254C10.0443 4.68314 10.7471 5.25991 11.2416 5.99994C11.7361 6.73996 12 7.60999 12 8.5C11.9987 9.69307 11.5241 10.8369 10.6805 11.6805C9.83689 12.5241 8.69307 12.9987 7.5 13ZM15 20C14.9984 18.6744 14.4711 17.4036 13.5338 16.4662C12.5964 15.5289 11.3256 15.0016 10 15H5C3.67441 15.0016 2.40356 15.5289 1.46622 16.4662C0.528882 17.4036 0.00158786 18.6744 0 20L0 24H15V20ZM17.5 9C16.61 9 15.74 8.73608 14.9999 8.24162C14.2599 7.74715 13.6831 7.04435 13.3425 6.22208C13.0019 5.39981 12.9128 4.49501 13.0865 3.6221C13.2601 2.74918 13.6887 1.94736 14.318 1.31802C14.9474 0.688685 15.7492 0.260102 16.6221 0.0864683C17.495 -0.0871652 18.3998 0.00194979 19.2221 0.342544C20.0443 0.683139 20.7471 1.25991 21.2416 1.99994C21.7361 2.73996 22 3.60999 22 4.5C21.9987 5.69307 21.5241 6.83689 20.6805 7.68052C19.8369 8.52415 18.6931 8.99868 17.5 9ZM19 11H14C13.8245 11.0081 13.6496 11.0257 13.476 11.053C13.1124 11.8943 12.5754 12.6494 11.9 13.269C13.3663 13.6839 14.6574 14.5654 15.5777 15.78C16.498 16.9946 16.9973 18.4761 17 20H24V16C23.9984 14.6744 23.4711 13.4036 22.5338 12.4662C21.5964 11.5289 20.3256 11.0016 19 11Z"
					fill={color ? color : '#A4A6B3'}/>
			</g>
			<defs>
				<clipPath id="clip0_1924_697">
					<rect width="24" height="24" fill={color ? color : '#A4A6B3'}/>
				</clipPath>
			</defs>
		</svg>
	)
};

export default NavBarUsersIcon;
